import React,{useState, useEffect} from 'react';
import TemplateOrder from './TemplateOrder';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { useHistory, Link } from "react-router-dom";
import ProductEmpty from '../../img/No Transaction.svg';
import PhotoTemplate from '../../img/broken-256.png';
import moment from 'moment';
import 'moment/locale/id'
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PaginationData from '../../components/Pagination';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    hidden: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
          },
    },
    fsizeBox:{
        fontSize:'13px',
        [theme.breakpoints.down('md')]: {
            fontSize:'12px',
        },
    },
    statusProd:{
        width:'50px', 
        margin:'10px 10px 10px 60px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    jmlProd:{
        width:'50px', 
        margin:'10px'
    },
    hrgProd:{
        width:'50px', 
        margin:'10px 10px 10px 17px'
    },
    variantProd:{
        width:'50px', 
        margin:'10px 10px 10px 57px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    desktopOnly:{
        display: 'block',
        [theme.breakpoints.down('md')]: {
          display: 'none !important',
        },
    },
    mobileOnlywthStyle:{
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
    },
    mobileOnly:{
        display: 'flex',
        width:'100%',
        justifyContent:'space-between',
        borderBottom: '1px solid #ddd',
        padding: '10px 15px',
        alignItems:'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    boxMyOrder :{
        margin:'60px 5px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 0px'
        },
    },
    BoxButton:{       
        [theme.breakpoints.up('md')]: {
            display:'flex',
            alignItems:'center'
        },
    },
    btnAction:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'46%',
        }
    },
    btnActionDelivery:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        fontSize:'10px',
        [theme.breakpoints.down('md')]: {
            width:'25%',
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 960,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        width:'100%',
        objectFit:'cover',
        maxHeight: '100px',
        height:'100%'
      },
    subtitle1:{
        margin:'0px 6px 10px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'5px 7px',
            height: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }
      },
  }));

export default function PurchaseOrder(){
    const classes = useStyles();
    const [dataIdProduct, setDataIdProduct]= useState();
    const [statusProduct , setStatusProduct] = useState();
    const [dataPurchased, setDataPurchased] = useState([]);

    const history = useHistory();

    const MySwal = withReactContent(Swal);

    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(15);  
    const [metaTotal , setMetaTotal] = useState();

    useEffect(() => {
        getDataPagingProduct();
        // getDataPaging();
      }, [currentPage]);
      
      const getDataPagingProduct = () =>{
        axios.get(`${baseUrl}/order/purchased?page=${currentPage}`, {
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = dataResponse.data;
              const dataTotal = dataResponse.total;
              console.log(dataResponse);
              setMetaTotal(dataTotal); 
              setDataPurchased(dataOrderProducts);
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)             
              })
              
            });
      }

    
    const DetailOrder = (uid) =>{
        history.push('/detail/transaksi',{params: uid})
    }


    const confirmCancelOrder = async (uid) =>{
        const { value: reason } = await MySwal.fire({
            title: 'Masukan Alasan',
            input: 'text',
            inputPlaceholder: 'Alasan Anda Membatalkan Order ini',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                return 'Kamu harus mengetikan alasan!'
                }
            }
          })
          
          if (reason) {
            // MySwal.fire(`Alasan Pembatalan: ${reason}`)
            axios.post(`${baseUrl}/order/${uid}/cancel`, { reason: reason }, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            .then((response) => {          
                const dataResponse = response.data;
                
                MySwal.fire({
                    icon: 'success',
                    title: "Pesanan telah dibatalkan"
                }).then((result) => {
                    window.location.reload();
                });
            });
          }
      }
     
    const imageDefaultProduct = ProductEmpty.toString();
    const imageDefault = PhotoTemplate.toString();

    return(
        <TemplateOrder>
        <Box width="100%" style={{padding:'10px 10px'}}>
        <div>
            {dataPurchased.length > 0 ?
            <div>
                {dataPurchased && dataPurchased.map((prc, dsx)=>{
                    return(
                        <List key={dsx}>
                            <Paper>
                            <div className={classes.mobileOnly}>
                                    <div >
                                        <div style={{display: 'flex',alignItems: 'center'}}>
                                            <div style={{color: '#ad3322'}}><LocalMallIcon/></div> 
                                            <div style={{margin:'0 10px'}}>
                                                Belanja
                                                <div>
                                                    <span style={{fontSize:'11px'}}> {moment(prc.date).format('LLLL')} </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <span style={{textTransform:'capitalize', background:'#efefef', padding:'0 10px', borderRadius:'5px'}}>{prc.status}</span>
                                    </div>
                            </div>
                            
                            <div className={classes.paper}>                                    
                                <Grid container spacing={2} xs={12} md={12}>                                        
                                    <Grid item xs={4} md={2}>
                                        <img className={classes.img} alt="complex" src={prc.photo === null ? imageDefault : prc.photo }/>
                                    </Grid>
                                    <Grid item xs={8} sm container style={{alignItems:'center'}}>
                                        <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                            {prc.invoice} <span style={{fontSize:'12px'}}>({moment(prc.date).format('LLLL')})</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={5} container direction="column" spacing={2}>
                                            <Grid item xs={12} md={12} className={classes.desktopOnly}>                                       
                                                <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                    <div><span>Status</span><span style={{width:'30px', margin:'10px'}}>:</span><span>{prc.status === "purchased" && "Menunggu Pembayaran"}</span></div>
                                                    <div><span>Jumlah Produk</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{prc.total_qty} pcs</span></div>
                                                    <div><span>Total Tagihan</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prc.total_amount)}</span></div>
                                                </Typography>                                   
                                            </Grid>

                                            <Grid item xs className={classes.mobileOnlywthStyle}>                                       
                                                <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                    <div>
                                                        <div><strong>Status</strong></div>
                                                        <div>{prc.status === "purchased" && "Menunggu Pembayaran"}</div>
                                                    </div>
                                                    <div>
                                                        <div><strong>Jumlah Produk</strong></div>
                                                        <div>{prc.total_qty} pcs</div>
                                                    </div>
                                                    <div>
                                                        <div><strong>Total Tagihan</strong></div>
                                                        <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prc.total_amount)}</div>
                                                    </div>
                                                </Typography>                                   
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid item xs={12} md={7} container direction="column" spacing={1}>
                                        
                                        <Typography variant="body2" gutterBottom  className={classes.desktopOnly}>
                                            <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                            <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                <span>Batas Pembayaran</span>
                                                <span style={{margin:'0 10px'}}>:</span>
                                                <span> {moment(prc.payment.expired_at).format('LLL')} </span>
                                            </div>                                                     
                                            </Typography>
                                            
                                            <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                    <span>Metode Pembayaran</span>
                                                    <span style={{margin:'0 10px'}}>:</span> 
                                                    <span style={{textTransform:'capitalize'}}>{prc.payment.type === "bank_transfer" && <div>Virtual Account <span style={{textTransform:'uppercase'}}>{prc.payment.bank}</span></div>} {prc.payment.type === "cstore" && <span>{prc.payment.store}</span>} {prc.payment.type === "gopay" && <span>GOPAY</span>}</span>
                                                </div>  
                                               
                                            </Typography>
                                            {prc.payment.type === "bank_transfer" && prc.payment.bank !== 'mandiri' ?
                                            <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                    <span>No Virtual Account</span>
                                                    <span style={{margin:'0 10px'}}>:</span> 
                                                    <span style={{textTransform:'capitalize'}}>{prc.payment.va_number}</span>
                                                </div>  
                                              
                                            </Typography>
                                            :
                                            <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                    <span>Nomor Biller </span>
                                                    <span style={{margin:'0 10px'}}>:</span> 
                                                    <span style={{textTransform:'capitalize'}}>{prc.payment.biller_code}{prc.payment.bill_key}</span>
                                                </div>  
                                              
                                            </Typography>
                                            }

                                            {prc.payment.type === "cstore" &&
                                            <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                    <span>Kode Pembayaran</span>
                                                    <span style={{margin:'0 10px'}}>:</span> 
                                                    <span style={{textTransform:'capitalize'}}>{prc.payment.payment_code}</span>
                                                </div>                                                         
                                            </Typography>}
                                        </Typography>
                                        </Grid>                                           
                                    
                                    </Grid>
                                    <Grid item xs={12} md={2} className={classes.BoxButton}>
                                            <div style={{margin:'10px 0 0', textAlign:'center'}}>
                                                <div>
                                                    <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(prc.uid)}>
                                                        Detail Pesanan
                                                    </Button>
                                                    <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(prc.uid)} >
                                                        Batalkan
                                                    </Button>
                                                </div>                                                 
                                            </div>
                                        </Grid>

                                        
                                </Grid>
                            </div>
                            </Paper>
                        </List>
                    )
                })}
                <PaginationData 
                    stateChanger={setcurrentPage} 
                    current={currentPage} 
                    limit={itemsPerPage} 
                    total_page={metaTotal} 
                    loadData={getDataPagingProduct}
                    />
            </div> 
            : 
            <div style={{textAlign:'center'}}>
                <img src={imageDefaultProduct} height="300"/>
                <p>Tidak ada transaksi</p>
            </div>
            } 
      
            
      </div>
        
        </Box>
    </TemplateOrder>
    )
}

