import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { baseUrl } from 'utils/index';

const useStyles = makeStyles((theme) => ({
    MainPage:{
        margin:'2em 0',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
          }
    }
  }));

function BlogDetail() {
    const classes = useStyles();
    const { slug } = useParams();
    const [dataBlog, setDataBlog] = useState([]); 

    useEffect(() => {
        getBlogDetail();
    }, []);
   
    const getBlogDetail = () => {
      axios.get(`${baseUrl}/home/blog/${slug}`)
        .then((response) => {          
            const dataResponse = response.data;
            setDataBlog(dataResponse);
        });
    }
    return (
        <div className={classes.MainPage}>
            <Grid container spacing={3}>
                
                
                <Grid item xs={12} md={9}>
                    <Paper>
                    {dataBlog.image && 
                        <div style={{padding:'10px'}}>
                            <img src= {dataBlog.image.uri} width="100%"/>
                            <h2>{dataBlog.title}</h2>
                            <p>{dataBlog.datetime}</p>
                            <p>{dataBlog.content.replace(/(<p>|<\/p>)/g, "")}</p>
                 
                        </div>
                    }
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper>
                        <div style={{padding:'10px'}}>
                            <Typography>Info Terbaru</Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            
        </div>
    );
}

export default BlogDetail;