import React, {useState, useEffect} from 'react';
import TemplateOrder from './TemplateOrder';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import ProductEmpty from '../../img/No Transaction.svg';
import PhotoTemplate from '../../img/broken-256.png';
import moment from 'moment';
import 'moment/locale/id'
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PaginationData from '../../components/Pagination';
import {baseUrl} from 'utils';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
  fsizeBox: {
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  statusProd: {
    width: '50px',
    margin: '10px 10px 10px 60px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  jmlProd: {
    width: '50px',
    margin: '10px'
  },
  hrgProd: {
    width: '50px',
    margin: '10px 10px 10px 17px'
  },
  variantProd: {
    width: '50px',
    margin: '10px 10px 10px 57px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  productBox: {
    border: '4px solid #ddd',
    width: '100%',
    marginBottom: '10px'
  },
  desktopOnly: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  mobileOnlywthStyle: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
  mobileOnly: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    padding: '10px 15px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  boxMyOrder: {
    margin: '60px 5px 0',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 0px'
    },
  },
  BoxButton: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
  },
  btnAction: {
    marginBottom: '10px',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '46%',
    }
  },
  btnActionDelivery: {
    marginBottom: '10px',
    marginRight: '10px',
    width: '100%',
    fontSize: '10px',
    [theme.breakpoints.down('md')]: {
      width: '25%',
    }
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 960,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  image: {
    width: 128,
    height: 100,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
    maxHeight: '100px',
    height: '100%'
  },
  subtitle1: {
    margin: '0px 6px 10px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 7px',
      height: '30px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    }
  },
}));

export default function PurchaseOrder() {
  const classes = useStyles();
  const [dataIdProduct, setDataIdProduct] = useState();
  const [statusProduct, setStatusProduct] = useState();
  const [totalPrice, setTotalPrice] = useState([]);
  const [totalDelivery, setTotalDelivery] = useState([]);
  const [dataOrderItems, setDataOrderItems] = useState([]);

  const history = useHistory();
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(15);
  const [metaTotal, setMetaTotal] = useState();

  useEffect(() => {
    getDataPagingProduct();
  }, [currentPage]);

  const getDataPagingProduct = () => {
    axios.get(`${baseUrl}/order/list?page=${currentPage}`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }, params: {status: 'cancelled'}
    })
    .then((response) => {
      const dataResponse = response.data;
      const dataOrderProducts = response.data.data;
      const dataTotal = dataResponse.total;
      setMetaTotal(dataTotal);
      setTotalPrice(dataResponse.total_price);
      setTotalDelivery(dataResponse.total_delivery);
      setDataOrderItems(dataOrderProducts);
      dataOrderProducts.forEach((puid) => {
        setDataIdProduct(puid.uid);
        setStatusProduct(puid.status)
      })
    });
  }


  const DetailOrder = (uid) => {
    history.push('/detail/transaksi', {params: uid})
  }


  const imageDefaultProduct = ProductEmpty.toString();
  const imageDefault = PhotoTemplate.toString();

  return (
    <TemplateOrder>
      <Box width="100%" style={{padding: '10px 10px'}}>
        <div>
          {dataOrderItems.length > 0 ?

            <div>
              {dataOrderItems && dataOrderItems.map((item, index) => {
                  return (
                    <List key={index}>
                      <Paper>
                        <div style={{padding: '5px 10px 0', display: 'flex', alignItems: 'center'}}
                             className={classes.desktopOnly}>
                          <span><h3 style={{margin: '5px'}}>{item.merchant.name}</h3></span>
                          <span>({moment(item.date).format('LLLL')})</span>
                        </div>
                        <div className={classes.mobileOnly}>
                          <div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <div style={{color: '#ad3322'}}><LocalMallIcon/></div>
                              <div style={{margin: '0 10px'}}>
                                {item.merchant.name}
                                <div>
                                  <span style={{fontSize: '11px'}}> {moment(item.date).format('LLLL')} </span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{
                              textTransform: 'capitalize',
                              background: '#efefef',
                              padding: '0 10px',
                              borderRadius: '5px'
                            }}>{item.status}</span>
                          </div>
                        </div>

                        <div className={classes.paper}>
                          <Grid container spacing={2} xs={12} md={12} style={{position: 'relative'}}>
                            <Grid item xs={12} md={10}>
                              {item.products.map((order, k) => {
                                return (
                                  <Grid container xs={12} md={12} style={{marginBottom: '50px'}}>
                                    <Grid item xs={3} md={2}>
                                      <img className={classes.img} alt="complex"
                                           src={order.cover === null ? imageDefault : order.cover}/>
                                    </Grid>
                                    <Grid item xs={9} sm container style={{padding: '0 10px'}}>
                                      <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                        <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                          {order.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} md={7} container direction="column" spacing={2}>
                                        <Grid item xs>
                                          <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                            <div><span>Status</span><span
                                              className={classes.statusProd}>:</span><span>{item.status === "cancelled" && "Pesanan Dibatalkan"}</span>
                                            </div>
                                            <div><span>Jumlah Produk</span><span
                                              className={classes.jmlProd}>:</span><span>{order.qty} pcs</span></div>
                                            <div><span>Harga Produk</span><span
                                              className={classes.hrgProd}>:</span><span>{new Intl.NumberFormat('id-ID', {
                                              style: 'currency',
                                              currency: 'IDR',
                                              minimumFractionDigits: 0
                                            }).format(order.price)}</span></div>
                                            <div><span>Variant</span><span
                                              className={classes.variantProd}>:</span><span>{order.variant === null ? "Tidak ada variant" : order.variant}</span>
                                            </div>
                                          </Typography>
                                        </Grid>

                                      </Grid>
                                      <Grid item xs={12} md={5} container direction="column" spacing={1}>
                                        <Typography variant="body2" gutterBottom className={classes.desktopOnly}>
                                          <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                            <div style={{display: 'flex', width: '100%'}}>
                                              <span>Kurir</span>
                                              <span style={{margin: '0 10px 0 60px'}}>:</span>
                                              <span>{item.delivery.courier_name} - {item.delivery.courier_service}</span>
                                            </div>
                                          </Typography>

                                          <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                            <div style={{display: 'flex', width: '100%'}}>
                                              <span>Harga Ongkir</span>
                                              <span style={{margin: '0 10px'}}>:</span>
                                              <span
                                                style={{textTransform: 'capitalize'}}>{new Intl.NumberFormat('id-ID', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                minimumFractionDigits: 0
                                              }).format(item.total_delivery)}</span>
                                            </div>
                                          </Typography>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                            </Grid>
                            <Grid item xs={12} md={2} className={classes.BoxButton}>
                              <div style={{margin: '10px 0 0', textAlign: 'center'}}>
                                <div>
                                  <Button variant="contained" color="primary" size="small" className={classes.btnAction}
                                          onClick={() => DetailOrder(item.uid)}>
                                    Detail Pesanan
                                  </Button>
                                </div>

                              </div>
                            </Grid>
                          </Grid>
                          <div className="footerPrice">
                            <div style={{textAlign: 'right'}}><span
                              style={{color: '#ab0004'}}>Total Belanja : {new Intl.NumberFormat('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            }).format(item.total_amount)}</span></div>
                          </div>
                        </div>

                      </Paper>
                    </List>
                  )
                }
              )}

              <PaginationData
                stateChanger={setcurrentPage}
                current={currentPage}
                limit={itemsPerPage}
                total_page={metaTotal}
                loadData={getDataPagingProduct}
              />
            </div>
            :
            <div style={{textAlign: 'center'}}>
              <img src={imageDefaultProduct} height="300"/>
              <p>Tidak ada transaksi</p>
            </div>
          }
        </div>

      </Box>
    </TemplateOrder>
  )
}

