import React,{ Fragment, useState, useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import '../../App.css';
import ProductEmpty from '../../img/No Product.svg';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PaginationData from '../../components/Pagination';
import { baseUrl } from 'utils';
// import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    titleButton:{
        background:'#ab0004',
        color:'#fff',
        textTransform:'capitalize',
        '&:hover': {
            backgroundColor: '#a70407',
        },  
        [theme.breakpoints.down('md')]: {
            fontSize:'12px',      
        },
    },
    productWrapper:{
        padding:'10px 25px',
        [theme.breakpoints.down('md')]: {
            padding:'10px 10px',
        },
    },
    input: {
        display: 'none',
      },
    boxStyle: {
        margin:'20px 10px 0',
        [theme.breakpoints.up('md')]: {
            margin:'60px 25px 0'
        },
       
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto auto 10px',
        maxWidth: 900,
      },
      image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 150,
        },
      },
      boxVariant:{
        display:'flex',
        margin:'25px 5px', 
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display:'block',
            margin: '25px 38px',
            height: '70px'
        },
      },
      subtitle1:{
        margin:'0 5px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'10px 7px',
            height: '40px'
        }
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height:'100%',
        objectFit:'cover'
      },
  }));



function MerchantProduct(){
    const classes = useStyles();    
    const history = useHistory();



   const [dataProductList, setDataProductList] = useState([]);
   
   const MySwal = withReactContent(Swal)

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(15);
  const [metaTotal , setMetaTotal] = useState();

  useEffect(() => {
    getDataPagingProduct();
  }, [currentPage]);

  const getDataPagingProduct = () => {
    axios.get(`${baseUrl}/product/list?page=${currentPage}`, {
      headers: {
        "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }})
    .then((response) => {
        const resData = response.data.data;        
        setDataProductList(resData);          
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
      
        
     });
  }
   
  const handleClickOpenDialog = () => {
    window.location.href="/merchant/addproduct"
}

 
  const url = baseUrl;
   
   
      const handleRemoveItem = idp =>{
        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post(`${baseUrl}/product/` + idp + '/delete', { id: idp }, { 
                    headers:{
                    "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                    "Content-Type": 'application/json'
                }}).then((result)=>{
                    MySwal.fire({
                        icon: 'success',
                        title: 'Product has been deleted'
                      }).then((result) => {
                        getDataPagingProduct();
                      });
                })
                
            }
          })
        
      }
    const imageDefaultProduct = ProductEmpty.toString();

    const handleClickOpenUpdateDialog = idProd => {
        history.push('/merchant/updateproduct',{params:idProd})
    }


    
    return(
        <DashboardLayout>
             <Grid container spacing={1} >
                <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxStyle}>
                    <Typography color="textPrimary" variant="h6">
                        Produk Toko
                    </Typography>                      
                </Box>
                <Box width="100%" p={3}>
                    <Paper>
                        <div style={{padding:'20px', textAlign:'center'}}>
                            {dataProductList.length === 0 ? <p>Tokomu belum memiliki produk. Yuk isi dengan produk oleh-oleh terbaik!</p> : <p>Yuk ramaikan tokomu dengan produk oleh-oleh terbaik!</p>}
                            <Typography color="textPrimary" variant="button">            
                            <Button variant="outlined" size="small" onClick={handleClickOpenDialog} className={classes.titleButton}> + Tambah Produk Toko</Button> 
                            </Typography>
                        </div>
                    
                    </Paper>
                </Box>
                
             
                <Box width="100%" className={classes.productWrapper}>
                    <Box display="flex" justifyContent="space-between" width="100%" style={{marginBottom:'10px'}}>
                        <Typography color="textPrimary" variant="h6">
                        Daftar Produk
                        </Typography>                      
                    </Box>
                    <Paper> 
                    {dataProductList.length === 0 ? (<div style={{textAlign:'center'}}><img src={imageDefaultProduct} height="300"/></div>):(
                        <List>     
                        {dataProductList.map((item, index) =>{  
                        return (  
                        <Paper className={classes.paper}>
                            <Grid container spacing={2} xs={12} md={12}>
                            <Grid item xs={12} md={2}>
                                <ButtonBase className={classes.image}>
                                <img className={classes.img} alt="complex" src={item.cover}/>
                                </ButtonBase>             
                            </Grid>
                            <Grid item xs={12} md={10} container>
                                <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                       {item.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={9}container direction="column" spacing={2}>
                                    <Grid item xs>                                       
                                        <Typography variant="body2" gutterBottom style={{marginTop:'20px'}}>
                                        <div><span>Kategori</span><span style={{width:'50px', margin:'10px'}}>:</span><span style={{textTransform: 'capitalize'}}>{item.category === "food" ? "Kuliner" : "Souvenir"}</span></div>                                
                                        <div><span>Harga</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.price)}</span></div>
                                        <div><span>Stock</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{item.qty === null ? ("unlimited"):(item.qty)}</span></div>
                                        </Typography>                                   
                                    </Grid>
                                   
                                </Grid>
                               
                                <Grid item xs={12} md={3}>
                                    <div>
                                        <Button variant="outlined" color="default" size="small" fullWidth style={{marginBottom:'10px'}} onClick={() =>handleClickOpenUpdateDialog(item.id)}>
                                            Edit
                                        </Button>
                                        <Button variant="outlined" color="secondary" size="small" fullWidth onClick={() =>handleRemoveItem(item.id)}>
                                            Hapus
                                        </Button>
                                    </div>
                                </Grid>
                               
                            </Grid>
                            </Grid>
                            
                            

                           

                        </Paper>


                            

                        )})}
                          <PaginationData 
                            stateChanger={setcurrentPage} 
                            current={currentPage} 
                            limit={itemsPerPage} 
                            total_page={metaTotal} 
                            loadData={getDataPagingProduct}
                        />
                    </List> 
                    
                    )}
                     
                   
                     </Paper>        
                </Box>


               
              
             </Grid>
           

            
        </DashboardLayout>
        
    )
}

export default MerchantProduct;
