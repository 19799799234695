import React,{useState, useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import { makeStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle'; 
// import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
// import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { baseUrl } from 'utils';

// const useStyles = makeStyles(() => ({
//     inline: {
//       display: 'inline',
//     },
//     productBox:{
//         border: '4px solid #ddd',
//         width:'100%',
//         marginBottom:'10px'
//     }
//   }));

export default function MerchantProduct(){
    // const classes = useStyles();
    const location = useLocation();
    const myparam = location.state.params;
    // const MySwal = withReactContent(Swal);
    const [dataTimeline, setDataTimeline]= useState([]);

    useEffect(()=>{
      axios.get(`${baseUrl}/order/merchant/${myparam}/tracking`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
          .then((response) => {          
              const respData = response.data;
              setDataTimeline(respData); 
              
          });
    },[])
 

    
    return(
        <DashboardLayout>
             <Grid container spacing={1}>
                <Box display="flex" justifyContent="space-between" width="100%" style={{padding:'40px 40px 10px'}}>
                    <Typography color="textPrimary" variant="h6">
                        Lacak Pesanan
                    </Typography> 
                </Box>
                <Box width="100%" style={{padding:'10px 10px'}}>
                <React.Fragment>
                    {dataTimeline.map((timeline, idx)=>{
                        return(
                            <Timeline align="alternate" key={idx}>
                                <TimelineItem>
                                <TimelineOppositeContent style={{maxWidth:'116px'}}>
                                    <Typography color="textSecondary">{timeline.date}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography>{timeline.description}</Typography>
                                </TimelineContent>
                                </TimelineItem>                        
                            </Timeline>
                        )
                    })}
                    
                </React.Fragment>
                </Box>
             </Grid>
        </DashboardLayout>
        
    )
}