import React from "react";
import Checkbox from '@material-ui/core/Checkbox';

const Checklist = ({ id, type, name, handleClick, isChecked }) => {
  return (
    <Checkbox
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

export default Checklist;