import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import { Link } from "react-router-dom";
import PhotoTemplate from '../img/broken-256.png';
import PaginationData from '../components/Pagination';
import StarIcon from '@material-ui/icons/Star';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({ 
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  buttonsee: {
    margin: theme.spacing(1),
    backgroundColor: '#ab0004',
    '&:hover': {
        backgroundColor: '#fd2d32',
      },    
    color:'#fff',
    fontSize:'12px'

  },
  fcstore:{
      color:'#ab0004'
  },
  media: {
    height: 140,
  },
  trText:{
      margin:"10px 0",
      fontSize:'14px',
      width:'100%'
  },
  textTittle:{
    fontSize: '14px'
  }
}));

export default function OurStores() {
  const classes = useStyles();
  const [value, setValue] = useState(3);
  const [data, setData] = useState([]);
  const imageDefault = PhotoTemplate.toString();

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(30);
  const [metaTotal , setMetaTotal] = useState();

  useEffect(() => {
    getDataPagingProduct();
    // getDataPaging();
  }, [currentPage]);
  
  const getDataPagingProduct = () =>{
    axios.get(
      `${baseUrl}/home/recommendation-products?page=${currentPage}&limit=${itemsPerPage}`
    ).then((response)=>{
      const dataPaging = response.data.data;
      const dataTotal = response.data.meta.total;
      const curPage = response.data.meta.current_page;
      // setCurPages(curPage);
      setMetaTotal(dataTotal);
      setData(dataPaging);
      
    })
  }

  
  return (
    <div className='boxContainerWrapper'>
    <React.Fragment>
        <CssBaseline />
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
           <h2 className={classes.fcstore}>Rekomendasi Oleh - Oleh</h2>    

        </Grid>

        <Grid 
            container 
            spacing={1} 
            direction="row"
            justify="flex-start"
            alignItems="center"
        >
            {data && data.map(item => (
            <Grid item xs={6} md={2} key={item.id}>
              
                <Card>
                <Link to={{ pathname: "/product/" + item.slug }} key={item.slug} style={{color:"#656565", textDecoration:'none'}}>
                      <div class="cardCenter">
                        <div className="cardMedia">
                          {item.qty === "unavailable" && <span>Stock Habis!</span>}
                          {item.cover === null ? <img src={imageDefault}/> : <img src={item.cover}/>}
                        </div>
                        <div className="cardContent">
                          <p><strong>{item.name}</strong></p>
                          <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                          {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.price)}
                          </Typography>            
                          <div style={{fontSize:'10px', margin:'10px 0'}}>
                            {item.location === null ? "Belum ada lokasi" : item.location.name}
                          </div>
                          <div style={{display:'flex', alignItems:'center'}}>
                            <StarIcon style={{color:'#FCCE00'}}/>
                            <span style={{fontSize:'13px', margin:'0 5px'}}>{item.rating}</span>
                          </div>
                        </div>
                      </div>
                      </Link>
                  
                  </Card>
               
                
            </Grid>
            ))}

            <PaginationData 
              stateChanger={setcurrentPage} 
              current={currentPage} 
              limit={itemsPerPage} 
              total_page={metaTotal} 
              loadData={getDataPagingProduct}
            />

        </Grid>
    </React.Fragment>
    </div>
  );
}
