import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { baseUrl } from 'utils';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
  }));

const FileUploadMerchant = () => {
  const classes = useStyles();
  const [isUpload, setUpload] = useState(false);
  const [progress, setProgress] = useState(0);


  const onChange = async (e) => {
    const filesupload = e.target.files[0]; 
    setUpload(true);
    const config = {
      headers: {
        "Authorization":  `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        "Access-Control-Allow-Origin":'*'
      },
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted);
      }
  }
    axios.post(`${baseUrl}/merchant/upload`, filesupload, config)
  .then((response)=>{
    window.location.reload();     
    })
  };
  
useEffect(() => {
}, [progress, isUpload])
  
  
  return (
    <Fragment>

        <div className='custom-file mb-4'>

          <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onChange}/>
            <label htmlFor="icon-button-file">
              <Button fullWidth variant="outlined" className="btnUpload" component="span">
                Ubah Foto
              </Button>               
            </label>
           
   
        </div>
        {isUpload &&<div style={{width:'100%', marginTop:'10px'}}><LinearProgressWithLabel value={progress} /></div>}
    </Fragment>
  );
};
export default FileUploadMerchant;