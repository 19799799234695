import React, {useEffect,useState} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../App.css";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../img/broken-256.png';
import TextField from '@material-ui/core/TextField';
import { Link, useLocation } from "react-router-dom";
import ProductEmpty from "../img/No Product (General).svg";
import StarIcon from '@material-ui/icons/Star';
import PaginationData from '../components/Pagination';
import {baseUrl} from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  }
}))

function FoodCategoryLocation() {
    const imageDefault = PhotoTemplate.toString();
    const [dataProductSearch, setDataProductSearch] = useState([]);
    const classes = useStyles();    
    const [priceMin, setPriceMin]= useState(0);
    const [priceMax, setPriceMax]= useState(999999999);
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(16);
    const [metaTotal , setMetaTotal] = useState();  
    const location = useLocation();
    const locfood = location.state.params.loc;
    const catfood = location.state.params.cat;
    const [sortValue, setSortValue] = useState('new');

    useEffect(()=>{
        getDataPagingProduct();
     },[currentPage, sortValue])

   const getDataPagingProduct = () => {
    axios.get(`${baseUrl}/home/products/${locfood}/?category=${catfood}&sorting=${sortValue}&price=${priceMin+'-'+priceMax}&page=${currentPage}&limit=${itemsPerPage}`,{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setDataProductSearch(dataResponse);       
        const dataTotal = response.data.meta.total;
        setMetaTotal(dataTotal);
        
    });
   }
    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Rating Tertinggi',
        value: 'popular'        
      }
    ]


    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/home/products/${locfood}/?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: {category:"food", price: priceMin+'-'+priceMax}
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setDataProductSearch(dataResponse);
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
        
    });
    }


    
      const NotFoundProduct = ProductEmpty.toString();

     
    return (
        <div className={classes.MainPage}>
               <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>

                <div style={{ marginBottom:'10px'}}>
                  <label>Urutkan : </label> 
                    <select style={{padding:'3px 10px'}} onChange={(e)=> setSortValue(e.target.value)}>
                    <option value="--Paling Sesuai--" disabled selected>--Paling Sesuai--</option>
                      {sortProduct.map(({ label, value}, index) => {
                        return ( 
                          <option value={value} key={index}>{label}</option>
                        )})}
                    </select>
                    
                  </div>

                </div>

            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                        </Accordion>
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper style={{padding:'10px'}}>
                        <React.Fragment>
                              {dataProductSearch.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductSearch.map((prdSearch, k) =>{
                                    return(
                                      
                                        <Grid item xs={12} md={3} key={k}>
                                            <Card>
                                            <Link to={{ pathname: "/detail/" + prdSearch.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                                <CardActionArea>
                                                    <img src={prdSearch.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                    <div className="cardContent">
                                                    <p>
                                                        {prdSearch.name}
                                                    </p>
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prdSearch.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        <span style={{fontSize:'12px'}}>{prdSearch.location.name}</span> <br />
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                          <StarIcon style={{color:'#FCCE00'}}/>
                                                          <span style={{fontSize:'13px', margin:'0 5px'}}>{prdSearch.rating}</span>
                                                        </div>
                                                    </Typography>
                                                    </div>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                   
                                    )
                                  })}
                                  </Grid>

                                  <PaginationData 
                                    stateChanger={setcurrentPage} 
                                    current={currentPage} 
                                    limit={itemsPerPage} 
                                    total_page={metaTotal} 
                                    loadData={getDataPagingProduct}
                                  />
                                </div>
                                
                              }
                               
                            </React.Fragment>
                   
                       
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default FoodCategoryLocation;