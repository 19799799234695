import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import axios from 'axios';
import { Link } from "react-router-dom";
import '../styles/styles.css';
import PaginationData from './Pagination';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({ 
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  MainPage:{
    margin:'2em 0',
    [theme.breakpoints.up('md')]: {
        margin:'6em'
      }
  },
  buttonsee: {
    margin: theme.spacing(1),
    backgroundColor: '#ab0004',
    '&:hover': {
        backgroundColor: '#fd2d32',
      },    
    color:'#fff',
    fontSize:'12px'

  },
  fcstore:{
      color:'#ab0004'
  },
  media: {
    height: 180,
  },
  trText:{
      margin:"10px 0"
  },
  pagingOpt:{
    margin: '20px auto'
  }
}));


export default function InfoMenarik() {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(12);
  const [metaTotal , setMetaTotal] = useState();

  
  useEffect(() => {   
     getDataPagingProduct();
  }, [currentPage]);


const getDataPagingProduct = () =>{
  axios.get(
    `${baseUrl}/home/blogs?page=${currentPage}&limit=${itemsPerPage}`
  ).then((response)=>{
    const dataPaging = response.data.data;
    const dataTotal = response.data.meta.total;
    const curPage = response.data.meta.current_page;
    // setCurPages(curPage);
    setMetaTotal(dataTotal);
    setData(dataPaging);
  })
}

  

  return (
    <React.Fragment>
        <div className={classes.MainPage}>
        <div style={{height:'50px', textAlign:'center'}}>
            <h2>Temukan info menarik lainya di sini</h2>
            {/* <p style={{maxWidth:'680px', margin:'auto', color:'#777'}}>Berbagai macam info menarik yang bisa  didapatkan di sini</p>     */}
        </div>
        <Grid 
            container 
            spacing={1} 
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{marginBottom:'20px'}}
        >
            {data && data.map(item => (
            <Grid item xs={6} md={3} key={item.id}>
                 <Card>
                 <Link to={{ pathname: "/blog/" + item.slug }} key={item.slug} style={{color:"#656565", textDecoration:'none'}}>
                     <CardActionArea>
                         <CardMedia
                        className={classes.media}
                        image={item.image.uri}
                        title={item.title}
                        />
                        <CardContent>
                        <Typography noWrap gutterBottom variant="h6" component="h6" >
                            {item.title}
                        </Typography>
                        <p>{item.content}</p>
                                        
                        
                        </CardContent>
                    </CardActionArea>
                    </Link>
                </Card>
            </Grid>
            ))}
            
            <PaginationData 
              stateChanger={setcurrentPage} 
              current={currentPage} 
              limit={itemsPerPage} 
              total_page={metaTotal} 
              loadData={getDataPagingProduct}
            />
         
        </Grid>
        </div>
        <CssBaseline />
    </React.Fragment>
  );
}
