import React,{useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    hidden: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
          },
    },
    fsizeBox:{
        fontSize:'13px',
        [theme.breakpoints.down('md')]: {
            fontSize:'12px',
        },
    },
    statusProd:{
        width:'50px', 
        margin:'10px 10px 10px 60px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    jmlProd:{
        width:'50px', 
        margin:'10px'
    },
    hrgProd:{
        width:'50px', 
        margin:'10px 10px 10px 17px'
    },
    variantProd:{
        width:'50px', 
        margin:'10px 10px 10px 57px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    desktopOnly:{
        display: 'block',
        [theme.breakpoints.down('md')]: {
          display: 'none !important',
        },
    },
    mobileOnlywthStyle:{
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
    },
    mobileOnly:{
        display: 'flex',
        width:'100%',
        justifyContent:'space-between',
        borderBottom: '1px solid #ddd',
        padding: '10px 15px',
        alignItems:'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    boxMyOrder :{
        margin:'60px 5px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 0px'
        },
    },
    BoxButton:{       
        [theme.breakpoints.up('md')]: {
            display:'flex',
            alignItems:'center'
        },
    },
    btnAction:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'46%',
        }
    },
    btnActionDelivery:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        fontSize:'10px',
        [theme.breakpoints.down('md')]: {
            width:'25%',
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 960,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        width:'100%',
        objectFit:'cover',
        maxHeight: '100px',
        height:'100%'
      },
    subtitle1:{
        margin:'0px 6px 10px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'5px 7px',
            height: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }
      },
  }));

export default function TemplateOrder(props){
    const classes = useStyles();

    const settings = {
        dots: false,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
        
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          }
        ]
      };

    let pathname = window.location.pathname;
    useEffect(() => {
        pathname = window.location.pathname;
    }, [window.location.pathname]);


    return(
        <DashboardLayout>
                <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}>
                    <Typography color="textPrimary" variant="h6">
                       Pesanan Saya
                    </Typography> 
                </Box>                
                <Box display="flex" width="100%" >
                <Slider {...settings} className="slideboxmenu">                
                    <Link to="/order/purchased" className={`${pathname.match('/order/purchased') ? 'link-active' : ''}`}>
                        Menunggu Pembayaran
                    </Link>
                    <Link to="/order/paid" className={`${pathname.match('/order/paid') ? 'link-active' : ''}`}>
                        Menunggu Konfirmasi
                    </Link> 
                    <Link to="/order/packed" className={`${pathname.match('/order/packed') ? 'link-active' : ''}`}>
                        Pesanan Diproses
                    </Link>
                    <Link to="/order/delivered" className={`${pathname.match('/order/delivered') ? 'link-active' : ''}`}>
                        Pesanan Dikirim
                    </Link> 
                    <Link to="/order/accepted" className={`${pathname.match('/order/accepted') ? 'link-active' : ''}`}>
                        Pesanan Selesai
                    </Link> 
                    <Link to="/order/cancelled" className={`${pathname.match('/order/cancelled') ? 'link-active' : ''}`}>
                        Pesanan Dibatalkan
                    </Link> 
                </Slider>
                </Box>
             <Grid container spacing={1}>
                 {props.children}                
             </Grid>      
        </DashboardLayout>
        
    )
}

