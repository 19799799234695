import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import PhotoTemplate from '../../img/watermark.png';
import '../../styles/styles.css'
import StarIcon from '@material-ui/icons/Star';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({ 
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  buttonsee: {
    margin: theme.spacing(1),
    backgroundColor: '#ab0004',
    '&:hover': {
        backgroundColor: '#fd2d32',
      },    
    color:'#fff',
    fontSize:'12px'

  },
  fcstore:{
      color:'#ab0004'
  },
  media: {
    height: 140,
  },
  trText:{
      margin:"10px 0",
      fontSize:'14px'
  }
}));

export default function OurStores() {
  const classes = useStyles();
  const [value, setValue] = useState(4);
  const [data, setData] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(12);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [metaTotal , setMetaTotal] = useState();

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(metaTotal / itemsPerPage); i++) {
    pages.push(i);
  }
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
    axios.get(
      `${baseUrl}/home/merchants?page=${currentPage}&limit=${itemsPerPage}`
    ).then((response)=>{
      const dataPaging = response.data.data;
      const dataTotal = response.data.meta.total;
      const curPage = response.data.meta.current_page;
      // setCurPages(curPage);
      setMetaTotal(dataTotal);
      setData(dataPaging);
      
    })
    // getDataPaging();
  }, [currentPage]);


  const handleNextbtn = (currentPage) => {

    setcurrentPage(currentPage + 1);   
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = (currentPage) => {

    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }


  const imageDefault = PhotoTemplate.toString();

  return (
    <div className='boxContainerWrapper'>
    <React.Fragment>
        <CssBaseline />
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
           <h2 className={classes.fcstore}>Toko Kami</h2>
        </Grid>

        <Grid 
            container 
            spacing={1} 
            direction="row"
            justify="flex-start"
            alignItems="center"
        >
            {data && data.map(item => (
            <Grid item xs={6} md={2} key={item.id}>
                 <Card>
                    <Link to={{ pathname: "/store/" + item.domain }} key={item.domain} style={{color:"#656565", textDecoration:'none'}}>
                    <div class="cardCenter ourStore" style={{minHeight:'119px'}}>
                        <div className="cardMedia" style={{margin:'10px'}}>
                          {item.photo === null ? <img src={imageDefault}/> : <img src={item.photo.uri}/>}
                        </div>
                        <div className="cardContent">
                          <p style={{textTransform:'uppercase', fontSize:'12px'}}><strong>{item.name}</strong></p>                                     
                          <div style={{fontSize:'10px', margin:'0 0 10px'}}>
                            {item.location === null ? "Belum ada lokasi" : item.location.province.name}
                          </div>
                          <div style={{display:'flex', alignItems:'center'}}>
                            <StarIcon style={{color:'#FCCE00'}}/>
                            <span style={{fontSize:'13px', margin:'0 5px'}}>{item.total_rating}.0</span>
                          </div>
                        </div>
                      </div>
                      </Link>
                      <div className='wrapperProdMerchant'>
                        {item.products.length > 0 ? 
                        <>
                        {item.products.map((prdmerchant, index)=>{
                          return(
                            <>
                              <div className='prodMerchant' key={index}>
                                <Link to={localStorage.getItem("token")? {pathname: "/product/" + prdmerchant.slug } : {pathname: "/detail/" + prdmerchant.slug }}>
                                  <img src={prdmerchant.cover} title={prdmerchant.name}/>
                                </Link>
                              </div> 
                            </>
                          )
                        })} 
                        </> 
                        : 
                        <div style={{height:'50px',display:'flex', alignItems:'center'}}>Tidak ada product</div>
                        }
                        
                                              
                      </div>
                  
                </Card> 
            </Grid>
            ))}
         
         <ul className="pageNumbers">
         <li>
                <button
                  onClick={()=>handlePrevbtn(currentPage)}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  <ChevronLeftIcon />
                </button>
              </li>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}

              <li>
                <button
                  onClick={()=>handleNextbtn(currentPage)}
                  disabled={currentPage == pages[pages.length - 1] ? true : false}
                >
                  <ChevronRightIcon />
                </button>
              </li>
            </ul>
        </Grid>
    </React.Fragment>
    </div>
  );
}
