import React,{useState, useEffect} from 'react';
import TemplateMerchantOrder from './TemplateOrderMerchant';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ProductEmpty from '../../../img/No Transaction.svg';
import PhotoTemplate from '../../../img/broken-256.png';
import moment from 'moment';
import 'moment/locale/id'
import LocalMallIcon from '@material-ui/icons/LocalMall';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import PaginationData from '../../../components/Pagination';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    merchStatus:{
      width:'50px', 
      margin:'10px 10px 10px 65px',
      [theme.breakpoints.down('md')]: {
        margin: '10px',
      },
    },
    merchFsize:{
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    merchJmlProd:{
      width:'50px', margin:'10px',
      [theme.breakpoints.down('md')]: {
        margin: '10px',
      },
    },
    merchJmlPrice:{
      width:'50px', margin:'10px 10px 10px 32px',
      [theme.breakpoints.down('md')]: {
        margin: '10px',
      },
    },
    merchVariant:{
      width:'50px', margin:'10px 10px 10px 60px',
      [theme.breakpoints.down('md')]: {
        margin: '10px',
      },
    },
    desktopOnly:{
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
          display: 'block !important',
        },
    },
    mobileOnly:{
        display: 'flex',
        width:'100%',
        justifyContent:'space-between',
        borderBottom: '1px solid #ddd',
        padding: '10px 15px',
        alignItems:'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    boxMyOrder :{
        margin:'60px 5px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 0px'
        },
    },
    BoxButton:{       
        [theme.breakpoints.up('md')]: {
            display:'flex',
            alignItems:'center'
        },
    },
    btnAction:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        fontSize:'12px',
        [theme.breakpoints.down('md')]: {
            width:'46%',
        }
    },
    btnActionDelivery:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'25%',
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 960,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        width:'100%',
        objectFit:'cover',
        maxHeight: '100px',
        height:'100%'
      },
    subtitle1:{
        margin:'0px 6px 10px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'5px 7px',
            height: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }
      },
  }));

function MerchantNewOrder(){
    const classes = useStyles();
    const history = useHistory();   
    const MySwal = withReactContent(Swal);
    const [dataIdProduct, setDataIdProduct]= useState();
    const [totalPrice, setTotalPrice] = useState([]);
    const [totalDelivery, setTotalDelivery] = useState([]);
    const [dataOrderItems, setDataOrderItems] = useState([]);
    const [statusProduct , setStatusProduct] = useState(); 

    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(15);  
    const [metaTotal , setMetaTotal] = useState();

    useEffect(()=>{
      getDataPagingProduct();
      },[currentPage])

      const getDataPagingProduct = () =>{
        axios.get(`${baseUrl}/order/merchant/list?page=${currentPage}`, {
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: { status: 'new'}
          })
        .then((response) => {          
            const dataResponse = response.data;            
            const dataOrderProducts = response.data.data;
            const dataTotal = response.data.meta.total;
            setMetaTotal(dataTotal);  
            setTotalPrice(dataResponse.total_price);
            setTotalDelivery(dataResponse.total_delivery); 
            setDataOrderItems(dataOrderProducts);         
            dataOrderProducts.map((puid, idx)=>{
              setDataIdProduct(puid.uid);
              setStatusProduct(puid.status)
           
            })
          });
      }

     
    const DetailOrder = (uid) =>{
        history.push('/mdetail/transaksi',{params: uid})
    }
    const confirmOrder = (uid) =>{
      axios.post(`${baseUrl}/order/merchant/${uid}/confirm`, {}, {
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
          .then((response) => {          
              const dataResponse = response.data;
              MySwal.fire({
                icon: 'success',
                title: "Pesanan telah di konfirmasi"
              }).then((result) => {
                window.location.reload();
            });
          }).catch((e)=>{
            MySwal.fire({
                icon: 'warning',
                title: "Tidak dapat konfirmasi dikarenakan status belum paid"
            })
        })
      }
    const confirmCancelOrder = async (uid) =>{
        const { value: reason } = await MySwal.fire({
            title: 'Masukan Alasan',
            input: 'text',
            inputPlaceholder: 'Alasan Anda Membatalkan Order ini',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                return 'Kamu harus mengetikan alasan!'
                }
            }
          })
          
          if (reason) {
            // MySwal.fire(`Alasan Pembatalan: ${reason}`)
            axios.post(`${baseUrl}/order/merchant/${uid}/cancel`, { reason: reason }, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            .then((response) => {          
                const dataResponse = response.data;
                
                MySwal.fire({
                    icon: 'success',
                    title: "Pesanan telah dibatalkan"
                }).then((result) => {
                    window.location.reload();
                });
            });
          }
      }

    const imageDefault = PhotoTemplate.toString();
    const imageDefaultProduct = ProductEmpty.toString();


    return(
        <TemplateMerchantOrder>
        <React.Fragment>
            {dataOrderItems.length > 0 ? 
            <div>
            {dataOrderItems && dataOrderItems.map((item,index)=>{
                return (                            
                <List key={index} >  
                    <Paper>  
                    <div style={{padding:'5px 10px 0', display:'flex', alignItems:'center'}} className={classes.desktopOnly}>
                        <span><h3 style={{margin:'5px'}}>{item.merchant.name}</h3></span>
                        <span>({moment(item.date).format('LLLL')})</span>
                    </div> 
                    <div className={classes.mobileOnly}>
                                <div >
                                    <div style={{display: 'flex',alignItems: 'center'}}>
                                        <div style={{color: '#ad3322'}}><LocalMallIcon/></div> 
                                        <div style={{margin:'0 10px'}}>
                                            Belanja
                                            <div>
                                                <span style={{fontSize:'11px'}}> {moment(item.date).format('LLLL')} </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <span style={{textTransform:'capitalize', background:'#efefef', padding:'0 10px', borderRadius:'5px'}}>{statusProduct}</span>
                                </div>
                        </div>                        
                
                                                        
                    <div className={classes.paper}>                                    
                        <Grid container spacing={2} xs={12} md={12}>   
                        <Grid item xs={12} md={10}>
                        {item.products.map((order, k)=>{
                            return(  
                                <Grid container xs={12} md={12} style={{marginBottom:'50px'}}>
                                    <Grid item xs={4} md={2}>
                                        <img className={classes.img} alt="complex" src={order.cover === null ? imageDefault : order.cover }/>
                                    </Grid>
                                    <Grid item xs={8} sm container style={{padding:'0 10px'}}>
                                        <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                            <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                            {order.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7} container direction="column" spacing={2}>
                                            <Grid item xs>                                       
                                                <Typography variant="body2" gutterBottom className={classes.merchFsize}>
                                                <div><span>Status</span><span className={classes.merchStatus}>:</span><span>{item.status === "paid" && "Pesanan Baru"}</span></div>
                                                <div><span>Jumlah Produk</span><span className={classes.merchJmlProd}>:</span><span>{order.qty} pcs</span></div>
                                                <div><span>Total Harga</span><span className={classes.merchJmlPrice}>:</span><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(order.price)}</span></div>
                                                <div><span>Variant</span><span className={classes.merchVariant}>:</span><span>{order.variant === null ? "Tidak ada variant" : order.variant }</span></div>
                                                </Typography>                                   
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid item xs={12} md={5} container direction="column" spacing={1}>
                                        <Typography variant="body2" gutterBottom className={classes.desktopOnly}>
                                            <Typography color="textPrimary" variant="div">                                
                                            <div style={{display:'flex',width:'100%'}}>
                                                <span>Kurir</span>
                                                <span style={{margin:'0 10px 0 65px'}}>:</span>
                                                <span>{item.delivery.courier_name} - {item.delivery.courier_service}</span>
                                            </div>  
                                            </Typography>
                                            
                                            <Typography color="textPrimary" variant="div">                                
                                                <div style={{display:'flex',width:'100%'}}>
                                                    <span>Harga Ongkir</span>
                                                    <span style={{margin:'0 10px'}}>:</span> 
                                                    <span style={{textTransform:'capitalize'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.total_delivery)}</span>
                                                    </div>  
                                            </Typography>

                                            
                                        </Typography>
                                        </Grid>                                           
                                    
                                    </Grid>
                                </Grid>
                            )
                            })}  
                        </Grid>                                     
                            
                            <Grid item xs={12} md={2} className={classes.BoxButton}>
                                    <div style={{margin:'10px 0 0', textAlign:'center'}}>
                                        <div>
                                        <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                            Detail Pesanan
                                        </Button>
                                        <Button variant="outlined" size="small" className={classes.btnAction} style={{fontSize:'12px', background:'#6dbb06', color:'#fff'}} onClick={()=>confirmOrder(item.uid)}>
                                            Terima Pesanan
                                        </Button>
                                        <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(item.uid)} >
                                            Tolak Pesanan
                                        </Button>
                                        </div>                                        
                                    </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="footerPriceMerchant">
                        <div style={{textAlign:'right'}}> <span style={{color:'#ab0004', marginRight:'10px'}}>Total Belanja : {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.total_amount)}</span> </div>
                    </div>
                    </Paper>
                </List>
                )
            }
            
                
            )} 

            <PaginationData 
              stateChanger={setcurrentPage} 
              current={currentPage} 
              limit={itemsPerPage} 
              total_page={metaTotal} 
              loadData={getDataPagingProduct}
            />
            </div> : 
            <div style={{textAlign:'center'}}>
            <img src={imageDefaultProduct} height="300"/>
            <p>Tidak Ada Transaksi</p>
            </div>}

            </React.Fragment>
        </TemplateMerchantOrder>
    )
}

export default MerchantNewOrder;