import React, { useState, useEffect } from 'react';
import DashboardLayout from '../page/DashboardLayout';
import axios from 'axios';
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from '@material-ui/core/Button';
import { baseUrl } from 'utils';

function UpdatePassword() {
    // const [dataChange, setDataChange] = useState([]);
    const [old_password, setOldPassword]= useState("")
    const [new_password, setNewPassword]= useState("")
    const [new_password_confirmation, setNewPasswordConfirmation]= useState("")
    const MySwal = withReactContent(Swal)

    const SubmitChangePassword = (e) =>{
        e.preventDefault();
      axios.post(`${baseUrl}/user/password`, {
            old_password,
            new_password,
            new_password_confirmation
        },{
          headers:{
            "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            "Content-Type": 'application/json'
          }}).then((result)=>{
            MySwal.fire({
              icon: 'success',
              title: 'Password has been changed'
            })
          }).then((result)=>{
            localStorage.clear();
            window.location.href="/login"
          }).catch((e)=>{
             MySwal.fire({
                icon: 'error',
                title: e.response.data.message
              })
          })
    }

    return (
        <React.Fragment> 
            <DashboardLayout>    
                <Paper className="changePswd">
                    <h3 style={{textAlign:'center'}}>Change Password</h3>
                    <form onSubmit={SubmitChangePassword}>
                        <TextField
                            id="old-password-input"
                            label="Old Password"
                            type="password"
                            autoComplete="old-password"
                            style={{margin:'10px'}}
                            variant="outlined"   
                            fullWidth           
                            size="small"              
                            onChange={(e)=> setOldPassword(e.target.value)}
                        />
                         <TextField
                            id="new-password-input"
                            label="New Password"
                            type="password"
                            autoComplete="new-password"
                            style={{margin:'10px'}}
                            fullWidth  
                            size="small"   
                            variant="outlined"
                            onChange={(e)=> setNewPassword(e.target.value)}
                        />
                         <TextField
                            id="confirmnew-password-input"
                            label="Confirm New Password"
                            type="password"
                            fullWidth  
                            size="small"   
                            style={{margin:'10px'}}
                            autoComplete="current-password"
                            variant="outlined"
                            onChange={(e)=> setNewPasswordConfirmation(e.target.value)}
                        />
                        <Button type="submit" variant="outlined" fullWidth style={{margin:'10px'}}>Submit</Button>
                    </form>              
                </Paper>
            </DashboardLayout>
        </React.Fragment>
    );
}

export default UpdatePassword;