import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import RoomIcon from '@material-ui/icons/Room';


const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
      margin:'10px',
    },
    linkProf:{     
        display: 'flex',
        alignItems:'center',   
        margin:'10px 0',
        [theme.breakpoints.down('md')]: {
         display: 'flex',
         alignItems:'center',
         fontSize:'13px',
         margin:'10px 0',
        }
      },

      profileWrap:{
        border:'1px solid #ddd',
        height:'120px',
        maxHeight:'120px',
        borderRadius:'10px',
        [theme.breakpoints.down('md')]: {
            margin:'25px 0'
        }
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    PadDense:{
        padding:'20px',
        [theme.breakpoints.down('md')]: {
            margin:'10px 0'
        }
    },
    subTitle:{
        margin:'0 5px',
        [theme.breakpoints.down('md')]: {
            fontSize:'12px'
        }
    },
    subHeader:{
        fontSize:'12px',
    },
    btnVar:{
        margin:'2px',
        fontSize:'11px',
        padding:'5px'
    }
  }));

export default function MerchantLocation(props){
    const classes = useStyles();
    const {dataLocation} = props;
    const addMerchantLocation =()=>{
        window.location.href="/addlocation/merchant"
    }
    return(
        <React.Fragment>

        <Paper className={classes.PadDense}>
            <Box display="flex" justifyContent="space-between">
                <Typography color="textPrimary" variant="h6">
                    Alamat Toko
                </Typography>                   
            </Box>
            
            <div className={classes.profileWrap}>
                {dataLocation !== null ? 
                <div className='boxShowLocation'>
                    <div>
                        <strong>{dataLocation && dataLocation.address}(Detail Alamat)</strong><br/>
                        {dataLocation && dataLocation.subdistrict.name}, {dataLocation && dataLocation.city.name}, {dataLocation && dataLocation.province.name}, {dataLocation && dataLocation.postal}
                        {dataLocation &&  dataLocation.longitude && dataLocation.latitude !== null ? <div style={{display:'flex', alignItems:'center'}}><RoomIcon/> Sudah Pinpoint </div>: "Anda belum melakukan pinpoin pada alamat"}
                    </div>
                    <div>
                    <Typography color="textPrimary" className={classes.link} variant="span">
                        <Link href="/mlocation/update">
                            <IconButton aria-label="Edit">
                                <CreateOutlinedIcon />
                            </IconButton>
                            Edit Location
                        </Link>
                    </Typography> 
                    </div>
                </div>
                :
                <div style={{padding:'15px'}}> 
                    <div>Kamu belum memiliki alamat pengiriman. Silahkan tambahkan alamat pengiriman</div>
                    <div><Button variant="outlined" size="small" style={{margin:'10px 0',borderColor:'#ab0004', color:'#ab0004'}} onClick={addMerchantLocation}>Tambah Alamat</Button></div> 
                </div>
                }
            </div>        
            
        </Paper>
        
                
        
    </React.Fragment>
        
        
        
    )
}
