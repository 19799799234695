import React, { useState, Suspense, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import ProductDetail from './page/ProductDetail';
import DetailProduct from './page/DetailProduct';
import Cart from './components/Cart';
import Blog from './components/Blog';
import Login from './components/Login';
import Register from './components/Register';
import RegisterVerify from './components/RegisterVerify';
import RegisterFinal from './components/RegisterFinal';
import RegisterSocial from './components/RegisterSocial';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import FoodCategory from './components/FoodCategory';
import FoodCategoryHome from './components/FoodCategoryHome';
import FoodCategoryHomeLocation from './components/FoodCategoryHomeLocation';
import FoodCategoryLocation from './components/FoodCategoryLocation';
import SouvenirCategory from './components/SouvenirCategory';
import SouvenirCategoryLocation from './components/SouvenirCategoryLocation';
import SouvenirCategoryHome from './components/SouvenirCategoryHome';
import SouvenirCategoryHomeLocation from './components/SouvenirCategoryHomeLocation';
import Pagings from './components/testPaging';
import { AuthContext } from "./components/Auth";
import PrivateRoute from './components/PrivateRoute';
import Template from './components/Template';
import TemplateLogin from './components/TemplateLogin';
import LoadingSpinner from './components/LoadingSpinner';
import Location from './page/settings/Location';
import Dashboard from './page/Beranda';
import Order from './page/MyOrder';
import ComplainProduct from './page/MyComplain';
import MerchantComplain from './page/merchant/MerchantComplain';
import BankAccount from './page/settings/BankAccount';
import Profile from './page/settings/Profile';
import RegisterMerchant from './page/merchant/RegisterMerchant';
import Wallet from './page/wallet/MyWallet';
import Saldo from './page/wallet/Saldo';
import EditLocation from './page/settings/EditLocation';
import EditLocationMerchant from './page/merchant/EditLocationMerchant';
import PurchaseOrder from './page/order/Purchased';
import PaidOrder from './page/order/Paid';
import PackedOrder from './page/order/Packed';
import DeliveredOrder from './page/order/Delivered';
import AcceptedOrder from './page/order/Accepted';
import CancelOrder from './page/order/Cancelled';
import AddLocationMerchant from './page/merchant/AddLocationMerchant';
import MerchantProfile from './page/merchant/ProfileMerchant';
import MerchantOrder from './page/merchant/MerchantOrder';
import MerchantNewOrder from './page/merchant/Order/NewOrder';
import MerchantPackedOrder from './page/merchant/Order/PackedOrder';
import MerchantDeliveredOrder from './page/merchant/Order/DeliveredOrder';
import MerchantAcceptedOrder from './page/merchant/Order/AcceptedOrder';
import MerchantCancelOrder from './page/merchant/Order/CancelOrder';
import MerchantDelivery from './page/merchant/MerchantDelivery';
import MerchantProduct from './page/merchant/ProductMerchant';
import AddProduct from './page/merchant/CRUD/AddProduct';
import EditProduct from './page/merchant/CRUD/EditProduct';
import Checkout from './components/Checkout';
import UpdatePassword from './components/UpdatePassword';
import ReactSelect from './components/ReactSelect';
import MapsLocation from './components/MapsBound';
import BlogDetail from './components/BlogDetail';
import Invoice from './components/Invoice';
import SearchProduct from './components/SearchProduct';
import SearchProductHome from './components/SearchProductHome';
import DetailOrder from './components/DetailOrder';
import DetailOrderMerchant from './components/DetailOrderMerchant';
import ChatComplain from './page/ChatComplain';
import ProductByLoc from './page/ProductByLoc';
import ProductByLocation from './page/ProductByLocation';
import Paginations from './components/testPaging';
import MerchantTracking from './page/merchant/MerchantTracking';
import HomeStore from './page/merchant/MerchantHome';
import MerchantChatComplain from './page/merchant/ChatKomplainMerchant';
import HomeStoreLogin from './page/merchant/MerchantStore';
import TermsCondition from './components/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQ from './components/FAQ';
import NotFound from './NotFound';
import ChatList from "./page/merchant/ChatList";
import ChatRoom from "./page/merchant/ChatRoom";
import AllMerchant from "./page/merchant/AllMerchant";
import ChatProduct from "./page/ChatProduct";
import ChatRoomMerchant from "./page/merchant/ChatMerchantRoom";
import ProductAll from './page/AllProduct';
import ProductAllHome from './page/AllProductHome';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));
const Home = React.lazy(() => delay(1800).then(() => import("./page/Home.js")));

function App() {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("token") || "");
  const setTokens = (data) => {
      localStorage.setItem("token", JSON.stringify(data));      
      setAuthTokens(data);
  } 
  const [isLoggedIn , setIsLoggedIn ] = useState(false);
  useEffect(() => {
    if(authTokens){
      setIsLoggedIn(true);
      localStorage.setItem("loggedIn", true);
    }else{
      setIsLoggedIn(false);
      localStorage.setItem("loggedIn", false);
    }
   }, [authTokens]);

  //  const [windowResizing, setWindowResizing] = useState(false);

  //  useEffect(() => {
  //    let timeout;
  //    const handleResize = () => {
  //      clearTimeout(timeout);
 
  //      setWindowResizing(true);
 
  //      timeout = setTimeout(() => {
  //        setWindowResizing(false);
  //      }, 1000);
  //    }
  //    window.addEventListener("resize", handleResize);
 
  //    return () => window.removeEventListener("resize", handleResize);
  //  }, []);
   const [open, setOpen] = useState(true);

    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens}}>
        <React.Fragment>  
        <Suspense fallback={<div className="loadingScreen"><LoadingSpinner/></div>}>
              <BrowserRouter forceRefresh={true}>
              <Switch>
              {/* <Route path="/:routeParam" component={CommonComponent} />      */}
              {isLoggedIn  ? (                                  
                <div> 
                  {/* <Route path="/:routeParam" component={CommonComponent} />  */}
                  <TemplateLogin>
                    <Route exact path="/" component={Home}></Route>                                
                    <Route path="/pagination" component={Pagings}></Route>  
                    <PrivateRoute exact path="/items/cart" component={Cart} /> 
                    <Route path="/dashboard" component={Dashboard}></Route>                      
                    <Route path="/mywallet" component={Wallet}></Route> 
                    <Route path="/profile" component={Profile}></Route> 
                    <Route path="/accbank" component={BankAccount}></Route>   
                    <Route path="/account/saldo" component={Saldo}></Route>  
                    <Route path="/location" component={Location}></Route> 
                    <Route path="/update/location" component={EditLocation}></Route>                   
                    <Route path="/mlocation/update" component={EditLocationMerchant}></Route> 
                    <Route path="/addlocation/merchant" component={AddLocationMerchant}></Route> 
                    <Route path="/merchant/register" component={RegisterMerchant}></Route>                  
                    <Route path="/merchant/profile" component={MerchantProfile}></Route>
                    <Route path="/merchant/product" component={MerchantProduct}></Route>
                    <Route path="/merchant/addproduct" component={AddProduct}></Route>  
                    <Route path="/merchant/updateproduct" component={EditProduct}></Route>    
                    <Route path="/store/:slug" component={HomeStoreLogin}></Route>                                      
                    <Route path="/mstore/all" component={AllMerchant}></Route>                                      
                    <Route path="/merchant/order" component={MerchantOrder}></Route>
                    <Route path="/invoice/:uid" component={Invoice}></Route>
                    <Route path="/product/:slug" component={ProductDetail}></Route> 
                    <Route path="/merchant/delivery" component={MerchantDelivery}></Route>
                    <Route path="/update/password" component={UpdatePassword}></Route>
                    <PrivateRoute exact path="/checkout" component={Checkout} />
                    <Route path="/articles" component={Blog}></Route>
                    <Route path="/blog/:slug" component={BlogDetail}></Route>
                    <Route path="/orderAll" component={Order} />   
                    <PrivateRoute exact path="/order/purchased" component={PurchaseOrder} />      
                    <PrivateRoute exact path="/order/paid" component={PaidOrder} />   
                    <PrivateRoute exact path="/order/packed" component={PackedOrder} />      
                    <PrivateRoute exact path="/order/delivered" component={DeliveredOrder} />  
                    <PrivateRoute exact path="/order/accepted" component={AcceptedOrder} />      
                    <PrivateRoute exact path="/order/cancelled" component={CancelOrder} />                          
                    <PrivateRoute exact path="/merchant/new-order" component={MerchantNewOrder} />   
                    <PrivateRoute exact path="/merchant/packed" component={MerchantPackedOrder} />      
                    <PrivateRoute exact path="/merchant/delivered" component={MerchantDeliveredOrder} />  
                    <PrivateRoute exact path="/merchant/accepted" component={MerchantAcceptedOrder} />      
                    <PrivateRoute exact path="/merchant/cancelled" component={MerchantCancelOrder} />   
                    <Route path="/complain" component={ComplainProduct} />      
                    <Route path="/merchant/complain" component={MerchantComplain} />      
                    <Route path="/search/product" component={SearchProduct} /> 
                    <Route path="/detail/transaksi" component={DetailOrder} /> 
                    <Route path="/mdetail/transaksi" component={DetailOrderMerchant} /> 
                    <Route path="/productby/location" component={ProductByLocation} /> 
                    <Route path="/tracking/order" component={MerchantTracking} /> 
                    <Route path="/react/select" component={ReactSelect} />
                    <Route path="/souvcategory/souvenir" component={SouvenirCategory} />
                    <Route path="/souvcategoryLocation/souvenir" component={SouvenirCategoryLocation} />
                    <Route path="/foodcategory/food" component={FoodCategory} />
                    <Route path="/foodcategoryloc/food" component={FoodCategoryLocation} />
                    <Route path="/maps/location" component={MapsLocation} />                     
                    <Route path="/terms" component={TermsCondition} />    
                    <Route path="/privacy" component={PrivacyPolicy} />    
                    <Route path="/faq" component={FAQ} />
                    <Route path="/chat/complain" component={ChatComplain} />
                    <Route path="/list/chat" component={ChatList} />
                    <Route path="/rooms/chat" component={ChatRoom} />
                    <Route path="/discuss/product" component={ChatProduct} />
                    <Route path="/mrooms/chat" component={ChatRoomMerchant} />
                    <Route path="/mchat/mcomplain" component={MerchantChatComplain} /> 
                    <Route path="/products/all" component={ProductAll}></Route>                        

                  </TemplateLogin>                          
                  </div>
                  ) : (
                    <Template>
                      <Route exact path="/" component={Home}></Route>
                      <Route path="/articles" component={Blog}></Route>
                      <Route path="/login" component={Login} render={props => <Login {...props} />}></Route>
                      <Route path="/register" component={Register}></Route>
                      <Route path="/reg/verify" component={RegisterVerify}></Route>
                      <Route path="/reg/final" component={RegisterFinal}></Route>
                      <Route path="/reg/social" component={RegisterSocial}></Route>
                      <Route path="/paging" component={Paginations}></Route>
                      <Route path="/forgot/password" component={ForgotPassword}></Route>
                      <Route path="/change/password" component={ChangePassword}></Route>
                      <Route path="/detail/:slug" component={DetailProduct}></Route>
                      <Route path="/blog/:slug" component={BlogDetail}></Route>
                      <Route path="/search/product-home" component={SearchProductHome} />
                      <Route path="/productby/loc" component={ProductByLoc} /> 
                      <Route path="/store/:slug" component={HomeStore}></Route> 
                      <Route path="/mstore/all" component={AllMerchant}></Route>  
                      <Route path="/prod/home" component={ProductAllHome}></Route>                        
                      <Route path="/terms" component={TermsCondition} />
                      <Route path="/faq" component={FAQ} />
                      <Route path="/privacy" component={PrivacyPolicy} /> 
                      <Route path="/reset/password" component={ResetPassword}/>
                      <Route path="/souvcategoryhome/souvenir" component={SouvenirCategoryHome} />
                      <Route path="/souvcategoryhomelocation/souvenir" component={SouvenirCategoryHomeLocation} />
                      <Route path="/foodcategoryhome/food" component={FoodCategoryHome} />
                      <Route path="/foodcategoryhomeloc/food" component={FoodCategoryHomeLocation} />
                      <PrivateRoute exact path="/items/cart" component={Cart} /> 
                      <PrivateRoute exact path="/order/purchased" component={PurchaseOrder} />      
                      <PrivateRoute exact path="/order/paid" component={PaidOrder} />   
                      <PrivateRoute exact path="/order/packed" component={PackedOrder} />      
                      <PrivateRoute exact path="/order/delivered" component={DeliveredOrder} />  
                      <PrivateRoute exact path="/order/accepted" component={AcceptedOrder} />      
                      <PrivateRoute exact path="/order/cancelled" component={CancelOrder} /> 
                      <PrivateRoute exact path="/checkout" component={Checkout} /> 
                      <PrivateRoute exact path="/merchant/new-order" component={MerchantNewOrder} />   
                      <PrivateRoute exact path="/merchant/packed" component={MerchantPackedOrder} />      
                      <PrivateRoute exact path="/merchant/delivered" component={MerchantDeliveredOrder} />  
                      <PrivateRoute exact path="/merchant/accepted" component={MerchantAcceptedOrder} />      
                      <PrivateRoute exact path="/merchant/cancelled" component={MerchantCancelOrder} />   
                    </Template>
                  )}
                 
              </Switch>
            </BrowserRouter> 
        </Suspense>
        
        
        
        </React.Fragment>
        
        </AuthContext.Provider>
      )
     
    
  
}

export default App;
