import React,{ useState, useEffect, useRef} from 'react';
import DashboardLayout from '../../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import '../../../App.css';
import ChipInput from "material-ui-chip-input";
import { useLocation } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Editor } from '@tinymce/tinymce-react';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    input: {
        display: 'none',
    },
    AddForm:{
        margin: '60px 35px 20px',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 20px'
        },
    },
    thumbDelete:{
        position:'absolute',
        background:'#eaeaea',
        padding:'0',
        bottom:'0',
        width:'100%',
        cursor: 'pointer',
        textAlign: 'center'

    }
  }));
export default function EditProduct(){
    const classes = useStyles();   
    const locate = useLocation();
    const idProd = locate.state.params;
    const [editProduct, setEditProduct] = useState("");
    const [category, setCategory] = useState();
    const [name, setProductName] = useState();
    const [urlVideo, setUrlVideo] = useState();
    const [location, setProvince] = useState();     
    const [stocksType, setStocksType] = useState();
    const [qty, setQty] = useState();
    const [description, setProductDesc] = useState();
    const [price, setPrice] = useState();
    const [variants, setInputList] = useState([]);
    const [imagesProd, setimagesProd]= useState([]);
    const [prodId, setIdProduct]= useState();
    const [imgUri, setimgUri] = useState([]);
    const editorRef = useRef(null);
    const editorDescription = (content) => {
      setProductDesc(content) 
    };

    const handleSelectedTags = (items) => {
        setInputList(items);
    }
    const handleDeleteChip = (items, index) =>{
        const newSelectedItem = [...variants];
        if (index !== -1) {
            newSelectedItem.splice(index, 1);            
         }
         setInputList(newSelectedItem);       
       
    }
    
    
    const [files, setFiles] = useState([]);
    const [filesUpload, setfilesUpload]= useState([]);
    const maxfileSize = 3097152;
    const [fileSizeImage, setfileSizeImage]= useState(0);
    const [isUploaded , setisUploaded] = useState(false);
    const [isUploadedImage , setisUploadedImage] = useState(false);
    

    function onFileUpload(event) {
        event.preventDefault();
        let id = event.target.id;
        let file_reader = new FileReader();
        let file = event.target.files[0]; 
        let sizeImage= file.size;       
        file_reader.onload = () => {
          setFiles(...files, file_reader.result);
          setfilesUpload([...filesUpload, {file_id:id, file_image: file_reader.result}]); 
          setisUploaded(true); 
          setisUploadedImage(true);      
        };
        setfileSizeImage(sizeImage);
        if (sizeImage > maxfileSize) 
        {
            MySwal.fire({
                icon: 'warning',
                title: "Gambar melebihi batas ukuran upload! maximal 3 Mb"
            })
        }
        file_reader.readAsDataURL(file);

      
        
    }

   
    useEffect(()=>{
        if(isUploadedImage === true){
          axios.post(`${baseUrl}/product/${idProd}/upload`, { image: files }, {
            headers: {
                "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": 'application/json'
            }})
            .then((response)=>{
                
                window.location.reload();
            })
        }
    },[isUploadedImage])
   

    const removeImageFromArray=(e)=>{
        const index = e.target.id;
        let newPreview = [...filesUpload];    
        newPreview.splice(index,1);
        setfilesUpload(newPreview);
    }

    const thumbsContainer = {
        display: 'flex',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        marginTop: 16
      };
      
      const thumb = {
        display: 'flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 'auto',
        maxWidth:'100%',
        height: 'auto',
        maxHeight:140,
        padding: 4,
        boxSizing: 'border-box'
      };
      const thumbEdit = {
        display: 'flex',
        borderRadius: 2,
        marginBottom: 8,
        marginRight: 8,
        width: 'auto',
        maxWidth:'100%',
        height: 'auto',
        maxHeight:140,
        padding: 4,
        boxSizing: 'border-box'
      };
      const thumbInner = {
        display: 'flex',
        minWidth: '100%',
        overflow: 'hidden',
        position:'relative'
      };
      const thumbInnerEdit = {
        display: 'flex',
        minWidth: '128px',
        maxWidth:'128px',
        marginRight:'10px',
        overflow: 'hidden',
        position:'relative',
        marginTop: 16,
        minHeight: 114,
        maxHeight: 114,
        objectFit:'cover'
      };
         
      const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit:'cover'
      };
    const thumbs = filesUpload.map((file, index) => (
        <div style={index > 4 ? {display:'none'} : thumb} key={index}>
            <div style={thumbInner}>
                <img
                    src={file.file_image}
                    style={img}
                    id={index}
                    alt={file.name}
                />
                {/* <span
                    className={classes.thumbDelete}
                    id={index}
                    key={index}
                    onClick={(e) => {
                        removeImageFromArray(e);
                    }}
                    >
                    Delete
                </span> */}
            </div>
      </div>
    ));
    const [optionValue, setOptionValue]= useState([]);
    const [labelSelect, setlabelSelect]= useState("");
    const [dataEdit , setDataEdit] = useState([]);
    const [currentWeight, setCurrentWeight] = useState("");

    useEffect(()=>{
      axios.get(`${baseUrl}/product/${idProd}`, { 
            headers:{
            "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            "Content-Type": 'application/json'
        }}).then((response)=>{ 
            const resDataProduct = response.data;
            const idProduct = response.data.id;           
            const nameProduct = resDataProduct.name;
            const categoryProduct = resDataProduct.category;
            const priceProduct = resDataProduct.price;
            const qtyProduct = resDataProduct.qty;
            const urlVid = resDataProduct.video; 
            const variantsProduct = resDataProduct.variants;
            const weightProduct = resDataProduct.weight;
            const imagesProduct = resDataProduct.images;
            const descProduct = resDataProduct.description; 
            const locationProduct = resDataProduct.location;            
            const locationProductName = locationProduct.name;
            const locationCode = locationProduct.code;    
            
            const imgProds = [];
            imagesProduct.forEach(result => {
                imgProds.push(result.uri);
            });     
            setimgUri(imgProds);
            setimagesProd(imagesProduct)
            setUrlVideo(urlVid);
            setProductName(nameProduct);
            setCategory(categoryProduct);
            setPrice(priceProduct);
            setProvince(locationCode)
            setInputList(variantsProduct);          
            setProductDesc(descProduct);           
            setQty(qtyProduct);
            setWeightValue(weightProduct);
            setlabelSelect(locationProductName);     
            setEditProduct(resDataProduct);
            setDataEdit(resDataProduct);
            setIdProduct(idProduct);
            setIsEdit(true);

            
            
        }) 
        provinceJson();  
    },[])



    // 
    // 
    // const handleChangeProvince = selectedBot => {
    //     setProvinceData(selectedBot);
    //     setProvince(selectedBot.province_code);
    //     
    // }
    // const handleChangeProvinces = (event) =>{
    //     setProvince(event.ta);
    // }
    const handleChangeStock = (event) => {
        setStocksType(event.target.value);
    };
    const handleRadioChange = (event) => {
        setCategory(event.target.value);
    };

     const onchangeSelect = (event) => {
        setCurrentWeight(event.target.value);
    };

    // const options = [
    //     { value: 'gram' },
    //     { value: 'kg' },
    // ]

    // const optionsval = ['Option 1', 'Option 2'];

    // const [weightType, setWeightType] = useState(options[0]);
   
    // const onchangeSelect = (item) => {
    //     setCurrentWeight(null);
    //     setWeightType(item);
    // };

    
  
    const [weight, setWeightValue] = useState([]);


    const provinceJson = async () => { 
        // return await fetch(`/provinces.json`)
        // .then(res => res.json()) 
        await axios.get('/provinces.json').then((response)=>{
            
            const resOptionValue = response.data;
            setOptionValue(resOptionValue);
        })
    };

    


    

   const [isEdit, setIsEdit] = useState(false);
   const MySwal = withReactContent(Swal);
//    const editChangesProduct =(event)=>{
//     const editValue = event.target.value;
//     const cloneProducts = [...dataEdit];
//     

//    }


   const onSubmitUpdate = () => {
    if(files.length === 0){
      axios.post(`${baseUrl}/product/${prodId}/update`, {
            name:name,
            category:category,
            location:location,
            description:description,
            price:price,
            qty:stocksType === 'unlimited' ? null : qty,
            weight:weight,
            variants: variants,
            video: urlVideo,
            image:null
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }}).then(result => {
            if (result.status === 200) {
              MySwal.fire({
                icon: 'success',
                title: result.data.message
              }).then((result) => {
                window.location.href="/merchant/product";
            });
            //   window.location.href="/merchant/product"
            } 
            
        }).catch(e => {
            
            if (e.response.data.status_code === 400) {
                MySwal.fire({
                    icon: 'warning',
                    title: e.response.data.message
                })
            }
        });  
    }else{
      axios.post(`${baseUrl}/product/${prodId}/update`, {
            name:name,
            category:category,
            location:location,
            description:description,
            price:price,
            qty:qty,
            weight:weight,
            variants: variants,
            video: urlVideo,
            image:files
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }}).then(result => {
            if (result.status === 200) {
              MySwal.fire({
                icon: 'success',
                title: result.data.message
              }).then((result) => {
                window.location.href="/merchant/product";
            });
            //   window.location.href="/merchant/product"
            } 
            
        }).catch(e => {
            
            if (e.response.data.status_code === 400) {
                MySwal.fire({
                    icon: 'warning',
                    title: e.response.data.message
                })
            }
        });  
    }
  

    }

    const removeImage = (idx, imgName) =>{
      axios.post(`${baseUrl}/product/${idx}/image-delete`, { filename: imgName }, {
        headers: {
            "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }})
        .then((response) => {
            
            window.location.reload();
        });
    }

    return(
        <DashboardLayout>
             <Grid container spacing={1}>
                <Box display="flex" justifyContent="space-between" width="100%" className={classes.AddForm}>
                    <Typography color="textPrimary" variant="h6">
                        Edit Product
                    </Typography> 
                </Box>
                <Grid item xs={12} md={12}>
                {editProduct && 
                    <Box width="100%" style={{maxWidth:'92%', margin:'auto'}}>
                        <form onSubmit={(e)=>onSubmitUpdate(e.preventDefault())}>
                        <label>Upload Gambar</label>
                            <div className="formGroup" style={{marginBottom:'30px'}}>   
                            <div>
                                    <div className="uploadWrapper">
                                        {imagesProd.length < 6 && 
                                        <>                                        
                                        <div style={thumbEdit}>
                                        {imagesProd.map((prod, isx)=>{
                                            return(
                                                <div style={thumbInnerEdit}>
                                                    <img id={prod.filename} src={prod.uri} alt={prod.filename} key={isx} style={img}/>
                                                    {imagesProd.length === 1 ?<></> : <span onClick={()=>removeImage(idProd, prod.filename)} style={{position:'absolute', right:'0', cursor:'pointer',color:'#888', top:'0' }}><HighlightOffIcon /></span>}
                                                    
                                                </div>
                                            )
                                        })}
                                        {isUploaded && <>
                                            {fileSizeImage > maxfileSize ? <></> :  
                                                    <div style={thumbsContainer}>
                                                        {thumbs}
                                                    </div>
                                                }   
                                                </>
                                        } 
                                        {imagesProd.length === 1 && <>
                                            {filesUpload.length > 3 ? <></> :  
                                            <label className="upload--button">
                                                <input
                                                    onChange={onFileUpload}
                                                    id={1}
                                                    accept="image/*"
                                                    type="file"
                                                    />
                                                    <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                                    Upload
                                            </label>
                                            }
                                        </>
                                        }   

                                        {imagesProd.length === 2 && <>
                                            {filesUpload.length > 2 ? <></> :  
                                            <label className="upload--button">
                                                <input
                                                    onChange={onFileUpload}
                                                    id={1}
                                                    accept="image/*"
                                                    type="file"
                                                    />
                                                    <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                                    Upload
                                            </label>
                                            }
                                        </>
                                        }  

                                        {imagesProd.length === 3 && <>
                                            {filesUpload.length > 1 ? <></> :  
                                            <label className="upload--button">
                                                <input
                                                    onChange={onFileUpload}
                                                    id={1}
                                                    accept="image/*"
                                                    type="file"
                                                    />
                                                    <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                                    Upload
                                            </label>
                                            }
                                        </>
                                        }   

                                        {imagesProd.length === 4 && <>
                                            {filesUpload.length < 1 ?
                                            <label className="upload--button">
                                                <input
                                                    onChange={onFileUpload}
                                                    id={1}
                                                    accept="image/*"
                                                    type="file"
                                                    />
                                                    <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                                    Upload
                                            </label>  :  
                                            <></>
                                            
                                            }
                                        </>
                                        }   

                                                                        
                                        
                                       
                                        </div>
                                        {/* <div> 
                                            {fileSizeImage > maxfileSize ? <></> :  
                                                <div style={thumbsContainer}>
                                                    {thumbs}
                                                </div>
                                            }   
                                        </div> */}
                                        
                                        
                                        </>
                                        }
                                       
                                    </div>
                                    
                                    
                                </div>                             
                                
                        </div>
                        <div className="formGroup">
                            <TextField 
                                id="productName" 
                                required 
                                label="Nama Product" 
                                variant="outlined" 
                                size="small" 
                                fullWidth 
                                name={editProduct.name}
                                placeholder="Oleh-Oleh Khas Jawa"
                                value={name}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </div>

                        <div className="formGroup">
                                <InputLabel>Kategori</InputLabel>
                                <RadioGroup aria-label="category" name="category"  defaultValue={category} checked={category === "food"? "food": "souvenir" } edited={true} required onChange={handleRadioChange} style={{flexWrap:'nowrap',flexDirection:'row'}}>                            
                                    <FormControlLabel  value="food"  control={<Radio />} label="Food" />
                                    <FormControlLabel  value="souvenir" control={<Radio />} label="Souvenir" />
                                </RadioGroup>
                        </div>

                        <div className="formGroup">
                        <FormControl variant="outlined" fullWidth required>
                            <label>Asal Daerah Product</label>                            
                                 <Autocomplete
                                    id="productDaerah"
                                    freeSolo
                                    getOptionLabel={(lb) => lb.province_name}
                                    onChange={(e,v) => setProvince(v.province_code)}
                                    options={optionValue}
                                    defaultValue={{ province_code: optionValue.province_code, province_name: labelSelect }}
                                    renderInput={(params) => (
                                      <TextField {...params} onChange={(e) => setProvince(e.currentTarget.value)} variant="outlined" fullWidth size="small"/>
                                    )}
                                />
                                
                        </FormControl>
                        
                        </div>

                        <div className="formGroup">
                            <label>Description</label>
                            <Editor
                            apiKey="rn0nqyvccgnjuvu7wm3p7m4s62bkavlppnrmrigmaen7uvqw"
                            ref={editorRef}
                            onEditorChange={editorDescription}
                            initialValue={description}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        </div>
                        
                        <div className="formGroup">
                        <TextField
                            id="outlined-multiline-static"
                            label="Url Video"
                            fullWidth
                            size="small"
                            placeholder="Url Video"
                            variant="outlined"
                            value={urlVideo}
                            onChange={e => setUrlVideo(e.target.value)}
                            />
                        </div>
                        <div className="formGroup">
                            <Grid container spacing={3}>                                
                                <Grid item xs={6}>
                                    <TextField
                                    id="outlined-multiline-static"
                                    label="Harga Product"
                                    fullWidth
                                    required
                                    size="small"
                                    placeholder="Harga Product"
                                    variant="outlined"
                                    defaultValue={price}
                                    onChange={e => setPrice(e.target.value)}
                                    />
                                </Grid>   
                                <Grid item xs={6}>                                  
                                    <TextField
                                        label="Satuan Product"
                                        id="filled-start-adornment"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">gram</InputAdornment>,
                                        }}
                                        defaultValue={weight}
                                        fullWidth
                                        variant="outlined"
                                        />
                                </Grid>                     
                            </Grid>
                        </div>
                        <div className="formGroup">
                        <Grid container spacing={3}>
                                <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth size="small" required>
                                    <label id="stockProduct">Stock Product</label>
                                    <Select
                                    labelId="stockProduct"
                                    id="demo-simple-select-outlined"
                                    defaultValue={qty === null ? "unlimited" : "limited"}
                                    onChange={handleChangeStock}
                                    label="Stock Product"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="unlimited">Unlimited</MenuItem>
                                    <MenuItem value="limited">Terbatas</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                {stocksType !== 'unlimited' && 
                                <Grid item xs={6}>
                                    <label>Jumlah Stok Produk</label>                                    
                                    <TextField
                                        id="outlined-multiline-static"                                    
                                        fullWidth
                                        required
                                        size="small"
                                        placeholder="Jumlah Stok Produk"
                                        value={qty}
                                        variant="outlined"
                                        onChange={e => setQty(e.target.value)}
                                        />                                    
                                </Grid>   
                                }                     
                            </Grid>
                        </div>

                        <div className="formGroup">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                            <FormControl style={{ width: "100%" }}>
                                    <ChipInput
                                    className="customChipInput"
                                    variant="outlined"
                                    label="Variant Product"
                                    placeholder="Tambah Variant"
                                    size="small"
                                    value={variants}
                                    onChange={(chip) => handleSelectedTags(chip)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                    />
                                    </FormControl>
                            </Grid>
                                {/* <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth size="small" required>
                                <label>Satuan Product</label>
                                
                                <Select
                                    labelId="stockProduct"
                                    id="demo-simple-select-outlined"
                                    defaultValue={currentWeight}
                                    onChange={onchangeSelect}
                                    label="Stock Product"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="gram">gram</MenuItem>
                                    <MenuItem value="kg">kilogram</MenuItem>
                                    </Select>
                            
                                </FormControl>
                                
                                </Grid>
                                <Grid item xs={6}>
                                    <label>Berat Product</label>
                                    <TextField
                                    id="outlined-multiline-static"
                                    required
                                    fullWidth
                                    size="small"
                                    defaultValue={weight}
                                    onChange={e => setWeightValue(e.target.value)}
                                    // onChange={handleChange}
                                    placeholder="Berat"
                                    variant="outlined"
                                    />
                                </Grid>                         */}
                            </Grid>
                        </div>
                        <Button type="submit" color="primary" variant="outlined" fullWidth>Submit</Button>
                    </form>
                    </Box>
                    }    
                </Grid>               
             </Grid>
        </DashboardLayout>
        
    )
}

   