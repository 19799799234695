import React from 'react';
import DashboardLayout from './DashboardLayout';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import Box from '@material-ui/core/Box';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import Paper from '@material-ui/core/Paper';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import Button from '@material-ui/core/Button';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';

const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
      margin:'10px'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    subHeader:{
        fontSize:'12px',
    },
    btnVar:{
        margin:'2px',
        fontSize:'11px',
        padding:'5px'
    }
  }));
export default function Beranda () {
    const classes = useStyles();
    return(
        <React.Fragment>
            <DashboardLayout>           
                <Box flexGrow={1}>  
                    <Typography color="textPrimary" className={classes.link} variant="h6">
                        Rumah
                    </Typography>
                </Box>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary" className={classes.link}>
                            <DashboardOutlinedIcon className={classes.icon} />
                            Dashboard
                        </Typography>
                        <Link color="inherit" href="/dashboard"  className={classes.link}>
                            <HomeIcon className={classes.icon} />
                            Rumah
                        </Link>
                    </Breadcrumbs>
                </Box>
            <Box flexDirection="row" style={{width:'100%'}}>
                <Paper style={{padding:'10px'}}>
                <Typography color="textPrimary" className={classes.link} variant="h6">
                    Selamat Datang di Leh Oleh                    
                </Typography>
                <Typography color="textPrimary" className={classes.link} variant="p">                    
                    Ini adalah halaman dashboard untuk pengguna, berikut lintasan untuk anda.                   
                </Typography>
                        <Box display="flex" flexDirection="row" p={1} m={1} >
                            <Box p={1}>
                                <Typography color="textPrimary" className={classes.link}>
                                    <HomeIcon className={classes.icon} />
                                    Dashboard
                                </Typography>
                                <Button startIcon={<HomeIcon/>} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Beranda
                                </Button>
                                <Button startIcon={<StoreOutlinedIcon/>} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Daftar Sebagai Toko
                                </Button>
                            </Box>
                            <Box p={1}>
                                <Typography color="textPrimary" className={classes.link}>
                                    <AccountBalanceWalletOutlinedIcon className={classes.icon} />
                                    Dompet
                                </Typography>
                                <Button startIcon={<AccountBalanceWalletOutlinedIcon/>} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Dompet Saya
                                </Button>
                                <Button startIcon={<MonetizationOnOutlinedIcon />} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Isi Ulang Saldo
                                </Button>
                                <Button startIcon={<LocalAtmOutlinedIcon />} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Tarik Saldo
                                </Button>
                            </Box>
                            <Box p={1}>
                                <Typography color="textPrimary" className={classes.link}>
                                    <SettingsOutlinedIcon className={classes.icon} />
                                    Pengaturan
                                </Typography>
                                <Button startIcon={<SettingsOutlinedIcon />} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Profile
                                </Button>
                                <Button startIcon={<AccountBalanceOutlinedIcon />} variant="outlined" color="default" size="small" className={classes.btnVar}>
                                    Akun Bank
                                </Button>
                            </Box>
                        </Box>
                </Paper>
                </Box>
                
            
            </DashboardLayout>
        </React.Fragment>
        
    )
}