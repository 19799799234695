import React,{useState, useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({ 
    MuiFullWidth :{
        width:'100%',
        paddingBottom:'30px'
    }
}));

export default function TransactionOrder(){
    const classes = useStyles();
    const [balanceTransaction, setbalanceTransaction] = useState([]);
    

    const columns = [
        { label: "Tanggal", name: "date" },
        { label: "Nominal", name: "amount" }, 
        { label: "Type", name: "type" }, 
        { label: "Description", name: "description" },
        { label: "Status", name: "status" },
    ];

    const data = balanceTransaction.map((dtrx, idx)=>{
        return [
            dtrx.date,
            dtrx.type === "in" ? <span style={{color:'#08a723'}}> + {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dtrx.amount)}</span>: <span style={{color:'#ab0004'}}> - {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dtrx.amount)}</span>,
            dtrx.type,
            dtrx.description,
            dtrx.status
        ]
    })

    const options = {
        filterType: 'checkbox',
        jsonMode: true,
    };
    
  useEffect(()=>{
    getBalanceTrx();
  },[])

    const getBalanceTrx = () =>{
      axios.get(`${baseUrl}/user/wallet/transactions`, {
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
          })
        .then((response) => {          
            
            const dataWalletTrx = response.data.data;
            setbalanceTransaction(dataWalletTrx);            
          });
      }

return(    
    <React.Fragment>
        <div className={classes.MuiFullWidth}>
            <MUIDataTable
                title={"Riwayat Saldo"}            
                data={data}
                columns={columns}
                options={options}
                />         
        </div>
    </React.Fragment>
)

}


