import React, { useState, useEffect, useRef } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { useParams, Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import PhotoTemplate from '../img/watermark.png';
import Slider from "react-slick";
import Rating from '@material-ui/lab/Rating';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import PlusIcon from '@material-ui/icons/Add';
import MinIcon from '@material-ui/icons/Remove';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../App.css'
import IconStore from '../img/toko.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import StarIcon from '@material-ui/icons/Star';
import 'moment/locale/id'
import Alert from '@material-ui/lab/Alert';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ReactHtmlParser from "react-html-parser";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { baseUrl } from 'utils';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
    MainPage:{
        margin:'2em 0',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
          }
    }
  }))


  function ButtonIncrement(props) {
  
    return (
      <button onClick={props.onClickFunc}>
      <PlusIcon style={{fontSize:'16px', color:'#ab0004'}}/>
      </button>
    )
 }
 function ButtonDecrement(props) {
   
   return (
     <button onClick={props.onClickFunc}>
     <MinIcon style={{fontSize:'16px'}}/>
     </button>
   )
 }
 function Display(props) {
   return (
     <label >{props.message}</label>
   )
 }


export default function ProductDetail() {
  const { slug } = useParams();
  const [dataProduct, setDataProduct] = useState("");  
  const [qty, setQtyCart]= useState(1);
  const [productId, setProductId]= useState("");
  const [productSlug, setProductSlug]= useState("");
  const [domainMerchant, setdomainMerchant]= useState("");
  const [variant, setvariant]= useState("");
  const [profile, setProfile] = useState(''); 
  const [galleryImage, setgalleryImage] = useState([]);
  const [DataVariant, setDataVariant]= useState([]);
  const classes = useStyles();
  const imageDefault = PhotoTemplate.toString();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef();
  const slider2 = useRef();
  const history = useHistory();
  const [isOpenGallery, setisOpenGallery] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const incrementCounter = () => {
    if(dataProduct.qty !== 'unlimited'){
        if(qty < dataProduct.qty){
            setQtyCart(qty + 1)
        } else{
            MySwal.fire({
                icon: 'warning',
                title: 'Maaf jumlah pembelian melebihi stok toko'
            }).then(()=>{
                setQtyCart(dataProduct.qty);
            })
            
        }
        
    }else{
        setQtyCart(qty + 1)
    }
    

   };
  let decrementCounter = () => setQtyCart(qty - 1);
  if(qty<=1) {
    decrementCounter = () => setQtyCart(1);
  }

  const theme = useTheme();
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeIndexTab = (index) => {
    setValueTab(index);
  };
  

  const settings = {
    dots: false,
    infinite: true,
    arrows:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

    useEffect(() => {
     getProductDetail();
     ReviewProduct();
     DiscussionsProduct();
     getRatings();
     axios.get(`${baseUrl}/user/profile`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }).then((response)=>{
        const allProfile = response.data;
       
        setProfile(allProfile);       
      }).catch(error=> console.error(`Error: ${error}`));
    }, [productId]);

    const getProductDetail = () => {
      axios.get(`${baseUrl}/product/detail/${slug}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
        })
      .then((response) => {          
          // 
          const dataResponse = response.data;
          const dataResponseImage = response.data.images;
          const dataSlug = response.data.slug;
          const dataId = response.data.id;
          const varProduct = dataResponse.variants;
          const merDom = dataResponse.merchant.domain;
        //   const variantNotNull = varProduct.filter(obj => obj !== "");
          setdomainMerchant(merDom);
          setDataVariant(varProduct);             
          setDataProduct(dataResponse);          
          setgalleryImage(dataResponseImage)
          setProductId(dataId);
          setProductSlug(dataSlug)
       
      });
    }

    const [dataRating, setDataRating] = useState([])
    const getRatings = () =>{
        axios.get(`${baseUrl}/product/review/${slug}/ratings`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
        })
      .then((response) => {          
          // 
          const dataResponseRating = response.data;
          setDataRating(dataResponseRating);         
      });
        
    }
    const MySwal = withReactContent(Swal);


    const AddCart = async (event) =>{
        event.preventDefault();   
        if(dataProduct.variants !== null && dataProduct.variants.length > 0 && variant !== ""){
            await axios.post(`${baseUrl}/cart/add`, {
                product:productId,
                qty:qty,
                variant:variant
            },{headers:{
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": "application/json"
            }}).then(result => {
                MySwal.fire({
                    icon: 'success',
                    title: "Produk berhasil ditambahkan kedalam keranjang"
                }).then(()=>{
                    window.location.reload();
                })
             
                
            }).catch(e => {
              
                MySwal.fire({
                    icon: 'warning',
                    title: e.response.data.message
                })
            });  
        }else if(dataProduct.variants === null || dataProduct.variants.length === 0 ){
            await axios.post(`${baseUrl}/cart/add`, {
                product:productId,
                qty:qty,
                variant:variant
            },{headers:{
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": "application/json"
            }}).then(result => {
                MySwal.fire({
                    icon: 'success',
                    title: "Produk berhasil ditambahkan kedalam keranjang"
                }).then(()=>{
                    window.location.reload();
                })
              
                
            }) 
        }else{
            MySwal.fire({
                icon: 'warning',
                title: "Harap isi Variant"
            })
        }

        // else if(dataProduct.variants === null || dataProduct.variants.length === 0 && variant === ""){
        //     MySwal.fire({
        //         icon: 'warning',
        //         title: "Harap isi Variant"
        //     })

        // }
        
    }
    const AddtoCart = async (event) =>{
    event.preventDefault();   
    if(dataProduct.variants !== null && dataProduct.variants.length > 0 && variant !== ""){ 
        await axios.post(`${baseUrl}/cart/add`, {
            product:productId,
            qty:qty,
            variant:variant
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            "Content-Type": "application/json"
        }}).then(result => {
            MySwal.fire({
              icon: 'success',
              title: "Produk berhasil ditambahkan kedalam keranjang"
            }).then((result) => {
                window.location.href="/items/cart"
            });
          
            
        }).catch(e => {
          
            MySwal.fire({
                icon: 'warning',
                title: "Harap isi Variant"
            })
        });  
    }else if(dataProduct.variants === null || dataProduct.variants.length === 0 ){
        await axios.post(`${baseUrl}/cart/add`, {
            product:productId,
            qty:qty,
            variant:variant
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            "Content-Type": "application/json"
        }}).then(result => {
            MySwal.fire({
                icon: 'success',
                title: "Produk berhasil ditambahkan kedalam keranjang"
            }).then(()=>{
                window.location.href="/items/cart"
            })
          
            
        }) 
    }else{
        MySwal.fire({
            icon: 'warning',
            title: "Harap isi Variant"
        })
    }
    }

    const [reviews, setReviews] = useState([]);
    const ReviewProduct = () =>{
        axios.get(`${baseUrl}/product/review/${slug}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              
              const repreview = response.data.data;              
              setReviews(repreview);
          });
    }


    const [discussions, setDiscussions] = useState([]);
    const DiscussionsProduct = () =>{
        axios.get(`${baseUrl}/product/discussion/${slug}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              
              const repdiscuss = response.data.data;
              setDiscussions(repdiscuss);
          });
    }

    const [openDiscussion, setOpenDiscussion] = useState(false);
    const [editDiscussion, setEditDiscussion] = useState(false);

    const createDiscussion = () =>{
        setOpenDiscussion(true);
    }

  

    const handleCloseDiscussion = () =>{
        setOpenDiscussion(false);
    }

    const handleCloseEditDiscussion = () =>{
        setEditDiscussion(false);
    }
    const [content, setContent] = useState();

    const submitDiscussion = () =>{
        axios.post(`${baseUrl}/product/discussion/${slug}/create`,{
            content:content
        },{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              ;
              setOpenDiscussion(false);
              MySwal.fire({
                icon: 'success',
                title: "Diskusi telah dibuat"
              }).then((result) => {
                  window.location.reload();
              });
          });
        
    }

    const editComment = () =>{
        setEditDiscussion(true);
    }

    const editSubmitComment = (idUpd) =>{
        axios.post(`${baseUrl}/product/discussion/${slug}/${idUpd}/update`, {content:content},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
          .then((response) => {          
              ;
              setEditDiscussion(false);
              MySwal.fire({
                icon: 'success',
                title: "Diskusi telah berhasil di edit"
              }).then((result) => {
                  window.location.reload();
              });

          });
    }

    const deleteComment = (idDel) =>{
        axios.post(`${baseUrl}/product/discussion/${slug}/${idDel}/delete`, {},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
          .then((response) => {          
              ;
              MySwal.fire({
                icon: 'success',
                title: "Diskusi telah berhasil di hapus"
              }).then((result) => {
                  window.location.reload();
              });

          });
    }

    const handleReply = (idRepl) =>{
        axios.post(`${baseUrl}/product/discussion/${slug}/${idRepl}/reply`, {content:content},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
          .then((response) => {          
              ;
              setEditDiscussion(false);
              MySwal.fire({
                icon: 'success',
                title: "Diskusi telah berhasil di balas"
              }).then((result) => {
                  window.location.reload();
              });

          });
    }


    const productChat = (domain, slug) =>{
        axios.post(`${baseUrl}/chat/connect`,{merchant:domain},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((res)=>{    
          const uidchat = res.data.data.uid;  
          history.push('/discuss/product',{params: {uid_product: uidchat , slug_product : slug}})
        })   
      
    }

  return (
    <div className={classes.MainPage}>     
            
              {dataProduct &&
                    <Grid container spacing={3} >
                  
                <Grid item md={9} xs={12}>
                    <Paper style={{padding:'10px',marginBottom:'20px'}}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                        <div>
                            <img src={dataProduct.cover} className="galProductDetailCover" onClick={() =>setisOpenGallery(true)}/>
                        </div>
                        <div style={{display:'flex'}}>
                        {galleryImage.map((imgList, kkx)=>{
                            return(
                                <img src={imgList} className="galProductDetail" onClick={() =>setisOpenGallery(true)}/>
                                
                            )
                            })}   
                        </div>
                       
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div>{dataProduct.qty === "unavailable" &&  <Alert severity="warning">Stock Habis!</Alert>}</div>
                            <div
                                id="portal"
                                className="portal"
                                style={{position:'absolute',top:'-15px', zIndex:'9'}}/>
                            <h2 style={{marginBottom:'0'}}>{dataProduct.name}</h2>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <StarIcon style={{color:'#FCCE00'}}/>
                                <span style={{fontSize:'13px', margin:'0 5px'}}>{dataProduct.rating}</span>
                                <span style={{fontSize:'13px', margin:'0 5px'}}>({dataProduct.review.total} Ulasan)</span>
                            </div>
                            <div style={{fontSize:'24px',fontWeight:'bold', margin:'20px 0', color:'#AB070B'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataProduct.price)}</div>

                        
                            <div style={{margin:'10px 0'}}>
                               
                                <div>
                                    <span style={{color:'#333'}}>Berat : </span> <span style={{color:'#AB070B'}}>{dataProduct.weight.join(" ")}</span>
                                </div>
                                <div>
                                    <span style={{color:'#333'}}>Kategori : </span> <span style={{textTransform:'capitalize', color:'#AB070B'}}>{dataProduct.category}</span>
                                </div>

                                <div>
                                    <span style={{color:'#333'}}>Lokasi : </span> <span style={{textTransform:'capitalize', color:'#AB070B'}}>{dataProduct.location.name}</span>
                                </div>
                                
                                <div style={{margin:'20px 0'}}> 
                                    {ReactHtmlParser(dataProduct.description)} 
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                           
                    </Paper>

                    <div style={{borderBottom:'1px solid #AB070B'}}>
                            <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            >
                            <Tab label="Informasi Toko" {...a11yProps(0)} className="btntabs"/>
                            <Tab label="Ulasan" {...a11yProps(1)} className="btntabs"/>
                            <Tab label="Diskusi" {...a11yProps(2)} className="btntabs"/>
                            </Tabs>
                        </div>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={valueTab}
                            onChangeIndex={handleChangeIndexTab}
                            
                        >
                            <TabPanel value={valueTab} index={0} dir={theme.direction}>
                                <Paper style={{padding:'10px',margin:'20px 0'}}>
                                <Grid container spacing={1}>
                                    <Grid item md={1} xs={2} style={{ marginRight:'10px'}}>
                                        <img src={dataProduct.merchant.photo !== null ? dataProduct.merchant.photo.uri:IconStore} width="60" height="60" style={{borderRadius:'100%', border:'1px solid #ddd'}}/>
                                    </Grid>
                                    <Grid item md={5} xs={6} style={{borderRight:'1px solid #ddd', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <div>
                                    <Link to={{ pathname: "/store/" + dataProduct.merchant.domain }} style={{color:"#656565", textDecoration:'none'}}>
                                        <div style={{textTransform:'capitalize'}}>{dataProduct.merchant.name}</div>
                                    </Link>
                                        <div style={{textTransform:'capitalize',color:'#888',fontSize:'10px'}}>{dataProduct.location.name}</div>
                                    </div>

                                    <div style={{margin:'10px', minWidth:'80px'}}>
                                        <Button variant="outlined" size="small" className="BtnDetail" onClick={()=>productChat(dataProduct.merchant.domain, slug )}>Chat Toko</Button>
                                    </div>
                                    </Grid>
                                    <Grid item md={5} xs={4}>
                                        <div>Penilaian : <span style={{color:'#ab0004'}}>
                                        <Rating
                                            name="customerReviewStar"
                                            value={reviews.reduce(function (acc, obj) { return acc + obj.rating / reviews.length; }, 0)}
                                            readOnly
                                            size="small"
                                            precision={0.5}
                                        />    
                                        </span>
                                        </div>
                                        {/* <div>Bergabung : <span style={{color:'#ab0004'}}>{dataProduct.merchant.joined_at}</span></div> */}
                                        <div>Produk : <span style={{color:'#ab0004'}}>{dataProduct.merchant.total_product}</span></div>
                                    </Grid>
                                </Grid>                  
                                </Paper>

                                <Paper style={{padding:'10px',margin:'20px 0'}}>
                                <h4>Ulasan ({reviews.length})</h4>
                                <h5>{dataProduct.name}</h5>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <div>
                                    {reviews.length > 0 &&
                                        <div style={{display:'flex'}}>
                                            <div className="ratingBall">
                                                {reviews.reduce(function (acc, obj) { return acc + obj.rating / reviews.length; }, 0)}
                                                {/* {dataProduct.merchant.total_rating} */}
                                            </div>
                                            <div style={{padding:'20px'}}>
                                                <Rating
                                                    name="customerReviewStar"
                                                    value={reviews.reduce(function (acc, obj) { return acc + obj.rating / reviews.length; }, 0)}
                                                    readOnly
                                                    size="small"
                                                    precision={0.5}
                                                />
                                                <p style={{fontSize:'10px', margin:'0'}}> dari {reviews.length} Ulasan</p>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>                            
                                <div style={{width:'100%', display:'flex', alignItems:'center'}}>
                                {reviews.length > 0 ? <ul  style={{listStyleType:'none',width:'100%'}}>                          
                                    {Object.entries(dataRating).map(([rateKey, rateVal])=>{
                                        return(         
                                                                    
                                        <li style={{display:'flex', alignItems:'center',}}>
                                            <span style={{display:'flex', alignItems:'center',fontSize:'14px',marginRight:'20px'}}>
                                                {rateKey} <StarIcon style={{color:'#ffb400',fontSize:'18px'}} size="small"/>
                                            </span> 
                                            <span style={{width:'100%',display:'flex', alignItems:'center'}}>                                            
                                                <span style={{width:'100%', marginRight:'5px'}}><LinearProgress variant="determinate" value={rateVal}/></span>
                                                <span> {rateVal} </span>
                                            </span>
                                        </li>
                                        )
                                    })}
                                </ul> : <div>Belum ada ulasan dan rating</div>
                                }
                                
                                </div>                            
                            </div>
                        
                            
                            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                <h5>Foto Dari Pembeli</h5>
                                <div><a href="#!" style={{textDecoration:'none',color:'#ab0004', fontSize:'12px'}}>Lihat Semua</a></div>
                            </div>
                        
                                                    
                            <div style={{display:'flex'}}>
                                {reviews && reviews.map((revs, i)=>{
                                    return(  
                                        <React.Fragment>                              
                                            <div>
                                                {revs.photo === null ? <div></div> : <div className="imgReviews"><img src={revs.photo.uri} width="100" /></div> }
                                            </div>
                                        </React.Fragment>
                                    )
                                })} 
                            </div>                              
                        
                            
                            

                            <div style={{marginBottom:'30px'}}>
                                <h5>Daftar Ulasan</h5>
                                {/* {reviews.length > 0 ? <div><Button variant="outlined" size="small">Semua</Button> <Button variant="outlined" size="small">Dengan foto</Button></div>:<div>Belum ada ulasan atau review</div>} */}
                                
                            </div>
                            {/* ulasan  */}
                            {/* <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', marginBottom:'20px'}}><Button variant="contained" color="primary" size="small" onClick={createReview}>Buat Review / Ulasan</Button></div> */}
                            {reviews.length === 0 ? "" 
                            : <React.Fragment>
                                {reviews && reviews.map((rev, idx)=>{
                                    return (
                                        <div style={{display:'flex', borderBottom:'1px solid #ddd', paddingBottom:'10px',marginBottom:'20px'}} key={idx}>
                                            <div style={{width:'78px'}}>
                                                <img src={rev.user.photo === null ? imageDefault : rev.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                            </div>
                                                                    
                                            <div style={{margin:'0 10px',width:'200px'}}>
                                                <p style={{margin:'0'}}>{rev.user.name}</p>
                                                <span style={{color:'#888'}}>{rev.date}</span>
                                            </div>
                                            <div style={{margin:'0 30px',maxWidth:'500px',width:'100%'}}>
                                                <Rating
                                                    name="customerReviewStar"
                                                    value={rev.rating}
                                                    readOnly
                                                    size="small"
                                                />
                                                <p style={{margin:'0', color:'#888'}}>{rev.description}</p>
                                                {rev.photo === null ? <div></div> : <div><img src={rev.photo.uri} width="83"/></div>} 
                        
                                            </div>

                                                {/* <div style={{padding:'15px'}}>
                                                    <a href="#!" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Laporkan</a>
                                                </div> */}
                                            </div>
                                    )
                                })}
                            
                        </React.Fragment>}
                                                
                    </Paper>

                    <Paper style={{padding:'10px',margin:'20px 0'}}>
                                <h4>Diskusi ({discussions.length})</h4>
                                <h5>{dataProduct.name}</h5>
                                <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom: '1px solid #a9a9a9', padding:'10px 0', marginBottom:'20px'}}>
                                    Ada Pertanyaan? Diskusikan dengan penjual dan pengguna lainya <Button variant="contained" size="small" style={{background:'#ab0004', color:'#fff'}} onClick={createDiscussion}>Tulis Pertanyaan</Button>
                                </div>
                                {discussions.length === 0 ? "Belum ada diskusi produk" : 
                                <React.Fragment>
                                    {discussions && discussions.map((dsc, k)=>{
                                        return(
                                            <div key={k} style={{display:'flex', borderBottom:'1px solid #ddd', paddingBottom:'10px',marginBottom:'20px'}}>
                                                <div>
                                                    <img src={dsc.user.photo && dsc.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                </div>
                                            
                                                <div style={{margin:'0 30px',maxWidth:'600px',width:'100%'}}>
                                                    <span style={{margin:'0', color:'#ab0004', fontWeight:'bold'}}>{dsc.user.name}</span>
                                                    <span style={{color:'#888', margin:'0 10px'}}>{dsc.date}</span>
                                                    <p style={{margin:'10px 0', color:'#888'}}>{dsc.content} {dsc.user.name === profile.name ? <span><Button size="small" onClick={()=> editComment()}><CreateOutlinedIcon/></Button> <Button onClick={()=>deleteComment(dsc.id)} size="small"><DeleteOutlineOutlinedIcon/></Button></span>:<span></span>}</p> 
                                                    {dsc.reply.items.map((rep, ik)=>{
                                                        return(
                                                            <div style={{background:'#eee',padding:'10px',borderRadius:'10px',display:'flex', marginBottom:'10px'}} key={ik}>
                                                                <div>
                                                                <img src={rep.user.photo && rep.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                                </div>
                                                                <div style={{margin:'0 15px'}}>
                                                                    {/* {data.merchant.name} <span className="badgePj">Penjual</span> */}
                                                                    {rep.user.name}
                                                                    <span style={{color:'#888', margin:'0 10px'}}>{rep.date}</span>
                                                                    <p>{rep.content}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                        
                                                    })}
                                                
                                                    
                                                    <div className="discussionboxReply">
                                                        <div>
                                                            <img src={profile.photo} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                        </div>
                                                        <div style={{margin:'0 10px', width:'100%', display:'flex'}}>
                                                        <TextField
                                                            id="standard-multiline-flexible"
                                                            placeholder="Isi Komentar disini"
                                                            multiline
                                                            fullWidth
                                                            maxRows={4}
                                                            onChange={e => setContent(e.target.value)} 
                                                            />
                                                        <Button onClick={()=>handleReply(dsc.id)}>Reply</Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div style={{padding:'15px'}}>
                                                    <a href="#!" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Laporkan</a>
                                                </div> */}
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                                }
                                

                            </Paper>


                            </TabPanel>
                            <TabPanel value={valueTab} index={1} dir={theme.direction}>
                                
                                <Paper style={{padding:'10px',margin:'20px 0'}}>
                                <h4>Ulasan ({reviews.length})</h4>
                                <h5>{dataProduct.name}</h5>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <div>
                                    {reviews.length > 0 &&
                                        <div style={{display:'flex'}}>
                                            <div className="ratingBall">
                                                {reviews.reduce(function (acc, obj) { return acc + obj.rating / reviews.length; }, 0)}
                                                {/* {dataProduct.merchant.total_rating} */}
                                            </div>
                                            <div style={{padding:'20px'}}>
                                                <Rating
                                                    name="customerReviewStar"
                                                    value={reviews.reduce(function (acc, obj) { return acc + obj.rating / reviews.length; }, 0)}
                                                    readOnly
                                                    size="small"
                                                    precision={0.5}
                                                />
                                                <p style={{fontSize:'10px', margin:'0'}}> dari {reviews.length} Ulasan</p>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>                            
                                <div style={{width:'100%', display:'flex', alignItems:'center'}}>
                                {reviews.length > 0 ? <ul  style={{listStyleType:'none',width:'100%'}}>                          
                                    {Object.entries(dataRating).map(([rateKey, rateVal])=>{
                                        return(         
                                                                    
                                        <li style={{display:'flex', alignItems:'center',}}>
                                            <span style={{display:'flex', alignItems:'center',fontSize:'14px',marginRight:'20px'}}>
                                                {rateKey} <StarIcon style={{color:'#ffb400',fontSize:'18px'}} size="small"/>
                                            </span> 
                                            <span style={{width:'100%',display:'flex', alignItems:'center'}}>                                            
                                                <span style={{width:'100%', marginRight:'5px'}}><LinearProgress variant="determinate" value={rateVal}/></span>
                                                <span> {rateVal} </span>
                                            </span>
                                        </li>
                                        )
                                    })}
                                </ul> : <div>Belum ada ulasan dan rating</div>
                                }
                                
                                </div>                            
                            </div>
                        
                            
                            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                <h5>Foto Dari Pembeli</h5>
                                <div><a href="#!" style={{textDecoration:'none',color:'#ab0004', fontSize:'12px'}}>Lihat Semua</a></div>
                            </div>
                        
                                                    
                            <div style={{display:'flex'}}>
                                {reviews && reviews.map((revs, i)=>{
                                    return(  
                                        <React.Fragment>                              
                                            <div>
                                                {revs.photo === null ? <div></div> : <div className="imgReviews"><img src={revs.photo.uri} width="100" /></div> }
                                            </div>
                                        </React.Fragment>
                                    )
                                })} 
                            </div>                              
                        
                            
                            

                            <div style={{marginBottom:'30px'}}>
                                <h5>Daftar Ulasan</h5>
                                {/* {reviews.length > 0 ? <div><Button variant="outlined" size="small">Semua</Button> <Button variant="outlined" size="small">Dengan foto</Button></div>:<div>Belum ada ulasan atau review</div>} */}
                                
                            </div>
                            {/* ulasan  */}
                            {/* <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', marginBottom:'20px'}}><Button variant="contained" color="primary" size="small" onClick={createReview}>Buat Review / Ulasan</Button></div> */}
                            {reviews.length === 0 ? "" 
                            : <React.Fragment>
                                {reviews && reviews.map((rev, idx)=>{
                                    return (
                                        <div style={{display:'flex', borderBottom:'1px solid #ddd', paddingBottom:'10px',marginBottom:'20px'}} key={idx}>
                                            <div>
                                                <img src={rev.user.photo === null ? imageDefault : rev.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                            </div>
                                                                    
                                            <div style={{margin:'0 10px',width:'200px'}}>
                                                <p style={{margin:'0'}}>{rev.user.name}</p>
                                                <span style={{color:'#888'}}>{rev.date}</span>
                                            </div>
                                            <div style={{margin:'0 30px',maxWidth:'500px',width:'100%'}}>
                                                <Rating
                                                    name="customerReviewStar"
                                                    value={rev.rating}
                                                    readOnly
                                                    size="small"
                                                />
                                                <p style={{margin:'0', color:'#888'}}>{rev.description}</p>
                                                {rev.photo === null ? <div></div> : <div><img src={rev.photo.uri} width="83"/></div>} 
                        
                                            </div>

                                                <div style={{padding:'15px'}}>
                                                    <a href="#!" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Laporkan</a>
                                                </div>
                                            </div>
                                    )
                                })}
                            
                        </React.Fragment>}
                                                
                    </Paper>

                            </TabPanel>
                            <TabPanel value={valueTab} index={2} dir={theme.direction}>
                            <Paper style={{padding:'10px',margin:'20px 0'}}>
                                <h4>Diskusi ({discussions.length})</h4>
                                <h5>{dataProduct.name}</h5>
                                <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom: '1px solid #a9a9a9', padding:'10px 0', marginBottom:'20px'}}>
                                    Ada Pertanyaan? Diskusikan dengan penjual dan pengguna lainya <Button variant="contained" size="small" style={{background:'#ab0004', color:'#fff'}} onClick={createDiscussion}>Tulis Pertanyaan</Button>
                                </div>
                                {discussions.length === 0 ? "Belum ada diskusi produk" : 
                                <React.Fragment>
                                    {discussions && discussions.map((dsc, k)=>{
                                        return(
                                            <div key={k} style={{display:'flex', borderBottom:'1px solid #ddd', paddingBottom:'10px',marginBottom:'20px'}}>
                                                <div>
                                                    <img src={dsc.user.photo && dsc.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                </div>
                                            
                                                <div style={{margin:'0 30px',maxWidth:'600px',width:'100%'}}>
                                                    <span style={{margin:'0', color:'#ab0004', fontWeight:'bold'}}>{dsc.user.name}</span>
                                                    <span style={{color:'#888', margin:'0 10px'}}>{dsc.date}</span>
                                                    <p style={{margin:'10px 0', color:'#888'}}>{dsc.content} {dsc.user.name === profile.name ? <span><Button size="small" onClick={()=> editComment()}><CreateOutlinedIcon/></Button> <Button onClick={()=>deleteComment(dsc.id)} size="small"><DeleteOutlineOutlinedIcon/></Button></span>:<span></span>}</p> 
                                                    {dsc.reply.items.map((rep, ik)=>{
                                                        return(
                                                            <div style={{background:'#eee',padding:'10px',borderRadius:'10px',display:'flex', marginBottom:'10px'}} key={ik}>
                                                                <div>
                                                                <img src={rep.user.photo && rep.user.photo.uri} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                                </div>
                                                                <div style={{margin:'0 15px'}}>
                                                                    {/* {data.merchant.name} <span className="badgePj">Penjual</span> */}
                                                                    {rep.user.name}
                                                                    <span style={{color:'#888', margin:'0 10px'}}>{rep.date}</span>
                                                                    <p>{rep.content}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                        
                                                    })}
                                                
                                                    
                                                    <div className="discussionboxReply">
                                                        <div>
                                                            <img src={profile.photo} width="40" height="40" style={{borderRadius:'100%'}}/>
                                                        </div>
                                                        <div style={{margin:'0 10px', width:'100%', display:'flex'}}>
                                                        <TextField
                                                            id="standard-multiline-flexible"
                                                            placeholder="Isi Komentar disini"
                                                            multiline
                                                            fullWidth
                                                            maxRows={4}
                                                            onChange={e => setContent(e.target.value)} 
                                                            />
                                                        <Button onClick={()=>handleReply(dsc.id)}>Reply</Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{padding:'15px'}}>
                                                    <a href="#!" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Laporkan</a>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                                }
                                

                            </Paper>
                            </TabPanel>
                        </SwipeableViews>
                    </Grid>
               

                  <Grid item md={3} xs={12}>
                      <Paper style={{padding:'10px',marginBottom:'20px'}}>
                      <div style={{width:'100%', marginBottom:'20px'}}>
                        <h4>Pilih Varian</h4>
                        {DataVariant === null || DataVariant.length > 0 &&
                        
                        <select required style={{width:'100%', padding:'10px'}} onChange={(e)=>setvariant(e.target.value)}>
                            <option selected>-- Pilih Variant --- </option>
                            {DataVariant && DataVariant.map((vars,k)=>{
                                return(
                                    <option key={k} value={vars === "" ? "tidak ada" : vars}>{vars === "" ? "variant kosong" : vars}</option>
                                )
                            })}
                        </select>      

                        }
                        </div>
                        <h4>Atur Jumlah</h4>
                        <div className='wrapperQty'>
                        <div className='fieldButtonQty'>
                            <ButtonDecrement onClickFunc={decrementCounter}/>
                            <Display message={qty}/> 
                            <ButtonIncrement onClickFunc={incrementCounter}/>
                        </div>
                        <div style={{marginLeft:'10px'}}>Stock : {dataProduct.qty === "unavailable" ? "Kosong" : dataProduct.qty} </div>
                        </div>
                        {dataProduct.qty !== "unavailable" ? <>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'10px'}}>SubTotal  <span style={{float:'right',fontWeight:'bold', fontSize:'24px', color:'#ab0004'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataProduct.price * qty)}</span></div>
                        
                        <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                               <Button
                                     type="submit" 
                                      variant="contained"
                                      color="secondary"
                                      className="btnKeranjang"
                                      startIcon={<PlusIcon />}
                                      onClick={AddCart}
                                  >
                                      Keranjang
                                  </Button>
                                  
                              </Grid>
  
                              <Grid item md={12} xs={12}>
                                  <Button
                                      type="submit" 
                                      variant="outlined"
                                      className="btnBuy"                                     
                                      onClick={AddtoCart}
                                  >
                                      Beli Langsung
                                  </Button>
                              </Grid>
                          </Grid>
                          </> : <></> }
                          
                      </Paper>
                      {/* <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Button startIcon={<MessageIcon />} onClick={()=>productChat(domainMerchant, productSlug )}>Chat</Button>
                        <Button startIcon={<ShareIcon />}>Share</Button>
                        
                    </div> */}
                        
                  </Grid>

              </Grid>
            }
            
            

            <Dialog open={editDiscussion} aria-labelledby="form-dialog-title" disableBackdropClick="true">  
                <DialogTitle id="form-dialog-title">
                Edit Discussion
                <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseEditDiscussion}/></span>
                </DialogTitle>      
                    <DialogContent> 
                    {discussions.map((dssc, l)=>{
                        return(
                            <div key={l}>
                                {dssc.user.name === profile.name ? <div><TextField
                                id="reviews-id"                                
                                placeholder="Isi review"
                                variant="outlined"
                                fullWidth
                                defaultValue={dssc.user.name === profile.name ? dssc.content : ""}
                                onChange={e => setContent(e.target.value)}   
                                />
                                <Button variant="outlined" fullWidth style={{marginTop:'10px'}} onClick={()=>editSubmitComment(dssc.id)}>Submit</Button></div> : ""}
                                
                            </div>
                            
                        )
                        
                    })}
                    </DialogContent>
            </Dialog>

            <Dialog open={openDiscussion}  aria-labelledby="form-dialog-title" disableBackdropClick="true">  
            <DialogTitle id="form-dialog-title">
              Buat Diskusi
              <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseDiscussion}/></span>
            </DialogTitle>      
                <DialogContent> 
                <TextField
                    id="reviews-id"
                    placeholder="Isi Diskusi"
                    variant="outlined"
                    fullWidth
                    onChange={e => setContent(e.target.value)}   
                />
               
                <Button variant="outlined" fullWidth style={{marginTop:'10px'}} onClick={submitDiscussion}>Submit</Button>

                </DialogContent>
            </Dialog>


            
            {isOpenGallery && (
                    <Lightbox
                        style={{top:'125px'}}
                        mainSrc={galleryImage[photoIndex]}
                        nextSrc={galleryImage[(photoIndex + 1) % galleryImage.length]}
                        prevSrc={galleryImage[(photoIndex + galleryImage.length - 1) % galleryImage.length]}
                        onCloseRequest={() => setisOpenGallery(false)}
                        onMovePrevRequest={() =>
                            setphotoIndex((photoIndex + galleryImage.length - 1) % galleryImage.length)
                        }
                        onMoveNextRequest={() =>
                            setphotoIndex((photoIndex + 1) % galleryImage.length)
                      
                        }
                    />
             )}

            
    </div>
  );
}
// import React, { useState, useEffect } from 'react';
// import { useParams } from "react-router";
// import axios from 'axios';

// function ProductDetail() {
//     var { slug } = useParams(),
//     productSlug = findPostBySlug(slug);
//     const [data, setData] = useState({ data: [] });

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const fetchData = async () => {
//         const result = await axios.get(
//             `${baseUrl}/product/detail/${productSlug}`,
//         );

//         setData(result.data);
//         
//         };
   
     

//     return (
//         <div>
//             {data.category}
//         </div>
//     );
// }

// function findPostBySlug(slug) {
//     return fetchData.find(o => o.slug === slug);
//   }

// export default ProductDetail;

/* <div style={{display:'flex', borderBottom:'1px solid #ddd', paddingBottom:'10px',marginBottom:'20px'}}>
            <div>
                <img src={PhotoReview} width="40" style={{borderRadius:'100%'}}/>
            </div>
            <div style={{margin:'0 10px'}}>
                <p style={{margin:'0'}}>Udin</p>
                <span style={{color:'#888'}}>3 bulan lalu</span>
            </div>
            <div style={{margin:'0 30px',maxWidth:'500px',width:'100%'}}>
                <Rating
                    name="customerReviewStar"
                    value={5}
                    readOnly
                    size="small"
                />
                <p style={{margin:'0', color:'#888'}}>Rasanya mantap gan</p>
                <div style={{background:'#eee',padding:'10px',borderRadius:'10px',display:'flex'}}>
                    <div>
                        <img src={IconStore} width="40"/>
                    </div>
                    <div style={{margin:'0 15px'}}>
                        {data.merchant.name} <span className="badgePj">Penjual</span>
                        <div style={{color:'#888'}}>3 bulan lalu</div>
                        <p>Terimakasih telah berbelanja di {data.merchant.name} bagikan link toko kami <a href="#!">https://leholeh.co.id/oleh-oleh-bali</a> dapatkan reward kode voucher dan hadiah menarik lainya</p>
                    </div>
                    
                </div>
            </div>

            <div style={{padding:'15px'}}>
                <a href="#!" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Laporkan</a>
            </div>
        </div> */
            /* <Slider {...settings} className="galleryProduct" asNavFor={nav2} ref={slider1 => setNav1(slider1)}>          
            {galleryImage.map((imgList, kkx)=>{
                return(
                    <div key={kkx}>
                        <div>
                            <img src={imgList} style={{ width:'100%',objectFit:'cover', height:'450px',maxHeight:'450px', margin:'0 15px 0 0'}}/>
                        </div>
                    </div> 
                )
            })}                        
        </Slider>

        <Slider
            asNavFor={nav1}
            ref={slider2 => setNav2(slider2)}
            slidesToShow={galleryImage.length}
            swipeToSlide={true}
            focusOnSelect={true}
            className="galProductNav"
        >
            {galleryImage.map((imgListslideNav, kkdx)=>{
                return(
                    <div key={kkdx} data-id={kkdx}>
                        <div>
                            <img src={imgListslideNav} style={{ width:'75px',objectFit:'cover', height:'75px',maxHeight:'75px'}}/>
                        </div>
                    </div> 
                )
            })}         
        </Slider> */
        /* <ReactSlick {...{
                rimProps: {
                    enlargedImagePortalId: 'portal',
                    enlargedImageContainerDimensions: {
                        width: '200%',
                        height: '100%'
                    }
                }
            }}/> */