import React, {useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useParams, Link, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../App.css";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../../img/watermark.png';
import TextField from '@material-ui/core/TextField';
import ProductEmpty from "../../img/No Product (General).svg";
import Banner from "../../img/BannerMerchant.jpg";
import IconStore from '../../img/toko.png';
import StarIcon from '@material-ui/icons/Star';
import PaginationData from '../../components/Pagination';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  },
  boxDisplay:{
    display:'block',
    [theme.breakpoints.up('md')]: {
      display:'flex',
      alignItems:'center', 
      justifyContent:'space-between'
    }
    
  },
  boxDis1:{
    width: '100%',
    display: 'block',
    float: 'left',
    margin:'10px 0',
    [theme.breakpoints.up('md')]: {
      margin:'10px',
      float: 'none',
      width:'auto'
    }
    
  },
  boxDis2:{
    display: 'block',
    float: 'left',
    [theme.breakpoints.up('md')]: {
      float:'none',
      maxWidth:'530px'
    }
    
  },
  boxDis3:{
    display: 'block',
    float: 'right',
    textAlign:'center',
    [theme.breakpoints.up('md')]: {
      textAlign:'center',
      float:'none'
    }
    
  }

}))


function MerchantHome() {
   
    const classes = useStyles();
    const history = useHistory();
    const { slug } = useParams(); 
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(15);
    const [priceMin, setPriceMin]= useState();
    const [priceMax, setPriceMax]= useState();
    const [metaTotal , setMetaTotal] = useState();
    const imageDefault = PhotoTemplate.toString();
    const bannerDummy = Banner.toString();
    const [dataProductByStore, setdataProductByStore] = useState();
    const [dataProductStore, setdataProductStore] = useState([]);
    const [dataStore, setDataTotalStore]= useState();
    const [locStore, setDataLocation] = useState([]);
    const [domainStore, setdomainStore] = useState();
    const [categoryName, setCategoryName] = useState([]);
    const [filteredCategoryName, setFilteredCategoryName] = useState("");
    const [sortValue, setSortValue] = useState();
  

    const sortSubmit = () =>{
      axios.get(`${baseUrl}/merchant/detail/${slug}/products?page=${currentPage}`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: { sorting:sortValue}
          })
      .then((response) => {          
          const dataResponse = response.data.data;
          setdataProductStore(dataResponse);            
          const dataTotal = response.data.meta.total;
          const curPage = response.data.meta.current_page;
          // setCurPages(curPage);
          setMetaTotal(dataTotal);
          
      });
    }

    useEffect(()=>{
        if(sortValue !== ''){
          sortSubmit();
        }else{
          getDataPagingProduct();
        }
        getDataStore();
        
        if (categoryName.length === 0) {
          setFilteredCategoryName("");
        } else {
          setFilteredCategoryName(categoryName.join(","));
        }
    },[currentPage, categoryName, sortValue])

  

    const getDataStore = () =>{
      axios.get(`${baseUrl}/merchant/detail/${slug}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
        .then((response) => {          
            const dataResponse = response.data;
            const dataResTotal = response.data.total_product;
            const dataLocation = response.data.location;
            const domainToko = dataResponse.domain;
            setdomainStore(domainToko);
            setdataProductByStore(dataResponse);
            setDataTotalStore(dataResTotal);
            setDataLocation(dataLocation); 
          
        });
    }


    const getDataPagingProduct = () =>{
      axios.get(`${baseUrl}/merchant/detail/${slug}/products?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
        .then((response) => {          
          const dataResProduct = response.data.data;
          const dataResTotal = response.data.meta.total;
          setdataProductStore(dataResProduct);  
          setMetaTotal(dataResTotal)     
          
        });
    }


    // const [category, setCategory] = useState([]);
    const categoryProduct = [
      {
        name: 'food',
        lable: 'Kuliner'        
      },
      {
        name: 'souvenir',
        lable: 'Sovenir'        
      }
    ]

    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Populer',
        value: 'popular'        
      },
      {
        label: 'Tidak Populer',
        value: 'unpopular'        
      }
    ]

 

    const handleCategory = (e, value) => {
      if (e.target.checked) {
        setCategoryName([...categoryName, e.target.value]);
      } else {
        setCategoryName(categoryName.filter((name) => name !== e.target.value));
      }
    };
     

    const submitFilter = () =>{
        axios.get(`${baseUrl}/merchant/detail/${slug}/products?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { price: priceMin+'-'+priceMax, category: filteredCategoryName}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setdataProductStore(dataResponse);         
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            setMetaTotal(dataTotal);
            
        });
    }

    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/merchant/detail/${slug}/products?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { price: priceMin+'-'+priceMax }
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setdataProductStore(dataResponse);     
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
    });
    }

    const submitCategory = () =>{
      axios.get(`${baseUrl}/merchant/detail/${slug}/products?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: {category: filteredCategoryName}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setdataProductStore(dataResponse);
            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
            
        });
    }

    
      const NotFoundProduct = ProductEmpty.toString();


    const chatToko = (domain) =>{
      axios.post(`${baseUrl}/chat/connect`,{merchant:domain},{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
        }).then((res)=>{    
          const uidchat = res.data.data.uid;  
          history.push('/rooms/chat',{params:uidchat})
        })      
    }
      

    return (
      <div className={classes.MainPage}>
            <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div><img src={bannerDummy} width="100%" height="100%"/></div>
            
              <Grid item xs={12} md={12}>
                <div className={classes.boxDisplay}>                  
                  <div className={classes.boxDis1}>
                    <div style={{display:'flex', alignItems:'center'}}>
                      <div>
                        {dataProductByStore && dataProductByStore.photo === null ? <img src={IconStore} width="60"/> : <img src={dataProductByStore && dataProductByStore.photo.uri} width="60"/>}                        
                     </div>
                      <div>
                        {dataProductByStore && <p style={{margin:'0 10px 10px', fontSize:'20px', fontWeight:'bold'}}>{dataProductByStore.name}</p>}  
                        {dataProductByStore && <p style={{margin:'0 10px 10px 10px', fontSize:'10px'}}>{dataProductByStore.location === null ? "Lokasi Toko anda belum di setting" : dataProductByStore.location.province.name}</p>}
                        <Button variant="outlined" size="small" style={{borderColor:'#ab0004', color:'#ab0004', fontSize:'12px'}} onClick={()=>chatToko(domainStore)}>Chat Toko</Button> 
                      </div>
                      
                    </div>                                       
                    
                  </div> 
                  <div className={classes.boxDis2}>
                    <h3 style={{margin:'0'}}>Description</h3> 
                    {dataProductByStore && <p>{dataProductByStore.description === null ? "Deskripsi toko belum ada" : dataProductByStore.description}</p>}
                  </div>
                  <div className={classes.boxDis3}>
                    <h3 style={{margin:'0'}}>Total Product</h3>
                    <h1 style={{margin:'0'}}>{dataStore}</h1>
                  </div>  
                                 
                </div>
              </Grid>
              
              </Grid>
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Kategori</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            
                            {categoryProduct.map(({ name, lable}, index) => {
                              return ( 
                                <FormControlLabel
                                  key={index}
                                  control={
                                  <Checkbox
                                      id={`custom-checkbox-${index}`}
                                      value={name}
                                      onChange={handleCategory}
                                      name={name}
                                      color="primary"
                                  />
                                  }
                                  label={lable}
                              />
                              )})}
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitCategory()}>Terapkan</Button></div>
                        </Accordion>

                       
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={submitFilter}>Terapkan Semua</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper style={{padding:'10px'}}>                                         
                          <React.Fragment>
                              {dataProductStore.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /> <h4>Maaf, Product dari toko yang anda cari tidak ditemukan</h4></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductStore && dataProductStore.map((prodStore, inx)=>{
                                    return(
                                      <Grid item xs={12} md={3} key={inx}>
                                            <Card>
                                            <Link to={{ pathname: "/product/" + prodStore.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                                <CardActionArea>
                                                    <img src={prodStore.cover === null ? imageDefault : prodStore.cover} width="100%" style={{maxHeight:'132px',minHeight:'132px'}}/>
                                                    <CardContent>
                                                    <Typography gutterBottom variant="body2" component="p" style={{height:'40px', overflow:'hidden', textOverflow: 'ellipsis', whiteSpace:'nowrap'}}> 
                                                        {prodStore.name}
                                                    </Typography>                                                    
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prodStore.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                    <div style={{fontSize:'12px', height:'34px'}}>{prodStore.location.name}</div>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <StarIcon style={{color:'#FCCE00'}}/>
                                                        <span style={{fontSize:'13px', margin:'0 5px'}}>{prodStore.rating}</span>
                                                      </div>
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                    )
                                  }) 
                                      
                                        
                                   }
                                  </Grid>

                                  <PaginationData 
                                    stateChanger={setcurrentPage} 
                                    current={currentPage} 
                                    limit={itemsPerPage} 
                                    total_page={metaTotal} 
                                    loadData={getDataPagingProduct}
                                  />
                                </div>
                                
                              }
                               
                            </React.Fragment>
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default MerchantHome;