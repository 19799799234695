import React, {useState, useEffect, useRef} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import logo from '../img/logo-white.png';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
// import MenuList from '@material-ui/core/MenuList';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ProductEmpty from "../img/No Product (General).svg";
import Rating from '@material-ui/lab/Rating';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CategoryIcon from '@material-ui/icons/Category';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from 'utils';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  btnSeeMore:{
    background:'#ab0004', 
    color:'#fff', 
    position:'absolute', 
    top:'30px',
    right:'100px'
  },
  popoverPaper: {
    width: '100%',
    height: 'auto',
    maxHeight: '70%',
    left: '1% !important',
    top:'135px !important'
  },
  typeText :{
    fontSize: '14px',
    color:'#ab0004',
    [theme.breakpoints.up('xs')]: {
      color:'#fff',
    },
  }, 
  noDecor :{
    textDecoration:'none',
    '&:hover': {
      textDecoration:'none',
    },
},
noDecorLink : {
  textDecoration:'none',
  color:'#ab0004',
},
  menuButton: {
    marginRight: theme.spacing(2),
    color:'#fff',
  },
  menuButtonMbl:{
    marginRight: theme.spacing(2),
    color:'#fff',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbarCustom: {
    backgroundColor:'#ab0004',
    boxShadow:'none'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    padding:'16px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.up('xs')]: { 
      color:'#fff'
    }
    
  },
  cssLabel: {
    color : '#fff !important',
  }, 
  notchedOutline: {
    borderWidth: '1px',
    borderColor:'#fff !important',  
    color: '#fff',
  },

  cssLabelMobile: {
    color : '#fff !important',  
  }, 
  notchedOutlineMobile: {
    borderWidth: '1px',
    borderColor: '#fff !important',
    color: '#fff',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'#737373',
  },
  inputRoot: {
    color: 'secondary',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    width:'100%',
    justifyContent:'space-between',
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('xs')]: { 
      background:'#ab0004',
      color:'#fff'
    }
    
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerHeaderIcon:{
    [theme.breakpoints.up('xs')]: { 
      color:'#fff'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const options = ['produk'];
export default function ToolBarMenu() {
  const classes = useStyles();
  const theme = useTheme();
  const MySwal = withReactContent(Swal)
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const [open, setOpen] = useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [openKategori, setOpenKategori] = useState(false);
  const [openKategoriItem, setOpenKategoriItem] = useState(false);
  const [anchorElMg, setAnchorElMg] = useState(null);
  const [expandIcon, setExpandIcon] = useState(false);
  const NotFoundProduct = ProductEmpty.toString();
  const [valueTab, setValueTab] =useState(0);
  axiosRetry(axios, { retries: 3 });
  const handleChangeTab = (event, newValue) => {
      setValueTab(newValue);
  };
  const handleClickPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const id = open ? 'simple-popover' : undefined;
  const [selectedOption, setselectedOption] = useState();
  const handleClickButtonMenu = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    setselectedOption(`${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    if(index === 0){
      setselectedOption('food');
    }else{
      setselectedOption('souvenir');
    }
    setSelectedIndex(index);
    setOpenPopupMenu(false);
  };


  const handleToggle = () => {
    setOpenPopupMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleClickMegaMenu = (event) => {
    // 
    setAnchorElMg(event.currentTarget);
    setExpandIcon(true);
  }
  const [dataProvince, setDataProvince]= useState([]);

  const provinceJson = async () => { 
    axios.get(`/provinces.json`)
    .then((response)=>{
   
        const dataProv = response.data;
        setDataProvince(dataProv);
    })
};

  useEffect(()=>{
    provinceJson();
  },[])

  const handleCloseMegaMenu = () => {
    setAnchorElMg(null);
    setExpandIcon(false);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpenKategori = () => {
    setOpenKategori(true);
    setOpen(false);
  };
  const handleDrawerCloseKategori = () => {
    setOpenKategori(false);
    setOpen(true);
  };
  const handleDrawerOpenKategoriItem = () => {
    setOpenKategoriItem(true);
    setOpen(false);
    setOpenKategori(false);
  };
  const handleDrawerCloseKategoriItem = () => {
    setOpenKategoriItem(false);
    setOpenKategori(true);
  };

  const linkToDetail = (slug) =>{
    window.location.href=`/detail/${slug}`
  }

  const linkToFoodLocation = (link,cat) =>{
    history.push('/foodcategoryhomeloc/food',{params: {
      loc:link,
      cat:cat
    }})
  }

  const linkToSouvLocation = (souv,catsouv) =>{
    history.push('/souvcategoryhomelocation/souvenir',{params: {
      loc:souv,
      cat:catsouv
    }})
  }

  

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const[productSearch, setProductSearch] = useState('');
  const history = useHistory();
  const searchProduct = (event)=>{
    if(event.key === 'Enter'){     
      axios.get(`${baseUrl}/product/search`, {
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { keyword: productSearch }
        })
      .then((response) => {          
          const dataResponse = response.data;      
          // window.location.href="/search/product"
          history.push('/search/product-home',{params: productSearch})
        });
    }
    
    
  }

  const searchOnProduct = ()=>{
    if(productSearch !== ''){     
      axios.get(`${baseUrl}/product/search`, {
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { keyword: productSearch }
        })
      .then(() => {          
          history.push('/search/product',{params: productSearch})
        });
    }else{
      MySwal.fire({
        icon: 'warning',
        title: "Keyword tidak boleh kosong!"
      })
    }
    
    
  }
  const[catLocationProductFood, setLocationProductFood]= useState([]);
  const[catLocationProductSouvenir, setLocationProductSouvenir]= useState([]);

  const [isLoading, setisLoading]= useState(false);

  const handleCategoryByLocation = async (location_code) =>{
    try {
      setisLoading(true);    

      await axios.get(`${baseUrl}/home/products-category/${location_code}`, {
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
        })
      .then((response) => {          
          const dataResponseCategoryFood = response.data.food;
          const dataResponseCategorySouvenir = response.data.souvenir;
          setLocationProductFood(dataResponseCategoryFood);
          setLocationProductSouvenir(dataResponseCategorySouvenir);
  
        });
    
    } finally{
      setTimeout(() => {
        setisLoading(false);
      }, 1000);
      
    }
    
  }
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profil</MenuItem>
      <MenuItem onClick={handleMenuClose}>Akun Saya</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="secondary">
          <Badge badgeContent={0} color="inherit">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifikasi</p>
      </MenuItem>      
    </Menu>
  );

  const redirectToHomepage = () =>{
    window.location.href="/"
  }

  const redirectToLogin = () =>{
    window.location.href="/login"
  }

  const handleRegister = () => {
    window.location.href="/register"
  }
 
  return (
     
      <div className={classes.grow}>
      <AppBar position="static" className={classes.toolbarCustom}>
      <Container>
          <Toolbar>
            <div className={classes.sectionDesktop}>
                <div>
                  <img src={logo} height='60' alt="Logo" onClick={redirectToHomepage} style={{cursor:'pointer'}}/>
                  <div onClick={handleClickMegaMenu} className='categoryClass'>
                    <CategoryIcon/> Kategori <span style={{display:'flex'}}>{expandIcon === true ?  <ExpandLessIcon /> : <ExpandMoreIcon/>}</span>
                  </div>
                </div>
               
                <Menu
                  PopoverClasses={{paper: classes.popoverPaper}}
                    id="customized-menu"
                    anchorEl={anchorElMg}
                    anchorOrigin={{ vertical: 'bottom' }}
                    transformOrigin={{ vertical: 'top' }}
                    getContentAnchorEl={null}
                    open={Boolean(anchorElMg)}
                    onClose={handleCloseMegaMenu}
                  >
                    <div style={{padding:'20px 80px'}}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3} style={{borderRight:'1px solid #ddd'}}>
                        <ul style={{listStyleType:'none', maxHeight:'380px', overflowY:'auto'}}>
                        {dataProvince.map((prov,index)=>{
                              return(
                                <li key={index} style={{margin:'10px 0'}}><a href="javascript:void(0)" onClick={(e)=>handleCategoryByLocation(e.currentTarget.dataset.location)} data-location={prov.province_code} style={{textDecoration:'none', color:'#000'}}>{prov.province_name}</a></li>
                              )
                          })}
                        </ul>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="simple tabs example" style={{width:'100%'}}>
                            <Tab label="Kuliner" {...a11yProps(0)} />
                            <Tab label="Sovenir" {...a11yProps(1)} />
                        </Tabs>

                        <TabPanel value={valueTab} index={0}>
                        <React.Fragment>
                        {isLoading ? <div style={{textAlign:'center',margin:'40px'}}> <CircularProgress /> <div>Loading ....</div> </div> : 
                          <>
                        {catLocationProductFood.length === 0 ? <div style={{textAlign:'center',margin:'40px'}}><img src={NotFoundProduct} height="300"/></div> : 
                          <div>
                           
                            <Grid container spacing={3}>   
                            {catLocationProductFood.map((food, k)=>{
                            return( 
                                <Grid item xs={12} md={3} key={k}>
                                  <Button variant="contained" size="small" className={classes.btnSeeMore} onClick={()=>linkToFoodLocation(food.location.code, food.category)}>Lihat Semua</Button>
                                  {catLocationProductFood.length > 0 &&
                                          <Card>
                                          <Link onClick={()=>linkToDetail(food.slug)} style={{color:"#656565", textDecoration:'none'}}>
                                              <CardActionArea>
                                                  <img src={food.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                  <CardContent>
                                                  <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                      {food.name}
                                                  </Typography>
                                                  <Typography variant="body2"  component="p">
                                                      <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(food.price)}</strong></div>
                                                  </Typography>
                                                  <Typography variant="body2" component="p">
                                                      <span style={{fontSize:'12px'}}>{food.location && food.location.name}</span> <br />
                                                      {food.rating === null ?  <Rating
                                                            name="simple-controlled"
                                                            value={0}
                                                            disabled
                                                        /> :  <Rating
                                                        name="simple-controlled"
                                                        value={food.rating}
                                                        disabled
                                                    />}
                                                  </Typography>
                                                  </CardContent>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                          }
                                      </Grid>)
                              })}        
                                  
                            
                            </Grid>
                          </div>
                          
                        }   
                        </>
                        }                            
                        </React.Fragment>
                        </TabPanel>

                        <TabPanel value={valueTab} index={1}>
                        <React.Fragment>
                        {isLoading ? <div style={{textAlign:'center',margin:'40px'}}> <CircularProgress /> <div>Loading ....</div> </div> : 
                          <>
                        {catLocationProductSouvenir.length === 0 ? <div style={{textAlign:'center', margin:'40px'}}><img src={NotFoundProduct}  height="300"/></div> : 
                          <div>                            
                            <Grid container spacing={3}>   
                            {catLocationProductSouvenir.map((souv, l)=>{
                            return( 
                                <Grid item xs={12} md={3} key={l}>
                                  <Button variant="contained" size="small" className={classes.btnSeeMore} onClick={()=>linkToSouvLocation(souv.location.code, souv.category)}>Lihat Semua</Button>
                                      {catLocationProductFood.length > 0 &&
                                          <Card>
                                          <Link onClick={()=>linkToDetail(souv.slug)} style={{color:"#656565", textDecoration:'none'}}>
                                              <CardActionArea>
                                                  <img src={souv.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                  <CardContent>
                                                  <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                      {souv.name}
                                                  </Typography>                                                
                                                  <Typography variant="body2"  component="p">
                                                      <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(souv.price)}</strong></div>
                                                  </Typography>
                                                  <Typography variant="body2" component="p">
                                                      <span style={{fontSize:'12px'}}>{souv.location && souv.location.name}</span> <br />
                                                      {souv.rating === null ?  <Rating
                                                            name="simple-controlled"
                                                            value={0}
                                                            disabled
                                                        /> :  <Rating
                                                        name="simple-controlled"
                                                        value={souv.rating}
                                                        disabled
                                                    />}
                                                  </Typography>
                                                  </CardContent>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                        }
                                      </Grid>)
                              })}        
                                  
                            
                            </Grid>
                          </div>
                          
                        }             
                        </>
                        }                  
                        </React.Fragment>
                        </TabPanel>                        
                      </Grid>
                    </Grid>
                    </div>
                </Menu>
                <div className={classes.search} >
                    <Typography component="div" style={{ display: { xs: 'none', sm: 'none', lg:'block' } }}>             
                    <div className="searchBarInput">
                    {/* <ButtonGroup variant="text" ref={anchorRef} aria-label="split button" style={{margin: '0 0 0 10px'}}>
                      <Button variant="text" onClick={handleClickButtonMenu} style={{borderRight:'none', color:'#000'}}>{options[selectedIndex]}</Button>
                      <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        variant="text"
                        onClick={handleToggle}
                      >
                        <KeyboardArrowDownIcon style={{color:'#A70B0B'}}/>
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={openPopupMenu}
                      className="popupMenu"
                      anchorEl={anchorRef.current}
                      role={undefined}
                      style={{width:'120px'}}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu" style={{padding:'0'}}>
                                {options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                    style={{textTransform:'uppercase'}}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    <div style={{borderRight:'1px solid #ddd'}}></div> */}
                    <div style={{width:'100%'}}>
                    <TextField
                      id="outlined-start-adornment"
                      variant="standard"
                      fullWidth
                      autoFocus
                      style={{paddingLeft:'5px'}}
                      onChange={(e)=>setProductSearch(e.target.value)}
                      onKeyPress={(e)=>searchProduct(e)}
                      placeholder='Ketik nama produk di sini lalu tekan enter'
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: 
                        <InputAdornment position="end" style={{cursor:'pointer'}} onClick={()=>searchOnProduct()}>
                          <div className="searchIconEnd"><SearchIcon/></div>
                        </InputAdornment>,
                      }}
                    />
                    </div>
                    </div>
                  </Typography>
   
                </div>

                <div style={{display:'flex', justifyContent:'space-between', width:'170px'}}>
                  <Button variant="outlined" size="small" style={{borderColor:'#fff', color:'#fff'}} onClick={redirectToLogin}>Masuk</Button>
                  <Button variant="outlined" size="small" style={{background:'#fff', color:'#ab0004'}} onClick={handleRegister}>Daftar</Button>
                </div>


            </div>
            <div className={classes.grow} />
            <div className={classes.sectionMobile}>
              <IconButton
                edge="start"
                className={classes.menuButtonMbl}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
              <MenuIcon />

              </IconButton>
              <img src={logo} height='40' alt="Logo" onClick={redirectToHomepage} style={{cursor:'pointer'}}/>
              {/* <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="secondary"
              >
                <MoreIcon /></IconButton> */}
                <Button size="small" style={{color:'#fff'}} onClick={redirectToLogin}>Login</Button>
            </div>
          </Toolbar>
          </Container>
        </AppBar>
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} className={classes.drawerHeaderIcon}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </div>
            <Divider />
            <div className={classes.search} >
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Cari Oleh - Oleh Disini"
                  onChange={e =>setProductSearch(e.target.value)}
                  onKeyPress={(e)=>searchProduct(e)}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssLabel,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.notchedOutline,
                      focused: classes.notchedOutline,
                      notchedOutline: classes.notchedOutline,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{color:'#fff'}}/>
                      </InputAdornment>
                    ),
                  }}
                />
   
                </div>
           
            <Link href="/" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <HomeOutlinedIcon style={{margin:'0 10px'}}/>
                     Rumah
                </IconButton>
            </Link>  
           
            
            <Link onClick={handleDrawerOpenKategori} className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <CategoryOutlinedIcon style={{margin:'0 10px'}}/>
                     Kategori
                </IconButton>
            </Link>  
           
            <Link href="/help" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <ContactSupportOutlinedIcon style={{margin:'0 10px'}}/>
                    Bantuan                
                </IconButton>
            </Link>  
           
            <Link href="/faq" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <HelpOutlineOutlinedIcon style={{margin:'0 10px'}}/>
                    F.A.Q                
                </IconButton>
            </Link>
           
            <Link href="/terms" className={classes.noDecor}>                
                <IconButton className={classes.typeText}>
                  <DescriptionOutlinedIcon style={{margin:'0 10px'}}/>
                    Syarat dan Ketentuan         
                </IconButton>                
            </Link> 
            <Link href="/contact" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                  <ContactsOutlinedIcon style={{margin:'0 10px'}}/>
                  Kontak Kami      
                </IconButton> 
            </Link> 
            <Link href="/blog" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                  <ListAltOutlinedIcon style={{margin:'0 10px'}}/>
                  Blog     
                </IconButton> 
            </Link> 
        </Drawer>


        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openKategori}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerCloseKategori} className={classes.drawerHeaderIcon}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </div>
            <Divider />
            <ul style={{listStyleType:'none'}}>
              {dataProvince.map((prov,index)=>{
                    return(
                      <li key={index} style={{margin:'10px 0'}}><a href="javascript:void(0)" onClick={(e)=>{handleCategoryByLocation(e.currentTarget.dataset.location);handleDrawerOpenKategoriItem()}} data-location={prov.province_code} style={{textDecoration:'none', color:'#fff'}}>{prov.province_name}</a></li>
                    )
                })}
            </ul>

        </Drawer>



        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openKategoriItem}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerCloseKategoriItem} className={classes.drawerHeaderIcon}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </div>
            <Divider />
            <Tabs value={valueTab} onChange={handleChangeTab} centered aria-label="simple tabs example" style={{width:'100%'}}>
                            <Tab label="Kuliner" {...a11yProps(0)} />
                            <Tab label="Sovenir" {...a11yProps(1)} />
                        </Tabs>

                        <TabPanel value={valueTab} index={0}>
                        <React.Fragment>
                        {catLocationProductFood.length === 0 ? <div style={{textAlign:'center',margin:'40px'}}><img src={NotFoundProduct} /></div> : 
                          <div>
                            <Grid container spacing={3}>   
                            {catLocationProductFood.map((food, k)=>{
                            return( 
                                <Grid item xs={12} md={3} key={k}>
                                        {catLocationProductFood.length > 0 && 
                                          <Card>
                                          <Link onClick={()=>linkToDetail(food.slug)} style={{color:"#656565", textDecoration:'none'}}>
                                              <CardActionArea>
                                                  <img src={food.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                  <CardContent>
                                                  <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                      {food.name}
                                                  </Typography>
                                                  <Typography variant="body2"  component="p">
                                                      <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(food.price)}</strong></div>
                                                  </Typography>
                                                  <Typography variant="body2" component="p">
                                                      <span style={{fontSize:'12px'}}>{food.location && food.location.name}</span> <br />
                                                      {food.rating === null ?  <Rating
                                                            name="simple-controlled"
                                                            value={0}
                                                            disabled
                                                        /> :  <Rating
                                                        name="simple-controlled"
                                                        value={food.rating}
                                                        disabled
                                                    />}
                                                  </Typography>
                                                  </CardContent>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                          }
                                      </Grid>)
                              })}        
                                  
                            
                            </Grid>
                          </div>
                          
                        }                               
                        </React.Fragment>
                        </TabPanel>

                        <TabPanel value={valueTab} index={1}>
                        <React.Fragment>
                        {catLocationProductSouvenir.length === 0 ? <div style={{textAlign:'center', margin:'40px'}}><img src={NotFoundProduct} /></div> : 
                          <div>
                            <Grid container spacing={3}>   
                            {catLocationProductSouvenir.map((souv, l)=>{
                            return( 
                                <Grid item xs={12} md={3} key={l}>
                                          <Card>
                                          <Link onClick={()=>linkToDetail(souv.slug)} style={{color:"#656565", textDecoration:'none'}}>
                                              <CardActionArea>
                                                  <img src={souv.cover} width="100%" style={{maxHeight:'132px'}}/>
                                                  <CardContent>
                                                  <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                      {souv.name}
                                                  </Typography>                                                
                                                  <Typography variant="body2"  component="p">
                                                      <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(souv.price)}</strong></div>
                                                  </Typography>
                                                  <Typography variant="body2" component="p">
                                                      <span style={{fontSize:'12px'}}>{souv.location.name}</span> <br />
                                                      {souv.rating === null ?  <Rating
                                                            name="simple-controlled"
                                                            value={0}
                                                            disabled
                                                        /> :  <Rating
                                                        name="simple-controlled"
                                                        value={souv.rating}
                                                        disabled
                                                    />}
                                                  </Typography>
                                                  </CardContent>
                                              </CardActionArea>
                                            </Link>
                                          </Card>
                                      </Grid>)
                              })}        
                                  
                            
                            </Grid>
                          </div>
                          
                        }                               
                        </React.Fragment>
                        </TabPanel>      

        </Drawer>


       

        {renderMobileMenu}
        {renderMenu}
      </div>
   
  );
}