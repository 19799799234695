import React, { useState} from "react";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import { baseUrl} from "utils";

const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  const classes = useStyles();
  const [username, setUsername] = useState(''); 
  const [token, setToken] = useState(''); 
  const MySwal = withReactContent(Swal);
  const history = useHistory();

  const handleSubmit = async e => {   
    e.preventDefault();  
    await axios.post(`${baseUrl}/auth/password-check`, {
        username
    }).then((result) => {
    
      MySwal.fire({
        icon: 'success',
        title: "Kode OTP telah dikirim, Silahkan check email / no hp anda!"
      }).then(async()=>{
        const { value: otp } = await MySwal.fire({
          title: 'Masukan OTP',
          input: 'number',
          inputPlaceholder: 'Masukan OTP',
          allowOutsideClick:false
        })        
        if (otp) {
          axios.post(`${baseUrl}/auth/password-verify`, {
            username: username,
            password:otp
          }).then((resp)=>{
            const dataToken = resp.data.data.token;
            MySwal.fire({
              icon: 'success',
              title: "Kode OTP valid"
            }).then(()=>{
              history.push('/reset/password',{params:{token: dataToken, username:username}})
            })
          })
        }
      })
        
    }).catch(e => {
        
        MySwal.fire({
          icon: 'warning',
          title: username + " tidak terdaftar!",
        })
    });    

   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <img src={BgLogin} alt=""/> 
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>        
            <form className={classes.form} onSubmit={handleSubmit} noValidate >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email atau No Hp"
                name="username"
                autoComplete="off"
                onChange={e => setUsername(e.target.value)}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}


