import React, { useContext, useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import Button from '@material-ui/core/Button';
import { useHistory  } from 'react-router-dom';
import ContextGlobal from "./data/useContextGlobal";
import { Divider } from '@material-ui/core';
import '../App.css';
import PhotoTemplate from '../img/blank-profile-picture.png';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
// import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
// import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
// import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
// import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
// import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
// import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
// import DescriptionIcon from '@material-ui/icons/Description';
// import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


export default function ListingItem(){
  const classes = useStyles();
  const context = useContext(ContextGlobal);
  const [openToko, setOpenToko] = useState(true);
  const [openProfile, setOpenProfile] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const profile = context.profile;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const imageDefault = PhotoTemplate.toString();


  const toLocation = () =>{
    history.push('/location')
  }


  const handleClickToko = () => {
    setOpenToko(!openToko);
  };

  const handleClickProfile = () => {
    setOpenProfile(!openProfile);
  };

  const handleClickOrder = () => {
    setOpenOrder(!openOrder);
  };

  
  return(
    <div>
      <div style={{display:'flex', alignItems:'center'}}>        
        { profile && profile.photo === null ? 
        (<img src={imageDefault} style={{margin:'10px',borderRadius:'100%', borderColor:'#ababab'}} width={40} height={40}/>)
        :
        (<img src={profile && profile.photo} style={{margin:'10px',borderRadius:'100%', objectFit:'contain', border:'1px solid #ababab'}} width={40} height={40}/>)}  <strong>{profile && profile.name}</strong>
      </div>
      <Divider/>
       <ListItem button onClick={handleClickProfile} style={{maxWidth: '214px'}}>       
        <ListItemText primary="Profil" className="listTextHeading"/>
        {openProfile ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openProfile} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/profile" className={classes.nested}>         
            <ListItemText primary="Profil Saya" className="listText"/>
          </ListItem>
          <ListItem button component={Link} to="/account/saldo" className={classes.nested}>          
              <ListItemText primary="Saldo" className="listText"/>
            </ListItem>    
        </List> 
      </Collapse>  

 

      <ListItem button onClick={handleClickOrder} style={{maxWidth: '214px'}}>
        <ListItemText primary="Pesanan" className="listTextHeading"/>
        {openOrder ? <ExpandLess /> : <ExpandMore />}
      </ListItem> 

      <Collapse in={openOrder} timeout="auto" unmountOnExit>   
        <List component="div" disablePadding>
            <ListItem button component={Link} to="/order/purchased" className={classes.nested}>
              <ListItemText primary="Pesanan Saya" className="listText"/>
            </ListItem>
            <ListItem button component={Link} to="/complain" className={classes.nested}>
              <ListItemText primary="Komplain Saya" className="listText"/>
            </ListItem>
        </List>       
      </Collapse>  

      <ListItem button onClick={handleClickToko} style={{maxWidth: '214px'}}>
        <ListItemText primary="Toko Saya" className="listTextHeading"/>
        {openToko ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {profile && profile.merchant == null ? (       
      <Collapse in={openToko} timeout="auto" unmountOnExit>
        <ListItem button component={Link} to="/merchant/register" className={classes.nested}>
              <ListItemText primary="Buka Toko" className="listText"/>
        </ListItem>
      </Collapse>  
      ):(
        <Collapse in={openToko} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/merchant/profile" className={classes.nested}>
                <ListItemText primary="Profil Toko" className="listText"/>
          </ListItem> 
          <ListItem button component={Link} to="/merchant/product" className={classes.nested}>
              <ListItemText primary="Produk Toko" className="listText"/>
          </ListItem>
          <ListItem button component={Link} to="/merchant/delivery" className={classes.nested}>
              <ListItemText primary="Pengiriman" className="listText"/>
          </ListItem>
          <ListItem button component={Link} to="/merchant/new-order" className={classes.nested}>
              <ListItemText primary="Pesanan Toko" className="listText"/>
          </ListItem>
          <ListItem button component={Link} to="/merchant/complain" className={classes.nested}>
              <ListItemText primary="Komplain Toko" className="listText"/>
          </ListItem>
        </List>
      </Collapse>  
        
      )}

       { open &&  (
         <Dialog open={open} aria-labelledby="form-dialog-title" disableBackdropClick="true">
            <DialogTitle id="form-dialog-title" style={{textAlign:'center',paddingBottom:'0'}}>
              Add Location User
              {/* <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleClose}/></span> */}
            </DialogTitle>
            <DialogContent style={{textAlign:'center'}}>          
                <p>Lokasi anda belum di setup silahkan click disini untuk setup lokasi anda</p>
                <Button variant="outlined" color="primary" onClick={toLocation}>Click Disini</Button>
            </DialogContent>            
        </Dialog>      
       )}

 
       
  </div>
  );
  

}
   
  