import React, { useState} from "react";
import axios from 'axios';
import Link from '@material-ui/core/Link';
import { useAuth } from "./Auth";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useLocation } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import Slider from "react-slick";
import BannerRegis1 from '../img/slide-reg-1.png';
import BannerRegis2 from '../img/slide-reg-2.png';
import { baseUrl } from "utils";

const settings = {
  dots: false,
  infinite: true,
  arrows:false,
  autoplay:true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
      arrows:false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px',
    [theme.breakpoints.up('md')]: {
      marginTop:'40px'
    },
  },
  sectionDesktop: {
    display: 'none',
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },   
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  ReactPixel.init('829256154386906', {}, { debug: true, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'InitiateCheckout');
  
  const classes = useStyles();
  const [name, setName] = useState(''); 
  const location = useLocation();
  // const username = location.state.params;
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get('username');
  const token = JSON.parse(localStorage.getItem('tokenRegister'));
  const [password, setPassword] = useState(''); 
  const [sex, setSex] = useState(''); 
  const [birthdate, setBirthdate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
 );
  
  const handleChangeDate = e => {
    setBirthdate(e.target.value);
 };
  const handleChange = (event) => {
    setSex(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const { setAuthTokens } = useAuth();
  const [isLoggedIn, setLoggedIn] = useState(false);

  const MySwal = withReactContent(Swal);

  // const isValidData = { name: name, username: username, password: password, sex:sex, birthdate:birthdate };
  // const checkIsValid = (fieldName, value) => {
  //   if (isValidData[fieldName] === value) {
  //     return true;
  //   }
  //   return false;
  // }; 

  // 
  const handleSubmit = async e => {   
    e.preventDefault(); 
  
    await axios.post(`${baseUrl}/auth/register`, {
        name,
        username,
        password,
        sex,
        birthdate,
        token
    }).then((result) => {
      
        MySwal.fire({
        icon: 'success',
        title: "Pendaftaran Berhasil!"
        }).then((res)=>{
        // window.location.href="/login"
          axios.post(`${baseUrl}/auth/login`, {
              username,
              password
          }).then(result => {              
              setAuthTokens(result.data.data.token);
              setLoggedIn(true);
              window.location.href="/profile"
              
              
          }).catch(e => {
              // setIsError(true);
              
          });
        })
        
    }).catch(e => {
      MySwal.fire({
        icon: 'warning',
        title: 'Maaf, Data yang anda masukan belum lengkap silahkan lengkapi terlebih dahulu'
      })
    }); 
   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <Slider {...settings}>
          <div>
            <img src={BannerRegis1} width="100%"/>
          </div>
          <div>
            <img src={BannerRegis2} width="100%"/>
          </div>
        </Slider>
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
          <div className={classes.sectionDesktop}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>   
          </div>     
                  
            <form className={classes.form} onSubmit={handleSubmit} validate >
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="fullname"
                label="Nama Lengkap"
                name="fullname"
                autoComplete="off"
                onChange={e => setName(e.target.value)}
                autoFocus
              />
               <TextField               
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="username"
                label="Email / Phone"
                name="username"
                autoComplete="off"
                value={username}
                autoFocus
              />
              <TextField              
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={e => setPassword(e.target.value)}
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
               <TextField              
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="cpassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                id="cpassword"
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <div className={classes.sexState}>
              <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Jenis Kelamin</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sex}
                    onChange={handleChange}
                    >
                    <MenuItem value={"male"}>Laki-Laki</MenuItem>
                    <MenuItem value={"female"}>Perempuan</MenuItem>
                    </Select>
                </FormControl>
              </div>
              <div className={classes.sexState}>
                <form className={classes.formControl} noValidate>
                <TextField                    
                    id="date"
                    label="Tanggal Lahir"
                    type="date"
                    required={true}
                    defaultValue="2004-05-05"
                    className={classes.textField}
                    onChange={handleChangeDate}                    
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </form>
                </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Register
              </Button>
              <Grid container>
             
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Sudah punya akun? Login sekarang"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}


