import React, { useContext, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Upload from '../../components/UploadImage';
import axios from 'axios';
import PhotoTemplate from '../../img/blank-profile-picture.png';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ShowLocation from './ShowLocation.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactPixel from 'react-facebook-pixel';
import ContextGlobal from "../../components/data/useContextGlobal";
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
      alignItems:'center',
      margin:'10px',   
    },
    linkProf:{
      display: 'flex',
      alignItems:'center',
      margin:'0 10px 10px',
      [theme.breakpoints.down('md')]: {
       fontSize:'13px'
      }
    },
    profileWrap:{
        [theme.breakpoints.down('md')]: {
            margin:'5px 0'
        }
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    PadDense:{
        padding:'10px',
        margin:'0 10px',
        [theme.breakpoints.down('md')]: {
            margin:'0'
        }
    },
    margDense:{
        margin:'0 0 10px',
        [theme.breakpoints.up('md')]: {
            margin:'60px 0'
        }
    },
    subHeader:{
        fontSize:'12px',
    },
    btnVar:{
        margin:'2px',
        fontSize:'11px',
        padding:'5px'
    }
  }));

export default function Profile (){
    ReactPixel.init('829256154386906', {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'ComplateRegistration');
    const context = useContext(ContextGlobal);
    const classes = useStyles();
    const profile = context.profile;
    const [open, setOpen] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openPhone, setOpenPhone] = useState(false);
    const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    const [emailOtp, setEmailOtp] = useState('');    
    const [phones, setPhone] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [otpPhone, setOtpPhone] = useState('');
  const location = profile.location;

    const imageDefault = PhotoTemplate.toString();
    const [birthdate, setBirthdate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
     );
       
    const handleChangeDate = e => {
        setBirthdate(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpenEmail = () => {
        setOpenEmail(true);
    };
    const handleCloseEmail = () => {
        setOpenEmail(false);
    };
    const handleCloseOtpEmail = () => {
        setOpenOtpEmail(false);
    };
    const handleCloseOtpPhone = () => {
        setOpenOtpPhone(false);
    };
    const handleClickOpenPhone = () => {
        setOpenPhone(true);
    };
    const handleClosePhone = () => {
        setOpenPhone(false);
    };

    const [OpenOtpEmail, setOpenOtpEmail] = useState(false);
    const [OpenOtpPhone, setOpenOtpPhone] = useState(false);
    const MySwal = withReactContent(Swal)

    const handleUpdateProfile = async(e) => {
        e.preventDefault();;    
      await axios.post(`${baseUrl}/user/profile/update`, {
            name,
            birthdate
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((result) => { 
            setOpen(false)         
            MySwal.fire({
            icon: 'success',
            title: result.data.message
            }).then((res)=>{
            window.location.reload()
            })
            
        }).catch(e => {
            
            setOpen(false)
            MySwal.fire({
                icon: 'error',
                title: e.response.data.message
            })
        }); 
    }

    const handleUpdateProfileEmail = async(e) => {
        e.preventDefault();;    
      await axios.post(`${baseUrl}/user/email/change`, {
            email:emailOtp
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((result) => {   
            setOpenEmail(false);                
            MySwal.fire({
            icon: 'success',
            title: result.data.message
            }).then((res)=>{
              setOpenOtpEmail(true);  
              setOpenEmail(false);
            })
            
        }).catch(e => {
            
            setOpenEmail(false)
            MySwal.fire({
                icon: 'error',
                title: e.response.data.message
            })
        }); 
    }

    const handleSaveProfileEmail = async(e) => {
        e.preventDefault();;    
      await axios.post(`${baseUrl}/user/email/save`, {
            email:emailOtp,
            password:otpEmail
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((result) => { 
            setOpenOtpEmail(false)      
            MySwal.fire({
            icon: 'success',
            title: result.data.message
            }).then(()=>{
                window.location.reload()
            })
            
        }).catch(e => {
            
            setOpenOtpEmail(false)
            MySwal.fire({
                icon: 'error',
                title: e.response.data.message
            })
        }); 
    }

    const handleUpdateProfilePhone = async(e) => {
        e.preventDefault();;    
      await axios.post(`${baseUrl}/user/phone/change`, {
            phone:phones
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((result) => { 
            setOpenPhone(false)         
            MySwal.fire({
            icon: 'success',
            title: result.data.data.password,
            text: result.data.message
            }).then((res)=>{
              setOpenOtpPhone(true);  
            })
            
        }).catch(e => {
            
            setOpenPhone(false)
            MySwal.fire({
                icon: 'error',
                title: e.response.data.message
            })
        }); 
    }


    const handleSaveProfilePhone = async(e) => {
        e.preventDefault();;    
      await axios.post(`${baseUrl}/user/phone/save`, {
            phone:phones,
            password:otpPhone
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((result) => { 
            setOpenOtpPhone(false)      
            MySwal.fire({
            icon: 'success',
            title: result.data.message
            }).then(()=>{
                window.location.reload()
            })
            
        }).catch(e => {
            
            setOpenOtpPhone(false)
            MySwal.fire({
                icon: 'error',
                title: e.response.data.message
            })
        }); 
    }
    

      
    //   if (!profile) return null;
    return( 
        <React.Fragment>      
      
            <DashboardLayout>    
            
                <Grid container className={classes.margDense}>
                    <Grid item xs={12} md={12} style={{marginBottom:'20px'}}>
                    <Paper className={classes.PadDense}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography color="textPrimary" className={classes.link} variant="h6">
                                Profile
                            </Typography> 
                            <Typography color="textPrimary" className={classes.link} variant="span">
                                <Link href="#" onClick={handleClickOpen}>
                                    <IconButton aria-label="Edit">
                                        <CreateOutlinedIcon />
                                    </IconButton>
                                    Edit Profile
                                </Link>
                            </Typography> 
                        </Box>
                        <Grid container>
                        <Grid item xs={6} md={3}>
                            <div className="avatarPhoto">                            
                                { profile.photo === null ? (<img src={imageDefault}/>):(<img src={profile.photo}/>)} 
                            </div>   
                            <Upload/>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <div className={classes.profileWrap}>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Nama Lengkap </div><span className='rightDivSectionProfile'> 
                                <TextField variant="outlined" size="small"  InputProps={{readOnly: true }} fullWidth value={profile.name === null ? ('please update your profile'):(profile.name)}/> </span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Jenis Kelamin  </div><span className='rightDivSectionProfile'> <TextField fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.sex === 'male' ? ('Pria'):('Wanita')}/>  </span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Email </div> <span className='rightDivSectionProfile'> 
                                <TextField fullWidth variant="outlined" size="small" 
                                InputProps={{readOnly: true }} 
                                value={profile.email === null ? ('please update your profile'):(profile.email)}
                                /> 
                                    <Link href="#" className="actionProfileIcon" onClick={handleClickOpenEmail}>
                                        <IconButton aria-label="Edit Email">
                                            <CreateOutlinedIcon />
                                        </IconButton>
                                        Edit 
                                    </Link></span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Tgl Lahir  </div><span className='rightDivSectionProfile'> <TextField fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.birthdate === null ? ('please update your profile'):(profile.birthdate)}/>   </span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">No Hp  </div><span className='rightDivSectionProfile'> <TextField fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.phone === null ? ('please update your profile'):(profile.phone)}/> 
                                <Link href="#" className="actionProfileIcon" onClick={handleClickOpenPhone}>
                                        <IconButton aria-label="Edit Telephone">
                                            <CreateOutlinedIcon />
                                        </IconButton>
                                        Edit
                                    </Link></span>
                            </Typography> 
                            </div>   
                        </Grid>
                        </Grid>
                       
                        
                                                                    
                        
                </Paper>
                </Grid>

                <Grid item xs={12} md={12}>
                    <ShowLocation dataLocation={location}/>
                </Grid>

            </Grid>
            
             <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                    Edit Profile User
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleClose}/></span>                 
                </DialogTitle>
                <DialogContent>
                <form onSubmit={handleUpdateProfile}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="fullname"
                        label="Nama Lengkap"
                        name="fullname"
                        autoComplete="fullname"
                        defaultValue={profile.name}
                        onChange={e => setName(e.target.value)}
                        autoFocus
                    />
                    
                    <TextField
                        id="date"
                        label="Tanggal Lahir"
                        type="date"
                        fullWidth
                        defaultValue={profile.birthdate}
                        className={classes.textField}
                        onChange={handleChangeDate}                    
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    <Button type="submit" color="primary" fullWidth variant="outlined" style={{marginTop:'10px'}}>
                        Simpan
                    </Button>
                </form>
                

                </DialogContent>
            </Dialog>


            <Dialog open={openEmail} onClose={handleCloseEmail} aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                    Tambah Email Pengguna
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseEmail}/></span>                 
                </DialogTitle>
                <DialogContent>               
                <form onSubmit={handleUpdateProfileEmail}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="off"
                        defaultValue={profile.email === null ? ("") : (profile.email)}
                        onChange={e => setEmailOtp(e.target.value)}
                        autoFocus
                    />
                    
                    <Button type="submit" color="primary" fullWidth variant="outlined" style={{marginTop:'10px'}}>
                        Simpan
                    </Button>
                </form> 
                </DialogContent>
            </Dialog>


            <Dialog open={OpenOtpEmail} onClose={handleCloseOtpEmail} aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                    Masukan OTP
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseOtpEmail}/></span>                 
                </DialogTitle>
                <DialogContent>
                <form onSubmit={handleSaveProfileEmail}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={emailOtp}
                        autoFocus
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="OTP"
                        label="OTP"
                        name="OTP"
                        defaultValue=""
                        onChange={e => setOtpEmail(e.target.value)}
                        autoFocus
                    />

                    
                    <Button type="submit" color="primary" fullWidth variant="outlined" style={{marginTop:'10px'}}>
                        Save
                    </Button>
                </form>
                
                

                </DialogContent>
            </Dialog>


            <Dialog open={openPhone} onClose={handleClosePhone} aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                    Tambah No Handphone
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleClosePhone}/></span>                 
                </DialogTitle>
                <DialogContent>
                <form onSubmit={handleUpdateProfilePhone}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Phone"
                        name="phone"
                        autoComplete="username"
                        defaultValue={profile.phone === null ? (''):(profile.phone)}
                        onChange={e => setPhone(e.target.value)}
                        autoFocus
                    />
                    
                    <Button type="submit" color="primary" fullWidth variant="outlined" style={{marginTop:'10px'}}>
                        Simpan
                    </Button>
                </form>
                

                </DialogContent>
            </Dialog>
            



            <Dialog open={OpenOtpPhone} onClose={handleCloseOtpPhone} aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                    Masukan OTP 
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseOtpPhone}/></span>                 
                </DialogTitle>
                <DialogContent>
                <form onSubmit={handleSaveProfilePhone}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        autoComplete="off"
                        value={phones}
                        autoFocus
                    />
                     <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="OTP"
                        label="OTP"
                        name="OTP"
                        defaultValue=""
                        onChange={e => setOtpPhone(e.target.value)}
                        autoFocus
                    />
                    
                    <Button type="submit" color="primary" fullWidth variant="outlined" style={{marginTop:'10px'}}>
                        Save
                    </Button>
                </form>
                

                </DialogContent>
            </Dialog>
            
            

            
        </DashboardLayout>        

        </React.Fragment>
    )
}

