import React, {useState, useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import {useHistory, Link} from "react-router-dom";
import ProductEmpty from '../../img/No Transaction.svg';
import PhotoTemplate from '../../img/broken-256.png';
import PhotoUser from '../../img/blank-profile-picture.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import 'moment/locale/id'
import LocalMallIcon from '@material-ui/icons/LocalMall';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import {baseUrl} from 'utils';


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
  fsizeBox: {
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  statusProd: {
    width: '50px',
    margin: '10px 10px 10px 60px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  uname: {
    width: '50px',
    margin: '10px 10px 10px 50px'
  },
  jmlProd: {
    width: '50px',
    margin: '10px'
  },
  hrgProd: {
    width: '50px',
    margin: '10px 10px 10px 17px'
  },
  variantProd: {
    width: '50px',
    margin: '10px 10px 10px 57px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  productBox: {
    border: '4px solid #ddd',
    width: '100%',
    marginBottom: '10px'
  },
  desktopOnly: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  mobileOnlywthStyle: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
  mobileOnly: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    padding: '10px 15px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  boxMyOrder: {
    margin: '60px 5px 0',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 0px'
    },
  },

  btnAction: {
    marginBottom: '10px',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '46%',
    }
  },

  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 960,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  image: {
    width: 128,
    height: 100,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '90px',
    width: '100%',
    borderRadius: '10px',
    objectFit: 'cover',
    maxHeight: '80px',
    height: '100%'
  },
  subtitle1: {
    margin: '0px 6px 10px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 7px',
      height: '30px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    }
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function MerchantComplain() {
  const classes = useStyles();
  const [dataOrderComplain, setdataOrderComplain] = useState([]);
  const [detailComplain, setdetailComplain] = useState();
  const [files, setFiles] = useState([]);

  const uploadImages = (e) => {
    let file_reader = new FileReader();
    let file = e.target.files[0];
    file_reader.onload = () => {
      setFiles(...files, file_reader.result);
    };
    file_reader.readAsDataURL(file);

  };


  const settings = {
    className: 'activeClass',
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          dots: false,
          arrows: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          dots: false,
          arrows: false,
        }
      }
    ]
  };
  const history = useHistory();

  const MySwal = withReactContent(Swal);


  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    getComplainList();
  }, []);

  const getComplainList = () => {
    axios.get(`${baseUrl}/order/merchant/complain/list`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }, params: {status: 'active'}
    })
    .then((response) => {
      const dataResponse = response.data.data;
      setdataOrderComplain(dataResponse)

    });
  }
  const [isLoading, setisLoading] = useState(false);


  const handleOnClickButton = async (index, el) => {
    try {
      setisLoading(true)
      setActiveIndex(index);
      if (el === "Sedang Berlangsung") {
        axios.get(`${baseUrl}/order/merchant/complain/list`, {
          headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }, params: {status: 'active'}
        })
        .then((response) => {
          const dataResponse = response.data.data;
          setdataOrderComplain(dataResponse)

        });
      } else if (el === "Selesai Diproses") {
        axios.get(`${baseUrl}/order/merchant/complain/list`, {
          headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }, params: {status: 'closed'}
        })
        .then((response) => {
          const dataResponse = response.data.data;
          setdataOrderComplain(dataResponse);

        });
      }
    } finally {
      setTimeout(() => {
        setisLoading(false);
      }, 1000);
    }

  };
  const child = {width: `100%`, height: `30px`, margin: `0 5px`, fontSize: '10px', whiteSpace: 'no-wrap'}
  const boxs = ["Sedang Berlangsung", "Selesai Diproses"];
  const boxButton = boxs.map((el, index) => {
    return (
      <div className="sliderMenuHorizontal">
        <Button
          key={index}
          onClick={() => handleOnClickButton(index, el)} // pass the index
          className={activeIndex === index ? "activeClass" : null}
          variant="outlined"
          size="small"
          style={child}
        >
          {el}
        </Button>
      </div>
    );
  });

  const merchantAction = (type, complain_uid) => {
    const val = type;
    setactionMerchant(false)
    axios.post(`${baseUrl}/order/merchant/complain/${complain_uid}/action`, {type: val, note: null}, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
    .then((response) => {
      const dataResponse = response.data;
      MySwal.fire({
        icon: 'success',
        title: "Keputusan komplain telah dibuat"
      }).then((result) => {
        window.location.reload();
      });

    });
  }
  const [actionMerchant, setactionMerchant] = useState(false);

  const handleActionMerchant = (complain_uid) => {
    setactionMerchant(true)
    axios.get(`${baseUrl}/order/merchant/complain/${complain_uid}/get`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
    .then((response) => {
      const dataResponse = response.data;
      setdetailComplain(dataResponse);
    });
  }


  const handleCloseActionModal = () => {
    setactionMerchant(false)
  }
  const discussComplain = (complain_uid, domain, merchant_photo, merchant_name, user_name, user_photo) => {
    history.push("/mchat/mcomplain", {
      params: {
        c_uid: complain_uid,
        c_user_id: domain,
        c_merchant_name: merchant_name,
        c_merchant_photo: merchant_photo,
        c_user_name: user_name,
        c_user_photo: user_photo,
      }
    })
  }
  const imageDefaultProduct = ProductEmpty.toString();
  const imageDefault = PhotoTemplate.toString();
  const imageDefaultUser = PhotoUser.toString();

  return (
    <DashboardLayout>
      <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}>
        <Typography color="textPrimary" variant="h6">
          Komplain Pesanan
        </Typography>
      </Box>

      <Box display="flex" width="100%">
        <Slider {...settings} className="slideboxmenu">
          {boxButton}
        </Slider>
      </Box>
      <Grid container spacing={1}>

        <Box width="100%" style={{padding: '10px 10px'}}>
          {isLoading ? <div style={{textAlign: 'center'}}>
              <div><CircularProgress/>
                <div>Loading ....</div>
              </div>
            </div> :
            <>
              <div style={{marginBottom: '20px'}}>
                {dataOrderComplain.length > 0 ?
                  <div>
                    {dataOrderComplain.map((cmpl, index) => {
                        return (
                          <List key={index}>
                            <Paper>
                              <div style={{padding: '5px 10px 0', display: 'flex', alignItems: 'center'}}
                                   className={classes.desktopOnly}>
                                <span><h3
                                  style={{margin: '5px'}}>{cmpl.type === "return" && "Ganti Barang"} {cmpl.type === "refund" && "Uang Dikembalikan"} {cmpl.type === "cancel" && "Komplain dibatalkan"}</h3></span>
                                <span>({moment(cmpl.date).format('LLLL')})</span>
                              </div>
                              <div style={{
                                padding: '5px 10px 0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }} className={classes.desktopOnly}>
                                <span style={{display: 'flex', alignItems: 'center'}}><img
                                  src={cmpl.user.photo === null ? imageDefaultUser : cmpl.user.photo} height={40} style={{
                                  margin: '0 10px',
                                  borderRadius: '100%',
                                  borderColor: '#ababab'
                                }}/>{cmpl.user.name}</span>
                                <span style={{marginRight: '55px'}}>{cmpl.invoice}</span>
                              </div>
                              <div className={classes.mobileOnly}>
                                <div>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{margin: '0 10px'}}>
                                      {cmpl.merchant.name}
                                      <div>
                                        <span style={{fontSize: '11px'}}> {moment(cmpl.date).format('LLLL')} </span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <span style={{
                                    textTransform: 'capitalize',
                                    background: '#efefef',
                                    padding: '0 10px',
                                    borderRadius: '5px'
                                  }}>{cmpl.status}</span>
                                </div>
                              </div>

                              <div className={classes.paper}>
                                <Grid container spacing={2} xs={12} md={12} style={{position: 'relative'}}>
                                  <Grid item xs={12} md={12}>
                                    {cmpl.product.items.map((order, k) => {
                                      return (
                                        <Grid container xs={12} md={12}>
                                          <Grid item xs={3} md={1}>
                                            <img className={classes.img} alt="complex"
                                                 src={order.cover === null ? imageDefault : order.cover}/>
                                          </Grid>
                                          <Grid item xs={9} md={11} container style={{padding: '0 10px'}}>

                                            <Grid item xs={12} md={9} container direction="column" spacing={2}>
                                              <Grid item xs>
                                                <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                  <div style={{fontSize: '16px'}}>{order.name}</div>
                                                  <div style={{fontSize: '16px'}}><span>{new Intl.NumberFormat('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                    minimumFractionDigits: 0
                                                  }).format(order.price)}</span></div>
                                                  <div><span>{order.qty} pcs</span></div>
                                                </Typography>
                                              </Grid>

                                            </Grid>
                                            <Grid item xs={12} md={3} container direction="column" spacing={1}
                                                  style={{textAlign: 'right', marginTop: '20px'}}>
                                              <Typography variant="body2" gutterBottom className={classes.desktopOnly}>
                                                <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                                  <div>
                                                    <div>Total Belanja</div>
                                                    <div style={{fontSize: '16px'}}>
                                                      <strong>{new Intl.NumberFormat('id-ID', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        minimumFractionDigits: 0
                                                      }).format(order.total_price)}</strong></div>
                                                  </div>
                                                </Typography>

                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )
                                    })}
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <div style={{textAlign: 'center', display: 'flex', justifyContent: 'flex-end'}}>
                                      {cmpl.status === "closed" ? <></> :
                                        <div style={{display: 'flex', width: '100%', maxWidth: '400px'}}>
                                          <Button variant="outlined" size="small" className={classes.btnAction}
                                                  style={{borderColor: '#ab0004', color: '#ab0004'}}
                                                  onClick={() => discussComplain(cmpl.uid, cmpl.merchant.domain, cmpl.merchant.photo, cmpl.merchant.name, cmpl.user.name, cmpl.user.photo)}>
                                            Diskusi Komplain
                                          </Button>
                                          <Button variant="contained" size="small" className={classes.btnAction}
                                                  style={{background: '#ab0004', color: '#fff'}}
                                                  onClick={() => handleActionMerchant(cmpl.uid)}>
                                            Konfirmasi Komplain
                                          </Button>

                                        </div>
                                      }

                                    </div>
                                  </Grid>
                                </Grid>
                              </div>

                            </Paper>
                          </List>
                        )
                      }
                    )}


                  </div>
                  :
                  <div style={{textAlign: 'center'}}>
                    <img src={imageDefaultProduct} height="300"/>
                    <p>Tidak ada komplain</p>
                  </div>
                }

              </div>
            </>
          }
        </Box>
      </Grid>

      <Dialog open={actionMerchant} fullWidth="true" maxWidth="md" aria-labelledby="form-dialog-title"
              disableBackdropClick="true">
        <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>
          Keputusan Komplain Pesanan
          <span style={{position: 'absolute', right: '25px', cursor: 'pointer'}}><ClearOutlinedIcon
            onClick={handleCloseActionModal}/></span>
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <div>
              {detailComplain &&
                <List>
                  <Paper>
                    <div style={{padding: '5px 10px 0', display: 'flex', alignItems: 'center'}}
                         className={classes.desktopOnly}>
                      <span><h3 style={{margin: '5px'}}>{detailComplain.merchant.name}</h3></span>
                      <span>({moment(detailComplain.date).format('LLLL')})</span>
                    </div>
                    <div className={classes.mobileOnly}>
                      <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <div style={{margin: '0 10px'}}>
                            {detailComplain.merchant.name}
                            <div>
                              <span style={{fontSize: '11px'}}> {moment(detailComplain.date).format('LLLL')} </span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{
                          textTransform: 'capitalize',
                          background: '#efefef',
                          padding: '0 10px',
                          borderRadius: '5px'
                        }}>{detailComplain.status}</span>
                      </div>
                    </div>

                    <div className={classes.paper}>
                      <Grid container spacing={2} xs={12} md={12} style={{position: 'relative'}}>
                        <Grid item xs={12} md={10}>
                          {detailComplain.product.items.map((order, k) => {
                            return (
                              <Grid container xs={12} md={12} style={{marginBottom: '20px'}}>
                                <Grid item xs={3} md={2}>
                                  <img className={classes.img} alt="complex"
                                       src={order.cover === null ? imageDefault : order.cover}/>
                                </Grid>
                                <Grid item xs={9} sm container style={{padding: '0 10px'}}>
                                  <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                      {order.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={5} container direction="column" spacing={2}>
                                    <Grid item xs>
                                      <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                        <div><span>Pembeli</span><span
                                          className={classes.uname}>:</span><span>{detailComplain.user.name}</span>
                                        </div>
                                        <div><span>Jumlah Produk</span><span
                                          className={classes.jmlProd}>:</span><span>{order.qty} pcs</span></div>
                                        <div><span>Harga Produk</span><span
                                          className={classes.hrgProd}>:</span><span>{new Intl.NumberFormat('id-ID', {
                                          style: 'currency',
                                          currency: 'IDR',
                                          minimumFractionDigits: 0
                                        }).format(order.price)}</span></div>
                                      </Typography>
                                    </Grid>

                                  </Grid>
                                  <Grid item xs={12} md={7} container direction="column" spacing={1}>
                                    <Typography variant="body2" gutterBottom className={classes.desktopOnly}>
                                      <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                        <div style={{display: 'flex', width: '100%'}}>
                                          <span>Invoice</span>
                                          <span style={{margin: '0 10px 0 73px'}}>:</span>
                                          <span>{detailComplain.invoice}</span>
                                        </div>
                                      </Typography>

                                      <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                        <div style={{display: 'flex', width: '100%'}}>
                                          <span><strong>Alasan</strong></span>
                                          <span style={{margin: '0 10px 0 72px'}}>:</span>
                                          <span
                                            style={{textTransform: 'capitalize'}}><strong>{detailComplain.reason}</strong></span>
                                        </div>
                                      </Typography>
                                      <Typography color="textPrimary" variant="div" style={{fontSize: '13px'}}>
                                        <div style={{display: 'flex', width: '100%'}}>
                                          <span>Tipe Komplain</span>
                                          <span style={{margin: '0px 10px 0 32px'}}>:</span>
                                          <span>{detailComplain.type === "return" && "Ganti Barang"} {detailComplain.type === "refund" && "Uang Dikembalikan"} {detailComplain.type === "cancel" && "Komplain dibatalkan"}</span>
                                        </div>
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.BoxButton}>
                          <div style={{margin: '10px 0 0', textAlign: 'center', width: '100%'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <Button variant="outlined" id="return" size="small" className={classes.btnAction}
                                      onClick={(e) => merchantAction(e.currentTarget.id, detailComplain.uid)}>
                                Kirim Ulang Barang
                              </Button>
                              <Button variant="outlined" id="refund" size="small" className={classes.btnAction}
                                      onClick={(e) => merchantAction(e.currentTarget.id, detailComplain.uid)}>
                                Uang Dikembalikan
                              </Button>
                              <Button variant="outlined" size="small" className={classes.btnAction}
                                      onClick={(e) => merchantAction(e.currentTarget.id, detailComplain.uid)}>
                                Komplain Ditolak
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                  </Paper>
                </List>
              }
            </div>

          </React.Fragment>
        </DialogContent>
      </Dialog>


    </DashboardLayout>

  )
}

