import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../styles/styles.css'
// import ReactPaginate from 'react-paginate';
 
// function TestPaging() {
//  const [postsPerPage] = useState(15);
//  const [offset, setOffset] = useState(1);
//  const [posts, setAllPosts] = useState([]);
//  const [pageCount, setPageCount] = useState(0)
 
//  const getPostData = (data) => {
//    return (
//      data.map(post => <div className="container" key={post.id}>
//        <p>Title: {post.name}</p>
//      </div>)
//    )
 
//  }
 
//  const getAllPosts = async () => {
//    const res = await axios.get(`https://dev-api.leholeh.co.id/api/home/recommendation-products`)
//    const data = res.data.data;
//    const slice =data.slice(offset - 1 , offset - 1 + postsPerPage)
  
//    // For displaying Data
//    const postData = getPostData(slice)
//    
//    // Using Hooks to set value
//    setAllPosts(postData)
//    setPageCount(Math.ceil(data.length / postsPerPage))
//  }
 
//  const handlePageClick = (event) => {
//    const selectedPage = event.selected;
//    setOffset(selectedPage + 1)
//  };
 
//  useEffect(() => {
//    getAllPosts()
//  }, [offset])
 
//  return (
//    <div className="main-app">
    
//      {/* Display all the posts */}
//      {posts}
 
//      {/* Using React Paginate */}
//      <ReactPaginate
//        previousLabel={"previous"}
//        nextLabel={"next"}
//        breakLabel={"..."}
//        breakClassName={"break-me"}
//        pageCount={pageCount}
//        onPageChange={handlePageClick}
//        containerClassName={"pagination"}
//        subContainerClassName={"pages pagination"}
//        activeClassName={"active"} />
//    </div>
//  );
// }
 
// export default TestPaging;



function PaginationComponent() {
  const [data, setData] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(15);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [metaTotal , setMetaTotal] = useState();

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));

    
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(metaTotal / itemsPerPage); i++) {
    pages.push(i);
  }
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const [curPages, setCurPages] = useState();

 



  useEffect(() => {
    axios.get(
      `https://api.leholeh.id/api/home/recommendation-products?page=${currentPage}`
    ).then((response)=>{
      const dataPaging = response.data.data;
      const dataTotal = response.data.meta.total;
      const curPage = response.data.meta.current_page;
      setCurPages(curPage);
      setMetaTotal(dataTotal);
      setData(dataPaging);
      
    })
    // getDataPaging();
  }, [currentPage]);


  const handleNextbtn = (currentPage) => {

    setcurrentPage(currentPage + 1);   
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = (currentPage) => {

    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
  };

  return (
    <>
      <h1>Todo List</h1> <br />
      {/* {renderData(currentItems)} */}
      <ul>
        {data.map((todo, index) => {
          return (
          <li key={index}>{todo.name} {todo.location}</li>
          
          );
        })}
      </ul>
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={()=>handleNextbtn(currentPage)}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      {/* <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button> */}
    </>
  );
}

export default PaginationComponent;


    // axios.get(
    //   `https://dev-api.leholeh.co.id/api/home/recommendation-products?page=${currentPage}`
    // ).then((response)=>{
    //   const dataPaging = response.data.data;
    //   const dataTotal = response.data.meta.total;
    //   const curPage = response.data.meta.current_page;
    //   setCurPages(curPage);
    //   setMetaTotal(dataTotal);
    //   setData(dataPaging);
    //   
    // })    // axios.get(
    //   `https://dev-api.leholeh.co.id/api/home/recommendation-products?page=${currentPage}`
    // ).then((response)=>{
    //   const dataPaging = response.data.data;
    //   const dataTotal = response.data.meta.total;
    //   const curPage = response.data.meta.current_page;
    //   setCurPages(curPage);
    //   setMetaTotal(dataTotal);
    //   setData(dataPaging);
    //   
    // })
     // const renderData = (data) => {

  //   return (
  //     <ul>
  //       {data.map((todo, index) => {
  //         return <li key={index}>{todo.name}</li>;
  //       })}
  //     </ul>
  //   );
  // };

      // axios.get(
    //   `https://dev-api.leholeh.co.id/api/home/recommendation-products?page=${currentPage}`
    // ).then((response)=>{
    //   const dataPaging = response.data.data;
    //   const dataTotal = response.data.meta.total;
    //   const curPage = response.data.meta.current_page;
    //   setCurPages(curPage);
    //   setMetaTotal(dataTotal);
    //   setData(dataPaging);
    //   
    // })

// import React, {useState, useEffect} from 'react'
// import axios from 'axios'
// import ReactPaginate from 'react-paginate';

// function App() {
//   const [offset, setOffset] = useState(1);
//   const [data, setData] = useState([]);
//   const [perPage] = useState(4);
//   const [pageCount, setPageCount] = useState(0)

 

//   const getData = async() => {   
//       const res = await axios.get(`https://dev-api.leholeh.co.id/api/news?page=${offset}`);
//       const data = res.data.data;
//         const slice = data.slice(offset, offset + perPage)
//         const postData = slice.map(pd => <div key={pd.id}>
//             <p>{pd.title}</p>
//             <img src={pd.image} alt=""/>
//         </div>)
//         setData(postData)
//         setPageCount(Math.ceil(res.data.total / perPage))
        
//   }

//   useEffect(() => {
//     getData()
//   }, [offset])

//   const handlePageClick = async e => {
//       const selectedPage = e.selected >= 0 ? e.selected + 1 : 0;   
//       await Promise.resolve( setOffset({ offset: selectedPage }));   
//       // setOffset(selectedPage + 1);
//       
//   };
// //   const handlePageClick = (e) => {
// //     const selectedPage = e.selected;
// //     let offset = selectedPage;
// //     // let offset = Math.ceil(selectedPage * perPage);
// //     setOffset( offset + 1);

// //     
// //     // setOffset(offset + 1)
// // };



//   return (
//     <div className="App">
//       {data}
//        <ReactPaginate
//                     previousLabel={"prev"}
//                     nextLabel={"next"}
//                     breakLabel={"..."}
//                     breakClassName={"break-me"}
//                     initialPage={offset - 1}
//                     pageCount={pageCount}
//                     marginPagesDisplayed={2}
//                     pageRangeDisplayed={5}
//                     onPageChange={handlePageClick}
//                     containerClassName={"pagination"}
//                     previousLinkClassName={"pagination__link"}
//                     nextLinkClassName={"pagination__link"}
//                     subContainerClassName={"pages pagination"}
//                     activeClassName={"active"}/>
//     </div>
//   );
// }

// export default App;