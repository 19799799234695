import React,{useState, useEffect} from 'react';
import DashboardLayout from './DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { useHistory, Link } from "react-router-dom";
import ProductEmpty from '../img/No Transaction.svg';
import PhotoTemplate from '../img/broken-256.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import 'moment/locale/id'
import PhotoUser from '../img/blank-profile-picture.png';
import { baseUrl } from 'utils';

import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    hidden: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
          },
    },
    fsizeBox:{
        fontSize:'13px',
        [theme.breakpoints.down('md')]: {
            fontSize:'12px',
        },
    },
    statusProd:{
        width:'50px', 
        margin:'10px 10px 10px 60px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    uname:{
        width:'50px', 
        margin:'10px 10px 10px 50px'
    },
    jmlProd:{
        width:'50px', 
        margin:'10px'
    },
    hrgProd:{
        width:'50px', 
        margin:'10px 10px 10px 17px'
    },
    variantProd:{
        width:'50px', 
        margin:'10px 10px 10px 57px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    desktopOnly:{
        display: 'block',
        [theme.breakpoints.down('md')]: {
          display: 'none !important',
        },
    },
    mobileOnlywthStyle:{
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
    },
    mobileOnly:{
        display: 'flex',
        width:'100%',
        justifyContent:'space-between',
        borderBottom: '1px solid #ddd',
        padding: '10px 15px',
        alignItems:'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    boxMyOrder :{
        margin:'60px 5px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 0px'
        },
    },
    BoxButton:{       
        [theme.breakpoints.up('md')]: {
            display:'flex',
            alignItems:'center'
        },
    },
    btnAction:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'46%',
        }
    },
    
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 960,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '90px',
        width:'100%',
        objectFit:'cover',
        maxHeight: '80px',
        borderRadius:'10px',
        height:'100%'
      },
    subtitle1:{
        margin:'0px 6px 10px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'5px 7px',
            height: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }
      },
  }));

export default function ComplainProduct(){
    const classes = useStyles();
    const [dataOrderComplain, setdataOrderComplain] = useState([]);
    const [files, setFiles] = useState([]);

    const uploadImages = (e) => {  
        let file_reader = new FileReader();
        let file = e.target.files[0]; 
        file_reader.onload = () => {
            setFiles(...files, file_reader.result);            
        };  
        file_reader.readAsDataURL(file);
      
    }; 

  
    const settings = {
        className: 'activeClass',
        dots: false,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
        
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          }
        ]
      };
    const history = useHistory();

    const MySwal = withReactContent(Swal);


    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(()=>{
        getComplainList();
    },[]);

    const getComplainList = () =>{
        axios.get(`${baseUrl}/order/complain/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'active' }
            })
          .then((response) => {          
              const dataResponse = response.data.data;
              setdataOrderComplain(dataResponse)
              
            });
    }

    const [isLoading, setisLoading]= useState(false);

    const handleOnClickButton = async (index, el) => {
     try {
      setisLoading(true)
      setActiveIndex(index);
      if(el === "Sedang Berlangsung"){
        axios.get(`${baseUrl}/order/complain/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'active' }
            })
          .then((response) => {          
              const dataResponse = response.data.data;
              setdataOrderComplain(dataResponse)
              
            });
      }else if(el === "Selesai Diproses"){
        axios.get(`${baseUrl}/order/complain/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'closed' }
            })
          .then((response) => {          
              const dataResponse = response.data.data;
              setdataOrderComplain(dataResponse);
              
        });
      }
    }finally{
    setTimeout(() => {
        setisLoading(false);
     }, 1000);
    }
    };
    const child   = { width: `100%`, height: `30px`, margin:`0 5px`, fontSize:'10px', whiteSpace:'no-wrap'}
    const boxs = ["Sedang Berlangsung", "Selesai Diproses"];
    const boxButton = boxs.map((el, index) => {
      return (
        <div className="sliderMenuHorizontal">
            <Button
            key={index}
            onClick={() => handleOnClickButton(index, el)} // pass the index
            className={activeIndex === index ? "activeClass" : null}
            variant="outlined"
            size="small"
            style={child}
            >
            {el}
            </Button>
        </div>
      );
    });

    const cancelComplain = (complain_uid) =>{
        axios.post(`${baseUrl}/order/complain/${complain_uid}/cancel`,{note:null},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataResponse = response.data;
              MySwal.fire({
                icon: 'success',
                title: "Komplain telah dibatalkan"
                }).then((result) => {
                    window.location.reload();
                });
              
        });
    }

    const discussComplain = (complain_uid, merchant_name, merchant_photo,user_id, user_name, user_photo) =>{
        history.push("/chat/complain", {params: {
            c_uid:complain_uid,
            c_name:merchant_name,
            c_photo:merchant_photo,
            c_user_id:user_id,
            c_user_name:user_name,
            c_user_photo:user_photo
        }
    })
    }

    const imageDefaultProduct = ProductEmpty.toString();
    const imageDefault = PhotoTemplate.toString();
    const imageDefaultUser = PhotoUser.toString();


    return(
        <DashboardLayout>
            <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}>
                    <Typography color="textPrimary" variant="h6">
                        Komplain Pesanan
                    </Typography> 
                </Box>
                
                <Box display="flex" width="100%" >
                <Slider {...settings} className="slideboxmenu">
                    {boxButton}
                </Slider>
                </Box>
             <Grid container spacing={1}>
                
            <Box width="100%" style={{padding:'10px 10px'}}>
            {isLoading ? <div style={{textAlign:'center'}}> <div><CircularProgress /> <div>Loading ....</div> </div></div> : 
               <>
              <div style={{marginBottom:'20px'}}>
               {dataOrderComplain.length > 0 ?  
                <div>
                    {dataOrderComplain.map((cmpl,index)=>{
                    return (
                      <List key={index}>
                        <Paper>
                        <div style={{padding:'5px 10px 0', display:'flex', alignItems:'center'}} className={classes.desktopOnly}>
                            <span><h3 style={{margin:'5px'}}>{cmpl.type === "return" && "Ganti Barang"} {cmpl.type === "refund" && "Uang Dikembalikan"} {cmpl.type === "cancel" && "Komplain dibatalkan"}</h3></span>
                            <span>({moment(cmpl.date).format('LLLL')})</span>
                        </div>
                        <div style={{padding:'5px 10px 0', display:'flex', alignItems:'center', justifyContent:'space-between'}} className={classes.desktopOnly}>
                            <span style={{display:'flex', alignItems:'center'}}><img src={cmpl.merchant.photo === null ? imageDefaultUser :cmpl.merchant.photo} height={40} style={{margin:'0 10px',borderRadius:'100%', borderColor:'#ababab'}}/>{cmpl.merchant.name}</span>
                            <span style={{marginRight:'55px'}}>{cmpl.invoice}</span>
                        </div>
                        <div className={classes.mobileOnly}>
                                <div >
                                    <div style={{display: 'flex',alignItems: 'center'}}>                                        
                                        <div style={{margin:'0 10px'}}>
                                            {cmpl.merchant.name}
                                            <div>
                                                <span style={{fontSize:'11px'}}> {moment(cmpl.date).format('LLLL')} </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <span style={{textTransform:'capitalize', background:'#efefef', padding:'0 10px', borderRadius:'5px'}}>{cmpl.status}</span>
                                </div>
                        </div>
                        
                                <div className={classes.paper}>                                    
                                    <Grid container spacing={2} xs={12} md={12} style={{position:'relative'}}>                                        
                                    <Grid item xs={12} md={12}> 
                                      {cmpl.product.items.map((order, k)=>{
                                        return(
                                             <Grid container xs={12} md={12}>                                          
                                                <Grid item xs={3} md={1}>
                                                    <img className={classes.img} alt="complex" src={order.cover === null ? imageDefault : order.cover }/>
                                                </Grid>
                                                <Grid item xs={9} md={11} container style={{padding:'0 10px'}}>
                                                 
                                                    <Grid item xs={12} md={9} container direction="column" spacing={2}>
                                                        <Grid item xs>                                       
                                                            <Typography variant="body2" gutterBottom className={classes.fsizeBox}>                                                           
                                                                <div style={{fontSize:'16px'}}>{order.name}</div>
                                                                <div style={{fontSize:'16px'}}><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(order.price)}</span></div>
                                                                <div><span>{order.qty} pcs</span></div>
                                                             </Typography>                                   
                                                        </Grid>
                                                    
                                                    </Grid>
                                                    <Grid item xs={12} md={3} container direction="column" spacing={1} style={{textAlign:'right', marginTop:'20px'}}>
                                                    <Typography variant="body2" gutterBottom  className={classes.desktopOnly}>
                                                        <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                        <div>
                                                            <div>Total Belanja</div>
                                                            <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(order.total_price)}</strong></div>
                                                        </div>  
                                                        </Typography>
                                                        
                                                    </Typography>
                                                    </Grid>                                           
                                                </Grid>
                                            </Grid>
                                        )
                                        })}    
                                        </Grid>                                    
                                        <Grid item xs={12} md={12}>
                                                <div style={{textAlign:'center', display:'flex', justifyContent:'flex-end'}}>
                                                    {cmpl.status === "closed" ? <></> :
                                                    <div style={{display:'flex', width:'100%', maxWidth:'400px'}}>
                                                         <Button variant="contained"  size="small" className={classes.btnAction} style={{background:'#ab0004', color:'#fff'}} onClick={()=>discussComplain(cmpl.uid, cmpl.merchant.domain, cmpl.merchant.photo, cmpl.merchant.name, cmpl.user.name, cmpl.user.photo)}>
                                                           Diskusi Komplain
                                                        </Button>

                                                        <Button variant="outlined"  size="small" className={classes.btnAction} style={{borderColor:'#ab0004', color:'#ab0004'}} onClick={()=>cancelComplain(cmpl.uid)}>
                                                            Batalkan Komplain
                                                        </Button>
                                                       
                                                        
                                                    </div>
                                                    }
                                                   
                                                </div>
                                            </Grid>
                                    </Grid>                                  
                                </div>
                         
                     </Paper>
                    </List>
                    )
                }
                   
                    
                )}

                           
                </div> 
                : 
                <div style={{textAlign:'center'}}>
                    <img src={imageDefaultProduct} height="300"/>
                    <p>Tidak ada komplain</p>
                </div>
                } 
                
            </div>
                </>
                }
                </Box>
             </Grid>

            
            
        </DashboardLayout>
        
    )
}

