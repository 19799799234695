import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../img/logo.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import playstoreIcon from '../img/en_badge_web_generic.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  Container: {
    borderTop: "2px solid #ddd",
    padding: "10px",
    backgroundColor: "#fff",
  },
  fcstore: {
    color: '#A70B0B',
    fontWeight: '700',
    fontSize: '16px'
  },
  linkFooter: {
    display: 'block',
    color: '#333',
    margin: '10px 30px 0 0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  borderBtn: {
    border: '1px solid #333',
    borderRadius: '10px',
    margin: '5px 6px 0 0',
    minHeight: '35px',
    padding: '0 27px'

  },
  textTittle: {
    fontSize: '13px'
  }

}));

export default function Footer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.Container}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <img src={logo} style={{ height: 80, width: 80 }} alt="logo" /><br /><br />
              <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                Merupakan situs jual beli oleh - oleh <br />daerah seluruh Indonesia.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                <div className={classes.fcstore}>Kontak Kami </div>
                No Telp / WA : <br />+62 812-8888-3745, <br />+62 812-8888-3752<br />
                Email : cs@leholeh.id, leholehnusantara@gmail.com
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                <div className={classes.fcstore}>Lokasi Kami </div>
                Menara Citibank, Lantai 3, Kav. II/BA No. 1 Pondok Indah, Jl. Metro Pondok Indah, RT.3/RW.14, Pd. Pinang, Kec. Kby. Lama, <br /> Kota Jakarta Selatan,<br /> Daerah Khusus Ibukota Jakarta 12310<br />
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom variant="h6" component="h6" className={classes.fcstore}>
                Unduh Aplikasi<br />
                <Link href="https://play.google.com/store/apps/details?id=co.id.leholeh.leholeh_mobile_app" target="_blank" className={classes.linkFooter}>
                  <img src={playstoreIcon} height="60" />
                </Link>
              </Typography>
              <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                <div className={classes.fcstore}>Ikuti Kami</div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Link href="https://www.facebook.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
                    <FacebookIcon />
                  </Link>
                  <Link href="https://www.instagram.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
                    <InstagramIcon />
                  </Link>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Container>

      </div>
    </React.Fragment>
  );
}
