import React,{useState,useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import '../../App.css';
import { useLocation, useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ImageIcon from '@material-ui/icons/Image';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/id'
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PhotoTemplate from '../../img/blank-profile-picture.png';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseUrl } from 'utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  hidden: {
      display: "-webkit-box",
      WebkitLineClamp: 4,
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
  },
  fsizeBox:{
      fontSize:'13px',
      [theme.breakpoints.down('md')]: {
          fontSize:'12px',
      },
  },
  statusProd:{
      width:'50px', 
      margin:'10px 10px 10px 60px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  uname:{
      width:'50px', 
      margin:'10px 10px 10px 50px'
  },
  jmlProd:{
      width:'50px', 
      margin:'10px'
  },
  hrgProd:{
      width:'50px', 
      margin:'10px 10px 10px 17px'
  },
  variantProd:{
      width:'50px', 
      margin:'10px 10px 10px 57px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  productBox:{
      border: '4px solid #ddd',
      width:'100%',
      marginBottom:'10px'
  },
  desktopOnly:{
      display: 'block',
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
  },
  mobileOnlywthStyle:{
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
  },
  mobileOnly:{
      display: 'flex',
      width:'100%',
      justifyContent:'space-between',
      borderBottom: '1px solid #ddd',
      padding: '10px 15px',
      alignItems:'center',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      }
  },
  boxMyOrder :{
      margin:'60px 5px 0',
      [theme.breakpoints.down('md')]: {
          margin: '0 15px 0px'
      },
  },
  BoxButton:{       
      [theme.breakpoints.up('md')]: {
          display:'flex',
          alignItems:'center'
      },
  },
  btnAction:{
      marginBottom:'10px', 
      marginRight:'10px', 
      width:'100%',
      [theme.breakpoints.down('md')]: {
          width:'46%',
      }
  },
  
  paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 960,
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  image: {
      width: 128,
      height: 100,
      [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '100%',
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      width:'100%',
      objectFit:'cover',
      maxHeight: '100px',
      height:'100%'
    },
  subtitle1:{
      margin:'0px 6px 10px',
      height:'auto',
      [theme.breakpoints.down('sm')]: {
          margin:'5px 7px',
          height: '30px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
      }
    },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '70vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '55vh',
    overflowY: 'auto',
    border:'1px solid #ddd'
  },
 
}));

function ChatStore() {
  const classes = useStyles();
  const [valueTabs, setValueTabs] = useState(0);
  const imageDefault = PhotoTemplate.toString();
  const history = useHistory();

  const [userName, setUsername] = useState()
  const [merchantName, setMerchantName]= useState();
  const [merchant, setMerchant]= useState();
  const [roomChat ,setRoomChat] = useState([]);
  const [roomMerchantChat ,setMerchantRoomChat] = useState([]);
  const [isLoading, setisLoading]= useState(false);
  
  const handleChangeTabs = async (event, newValue) => {
    try {
      setisLoading(true);
      await setValueTabs(newValue);
    } finally {
      setTimeout(() => {
        setisLoading(false);
      }, 1000);
    }
    
  };

  const getProfile = () => {
    axios.get(`${baseUrl}/user/profile`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }).then((response)=>{
        const allProfile = response.data; 
        const ismerchant = response.data.merchant; 
        const user_name = allProfile.name;
        if(ismerchant !== null){
          const merchant_name = allProfile.merchant.name;
          setMerchantName(merchant_name);  
        }
        console.log(allProfile);
        setUsername(user_name);        
        setMerchant(ismerchant);
            
    }).catch(error=> console.error(`Error: ${error}`));  
  }

  const getChatroom = () =>{
    axios.get(`${baseUrl}/chat/rooms`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    })
    .then((response) => {     
        const dataChatRoom = response.data; 
        setRoomChat(dataChatRoom); 
        
    });
  }

  useEffect(()=>{
    getChatroom();   
    getProfile(); 
    if(merchant !== null){      
      getMerchantChatroom();
    }
    
    // getDiscuss();
  
  },[]);

  
 

  const getMerchantChatroom = async() =>{
    try {
      axios.get(`${baseUrl}/chat/merchant/rooms`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
      })
      .then((response) => {     
          const dataChatRoomMerchant = response.data; 
          setMerchantRoomChat(dataChatRoomMerchant);   
      });
    } catch (error) {
      console.log(error.response)
    }
     
    
  }

  const startChat = (uid) =>{
    axios.post(`${baseUrl}/chat/rooms/${uid}/status`,{
        is_read: true,
    },{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }).then(()=>{
        history.push('/rooms/chat',{params:uid})
    })
    
  }

  const startChatMerchant = (uid) => {
    axios.post(`${baseUrl}/chat/merchant/rooms/${uid}/status`,{
        is_read: true,
    },{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }).then(()=>{
        history.push('/mrooms/chat',{params:uid})
    })
  }


  
  return (
        <DashboardLayout>
           
           <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}></Box>
            <Grid container component={Paper}> 
            <div style={{width:'100%'}}>
            <Tabs value={valueTabs} onChange={handleChangeTabs} aria-label="simple tabs example">
                <Tab label={userName} {...a11yProps(0)} />
                {merchant !== null && <Tab label={merchantName}  {...a11yProps(1)} />}
                
            </Tabs>
            </div>
            <div style={{width:'100%'}}>
            {isLoading ? <div style={{textAlign:'center'}}> <div><CircularProgress /> <div>Loading ....</div> </div></div> : 
            <>
            <TabPanel value={valueTabs} index={0}>              
                <List style={{width:'100%'}}>
                <Grid container >
                <Grid item xs={12} md={12}>
                {roomChat.map((ds,ik)=>{
                        return(
                            <React.Fragment key={ik}>                            
                                    <ListItem alignItems="flex-start" onClick={()=>startChat(ds.uid)} style={{cursor:'pointer'}}>
                                        <ListItemAvatar>
                                        <Avatar alt={ds.name && ds.name} src={ds.photo} />
                                        </ListItemAvatar>
                                        {ds.is_read === false ? <FiberNewIcon style={{color:'#ab0004'}}/> : <></>}
                                        <ListItemText
                                        primary={ds.name && ds.name}
                                        secondary={
                                            <React.Fragment>                                            
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                               {/* <div className="textChatMessage"> {ds.message.type === "image" &&  <ImageIcon style={{color:'#9f9f9f'}}/>} {ds.message === null ? "" : ds.message.content}</div> */}
                                               <div className="textChatMessage"> {ds.message && ds.message.type === "product" &&  <ImageIcon style={{color:'#9f9f9f'}}/>} {ds.message && ds.message.content}</div>
                                            </Typography>
                                            </React.Fragment>
                                        }
                                        style={{textTransform:'capitalize'}}
                                        />
                                        
                                        <span><small>{ds.updated_at}</small></span>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                 
                            </React.Fragment>
                        )
                    })}
                </Grid>  
                

                </Grid>
                </List>
                
               
            </TabPanel>
            <TabPanel value={valueTabs} index={1}>
            
            <List style={{width:'100%'}}>
                <Grid container >
                <Grid item xs={12} md={12}>
                  {roomMerchantChat.length === 0 ? 
                  <div style={{textAlign:'center'}}>
                      Tidak ada Chat
                  </div>
                  : 
                  <>                  
                  {roomMerchantChat.map((mds,ik)=>{
                        return(
                            <React.Fragment key={ik}> 
                                  {mds !== null ?   
                                  <>
                                   <ListItem alignItems="flex-start" onClick={()=>startChatMerchant(mds.uid)} style={{cursor:'pointer'}}>
                                        <ListItemAvatar>
                                        <Avatar alt={mds.name && mds.name} src={mds.photo} />
                                        </ListItemAvatar>
                                        {mds.is_read === false ? <FiberNewIcon style={{color:'#ab0004'}}/> : <></>}
                                        <ListItemText
                                        primary={mds.name && mds.name}
                                        secondary={
                                            <React.Fragment>                                            
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                               <div className="textChatMessage"> {mds.message && mds.message.type === "image" && <ImageIcon style={{color:'#9f9f9f'}}/>}{mds.message === null ? "" : mds.message.content}</div>
                                            </Typography>
                                            </React.Fragment>
                                        }
                                        style={{textTransform:'capitalize'}}
                                        />
                                        
                                        <span><small>{mds.updated_at}</small></span>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </>                       
                                   
                                  : <></>} 
                                 
                            </React.Fragment>
                        )
                    })}
                  </>
                  }
                
                </Grid>  
                

                </Grid>
                </List>
            </TabPanel>
            </> 
            }
            </div>
            </Grid>
            
          {/* <input type="text" onChange={(e)=>smChat(e.target.value)}/>
          <button >Send</button> */}
        </DashboardLayout>
  );
}

export default ChatStore;