import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Countdown from "react-countdown";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseUrl } from 'utils';

// Random component


function Invoice (){
    const { uid } = useParams();
    const [copySuccess, setCopySuccess] = useState('');
    const [isCopied, setIsCopied] = useState(false); 
    const copyToClipBoard = async copyMe => {
        try {
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess('Copied!');
        setIsCopied(true);
        } catch (err) {
        setCopySuccess('Failed to copy!');
        setIsCopied(false);
        }
    };
      const [paymentDetail, setPaymentDetail] = useState([]);
      const [vaNumber, setVaNumber] = useState("");
      const [billerCode, setBillerCode] = useState("");
      const [billerKey, setBillerKey] = useState("");
      const [bankInfo, setBankInfo] = useState("");
      const [deadline, setdeadlineTransTime] = useState();
      useEffect(()=>{
        axios.get(`${baseUrl}/order/payment/${uid}`, {
            headers:{
                "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": 'application/json'
            }}).then((result)=>{    
                const payment = result.data;
                const transTimeDate = result.data.method.expired_at;
                const vaNumb = result.data.method.va_number;
                const bank = result.data.method.bank;
                const bill_key = result.data.method.bill_key;
                const biller_code = result.data.method.biller_code;
                setBillerKey(bill_key);
                setBillerCode(biller_code);
                setVaNumber(vaNumb);
                setBankInfo(bank);
                setdeadlineTransTime(transTimeDate)
                setPaymentDetail(payment);
            })
      },[])

    const MySwal = withReactContent(Swal);

    const Completionist = () => <span>Waktu pembayaran kamu telah habis</span>;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
        return <Completionist />;
        } else {
        return (
            <span style={{fontSize:'24px'}}>
            {hours}:{minutes}:{seconds}
            </span>
        );
        }
    };
      const redirectToHome = () =>{
          window.location.href="/"
      }

      const redirectToOrder = () =>{
          if(paymentDetail.status === "pending"){
            window.location.href="/order/purchased"
          }else if(paymentDetail.status === "settlement"){
            window.location.href="/order/paid"
          }
    
    }

    return (
        <div style={{margin:'6em 0'}}>
            <Container maxWidth="sm">
                <Paper style={{padding:'20px', textAlign:'center', marginBottom:'20px'}}>
                    <Typography><strong>{paymentDetail.invoice}</strong></Typography>  
                    
                    {paymentDetail.status === "settlement" ? <div>Sudah Dibayar</div> : <div>
                        <Typography>Selesaikan Pembayaran Dalam</Typography>
                        <Countdown date={(new Date(deadline))} renderer={renderer}/>   
                        <Typography style={{color:'#888', fontSize:'14px'}}>Batas Akhir Pembayaran</Typography>
                        <Typography style={{fontWeight:'bold'}}>{paymentDetail.expired_at}</Typography>
                        <Typography style={{fontWeight:'bold'}}><Button variant="outlined" style={{backgroundColor:'#ddd'}}>{paymentDetail.status}</Button></Typography>
                    </div>
                    }  
                    
                </Paper>

                <Paper style={{padding:'20px', textAlign:'left', marginBottom:'20px'}}>
                    <Box display="flex" style={{justifyContent:'space-between', width:'100%', alignItems:'center', borderBottom: '2px solid #b7b7b7'}}>
                        <div>
                            {paymentDetail.method && paymentDetail.method.type === "bank_transfer" && <h3><span style={{textTransform:'uppercase'}}>{bankInfo}</span> Virtual Account</h3>}
                            {paymentDetail.method && paymentDetail.method.type === "gopay" && <h3><span style={{textTransform:'uppercase'}}>Gopay</span></h3>}
                            {paymentDetail.method && paymentDetail.method.type === "cstore" && <h3><span style={{textTransform:'uppercase'}}>{paymentDetail.method.store}</span></h3>}
                        </div>
                    </Box>
                    <Box>
                        {paymentDetail.method && paymentDetail.method.type === "bank_transfer" &&
                        <Box display="flex" style={{justifyContent:'space-between', width:'100%', alignItems:'center', margin:'10px 0'}}>
                            {bankInfo === 'mandiri' ? 
                            <div>
                                <label style={{color:'#888', fontSize:'12px'}}>Nomor Biller code</label>
                                <div><strong>{billerCode}{billerKey}</strong></div>
                            </div>
                            :
                            <div>
                                <label style={{color:'#888', fontSize:'12px'}}>Nomor Virtual Account</label>
                                <div><strong>{vaNumber}</strong></div>
                            </div>
                            }
                            
                            {paymentDetail.status === "pending" && <div>
                            {isCopied === true ? (copySuccess) : (<Button onClick={bankInfo === 'mandiri'? () => copyToClipBoard(`${billerCode}${billerKey}`) : () => copyToClipBoard(vaNumber)} endIcon={<FileCopyOutlinedIcon/>} style={{color:'#ab0004', fontWeight:'bold', textTransform:'capitalize'}}>
                                Salin 
                            </Button>)}                             
                            </div>
                            }
                            
                        </Box> }

                        {paymentDetail.method && paymentDetail.method.type === "cstore" &&
                        <Box display="flex" style={{justifyContent:'space-between', width:'100%', alignItems:'center', margin:'10px 0'}}>
                            <div>
                                <label style={{color:'#888', fontSize:'12px'}}>Kode Pembayaran</label>
                                <div><strong>{paymentDetail.method.payment_code}</strong></div>
                            </div>
                            {paymentDetail.status === "pending" && <div>
                            {isCopied === true ? (copySuccess) : (<Button onClick={() => copyToClipBoard(paymentDetail.method.payment_code)} endIcon={<FileCopyOutlinedIcon/>} style={{color:'#ab0004', fontWeight:'bold', textTransform:'capitalize'}}>
                                Salin 
                            </Button>)}                             
                            </div>
                            }
                            
                        </Box> }
                        <Box display="flex" style={{justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                            <div>
                                <label style={{color:'#888', fontSize:'12px'}}>Total Pembayaran</label>
                                <div><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(paymentDetail.total)}</strong></div>
                            </div>
                            <div>
                            <a href="/order" style={{color:'#ab0004', fontWeight:'bold', textDecoration:'none'}}>Lihat Detail</a>
                            </div>
                        </Box>
                    </Box>
                    

                </Paper>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Button fullWidth variant="outlined" style={{borderColor:'#ab0004', color:'#ab0004'}} onClick={()=>redirectToHome()}>Belanja Lagi</Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button fullWidth variant="contained" style={{background:'#ab0004', color:'#fff'}} onClick={()=>redirectToOrder()}>Cek Status Pembayaran</Button>
                    </Grid>
                </Grid>

                {/* <Paper style={{padding:'20px', textAlign:'left', marginTop:'20px'}}>
                    <Box display="flex" style={{justifyContent:'space-between', width:'100%', alignItems:'center', borderBottom: '2px solid #b7b7b7'}}>
                        <div>
                            <h3>Cara Pembayaran</h3>
                        </div>
                    </Box>
                    <Box>
                    <div style={{margin:'10px 0'}}>Panduan Pembayaran</div>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>ATM <span style={{textTransform:'uppercase'}}>{bankInfo}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography>Internet Banking <span style={{textTransform:'uppercase'}}>{bankInfo}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                        <Typography>Mobile Banking <span style={{textTransform:'uppercase'}}>{bankInfo}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </Box>
                </Paper> */}

            </Container>
        </div>
    );
}

export default Invoice;