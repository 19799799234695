import React, { useState, useEffect} from "react";
import axios from 'axios';
import DashboardLayout from '../DashboardLayout';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenStore from '../../img/Open Shop.svg';
import Paper from '@material-ui/core/Paper';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit:{
          background:'#ab0004',
          color:'#fff',
          '&:hover': {
            backgroundColor: '#ab0004',
          },   
      }
}));
export default function MerchantRegister(){
    const classes = useStyles();
    const [name, setStoreName] = useState(''); 
    const [domain, setDomain] = useState(''); 
    const [description, setStoreDescription] = useState(''); 
    const [showBtn, setShowBtn] = useState(false)
    const [textAvailable , setTextAvailable] = useState('');
    const [textUnAvailable , setTextUnAvailable] = useState('');
    const [OpenPhoneAlert, setOpenPhoneAlert] = useState(false);

    // const [statusDomain, setStatusDomain] = useState('');
    
    const MySwal = withReactContent(Swal)

    const handleChangeNameDomain = e => {
        e.preventDefault();
        // 
        setStoreName(e.target.value);
        setDomain(e.target.value.replace(/\s+/g,'-').toLowerCase())
        // const varName = name.replace(/\s+/g, '-').toLowerCase();
        // setDomain(varName);
    }
   useEffect(()=>{
     axios.get(`${baseUrl}/user/profile`, {
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
        }).then((response)=>{
            const allProfile = response.data; 
            if(allProfile.phone === null){
                setOpenPhoneAlert(true);
            }else if(allProfile.merchant !== null){
                MySwal.fire({
                    icon: 'error',
                    title: 'Anda sudah memiliki toko!'
                }).then(()=>{
                    window.location.href="/merchant/profile"
                })
            }           
                   
          }).catch(error=> console.error(`Error: ${error}`));      
    },[]);
    const toProfile = () =>{
        window.location.href="/profile"
    }


    const handleSubmit = async e => {   
        e.preventDefault();    
        // 
        await axios.post(`${baseUrl}/merchant/create`, {
            name,
            domain,
            description
        }, {headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }}).then(result => {                    
            MySwal.fire({
                icon: 'success',
                title: result.data.message
            }).then(()=>{
                window.location.href="/merchant/profile"
            })                    
            // 
        }).catch(e => {
            // 
            MySwal.fire({
                icon: 'warning',
                title: e.response.data.message
            }).then(()=>{
                window.location.href="/profile";
            })
        }); 
    };

    return(
        <DashboardLayout>
            <Box p={5} width="100%" >           
                <Typography color="textPrimary" variant="h6" style={{margin:'20px 0'}}>
                    Buka Toko
                </Typography>
                <Paper>
                <Box p={3}>
                <Grid container spacing={3}>
                              
                              <Grid item xs={12} md={6} style={{textAlign:'center'}}>
                                  <p style={{fontSize:'20px'}}>Daftarkan tokomu terlebih dahulu ya!</p>
                                  <img src={OpenStore} width="100%"/>
                              </Grid>
                              <Grid item xs={12} md={6}>
                              {/* onSubmit={handleSubmit} */}
                              <form className={classes.form}  noValidate onSubmit={handleSubmit} style={{margin:'40px 0 0'}}>
                                  <label>Tuliskan Nama Tokomu</label>
                                  <TextField
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="storename"
                                      label="Nama Toko"
                                      name="name"
                                      autoComplete="off"
                                      // onChange={e => setStoreName(e.target.value)}
                                      onChange={handleChangeNameDomain} 
                                     
                                  />
                                  <small>Pastikan nama toko yang kamu isi sudah benar</small>
                                
                                  <div style={{marginTop:'20px'}}>
                                  <label>Tuliskan Deskripsi Tokomu</label>
                                  <TextField
                                      variant="outlined"
                                      margin="normal"
                                      multiline
                                      rows={4}
                                      fullWidth
                                      id="storedesc"
                                      label="Deskripsi Toko"
                                      name="storedesc"
                                      autoComplete="off"
                                      onChange={e => setStoreDescription(e.target.value)}
                                  />
                                  </div>
                                  
                                  <Button
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      className={classes.submit}
                                  >
                                      Buka Toko
                                  </Button>
                              </form>
                              </Grid>
                          </Grid>
                </Box>
               
                </Paper>
               
           </Box>


           <Dialog open={OpenPhoneAlert} aria-labelledby="form-dialog-title" disableBackdropClick="true">
            <DialogTitle id="form-dialog-title" style={{textAlign:'center',paddingBottom:'0'}}>
              Tambah No Pengguna
              {/* <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleClose}/></span> */}
            </DialogTitle>
            <DialogContent style={{textAlign:'center'}}>          
                <p>Anda belum menambahkan no telepon, silahkan tambahkan terlebih dahulu</p>
                <Button variant="outlined" color="primary" onClick={toProfile}>Click Disini</Button>
            </DialogContent>            
        </Dialog>  
          
        </DashboardLayout>
        
    )

}
  /* <small>suggestion domain toko : {name.replace(/\s+/g,'-').toLowerCase()} {showBtn ? (<span className="useThisButton" onClick={btnBlur}>{textAvailable}</span>):(<span className="useThisButtonUn">{textUnAvailable}</span>)} </small> */
          
    /* <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="domain"
        label="Domain"
        name="domain"
        autoComplete="off"
        value={name.replace(/\s+/g,'-').toLowerCase()}
        // onInput={e => setDomain(e.target.value.replace(/\s+/g,'-').toLowerCase())}
    /> */
    // const BlurButton = async () =>{        
    //     await axios.post(`${baseUrl}/merchant/check`, {
    //         name
    //     }, {headers:{
    //         "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    //     }}).then(result => {
    //           const dataMesage = result.data;
    //           
    //           setTextAvailable(dataMesage.message);
    //           setShowBtn(true); 

    //     }).catch(e => {
    //         // 
    //         setTextUnAvailable("Nama Domain Sudah Ada");
    //         setShowBtn(false)
    //     }); 
    // }

    // const btnBlur = ()=>{
    //     setDomain(name.replace(/\s+/g,'-').toLowerCase())
    // }

     // axios.post(`${baseUrl}/merchant/check`, {
        //     name
        // }, {headers:{
        //     "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        // }}).then(result => {
        //     const dataMesage = result.data;
        //     if(dataMesage.status_code === 200){
        //         // 
        //         MySwal.fire({
        //             icon: 'success',
        //             title: dataMesage.message
        //         }).then((res)=>{
        //             axios.post(`${baseUrl}/merchant/create`, {
        //                 name,
        //                 domain,
        //                 description
        //             }, {headers:{
        //                 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        //             }}).then(result => {                    
        //                 MySwal.fire({
        //                     icon: 'success',
        //                     title: result.data.message
        //                 }).then(()=>{
        //                     window.location.href="/merchant/profile"
        //                 })                    
        //                 // 
        //             }).catch(e => {
        //                 // 
        //                 MySwal.fire({
        //                     icon: 'error',
        //                     title: e.response.data.message
        //                 })
        //             }); 
        //         })
        //     }
        // }).catch(e => {
        //     // 
        //     MySwal.fire({
        //         icon: 'error',
        //         title: e.response.data.message
        //     })
        // }); 
    // const DomainButton = () => (
    //     <Button      
    //         variant="outlined"
    //         color="primary"
    //         size="small"
    //     >
    //     Check
    //     </Button>
    // )

    // const handleChangeName = async e => {
    //     e.preventDefault();
    //     // 
    //     setStoreName(e.target.value);
    //     // const varName = name.replace(/\s+/g, '-').toLowerCase();
    //     // setDomain(varName);
    //     await axios.post(`${baseUrl}/merchant/check`, {
    //         name
    //     }, {headers:{
    //         "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    //     }}).then(result => {
    //           const dataMesage = result.data;
    //           if(dataMesage.status_code === 200){
    //             
    //           }else if(dataMesage.status_code === 402){
    //             
    //           }
                            
            
    //     }).catch(e => {
    //         
    //     }); 
      
    // }

    // const handleChangeDomain = async e => {
    //     e.preventDefault();
    //     // 
    //     setDomain(e.target.value)
    //     // const varName = name.replace(/\s+/g, '-').toLowerCase();
    //     // setDomain(varName);
    //     await axios.post(`${baseUrl}/merchant/check`, {
    //         domain
    //     }, {headers:{
    //         "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    //     }}).then(result => {
    //           const dataMesage = result.data;
    //           if(dataMesage.status_code === 200){
    //             
    //           }else if(dataMesage.status_code === 402){
    //             
    //           }
                            
            
    //     }).catch(e => {
    //         
    //     }); 
      
    // }