import React, {useEffect, useState, createContext} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ToolbarMenuLogin from '../components/ToolbarMenuLogin.js';
import ToolbarMenuUpLogin from '../components/ToolbarMenuUpLogin.js';
import Footer from '../components/Footer.js';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import axios from 'axios';
import ContextGlobal from "./data/useContextGlobal";
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};



export default function ElevateAppBar(props) {
  const [totalCart, setTotalCart]= useState(0);
  const [balance , setBalance] =  useState(0);
  const [dataProfile, setDataProfile] = useState([]);

  useEffect(() => {
    getDataCart();
    getDataBalance();
    getDataProfile();
           
  }, []);

  const getDataCart = async() =>{
    try {
      await axios.get(`${baseUrl}/cart/items`,{headers:{
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }}).then((response)=>{
        const res= response.data;
        setTotalCart(res.total); 
      })  
    } catch (error) {
      console.log(error)
    }
   
  }

  const getDataBalance = async() =>{
    try {
      await axios.get(`${baseUrl}/user/wallet`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
        })
      .then((response) => { 
          const dataWallet = response.data.balance;
          setBalance(dataWallet);
          
        });
    } catch (error) {
      console.log(error)
    }
   
  }

  const getDataProfile = async() =>{
    try {
      await axios.get(`${baseUrl}/user/profile`,{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {   
        const profileData= response.data;
        setDataProfile(profileData);
      });
    } catch (error) {
      console.log(error)
    }
    
  }


  return (
    <ContextGlobal.Provider value={{ wallet: balance, profile: dataProfile }}>

    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar style={{paddingRight:0}}>
          <ToolbarMenuUpLogin/>
          <ToolbarMenuLogin dataCart={totalCart} dataWallet={balance} profile={dataProfile}/>
        </AppBar>        
      </ElevationScroll>
      <Toolbar id="back-to-top-anchor"/>
      <Container>
        <Box>
        {props.children}
        </Box>        
      </Container>
      <ScrollTop {...props}>
        <Fab color="default" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer/>
      
    </React.Fragment>

    </ContextGlobal.Provider>
  );
}
