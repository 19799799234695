import React, {useEffect,useState} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../App.css";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../img/broken-256.png';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import ProductEmpty from "../img/No Product (General).svg";
import Rating from '@material-ui/lab/Rating';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  }
}))
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


function SouvenirCategoryHome() {
    const [valueTab, setValueTab] =useState(0);
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    const classes = useStyles();
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(15);
    const [priceMin, setPriceMin]= useState();
    const [priceMax, setPriceMax]= useState();
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [metaTotal , setMetaTotal] = useState();
    const imageDefault = PhotoTemplate.toString();

    const [dataProductSearch, setDataProductSearch] = useState([]);

    useEffect(()=>{
        axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { category:"souvenir" }
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse)
           
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
           
        });
        provinceJson();
    },[currentPage])

    const [dataProvince, setDataProvince] = useState([]);
    const provinceJson = async () => { 
        axios.get(`/provinces.json`)
        .then((response)=>{
           
            const dataProv = response.data;
            setDataProvince(dataProv);
        })
    };


    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Populer',
        value: 'popular'        
      },
      {
        label: 'Tidak Populer',
        value: 'unpopular'        
      }
    ]

    const [provinceCode, setProvinceCode] = useState([]);
    const [filteredProvinceCode, setFilteredProvinceCode] = useState("");
    const handleChangeProvince = (e, value) =>{
      if (e.target.checked) {
        setProvinceCode([...provinceCode, e.target.value]);
      } else {
        setProvinceCode(provinceCode.filter((province_code) => province_code !== e.target.value));
      }
    }

    useEffect(() => {
      if (provinceCode.length === 0) {
        setFilteredProvinceCode("");
      } else {
        setFilteredProvinceCode(provinceCode.join(","));
      }
    }, [provinceCode]);
     

    const submitFilter = () =>{
        axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: {category:"souvenir", price: priceMin+'-'+priceMax,  location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
           
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
           
        });
    }

    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: {category:"souvenir", price: priceMin+'-'+priceMax}
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setDataProductSearch(dataResponse);
       
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
       
    });
    }


    const submitLocation = () =>{
      axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { category:"souvenir", location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
           
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
           
        });
    }

    


  
    const handleClick = (event) => {
      setcurrentPage(Number(event.target.id));
    };
  
    const pages = [];
    for (let i = 1; i <= Math.ceil(metaTotal / itemsPerPage); i++) {
      pages.push(i);
    }
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataProductSearch.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });


    const handleNextbtn = (currentPage) => {

        setcurrentPage(currentPage + 1);   
        if (currentPage + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = (currentPage) => {
    
        setcurrentPage(currentPage - 1);
    
        if ((currentPage - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }

      const NotFoundProduct = ProductEmpty.toString();

      const [sortValue, setSortValue] = useState();
      useEffect(()=>{
        sortSubmit();
      },[sortValue])

      const sortSubmit = () =>{
        axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { category:"souvenir", sorting:sortValue}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
           
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            setMetaTotal(dataTotal);
           
        });
      }

      

    return (
        <div className={classes.MainPage}>
            
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Lokasi</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                               
                                {dataProvince.map((prov,index)=>{
                                    return(
                                        <FormControlLabel
                                            key={index}
                                            control={
                                            <Checkbox
                                                onChange={handleChangeProvince}
                                                name={prov.province_name}
                                                value={prov.province_code}
                                                color="primary"
                                            />
                                            }
                                            label={prov.province_name}
                                        />
                                    )
                                })}
                                
                                </div>
                                
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitLocation()}>Terapkan</Button></div>
                        </Accordion>
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={submitFilter}>Terapkan Semua</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper>
                       <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>

                       <Tabs value={valueTab} onChange={handleChangeTab} aria-label="simple tabs example" style={{width:'70%'}}>
                            <Tab label="Produk" {...a11yProps(0)} />
                            {/* <Tab label="Toko" {...a11yProps(1)} /> */}
                        </Tabs>
                        <div style={{marginRight:'10px'}}>
                          <label>Urutkan : </label> 
                            <select style={{padding:'3px 10px'}} onClick={(e)=>setSortValue(e.target.value)} onChange={()=> sortSubmit()}>
                            <option value="--Paling Sesuai--" disabled selected>--Paling Sesuai--</option>
                              {sortProduct.map(({ label, value}, index) => {
                                return ( 
                                  <option value={value} key={index}>{label}</option>
                                )})}
                            </select>
                            
                          </div>

                       </div>
                        

                        <TabPanel value={valueTab} index={0}>
                          <React.Fragment>
                              {dataProductSearch.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductSearch.map((prdSearch, k) =>{
                                    return(
                                      
                                        <Grid item xs={12} md={3} key={k}>
                                            <Card>
                                            <Link to={{ pathname: "/detail/" + prdSearch.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                                <CardActionArea>
                                                    <img src={prdSearch.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                    <CardContent>
                                                    <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                        {prdSearch.name}
                                                    </Typography>
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prdSearch.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        <span style={{fontSize:'12px'}}>{prdSearch.location.name}</span> <br />
                                                        {prdSearch.rating === null ?  <Rating
                                                              name="simple-controlled"
                                                              value={0}
                                                              disabled
                                                          /> :  <Rating
                                                          name="simple-controlled"
                                                          value={prdSearch.rating}
                                                          disabled
                                                      />}
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                   
                                    )
                                  })}
                                  </Grid>

                                  <ul className="pageNumbers">
                                    <li>
                                            <button
                                              onClick={()=>handlePrevbtn(currentPage)}
                                              disabled={currentPage === pages[0] ? true : false}
                                            >
                                              <ChevronLeftIcon />
                                            </button>
                                          </li>
                                          {pageDecrementBtn}
                                          {renderPageNumbers}
                                          {pageIncrementBtn}

                                          <li>
                                            <button
                                              onClick={()=>handleNextbtn(currentPage)}
                                              disabled={currentPage == pages[pages.length - 1] ? true : false}
                                            >
                                              <ChevronRightIcon />
                                            </button>
                                          </li>
                                        </ul>
                                </div>
                                
                              }
                               
                            </React.Fragment>
                        </TabPanel>
                       
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default SouvenirCategoryHome;