import React, { useState, useEffect } from "react";
import Checklist from "./Checkbox";
import axios from 'axios';
import {Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import CartEmpty from '../img/Empty Cart.svg';
import PhotoTemplate from '../img/broken-256.png';
import Swal from 'sweetalert2';
import Paper from '@material-ui/core/Paper';
import withReactContent from 'sweetalert2-react-content';
import '../App.css';
import List from '@material-ui/core/List';
import { baseUrl } from 'utils';

function Cart(){
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [CheckedLength, setCheckedLength] = useState()
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [totalCart, setTotalCart]= useState(0);
  const [totalPrice, setTotalPrice]= useState(0);
  const [qtyp, setQtyp]= useState();
  const [prdMax, setPrdMax] = useState()
  const imageDefault = PhotoTemplate.toString();
  const [dataProduct, setdataProduct] = useState([]);
  const MySwal = withReactContent(Swal);
  const imageDefaultCart = CartEmpty.toString();

  useEffect(() => {
    let mounted = true;   
    getCartProduct();
    return () => mounted = false;
    
  }, [qtyp]);

  const getCartProduct = async () => {
    try {
      const res = await axios.get(`${baseUrl}/cart/items`,{headers:{
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }})   
      const dataItems = res.data.items;
      const products = [];  
      dataItems.forEach(element => {
        products.push(...element.products)
      });   
      const size = products.filter(obj => obj.is_checked).length;
      const dataProdCheck = products.filter(obj => obj.item_id && obj.is_checked === true);
      const daprod = dataProdCheck.map(drp => drp.item_id);
      const maxProd = products.map(prd => prd.product_qty);
      setPrdMax(maxProd);
      setCheckedLength(size);
      setList(products);
      setIsCheck(daprod);
      setdataProduct(dataItems);
      setTotalPrice(res.data.total_price)
      setTotalCart(res.data.total); 
      return products;

    } catch (e) {
      
    }
  };
  



  const handleSelectAll = (e) => { 
    const {checked} = e.target;  
    const checkAll = list.map(lsd => lsd.item_id);
    if(checked){
      axios.post(`${baseUrl}/cart/check/`,{product:checkAll},{
        headers:{
              "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
              "Content-Type": 'application/json',
              "Access-Control-Allow-Origin":'*'
        }}).then((result)=>{
          setIsCheckAll(!isCheckAll);
          getCartProduct();
        })
    }else{
      axios.post(`${baseUrl}/cart/check/`,{product:'empty'},{
        headers:{
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin":'*'
    }}).then((result)=>{
      setIsCheckAll(isCheckAll);
      getCartProduct();
    })
    }
    
  };

  const handleClickCheckBox = (e) => {
    const { id, checked} = e.target;    
    if(checked){
      isCheck.push(parseInt(id));
    }else{
      const index = isCheck.indexOf(parseInt(id));
      if (index !== -1) {
        isCheck.splice(index, 1);
      }
    }
  
    if(isCheck.length > 0){
      axios.post(`${baseUrl}/cart/check/`,{product:isCheck},{
        headers:{
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin":'*'
       }}).then((result)=>{
         getCartProduct();
       })
    }
    else{
      axios.post(`${baseUrl}/cart/check/`,{product:'empty'},{
        headers:{
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin":'*'
       }}).then((result)=>{
         getCartProduct();
       })
    }
   
  
  };

  const onChangeProductNote = (event, idi)=>{
    const noted = event.target.value; 
    axios.post(`${baseUrl}/cart/items/${idi}/change`,{note:noted},{
      headers:{
        "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        "Content-Type": 'application/json'
     }}).then((result)=>{
       
       getCartProduct();
     }).catch(()=>{
      MySwal.fire({
        icon: 'error',
        title: 'Produk Tidak Ditemukan'
      })
     })
  }

  const onChangeProductQuantity = (event, idi, pdqty) => {
      const qtyVal = event.target.value; 
      setQtyp(qtyVal);
      if(qtyVal > pdqty){
        MySwal.fire({
          icon: 'error',
          title: 'Stok produk tidak tersedia'
        })
      }
      axios.post(`${baseUrl}/cart/items/${idi}/change`,{qty:qtyVal},{
        headers:{
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          "Content-Type": 'application/json'
        }}).then((result)=>{          
          getCartProduct();
        }).catch((err)=>{
          
        })
      
   }


  const CartItem = dataProduct.map((li)=>{

    return(
      <Paper style={{marginBottom:'20px', border:'1px solid #ddd', position:'relative'}}>      
        <Typography color="textPrimary" ><div style={{fontWeight:'bold', margin:'10px 25px 0'}}>{li.merchant.name}</div></Typography>
        <Typography style={{color:'#888',margin:'0 25px', fontSize:'13px'}}>{li.merchant.location}</Typography>
        {li.products.map((cm, indexProduct)=>{          
          return(
            <>
            {cm.product_is_deleted === false || cm.product_is_banned === false  ? 
            <div className="containerBoxCart" >
              <div style={{display:'flex',margin:'0 25px'}}>
                <Checklist
                    key={cm.item_id}
                    type="checkbox"
                    name={cm.name}
                    id={cm.item_id}
                    handleClick={(e)=>handleClickCheckBox(e, cm.price, cm.qty, cm.note, indexProduct)}
                    isChecked={li.products[indexProduct].is_checked}
                  />
                  { cm.cover === null ? (<img src={imageDefault} className="imgProductCart"/>):(<img src={cm.cover} className="imgProductCart" />)} 
                  <div>
                      <Link to={{ pathname: "/product/" + cm.slug }} style={{color:"#656565", textDecoration:'none'}}> <Typography color="textPrimary" style={{fontSize:'14px', fontWeight:'bold'}}>{cm.name}</Typography></Link>
                      <Typography color="textPrimary" style={{fontSize:'13px'}}>{cm.variant === null ? "Tidak ada variant" : cm.variant}</Typography>
                      <Typography color="textPrimary" style={{fontSize:'13px',fontWeight:'bold'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(cm.price)} </Typography> 
                    </div> 
                    <div style={{position:'absolute', top:'0px', right:'40px'}}><ClearIcon onClick={() =>handleRemoveItem(cm.item_id)} style={{cursor:'pointer'}}/></div>   
              </div>
              <div style={{display:'flex', justifyContent:'space-between', margin:'15px 25px'}}>                               
                    <div>
                      <TextField
                        id={"text"+cm.item_id}
                        label="Notes"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        variant="outlined"
                        onBlur={(e)=>onChangeProductNote(e, cm.item_id)}
                        defaultValue={cm.note}
                        style={{marginRight:'10px'}}
                      /> 
                      
                    </div> 
                              
                    <div>
                      <div className="number_count" >                              
                          <TextField
                            id="outlined-number"
                            size="small"
                            type="number"
                            className="centerInput"
                            style={{maxWidth:'80px'}}
                            defaultValue={cm.qty}
                            variant="outlined"
                            InputProps={{ inputProps: { min: "1", max: cm.product_qty, step: "1" } }}                            
                            onChange={(event) => onChangeProductQuantity(event, cm.item_id, cm.product_qty)}
                           
                          />      
                        
                        </div>
                      </div>
                    </div>
            </div> : <><div className="containerBoxCart">
              <div style={{display:'flex',margin:'0 25px', alignItems:'center'}}>
                  { cm.cover === null ? (<img src={imageDefault} className="imgProductCart"/>):(<img src={cm.cover} className="imgProductCart" />)} 
                    <div>
                      <Typography color="textPrimary" style={{fontSize:'13px',textDecoration: 'line-through'}}>{cm.name}</Typography>
                      <Typography color="textPrimary" style={{fontSize:'13px',textDecoration: 'line-through'}}>{cm.variant === null ? "Tidak ada variant" : cm.variant}</Typography>
                      <Typography color="textPrimary" style={{fontSize:'13px',fontWeight:'bold',textDecoration: 'line-through'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(cm.price)} </Typography> 
                    </div> 
                    <div style={{textAlign:'center', width:'285px', color:'#ab0004'}}>Product Telah di Hapus</div>
                    <div style={{position:'absolute', top:'0px', right:'40px'}}><ClearIcon onClick={() =>handleRemoveItem(cm.item_id)} style={{cursor:'pointer'}}/></div>   
              </div>
              <div style={{display:'flex', justifyContent:'space-between', margin:'15px 25px'}}>                               
                    <div>
                      <TextField
                        id={"text"+cm.item_id}
                        label="Notes"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        variant="outlined"
                        defaultValue={cm.note}
                        style={{marginRight:'10px',textDecoration: 'line-through'}}
                        InputProps={{
                          readOnly: true,
                        }}   
                      /> 
                     
                    </div> 
                              
                    <div>
                      <div className="number_count" >                              
                          <TextField
                            id="outlined-number"
                            size="small"
                            type="number"
                            className="centerInput"
                            style={{maxWidth:'60px',textDecoration: 'line-through'}}
                            defaultValue={cm.qty}
                            variant="outlined"
                            InputProps={{ readOnly: true, inputProps: { min: "1", max: "100", step: "1" } }}                            
                          />      
                        
                        </div>
                      </div>
                    </div>
            </div> </>}
            </>
          )})}
      </Paper>
    )
  })

  const handleRemoveItem = idp =>{
    MySwal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Kamu tidak dapat mengulangnya kembali!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${baseUrl}/cart/items/`+idp+'/remove',{id: idp},{
          headers:{
          "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
          "Content-Type": 'application/json'
          }}).then((result)=>{
            MySwal.fire({
              icon: 'success',
              title: 'Produk berhasil di hapus dari keranjang'
            }).then((result) => {
                window.location.reload();
            });
            
          })
          
      }
    })
   
  }

  const removeCart = (id) => {
    MySwal.fire({
      title: 'Apakah Anda Yakin?',
      text: "Kamu tidak dapat mengulangnya kembali!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${baseUrl}/cart/items/remove`,{id:id},{
            headers:{
              "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
              "Content-Type": 'application/json'
          }}).then((result)=>{       
            MySwal.fire({
              icon: 'success',
              title: 'Produk berhasil di hapus dari keranjang'
            }).then((result) => {
                window.location.reload();
            });
          })
          
      }
    })
    
  }

  const CheckoutProduct = () =>{
    // const productdata = dataProduct.filter(item => item.is_checked === true);
    axios.post(`${baseUrl}/cart/checkout`,{},{
      headers:{
        "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        "Content-Type": 'application/json'
     }}).then((result)=>{   
      window.location.href="/checkout/"  
     
     }).catch(e => {
      
      if(e.response.data.message === "Product empty"){
        MySwal.fire({
          icon: 'error',
          title: "Tidak ada produk di keranjang!"
        })
      }else if(e.response.data.message === "User doesnt has location"){
        MySwal.fire({
          icon: 'error',
          title: "Anda belum setting lokasi atau alamat!"
        }).then((result) => {
          window.location.href="/location"           
      });
      }else if(e.response.data.message === "User phone can not be empty"){
        MySwal.fire({
          icon: 'error',
          title: "Anda belum memasukan nomor handphone anda!"
        }).then((result) => {
          window.location.href="/profile"           
      });
      }
     
    }); 
    
    
  }

  return (
    <div style={{margin:'6em 0'}}>
    <Container maxWidth="md">
    {totalCart === 0 ? (<div style={{textAlign:'center', marginTop:'8em'}}>
          <img src={imageDefaultCart} style={{maxWidth:'65%'}}/> 
          <h3>Keranjangmu masih kosong</h3>
          <p>Kalau ada produk yang kamu suka, tambahkan ke keranjang ya!</p>
          </div>) : (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
      <Card>
       
        <CardContent>
          
         <h3>Keranjang</h3>
         <Box display="flex" justifyContent="space-between" style={{borderBottom:'2px solid #bbb', margin:'10px 0'}}>
            <div>
                <Checklist
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={(e)=>handleSelectAll(e)}
                    isChecked={CheckedLength === totalCart ? true : false }
                    // isChecked={list.map(lsd => lsd.is_checked === true ? true : false)}
                  />
                <label>Pilih Semua</label>
               
              </div>
            <Button className="btnCartNoBg" onClick={()=>removeCart()}>Hapus Semua Cart</Button>
        </Box>
        <div>         
            <div className="CartContainer">
              <List>                
                  {CartItem}
              </List>
            </div>          
         
          </div>       
       
        </CardContent>
       
      </Card>
      </Grid>
      <Grid item xs={12} md={4}>
          <Card>             
            <CardContent>
              <h3>Ringkasan Belanja</h3>
              <Box display="flex" justifyContent="space-between">
                <div >
                  Total Harga Barang
                </div>
                <div>
                  {/* {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(checkedPrice.reduce((a,v) =>  a = a + v.price * v.qty , 0 ))}                   */}
                  {/* {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(isCheckAll === true ? totalPrice : totalAmount)}  */}
                  {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(totalPrice)} 
                </div>
              </Box>
              <div style={{borderBottom:'2px solid #bbb', margin:'10px 0'}}></div>
              <Box display="flex" justifyContent="space-between" style={{fontWeight:'bold'}}>
                <div >
                  Total Harga
                </div>
                <div>
                {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(totalPrice)}
              
                </div>
              </Box>
              <div style={{marginTop:'20px'}}>
                { totalCart > 0 ? (<Button variant="contained" size="small" fullWidth className="btnCart" onClick={CheckoutProduct}>Beli </Button>):(<Button variant="contained" size="small" fullWidth  disabled>Beli</Button>)} 
              </div>
            </CardContent>
          </Card>
      </Grid>
    </Grid>
     )} 
    </Container>

  </div>

  );
};


export default Cart;
