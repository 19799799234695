import React,{useState,useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import '../../App.css';
import {firestore} from '../../FirebaseConfig';
import { useLocation, useHistory, Link} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/id'
import ScrollToBottom from 'react-scroll-to-bottom';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  hidden: {
      display: "-webkit-box",
      WebkitLineClamp: 4,
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
  },
  fsizeBox:{
      fontSize:'13px',
      [theme.breakpoints.down('md')]: {
          fontSize:'12px',
      },
  },
  statusProd:{
      width:'50px', 
      margin:'10px 10px 10px 60px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  uname:{
      width:'50px', 
      margin:'10px 10px 10px 50px'
  },
  jmlProd:{
      width:'50px', 
      margin:'10px'
  },
  hrgProd:{
      width:'50px', 
      margin:'10px 10px 10px 17px'
  },
  variantProd:{
      width:'50px', 
      margin:'10px 10px 10px 57px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  productBox:{
      border: '4px solid #ddd',
      width:'100%',
      marginBottom:'10px'
  },
  desktopOnly:{
      display: 'block',
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
  },
  mobileOnlywthStyle:{
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
  },
  mobileOnly:{
      display: 'flex',
      width:'100%',
      justifyContent:'space-between',
      borderBottom: '1px solid #ddd',
      padding: '10px 15px',
      alignItems:'center',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      }
  },
  boxMyOrder :{
      margin:'60px 5px 0',
      [theme.breakpoints.down('md')]: {
          margin: '0 15px 0px'
      },
  },
  BoxButton:{       
      [theme.breakpoints.up('md')]: {
          display:'flex',
          alignItems:'center'
      },
  },
  btnAction:{
      marginBottom:'10px', 
      marginRight:'10px', 
      width:'100%',
      [theme.breakpoints.down('md')]: {
          width:'46%',
      }
  },
  
  paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 960,
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  image: {
      width: 128,
      height: 100,
      [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '100%',
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      width:'100%',
      objectFit:'cover',
      maxHeight: '100px',
      height:'100%'
    },
  subtitle1:{
      margin:'0px 6px 10px',
      height:'auto',
      [theme.breakpoints.down('sm')]: {
          margin:'5px 7px',
          height: '30px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
      }
    },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '70vh',
    position:'relative'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '50vh',
    overflowY: 'auto',
    padding:'20px'
  },
  appBar: {
    position: 'relative',
    backgroundColor:'#ab0004'
  },
 
}));

function ChatRooms() {
  const classes = useStyles();
  const [discuss,setDiscuss]=useState([]);
  const [dataChat, setdataChat] = useState();  
  const [domain, setdomain] = useState();  
  const [merchantName, setmerchantName] = useState();  
  const [merchantPhoto, setmerchantPhoto] = useState();  
  const [userId, setuserId] = useState();  
  const [userName, setuserName] = useState();  
  const [userPhoto, setuserPhoto] = useState();  
  const location = useLocation();
  const complUid = location.state.params; 
  const history = useHistory();
  const [newLine, setNewLine] = useState(false);
  const [isUpload, setUpload] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesUpload, setfilesUpload]= useState([]);
  const maxfileSize = 3097152;
  const [fileSizeImage, setfileSizeImage]= useState(0);
  const [fileType, setfileType] = useState();
  const [openPreview, setopenPreview] = useState(false);
  const MySwal = withReactContent(Swal)
  const handleClickOpenPreview = () => {
    setopenPreview(true);
  };

  const handleClosePreview = () => {
    setopenPreview(false);
  };


  const [scm ,smChat] = useState();

  useEffect(()=>{
    getDiscuss();
    getChatDetail();
  },[])


  const uploadfile = () =>{
    if(isUpload === true){
      axios.post(`${baseUrl}/chat/rooms/${complUid}/upload`,{image:files}, {
        headers: {
            "Authorization":  `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            "Access-Control-Allow-Origin":'*'
          }
        })
        .then((res)=>{         
          
          const fileImage = res.data.data.uri;
          setopenPreview(false);
          getDiscuss();
          firestore.collection(`chat/${complUid}/messages/`).add({
            message: fileImage, 
            type: "image", 
            date: moment().format('Do MMMM YYYY, HH:mm:ss'), 
            userid: userId, 
            status:'sent'
          }).then(()=>{
            getDiscuss();
          })
        }) 
    }
    
  }

  const getChatDetail = () =>{
    axios.get(`${baseUrl}/chat/rooms/${complUid}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    })
    .then((response) => {     
        const dataChatRoom = response.data; 
        const merchDomain = dataChatRoom.merchant.domain;
        const merchName = dataChatRoom.merchant.name;
        const merchPhoto = dataChatRoom.merchant.photo;
        const userid = dataChatRoom.user.uname;
        const username = dataChatRoom.user.name;
        const userphoto = dataChatRoom.user.photo;
        setuserName(username);
        setuserId(userid);
        setuserPhoto(userphoto);
        setdomain(merchDomain);
        setmerchantName(merchName);
        setmerchantPhoto(merchPhoto)
        setdataChat(dataChatRoom);    
        

    });
  }

  const getDiscuss = () =>{
    firestore.collection(`chat`).doc(`${complUid}`).collection('messages').orderBy("date","asc")
    .onSnapshot((querySnapshot) => {
      var messagesKomplain = [];
      querySnapshot.forEach((doc) => {
        messagesKomplain.push(doc.data());
      });
      setDiscuss(messagesKomplain);
      
  })

    
  }

  const onFileUpload=(event)=> {
    event.preventDefault();
    let id = event.target.id;
    let file_reader = new FileReader();
    let file = event.target.files[0]; 
    let sizeImage= file.size;       
    file_reader.onload = () => {
      setFiles(...files, file_reader.result);
      setfilesUpload([...filesUpload, {file_id:id, file_image: file_reader.result}]);        
    };
    setUpload(true);
    setfileSizeImage(sizeImage);
    if (sizeImage > maxfileSize) // 2 MiB for bytes.
    {
        MySwal.fire({
            icon: 'warning',
            title: "Gambar melebihi batas ukuran upload! maximal 3 Mb"
        })
    }
    file_reader.readAsDataURL(file);
    
  }

  
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    height: '100%',
    
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: '100%',
    overflow: 'hidden',
    position:'relative'
  };
     
  const img = {
    display: 'block',
    width: '100%',
    height: '400px',
    objectFit:'contain',
  };


const thumbs = filesUpload.map((file, index) => (
    <div style={thumb} key={index}>
       <Paper style={{margin:'auto', maxWidth:'400px'}}>
        <img
            src={file.file_image}
            style={img}
            id={index}
            alt={file.name}
        />
        </Paper>
  </div>
));
const handleChangeMessage = (val) =>{
  smChat(val);    
}

  const sendMessage =(event) =>{
    if(event.key === 'Enter'){
     
      firestore.collection(`chat/${complUid}/messages/`).add({
        message: scm, 
        type:"text", 
        date: moment().format(), 
        userid:userId, 
        status:'sent'
      })
      .then(msg => {
        smChat('');
        getDiscuss();
        if(isUpload === true){
          axios.post(`${baseUrl}/chat/rooms/${complUid}/message`,{type:"image", content:"Gambar diterima"},{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
          }).then(()=>{
              axios.post(`${baseUrl}/chat/rooms/${complUid}/notification`,{
                  title: "Chat Baru",
                  body: "Ada Chat Baru",
                  icon: "https://storage.googleapis.com/storage.leholeh.co.id/assets/images/logo.png",
                  click_action: "https://leholeh.id"
              },{
                  headers: {
                      "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                  }
              })
          })
        }else{
          axios.post(`${baseUrl}/chat/rooms/${complUid}/message`,{type:"text", content:scm},{
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
        }).then(()=>{
            axios.post(`${baseUrl}/chat/rooms/${complUid}/notification`,{
                title: "Chat Baru",
                body: "Ada Chat Baru",
                icon: "https://storage.googleapis.com/storage.leholeh.co.id/assets/images/logo.png",
                click_action: "https://leholeh.id"
            },{
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        })
        }
        

        
      })
      .catch(error => {
        
      })
    }
    
  }

  const goBack = () => {
      history.push('/list/chat')
  } 

  
  return (
        <DashboardLayout>
           <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}></Box>
           
            <Grid container component={Paper} className={classes.chatSection}>  
            <div style={{borderBottom:'1px solid #ddd', width:'100%'}}>
                <List>
                    <ListItem button key={merchantName}>
                        <div><ArrowBackIcon onClick={goBack}/></div>
                        <ListItemIcon style={{margin:'0 0 0 20px'}}>
                        <Avatar alt={merchantName} src={merchantPhoto === null ? "" : merchantPhoto && merchantPhoto.uri} />
                        </ListItemIcon>
                        <div style={{textTransform:'capitalize', fontWeight:'bold'}}>{merchantName}</div>
                        <span style={{textAlign:'right', color:'#088700'}}></span>
                    </ListItem>
                </List>
                </div>
                <Grid item xs={12}>
                    <List> 
                    <ScrollToBottom className={classes.messageArea}>  
                        {discuss.length !== 0 ? <>
                          {discuss.map((dsc, idx)=>{
                            return(                           
                            <div>
                              {dsc.userid !==  userId ? 
                              <ListItem key={idx} style={{justifyContent:'start', alignItems:'flex-start'}}>
                                <div>
                                     <Avatar alt={merchantName} src={merchantPhoto === null ? "" : merchantPhoto && merchantPhoto.uri} />
                                </div>
                                <div className="talk-bubble-sender tri-right left-top" >
                                <div>
                                   {dsc.type === "text" && <div style={{wordBreak:'break-word'}}>{dsc.message}</div>}
                                   {dsc.type === "image" && <div style={{textAlign:'center'}}><img src={dsc.message} height="120" /></div>}
                                   {dsc.type === "product" && 
                                    <>
                                    <div className="productChatCard">
                                        <img src={dsc.message.image} height="60" />
                                        <div style={{margin:'0 5px', color:'#333'}}>
                                                <Link to={{ pathname: "/product/" + dsc.message.slug }} style={{color:"#333"}}><div>{dsc.message.title}</div></Link>
                                                <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dsc.message.price)}</div>
                                            </div>  
                                        </div>
                                        <div>{dsc.message.text}</div>
                                        </>                                  
                                    }
                                    <small>{moment(dsc.date).format("HH:mm")}</small>
                                  </div>
                                </div>
                              </ListItem> 
                              : 
                              <ListItem key={idx} style={{justifyContent:'end', alignItems:'flex-start'}}>
                                <div className="talk-bubble-me tri-left left-top" >
                                <div>
                                   {dsc.type === "text" && <div style={{wordBreak:'break-word'}}>{dsc.message}</div>}
                                   {dsc.type === "image" && <div style={{textAlign:'center'}}><img src={dsc.message} height="120" /></div>}
                                   {dsc.type === "product" && 
                                    <>
                                    <div className="productChatCard">
                                        <img src={dsc.message.image} height="60" />
                                        <div style={{margin:'0 5px', color:'#333'}}>
                                                <Link to={{ pathname: "/product/" + dsc.message.slug }} style={{color:"#333"}}><div>{dsc.message.title}</div></Link>
                                                <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dsc.message.price)}</div>
                                            </div>  
                                        </div>
                                        <div>{dsc.message.text}</div>
                                        </>                                  
                                    }
                                    <small>{moment(dsc.date).format("HH:mm")}</small>
                                  </div>
                                </div>
                                <div>
                                     <Avatar alt={userName} src={userPhoto === null ? "" : userPhoto && userPhoto.uri} />
                                </div>
                              </ListItem>
                              }
                              
                            </div>
                            )
                          })}
                          </>  : <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>Belum Ada Diskusi</div>}                    
                        </ScrollToBottom>
                        </List>
                        <Grid container style={{position:'relative', bottom:'30px', padding:'10px', borderTop:'1px solid #ddd', background:'#fff'}}>
                        <div style={{display:'flex', width:'100%'}}>                           
                            <TextField 
                            type="text" 
                            multiline
                            variant="outlined"
                            size="small"
                            onChange={(e)=>handleChangeMessage(e.target.value)} 
                            className="inputMessage"
                            placeholder="Ketik Pesan"
                            onKeyPress={sendMessage}                        
                            value={scm}
                            />
                            <div  onClick={()=>handleClickOpenPreview()} style={{cursor:'pointer'}}> <AddPhotoAlternateOutlinedIcon style={{margin:'0 15px', fontSize:'35px'}}/></div>
                            {/* <div onClick={sendMessage}> <SendIcon /></div> */}
                        </div>
                       
                    </Grid>
                </Grid>
            </Grid>
            <Dialog fullScreen open={openPreview} onClose={handleClosePreview} >
                          <AppBar className={classes.appBar}>
                            <Toolbar style={{justifyContent:'space-between'}}>
                              <IconButton edge="start" color="inherit" onClick={handleClosePreview} aria-label="close">
                                <CloseIcon />
                              </IconButton>
                              <Typography variant="h6">
                                Preview Gambar
                              </Typography>
                              <Button autoFocus color="inherit" onClick={uploadfile}>
                                Upload
                              </Button>
                            </Toolbar>
                          </AppBar>
                          <DialogContent>
                          <List>
                          
                          {isUpload === true ?
                          <div style={{width:'100%', maxWidth:'900px', margin:'auto'}}>
                              {thumbs}                              
                          </div>  
                           : <div className="uploadWrapper" style={{padding:'10px'}}>                                 
                              <>
                                
                                <label className="upload--button" style={{justifyContent: 'center', width:'100%', height:'400px'}}>
                                    <input
                                        onChange={onFileUpload}
                                        id={1}
                                        accept="image/*"
                                        type="file"
                                        />
                                        <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                        Upload
                                </label> 
                              </>
                                
                            </div>
                            }
                            {/* <div style={{padding:'10px'}}>
                             <TextField 
                              type="text" 
                              multiline
                              size="small"
                              variant="outlined"
                              onChange={(e)=>smChatUpload(e.target.value)} 
                              className="inputMessage"
                              placeholder="Ketik Pesan"
                              value={scmUpload}
                              />                      
                            </div> */}
                          </List>
                          </DialogContent>
                        </Dialog>
        </DashboardLayout>
  );
}

export default ChatRooms;