import React from "react";
import Select, { components } from "react-select";

function ReactSelect() {
    const colourOptions = [
        { value: "blue", label: "Blue", color: "#0052CC" },
        { value: "yellow", label: "Yellow", color: "#FFC400" }
      ];
      
      const flavourOptions = [
        { value: "vanilla", label: "Vanilla", rating: "safe" },
        { value: "chocolate", label: "Chocolate", rating: "good" }
      ];
      
      const groupedOptions = [
        {
          label: "Colours",
          options: colourOptions
        },
        {
          label: "Flavours",
          options: flavourOptions
        }
      ];
    // handle options group header click event
    // hide and show the options under clicked group
    const handleHeaderClick = id => {
      const node = document.querySelector(`#${id}`).parentElement
        .nextElementSibling;
      const classes = node.classList;
      if (classes.contains("collapsed")) {
        node.classList.remove("collapsed");
      } else {
        node.classList.add("collapsed");
      }
    };
    
    // Create custom GroupHeading component, which will wrap
    // react-select GroupHeading component inside a div and
    // register onClick event on that div
    const CustomGroupHeading = props => {
      return (
        <div
          className="group-heading-wrapper"
          onClick={() => handleHeaderClick(props.id)}
        >
          <components.GroupHeading {...props} />
        </div>
      );
    };

    return (
        <div className="container" style={{margin:'6em'}}>
            <Select
            options={groupedOptions}
            components={{ GroupHeading: CustomGroupHeading }}
            />
        </div>
    );
}

export default ReactSelect;