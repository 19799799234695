import React, { useEffect, useState } from 'react';
import "../App.css";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhotoTemplate from '../img/broken-256.png';
import { useLocation, Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id'
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    MainPage:{
        margin:'2em 0',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
        }
    },
    boxProducts:{
        border:'2px solid #ddd', 
        borderRadius:'10px', 
        display:'flex',
        justifyContent:'space-between',
        padding:'10px', 
        maxHeight:'120px',
        marginBottom:'10px',
        [theme.breakpoints.down('md')]: {
            alignItems:'center',
            fontSize:'12px'
        }
    },
    imgCoverProd:{
        height:'80px',
        maxWidth:'100px',
        objectFit:'cover',
        [theme.breakpoints.down('md')]: {
            height:'50px'
        }

    },
    dkR:{
        margin: '0 20px 0 64px',
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            width:'auto',
            margin: '0 30px 0 40px'
        }
    },
    dkRA:{
        margin: '0 20px 0 48px',
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            width:'auto',
            margin: '0 30px 0 24px'
        }
    }
}))
function DetailOrder() {
    const classes = useStyles();
    const imageDefault = PhotoTemplate.toString();
    const location = useLocation();
    const myuid = location.state.params;

    const [dataTransaction, setDataTransaction]=useState()
    useEffect(()=>{
        axios.get(`${baseUrl}/order/${myuid}/`,{
            headers:{
                "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": 'application/json'
            }}).then((result)=>{   
                const dataResp = result.data;            
                
                setDataTransaction(dataResp);
            });
            getPayment();
    },[])

    const [paymentData, setPaymentData] = useState();
    const getPayment = () =>{
        axios.get(`${baseUrl}/order/payment/${myuid}/`,{
            headers:{
                "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": 'application/json'
            }}).then((result)=>{   
                const dataRespPayment = result.data;   
                setPaymentData(dataRespPayment);
            })
    }

    return (
        <div className={classes.MainPage}>
            <Paper>
                <div className="detailPage">
                    <div style={{borderBottom:'1px solid #ddd'}}><h2 style={{textAlign:'center',margin:'0',padding:'10px'}}>Detail Transaksi</h2></div>
                    {dataTransaction && 
                        <React.Fragment >
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div style={{borderBottom:'5px solid #ddd', borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>Status 
                                        <span style={{textTransform:'capitalize', margin:'0 5px'}}>
                                            {dataTransaction.status === "purchased" && "Menunggu Pembayaran"}
                                            {dataTransaction.status === "paid" && "Menunggu Konfirmasi"}
                                            {dataTransaction.status === "packed" && "Pesanan Diproses"}
                                            {dataTransaction.status === "delivered" && "Pesanan Dikirim"}
                                            {dataTransaction.status === "accepted" && "Pesanan Selesai"}
                                            {dataTransaction.status === "cancelled" && "Pesanan Dibatalkan"}
                                            
                                        </span>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>
                                        <div>No. Invoice</div>
                                        <div>
                                            <span>{dataTransaction.payment.invoice}</span>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>
                                        <div>Tanggal Pembelian</div>
                                        <div>
                                            <span>{paymentData && moment(paymentData.info.transaction_time).format('LLLL')}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{borderBottom:'5px solid #ddd', borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                    <div style={{padding: '10px 20px 0', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <div>Detail Produk</div>
                                        <div> 
                                        {dataTransaction.items && dataTransaction.items.map((trxDataMerchant,tridM)=>{
                                            return(
                                                <div key={tridM}>                                                
                                                        <div style={{padding:'10px', width:'100%'}}>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                    <div><Link to={{ pathname: "/store/" + trxDataMerchant.merchant.domain }} style={{color:"#656565", textDecoration:'none', display:'flex', alignItems:'center'}}>{trxDataMerchant.merchant.name} <ChevronRightIcon /></Link></div>                                                               
                                                            </div> 
                                                        </div>                            
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                    {dataTransaction.items && dataTransaction.items.map((trxData,trid)=>{
                                        return(
                                            <div style={{padding:'0 20px 20px'}} key={trid}>
                                                {trxData.products.map((pData, k)=>{
                                                    return(
                                                        <div className={classes.boxProducts} key={k}>
                                                            <div style={{display:'flex'}}>
                                                                <div><img src={pData.cover === null ? imageDefault : pData.cover} className={classes.imgCoverProd}/></div>
                                                                <div style={{padding:'10px', width:'520px',borderRight:'1px solid #f1f1f1',marginRight:'20px'}}>
                                                                    <div>{pData.name}</div>
                                                                    <div>{pData.qty} x {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(pData.price)} </div>
                                                                    <div>{pData.variant === null ? "tidak ada variant" : pData.variant}</div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div>
                                                                Total Harga
                                                                <div style={{fontSize:'16px'}}>
                                                                    <strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(pData.price * pData.qty)}</strong>
                                                                </div>
                                                                <div>
                                                                <Link to={{ pathname: "/product/" + pData.slug }} style={{color:"#656565", textDecoration:'none'}}><Button fullWidth variant="outlined" size="small" style={{borderColor:'#ab0004', color:'#ab0004',textTransform:'capitalize'}}>Beli Lagi</Button></Link>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    )                                                   
                                                })}
                                                
                                            </div>
                                        )
                                    })}
                                    </div>

                                    <div style={{borderBottom:'5px solid #ddd', borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                        <div style={{padding:'10px 30px', fontWeight:'bold'}}>Info Pengiriman</div>
                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Kurir </div>
                                            <div className={classes.dkR}>:</div>
                                            {dataTransaction.items && dataTransaction.items.map((cour, ik)=>{
                                                return(
                                                    <div key={ik}>
                                                        <div>{cour.delivery.courier_name} {cour.delivery.courier_service}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                   
                                    

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Resi </div>
                                            <div className={classes.dkR}>:</div>
                                            <div>{dataTransaction.items && dataTransaction.items.map((airwayBill,trid)=>{
                                                return(
                                                    <>
                                                      {airwayBill.delivery.delivery_number === null ? "Belum ada resi" : airwayBill.delivery.delivery_number}  
                                                    </>
                                                )
                                            })}</div>
                                        </div>
                                        {dataTransaction.delivery &&
                                            <div>
                                                <div style={{padding:'0 30px 20px', display:'flex'}}>
                                                    <div>Alamat </div>
                                                    <div className={classes.dkRA}>:</div>
                                                    <div>
                                                        <p style={{margin:'0'}}><strong>{dataTransaction.delivery.recipient}</strong></p>
                                                        <p style={{margin:'0'}}>{dataTransaction.delivery.phone}</p>
                                                        <p style={{margin:'0'}}>{dataTransaction.delivery.location.address} <br/> {dataTransaction.delivery.location.subdistrict.name} {dataTransaction.delivery.location.city.name} <br/> {dataTransaction.delivery.location.province.name} {dataTransaction.delivery.location.postal}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }                                   
                                </div>

                                <div style={{borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                        <div style={{padding:'10px 30px', fontWeight:'bold'}}>Info Pembayaran</div>
                                        {dataTransaction.status === "purchased" && 
                                        <>
                                            <div style={{padding:'0 30px 20px', display:'flex'}}>
                                                <div>Metode Pembayaran</div>
                                                <div style={{width:'50px',textAlign:'center'}}>:</div>
                                                <div style={{textTransform:'uppercase'}}>
                                                    {dataTransaction.payment.method.type === "bank_transfer" && <div>{dataTransaction.payment.method.bank} Virtual Account </div>} 
                                                    {dataTransaction.payment.method.type === "gopay" && <div>GOPAY</div>}
                                                    {dataTransaction.payment.method.type === "cstore" && <div>{dataTransaction.payment.method.store}</div>}
                                                </div>
                                            </div>

                                            <div style={{padding:'0 30px 20px', display:'flex'}}>
                                                <div>{dataTransaction.payment.method.type === "bank_transfer" && "No Virtual Account"} {dataTransaction.payment.method.type === "cstore" && "Kode Pembayaran"}</div>
                                                <div style={{width:'50px',textAlign:'center', margin:'0 0px 0 15px'}}>:</div>
                                                <div style={{textTransform:'uppercase'}}>
                                                    {dataTransaction.payment.method.type === "bank_transfer" && <div>{dataTransaction.payment.method.va_number}</div>} 
                                                    {dataTransaction.payment.method.type === "cstore" && <div>{dataTransaction.payment.method.payment_code}</div>}
                                                </div>
                                            </div>
                                        </>
                                        }
                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Harga Barang </div>
                                            <div style={{width:'30px',textAlign:'center', margin:'0 10px 0 54px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_price)}</div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Ongkos Kirim </div>
                                            <div style={{width:'30px',textAlign:'center', margin:'0 10px 0 57px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_delivery)}</div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Biaya Admin </div>
                                            <div style={{width:'30px',textAlign:'center', margin:'0 10px 0 63px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.fee)}</div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Total Pembayaran </div>
                                            <div style={{width:'30px',textAlign:'center',margin: '0 10px 0 28px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_amount)}</div>
                                        </div>
                                                                          
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                                <div style={{padding:'10px'}}>
                                <Button fullWidth style={{backgroundColor:'#ab0004', color:'#fff', marginBottom:'10px'}} variant="contained" size="small">Beri Ulasan</Button>
                                <Button fullWidth variant="outlined" size="small">Bantuan</Button>
                                </div>
                            </Grid> */}
                        </Grid>
                    </React.Fragment>
                    }
                    
                </div>
            </Paper>            
        </div>
    );
}

export default DetailOrder;