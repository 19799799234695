import React, { useState, useEffect } from 'react';
import DashboardLayout from '../DashboardLayout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MapPicker from 'react-google-map-picker';
import Box from '@material-ui/core/Box';
import AsyncSelect from 'react-select/async';
import {TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../../App.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Grid from '@material-ui/core/Grid';
import Geocode from "react-geocode";
// import GmapsLoc from '../../components/MapsBound';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  BoxLocationDiv:{
    margin:'10px',
    [theme.breakpoints.up('md')]: {
      width:'100%'
    }
  }
}))


const EditLocation = () => {
  const classes = useStyles();
  const url = baseUrl;
  const [locationUpdate, setLocationUpdate]= useState('');
  const DefaultLocation = { lat: locationUpdate.latitude, lng: locationUpdate.longitude};
  const [isSearch, setisSearch] = useState(false);  
  const DefaultZoom = 17;
  const [defLatLngs, setdefLatLngs] = useState({});
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [locateMarker, setlocateMarker] = useState(defLatLngs);
  const MySwal = withReactContent(Swal)
  const [zoom, setZoom] = useState(DefaultZoom);
  const [inputValue, setValue] = useState('');
  const [postal, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [subdistrict, setSubdistrict] = useState({code:'', name:''});
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);
  const [AddrLoc, setAddrLoc] = useState('');
  function handleChangeLocation (lat, lng){
    setlocateMarker({lat:lat, lng:lng});
    // 
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }
  
  useEffect(()=>{
    axios.get(`${url}/user/profile`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }).then((response)=>{
        const allLocation = response.data.location; 
        setLocationUpdate(allLocation); 
        
      }).catch(error=> console.error(`Error: ${error}`));    
},[]);

  const handleInputChange = value => {
    setValue(value);
  };
  
  // handle selection
  const handleChange = value => {
    setSelectedValue(value);  
    setSubdistrict({code:value.code, name:value.name})
    setCity(value.city)  
    setProvince(value.province)
    
  }

  
  const handleSubmitAddress = async (event) => {
    event.preventDefault();
    await axios.post(`${baseUrl}/user/profile/update-address`, {
         address: address,
    },{headers:{
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        "Content-Type": "application/json"
    }}).then(result => {
        MySwal.fire({
          icon: 'success',
          title: result.data.message
        }).then((result) => {
          window.location.href="/profile";
      });
        
        
    }).catch(e => {
      
      MySwal.fire({
        icon: 'warning',
        title: e.response.data.message
      })
    });  
 }

 const handleSubmitLocation = async (event) => {
  event.preventDefault();
  if(subdistrict.code === "" && subdistrict.name === "" || address === ""){
    if(address === ""){
      MySwal.fire({
        icon: 'warning',
        title: "Mohon Maaf! Anda belum mengatur alamat"

      })
    }else{
      MySwal.fire({
        icon: 'warning',
        title: "Mohon Maaf! Anda belum mengatur kecamatan"  
      })
    }
   
  }else{
    await axios.post(`${baseUrl}/user/profile/update-location`, {
        subdistrict: subdistrict,
        city: city,
        province: province,
        postal: postal,
        address: address,
        latitude: locateMarker.lat,
        longitude: locateMarker.lng
    },{headers:{
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        "Content-Type": "application/json"
    }}).then(result => {
        MySwal.fire({
          icon: 'success',
          title: result.data.message
        }).then((result) => {
          window.location.href="/profile";
      });
        
        
    }).catch(e => {
      
      MySwal.fire({
        icon: 'warning',
        title: "Mohon Maaf! Anda belum mengatur koordinat pada maps! Silahkan di geser pin point nya!"
      })        
    });  
  }

}
  const loadOptions = async (inputValue) => {   
    return await fetch(`${baseUrl}/location/subdistrict?name=${inputValue}`)
    .then(res => res.json()) 
  };
  
  const [dataPostal , setDataPostal] = useState([]);
  const getPostalCode = () => {
    axios.get(`${url}/location/postal`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      },params:{
        name:selectedValue.code,
        city_code:selectedValue.city.code
      }
    }).then((response)=>{
       
       setDataPostal(response.data);
    }).catch(error=> console.error(`Error: ${error}`));
  }

  Geocode.setApiKey("AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM");
  // Geocode.setApiKey("AIzaSyCSwuS4b3Zr--DbsjZtzq276wq1g4vIw1I");
  Geocode.setLanguage("en");
  Geocode.setRegion("id");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  useEffect(()=>{    
    Geocode.fromAddress(selectedValue.name).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setdefLatLngs({lat,lng});
        // setisSearch(true);
      },
      (error) => {
        console.error(error);
      }
    );

  },[selectedValue]);

  const handleSearchAddress = (e) =>{
    const val = e.target.value;
    Geocode.fromAddress(val).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setdefLatLngs({lat,lng});
        // setisSearch(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }


  const [isEdited, setisEdited] = useState(false);
  const editBtnFn = () =>{
    setisEdited(true);
    setisEditedAddr(false);
  }
  const [isEditedAddr, setisEditedAddr] = useState(false);
  const editBtnAddrFn = () =>{
    setisEditedAddr(true);
    setisEdited(false);
  }

  const backTo = () =>{
    window.location.href="/update/location"
  }

  return (
    <React.Fragment>
      <DashboardLayout>      
    <div className={classes.BoxLocationDiv}>
      <Box width="100%" >
      <div className="boxLocation" style={{marginTop:'80px'}}>
        {!isEdited && !isEditedAddr ? <Button variant="outlined" onClick={editBtnFn} style={{marginRight:'10px'}}> Edit semua lokasi saat ini</Button>: <Button variant="outlined" onClick={backTo}>Kembali</Button>}
        {!isEditedAddr && !isEdited ? <Button variant="outlined" onClick={editBtnAddrFn}> Edit alamat saat ini</Button>: <></>}
      </div>
        <form onSubmit={!isEditedAddr ? (e)=>handleSubmitLocation(e) : (e)=>handleSubmitAddress(e)}>
           <Grid container spacing={3}>
              {isEditedAddr === true ? <></>:<Grid item xs={12} md={6} style={{marginTop:'1em'}}>
                {!isEdited ? <div className="boxLocation">       
                       <div>                
                       <TextField 
                           id="outlined-basic" 
                           label="Provinsi" 
                           variant="outlined" 
                           fullWidth
                           size="small"
                           value={locationUpdate && locationUpdate.province.name}
                       />                    
                       </div>
                   </div> :  <div className="boxLocation">
                   <AsyncSelect
                       style={{marginBottom:'30px'}}
                       value={selectedValue}
                       debounceTimeout={1000}
                       getOptionLabel={e => `${e.province.name}, ${e.city.name}, ${e.formatted_name}`}
                       getOptionValue={e => `${e.code}`}
                       loadOptions={loadOptions}
                       onChange={handleChange}
                       placeholder="Cari Kecamatan"                       
                   />
               </div> }
                {!isEdited ? 
                   <div className="boxLocation">
                       <div>
                       <TextField 
                           id="outlined-basic" 
                           label="Kota / Kabupaten" 
                           variant="outlined" 
                           fullWidth
                           size="small"
                           value={locationUpdate && locationUpdate.city.name}
                       />                    
                       </div>
                   
                   </div>
                  :
                   <div className="boxLocation">             
             
                   {selectedValue &&
                       <div>                
                       <TextField 
                           id="outlined-basic" 
                           label="Provinsi" 
                           variant="outlined" 
                           fullWidth
                           size="small"
                           readOnly
                           value={selectedValue && selectedValue.province.name}
                       />                    
                       </div>
                   } 
                   </div>
                }
                  

               </Grid>}
               {isEditedAddr === true ? <></> :  <Grid item xs={12} md={6} style={{marginTop:'1em'}}>
               {!isEdited ? 
                  <div className="boxLocation"> 
                 
                       <div>
                       <TextField 
                           id="outlined-basic" 
                           label="Kecamatan" 
                           variant="outlined" 
                           fullWidth
                           size="small"
                           value={locationUpdate && locationUpdate.subdistrict.name}
                       />                    
                       </div>
                    
                   </div>
                  :
                   <div className="boxLocation"> 
                   {selectedValue &&
                       <div>
                       <TextField 
                           id="outlined-basic" 
                           label="Kecamatan" 
                           variant="outlined" 
                           fullWidth
                           size="small"
                           readOnly
                           value={selectedValue && selectedValue.name}
                       />                    
                       </div>
                   } 
                   </div>
                  }
                  
                  {!isEdited ? 
                  
                  <div className="boxLocation">
                    <TextField 
                        id="outlined-basic" 
                        label="Postal Code" 
                        variant="outlined" 
                        fullWidth
                        size="small"
                        value={locationUpdate && locationUpdate.postal}
                    />                    
                  </div>
                  : 
                  <div className="boxLocation">    
                       {selectedValue &&
                       <div>
                           <select onClick={getPostalCode} onChange={e => setPostalCode(e.target.value)} style={{padding:'10px', width:'100%', fontSize:'16px'}}>
                           <option disabled selected>--Pilih Postal Code--</option>
                           {dataPostal && dataPostal.map((zipCode, idx)=>{
                               return(
                                <option key={idx} value={zipCode.postal_code}>{zipCode.postal_code} ({zipCode.village.name})</option>
                               )
                           })}
                           </select>
                        </div>
                       
                        }                   
                   </div>
                }

                   
             <div className="boxLocation" style={{display:'none'}}>                 
                     <div style={{margin:'20px 0'}}>
                       <TextField 
                         id="outlined-basic" 
                         label="Latitude"
                         variant="outlined" 
                         fullWidth
                         size="small" 
                         autoComplete="off"
                         value={locateMarker.lat} 
                         InputLabelProps={{
                           shrink: true,
                         }}
                         InputProps={{
                           readOnly: true,
                         }}                                            
                       /> 
                     </div>
                     <div style={{margin:'20px 0'}}>
                       <TextField 
                         id="outlined-basic" 
                         label="Longitude"
                         variant="outlined" 
                         fullWidth
                         size="small" 
                         autoComplete="off"
                         value={locateMarker.lng} 
                         InputLabelProps={{
                           shrink: true,
                         }}
                         InputProps={{
                           readOnly: true,
                         }}                                             
                       /> 
                     </div>

                     <div style={{margin:'20px 0'}}>
                       <TextField 
                         id="outlined-basic" 
                         label="Zoom"
                         variant="outlined" 
                         fullWidth
                         size="small" 
                         autoComplete="off"
                         value={zoom} 
                         InputLabelProps={{
                           shrink: true,
                         }}
                         InputProps={{
                           readOnly: true,
                         }}                                             
                       /> 
                     </div> 
               
                     
              
             </div>
            
               </Grid>}
              
           </Grid>
           {/* <GmapsLoc/> */}
           {isEditedAddr === true ? <></>: <div style={{position:'relative'}}>
            <div className="setLocaddress">
            <TextField 
              id="outlined-basic" 
              variant="outlined" 
              fullWidth
              size="small" 
              placeholder="Cari Alamat Disini"
              autoComplete="off"
              InputProps={{
                readOnly: false,
              }}
              onChange={handleSearchAddress}                                    
            />    
            </div>          
           <MapPicker 
                 defaultLocation={!isEdited ? DefaultLocation : defLatLngs}
                 zoom={zoom}
                 mapTypeId="roadmap"
                 style={{height:'300px'}}
                 onChangeLocation={handleChangeLocation} 
                 onChangeZoom={handleChangeZoom}
                 apiKey='AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM'
                //  apikey='AIzaSyCSwuS4b3Zr--DbsjZtzq276wq1g4vIw1I'
                 />
          
            </div>
             }
           <br/>
            {isEdited && 
            <div className="boxLocation">  
                <label>Alamat Lengkap</label>             
                 <div>
                     <TextField 
                       id="outlined-basic" 
                       variant="outlined" 
                       fullWidth
                       multiline
                       rows={4}
                       size="small" 
                       autoComplete="off"
                       InputProps={{
                         readOnly: false,
                        }}    
                       onChange={e => setAddress(e.target.value)}                                         
                     />               
                   
               </div>
             </div> }

             {isEditedAddr && <div className="boxLocation">  
                <label>Alamat Lengkap</label>             
                 <div>
                     <TextField 
                       id="outlined-basic" 
                       variant="outlined" 
                       fullWidth
                       multiline
                       rows={4}
                       size="small" 
                       autoComplete="off"
                       InputProps={{
                        readOnly: false,
                       }}     
                       defaultValue={locationUpdate.address} 
                       onChange={e => setAddress(e.target.value)}                                         
                     />               
                   
               </div>
             </div> }

             {!isEditedAddr && !isEdited && <div className="boxLocation">  
                <label>Alamat Lengkap</label>             
                 <div>
                     <TextField 
                       id="outlined-basic" 
                       variant="outlined" 
                       fullWidth
                       multiline
                       rows={4}
                       size="small" 
                       autoComplete="off"
                       InputProps={{
                        readOnly: true,
                       }}     
                       defaultValue={locationUpdate.address}                                         
                     />               
                   
               </div>
             </div> }
            {!isEditedAddr ? <div>{!isEdited ? 
              <Button type="submit" color="primary" variant="outlined" fullWidth disabled>Simpan Lokasi</Button>
              :
              <Button type="submit" color="primary" variant="outlined" fullWidth >Simpan Lokasi</Button>
            }</div>: <Button type="submit" color="primary" variant="outlined" fullWidth >Simpan Alamat</Button>}
              
             
             </form>
           </Box>   
    </div>
   
    </DashboardLayout>  
    </React.Fragment>
  );
}

export default EditLocation


