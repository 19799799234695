import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
 grow: {
    flexGrow: 1,
 },
  toolbarCustomUp: {
    backgroundColor:'#ab0004',
    height:'100%'
  },
  typeText :{
      fontSize: '12px',
      color:'#fff',
  },
  noDecor :{
      textDecoration:'none',
      '&:hover': {
        textDecoration:'none',
      },
  },
  rightPlace :{
    float: 'right',
    padding:'14px',
  },
  rightPlaceText :{      
      textDecoration:'none',
      '&:hover': {
        textDecoration:'none',
      },
      fontSize: '13px',
      color:'#fff',
      padding:'20px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function ToolbarMenuUp() {
  const classes = useStyles();
  return (
    <div className={classes.sectionDesktop} style={{height:'40px'}}>
     
    <div className={classes.grow}> 
        <Typography className={classes.toolbarCustomUp}>        
        <Container> 
          
            <Link href="/faq" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <ContactsOutlinedIcon />
                    Bantuan                
                </IconButton>
            </Link>  

            <Link href="/faq" className={classes.noDecor}>
                <IconButton className={classes.typeText}>
                    <HelpOutlineOutlinedIcon />
                    F.A.Q                
                </IconButton>
            </Link>
            <div className={classes.rightPlace}>  
                <Link href="/terms" className={classes.rightPlaceText}>
                    Syarat dan Ketentuan
                </Link> 
                
                <Link href="/articles" className={classes.rightPlaceText}>
                    Blog
                </Link> 
            </div>
            </Container>
        </Typography >      
    </div>
  
    </div>
  );
}

/* <Link href="/contact" className={classes.rightPlaceText}>
                    Kontak Kami
                </Link>  */
/* <Link href="/" className={classes.noDecor}>
      <IconButton className={classes.typeText}>
          <HomeOutlinedIcon />
            Rumah
      </IconButton>
  </Link>   */
  /* <Link href="/profile" className={classes.noDecor}>
      <IconButton className={classes.typeText}>
          <LockOutlinedIcon />
            Dashboard
      </IconButton>
  </Link>   */
  
  /* <Link href="/location" className={classes.noDecor}>
      <IconButton className={classes.typeText}>
          <EditLocationOutlinedIcon />
            Lokasi Saya
      </IconButton>
  </Link>   */