import firebase from 'firebase';

const firebaseConfig = {
    // apiKey: "AIzaSyB0v9LE87NlxFH-3LY9QhO4IqUJBQqzBiE",
    apikey: "AIzaSyCSwuS4b3Zr--DbsjZtzq276wq1g4vIw1I",
    authDomain: "leholeh-mobile-app.firebaseapp.com",
    projectId: "leholeh-mobile-app",
    storageBucket: "leholeh-mobile-app.appspot.com",
    messagingSenderId: "863479904000",
    appId: "1:863479904000:web:aaebdd2c860969732aa5e5",
    measurementId: "G-FHW5X1LNWG"
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export default firebaseConfig;

