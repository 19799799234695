import React, {useEffect,useState} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../App.css";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../img/broken-256.png';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import ProductEmpty from "../img/No Product (General).svg";
import StarIcon from '@material-ui/icons/Star';
import PaginationData from '../components/Pagination';
import {baseUrl} from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  }
}))



function FoodCategoryHome() {

    const classes = useStyles();
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(16);
    const [priceMin, setPriceMin]= useState(0);
    const [priceMax, setPriceMax]= useState(999999999);
    const [metaTotal , setMetaTotal] = useState();
    const imageDefault = PhotoTemplate.toString();

    const [dataProductSearch, setDataProductSearch] = useState([]);
    const [sortValue, setSortValue] = useState('');

    const sortSubmit = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { sorting:sortValue,  price: priceMin+"-"+priceMax, location:filteredProvinceCode}
        })
      .then((response) => {          
          const dataResponse = response.data.data;
          setDataProductSearch(dataResponse);        
          const dataTotal = response.data.meta.total;
          setMetaTotal(dataTotal);
          
      });
      
    }

    const getDataPagingProduct = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: { category: 'food' }
          })
      .then((response) => {          
          const dataResponse = response.data.data;
          setDataProductSearch(dataResponse);            
          const dataTotal = response.data.meta.total;
          setMetaTotal(dataTotal);
          
      });
    }

    const [dataProvince, setDataProvince] = useState([]);
    const provinceJson = async () => { 
        axios.get(`/provinces.json`)
        .then((response)=>{
            
            const dataProv = response.data;
            setDataProvince(dataProv);
        })
    };


    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Populer',
        value: 'popular'        
      },
      {
        label: 'Tidak Populer',
        value: 'unpopular'        
      }
    ]

    const [provinceCode, setProvinceCode] = useState([]);
    const [filteredProvinceCode, setFilteredProvinceCode] = useState("");
    const handleChangeProvince = (e, value) =>{
      if (e.target.checked) {
        setProvinceCode([...provinceCode, e.target.value]);
      } else {
        setProvinceCode(provinceCode.filter((province_code) => province_code !== e.target.value));
      }
    }

    useEffect(()=>{
      if(sortValue !== ''){
        sortSubmit();
      }else{
        getDataPagingProduct();
      }
      provinceJson();

      if (provinceCode.length === 0) {
        setFilteredProvinceCode("");
      } else {
        setFilteredProvinceCode(provinceCode.join(","));
      }
     
    },[currentPage, sortValue, provinceCode])
     

    const submitFilter = () =>{
        axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: {category:"food", price: priceMin+'-'+priceMax,  location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
            
        });
    }

    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: {category:"food", price: priceMin+'-'+priceMax}
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setDataProductSearch(dataResponse);        
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
        
    });
    }


    const submitLocation = () =>{
      axios.get(`${baseUrl}/home/products/?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { category:"food", location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
            
        });
    }

    
      const NotFoundProduct = ProductEmpty.toString();

    
    return (
        <div className={classes.MainPage}>
             <div style={{marginRight:'10px'}}>
              <label>Urutkan : </label> 
                <select style={{padding:'3px 10px'}} onClick={(e)=>setSortValue(e.target.value)} onChange={()=> sortSubmit()}>
                <option value="--Paling Sesuai--" disabled selected>--Paling Sesuai--</option>
                  {sortProduct.map(({ label, value}, index) => {
                    return ( 
                      <option value={value} key={index}>{label}</option>
                    )})}
                </select>
                
              </div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Lokasi</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                               
                                {dataProvince.map((prov,index)=>{
                                    return(
                                        <FormControlLabel
                                            key={index}
                                            style={{width:'100%'}}
                                            control={
                                            <Checkbox
                                                onChange={handleChangeProvince}
                                                name={prov.province_name}
                                                value={prov.province_code}
                                                color="primary"
                                                
                                            />
                                            }
                                            label={prov.province_name}
                                        />
                                    )
                                })}
                                
                                </div>
                                
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitLocation()}>Terapkan</Button></div>
                        </Accordion>
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={submitFilter}>Terapkan Semua</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper>
                                           
                          <React.Fragment>
                              {dataProductSearch.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductSearch.map((prdSearch, k) =>{
                                    return(
                                      
                                        <Grid item xs={12} md={3} key={k}>
                                            <Card>
                                            <Link to={{ pathname: "/product/" + prdSearch.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                            <CardActionArea>
                                                    <img src={prdSearch.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                    <div className="cardContent">
                                                    <p>
                                                        {prdSearch.name}
                                                    </p>
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prdSearch.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        <span style={{fontSize:'12px'}}>{prdSearch.location.name}</span> <br />
                                                       <div style={{display:'flex', alignItems:'center'}}>
                                                          <StarIcon style={{color:'#FCCE00'}}/>
                                                          <span style={{fontSize:'13px', margin:'0 5px'}}>{prdSearch.rating}</span>
                                                        </div>
                                                    </Typography>
                                                    </div>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                   
                                    )
                                  })}
                                  </Grid>

                                  <PaginationData 
                                    stateChanger={setcurrentPage} 
                                    current={currentPage} 
                                    limit={itemsPerPage} 
                                    total_page={metaTotal} 
                                    loadData={getDataPagingProduct}
                                  />
                                </div>
                                
                              }
                               
                            </React.Fragment>
                   
                       
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default FoodCategoryHome;