import React, { useEffect, useState } from 'react';
import "../App.css";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/id'
import PhotoTemplate from '../img/broken-256.png';
import { useLocation} from "react-router-dom";
import axios from 'axios';
import {baseUrl} from 'utils';

const useStyles = makeStyles((theme) => ({
    MainPage:{
        margin:'2em 0',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
        }
    },
    boxProducts:{
        border:'2px solid #ddd', 
        borderRadius:'10px', 
        display:'flex',
        padding:'10px',
        maxHeight:'120px',
        [theme.breakpoints.down('md')]: {
            alignItems:'center',
            fontSize:'12px'
        }
    },
    imgCoverProd:{
        height:'80px',
        maxWidth:'100px',
        objectFit:'cover',
        [theme.breakpoints.down('md')]: {
            height:'50px'
        }

    },
    dkR:{
        margin: '0 20px 0 64px',
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            width:'auto',
            margin: '0 30px 0 40px'
        }
    },
    dkRA:{
        margin: '0 20px 0 48px',
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            width:'auto',
            margin: '0 30px 0 24px'
        }
    }
}))
function DetailOrderMerchant() {
    const classes = useStyles();
    const imageDefault = PhotoTemplate.toString();
    const location = useLocation();
    const myuid = location.state.params;

    const [dataTransaction, setDataTransaction]=useState([])
    useEffect(()=>{
        axios.get(`${baseUrl}/order/merchant/${myuid}/get`,{
            headers:{
                "Authorization":`Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                "Content-Type": 'application/json'
            }}).then((result)=>{   
                const dataResp = result.data;    
                setDataTransaction(dataResp);
            });
    },[])



    return (
        <div className={classes.MainPage}>
            <Paper>
                <div className="detailPage">
                    <div style={{borderBottom:'1px solid #ddd'}}><h2 style={{textAlign:'center',margin:'0',padding:'10px'}}>Detail Transaksi</h2></div>
                    {dataTransaction && 
                        <React.Fragment >
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <div style={{borderBottom:'5px solid #ddd',paddingBottom:'10px'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>
                                        Status 
                                            <span style={{textTransform:'capitalize', margin:'0 5px'}}>
                                                {dataTransaction.status === "paid" && "Pesanan Baru"}
                                                {dataTransaction.status === "packed" && "Pesanan Diproses"}
                                                {dataTransaction.status === "delivered" && "Pesanan Dikirim"}
                                                {dataTransaction.status === "accepted" && "Pesanan Selesai"}
                                                {dataTransaction.status === "cancelled" && "Pesanan Dibatalkan"}
                                                
                                            </span>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>
                                        <div>No. Invoice</div>
                                        <div>
                                            <span>{dataTransaction.payment && dataTransaction.payment.invoice}</span>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:'10px 30px' }}>
                                        <div>Tanggal Pembelian</div>
                                        <div>
                                            <span>{moment(dataTransaction.date).format('LLLL')}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{borderBottom:'5px solid #ddd', borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                    <div style={{padding: '10px 20px 0', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <div>Detail Produk</div>
                                    </div>
                                    {dataTransaction.items && dataTransaction.items.map((trxData,trid)=>{
                                        return(
                                            <div style={{padding:'0 20px 20px'}} key={trid}>
                                                <div className={classes.boxProducts}>
                                                            <div><img src={trxData.cover === null ? imageDefault : trxData.cover} className={classes.imgCoverProd}/></div>
                                                            <div style={{padding:'10px', width:'728px',borderRight:'1px solid #f1f1f1',marginRight:'20px'}}>
                                                                <div>{trxData.name}</div>
                                                                <div>{trxData.qty} x {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(trxData.price)} </div>
                                                                <div>{trxData.variant === null ? "tidak ada variant" : trxData.variant}</div>
                                                            </div>
                                                            
                                                            <div>
                                                                Total Harga
                                                                <div style={{fontSize:'16px'}}>
                                                                    <strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(trxData.price * trxData.qty)}</strong>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                
                                            </div>
                                        )
                                    })}
                                    </div>

                                    <div style={{borderBottom:'5px solid #ddd', borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                        <div style={{padding:'10px 30px', fontWeight:'bold'}}>Info Pengiriman</div>
                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Kurir </div>
                                            <div className={classes.dkR}>:</div>
                                            {dataTransaction.delivery && dataTransaction.delivery.courier_name} {dataTransaction.delivery && dataTransaction.delivery.courier_service}
                                        </div>
                                        {dataTransaction.delivery && dataTransaction.delivery.delivery_number !== null && 
                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Resi </div>
                                            <div className={classes.dkR}>:</div>
                                            <div>
                                                {dataTransaction.delivery && dataTransaction.delivery.delivery_number === null ? "Belum ada resi" : dataTransaction.delivery && dataTransaction.delivery.delivery_number}
                                             </div>
                                        </div>
                                        }
                                        {dataTransaction.delivery &&
                                            <div>
                                                <div style={{padding:'0 30px 20px', display:'flex'}}>
                                                    <div>Alamat </div>
                                                    <div className={classes.dkRA}>:</div>
                                                    <div>
                                                        <p style={{margin:'0'}}><strong>{dataTransaction.delivery && dataTransaction.delivery.recipient}</strong></p>
                                                        <p style={{margin:'0'}}>{dataTransaction.delivery && dataTransaction.delivery.phone}</p>
                                                        <p style={{margin:'0'}}>{dataTransaction.delivery.location && dataTransaction.delivery.location.address} <br/> {dataTransaction.delivery.location && dataTransaction.delivery.location.subdistrict.name} {dataTransaction.delivery.location && dataTransaction.delivery.location.city.name} <br/> {dataTransaction.delivery.location && dataTransaction.delivery.location.province.name} 
                                                        <br/>{dataTransaction.delivery.location && dataTransaction.delivery.location.postal}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }                                   
                                </div>

                                <div style={{borderRight:'1px solid #f1f1f1', paddingBottom:'10px'}}>
                                        <div style={{padding:'10px 30px', fontWeight:'bold'}}>Info Pembayaran</div>
                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Metode Pembayaran</div>
                                            <div style={{width:'50px',textAlign:'center', margin: '0 0px 0 12px'}}>:</div>
                                            <div>
                                            {dataTransaction.payment && dataTransaction.payment.method.type === "bank_transfer" && <div style={{textTransform:'uppercase'}}>{dataTransaction.payment && dataTransaction.payment.method.bank} Virtual Account</div>} 
                                            </div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Harga Barang </div>
                                            <div style={{width:'30px',textAlign:'center', margin:'0 10px 0 66px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_price)}</div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Ongkos Kirim ({dataTransaction.total_weight}) </div>
                                            <div style={{width:'30px',textAlign:'center'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_delivery)}</div>
                                        </div>

                                        <div style={{padding:'0 30px 20px', display:'flex'}}>
                                            <div>Total Pembayaran </div>
                                            <div style={{width:'30px',textAlign:'center', margin:'0 0 0 40px'}}>:</div>
                                            <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(dataTransaction.total_price + dataTransaction.total_delivery)}</div>
                                        </div>
                                                                          
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                                <div style={{padding:'10px'}}>
                                <Button fullWidth style={{backgroundColor:'#ab0004', color:'#fff', marginBottom:'10px'}} variant="contained" size="small">Beri Ulasan</Button>
                                <Button fullWidth variant="outlined" size="small">Bantuan</Button>
                                </div>
                            </Grid> */}
                        </Grid>
                    </React.Fragment>
                    }
                    
                </div>
            </Paper>            
        </div>
    );
}

export default DetailOrderMerchant;