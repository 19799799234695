import React,{useState, useEffect} from 'react';
import DashboardLayout from './DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import TextField from '@material-ui/core/TextField';
import { useHistory, Link } from "react-router-dom";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import ProductEmpty from '../img/No Transaction.svg';
import PhotoTemplate from '../img/broken-256.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import 'moment/locale/id'
import LocalMallIcon from '@material-ui/icons/LocalMall';
import Rating from '@material-ui/lab/Rating';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    hidden: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
          },
    },
    fsizeBox:{
        fontSize:'13px',
        [theme.breakpoints.down('md')]: {
            fontSize:'12px',
        },
    },
    statusProd:{
        width:'50px', 
        margin:'10px 10px 10px 60px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    jmlProd:{
        width:'50px', 
        margin:'10px'
    },
    hrgProd:{
        width:'50px', 
        margin:'10px 10px 10px 17px'
    },
    variantProd:{
        width:'50px', 
        margin:'10px 10px 10px 57px',
        [theme.breakpoints.down('md')]: {
            margin:'10px',
        },
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    desktopOnly:{
        display: 'block',
        [theme.breakpoints.down('md')]: {
          display: 'none !important',
        },
    },
    mobileOnlywthStyle:{
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
    },
    mobileOnly:{
        display: 'flex',
        width:'100%',
        justifyContent:'space-between',
        borderBottom: '1px solid #ddd',
        padding: '10px 15px',
        alignItems:'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    boxMyOrder :{
        margin:'60px 5px 0',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 0px'
        },
    },
    BoxButton:{       
        [theme.breakpoints.up('md')]: {
            display:'flex',
            alignItems:'center'
        },
    },
    btnAction:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        [theme.breakpoints.down('md')]: {
            width:'46%',
        }
    },
    btnActionDelivery:{
        marginBottom:'10px', 
        marginRight:'10px', 
        width:'100%',
        fontSize:'10px',
        [theme.breakpoints.down('md')]: {
            width:'25%',
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 960,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    image: {
        width: 128,
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
        },
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        width:'100%',
        objectFit:'cover',
        maxHeight: '100px',
        height:'100%'
      },
    subtitle1:{
        margin:'0px 6px 10px',
        height:'auto',
        [theme.breakpoints.down('sm')]: {
            margin:'5px 7px',
            height: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }
      },
  }));

export default function MyOrder(){
    const classes = useStyles();
    const [dataIdProduct, setDataIdProduct]= useState();
    const [totalPrice, setTotalPrice] = useState([]);
    const [totalDelivery, setTotalDelivery] = useState([]);
    const [dataOrderItems, setDataOrderItems] = useState([]);
    const [statusProduct , setStatusProduct] = useState();
    const [dataPurchased, setDataPurchased] = useState([]);
    const [ToggleContent, setToggleContent] = useState(false);
    const [openReview , setOpenReview] = useState(false);
    const [ratingValue, setRatingValue] = useState(0);
    const [description, setReviewsProduct] =  useState("");
    const [dataProductReview , setdataProductReview] = useState([]);
    const [dataUid ,setdataUid] = useState();
    const [dataMerchDomain, setdataMerchDomain] = useState();


    const createReview = (uid, domain) =>{
        setOpenReview(true);
        axios.get(`${baseUrl}/order/review/${uid}/${domain}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataRev = response.data;
              const dataRevItems = dataRev.items;
              const dataMuid = dataRev.uid;
              const dataMdomain = dataRev.merchant.domain;
              setdataProductReview(dataRevItems);
              setdataUid(dataMuid);
              setdataMerchDomain(dataMdomain);
              
          });
    }
    const [files, setFiles] = useState([]);

    const uploadImages = (e) => {  
        let file_reader = new FileReader();
        let file = e.target.files[0]; 
        file_reader.onload = () => {
            setFiles(...files, file_reader.result);            
        };  
        file_reader.readAsDataURL(file);
        
    }; 

    const submitReviews = (uid, pid) =>{
        
        axios.post(`${baseUrl}/order/review/${uid}/${pid}/send`,{
            rating:ratingValue,
            description:description,
            image: files
        },{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
          .then((response) => {          
            setOpenReview(false);
            MySwal.fire({
                icon: 'success',
                title: "Review telah dibuat"
              }).then((result) => {                
                window.location.reload();
            });
          });
    }
    const handleCloseReview = () =>{
        setOpenReview(false);
    }
    const settings = {
        className: 'activeClass',
        dots: false,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
        
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0,
              dots: false,
              arrows:false,
            }
          }
        ]
      };
    const history = useHistory();

    const MySwal = withReactContent(Swal);


    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(()=>{
        setToggleContent(true);
        axios.get(`${baseUrl}/order/purchased`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = dataResponse.data;
              setDataPurchased(dataOrderProducts);
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)             
              })
              
            });
    },[]);

    const handleOnClickButton = (index, el) => {
      setActiveIndex(index);
      if(el === "Menunggu Pembayaran"){
        setToggleContent(true);
        axios.get(`${baseUrl}/order/purchased`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = dataResponse.data;
              setDataPurchased(dataOrderProducts);
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)             
              })
              
            });
      }else if(el === "Menunggu Konfirmasi"){
        setToggleContent(false);
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'paid' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            
        });
      }else if(el === "Pesanan Diproses"){
        setToggleContent(false);        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'packed' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
        });
      }else if(el === "Pesanan Dikirim"){
        setToggleContent(false);        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'delivered' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
                
        });
      }else if(el === "Pesanan Selesai"){
        setToggleContent(false);        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'completed' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            
        });
      }else if(el === "Pesanan Dibatalkan"){
        setToggleContent(false);        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'cancelled' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data; 
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            //   
            });
      }
    };
    const child   = { width: `100%`, height: `30px`, margin:`0 5px`, fontSize:'10px', whiteSpace:'no-wrap'}
    const boxs = ["Menunggu Pembayaran", "Menunggu Konfirmasi", "Pesanan Diproses", "Pesanan Dikirim", "Pesanan Selesai", "Pesanan Dibatalkan"];
    const boxButton = boxs.map((el, index) => {
      return (
        <div className="sliderMenuHorizontal">
            <Button
            key={index}
            onClick={() => handleOnClickButton(index, el)} // pass the index
            className={activeIndex === index ? "activeClass" : null}
            variant="outlined"
            size="small"
            style={child}
            >
            {el}
            </Button>
        </div>
      );
    });


      const confirmCancelOrder = async (uid) =>{
        const { value: reason } = await MySwal.fire({
            title: 'Masukan Alasan',
            input: 'text',
            inputPlaceholder: 'Alasan Anda Membatalkan Order ini',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                return 'Kamu harus mengetikan alasan!'
                }
            }
          })
          
          if (reason) {
            // MySwal.fire(`Alasan Pembatalan: ${reason}`)
            axios.post(`${baseUrl}/order/${uid}/cancel`,{reason:reason},{
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            .then((response) => {          
                const dataResponse = response.data;
                
                MySwal.fire({
                    icon: 'success',
                    title: "Pesanan telah dibatalkan"
                }).then((result) => {
                    window.location.reload();
                });
            });
          }
      }



    const [trackingModal, setTrackingModal]= useState(false);
    const [dataTimeline, setDataTimeline]= useState([]);

    const handleCloseTrackingModal = () =>{
      setTrackingModal(false)
    }

    const trackingOrder = (uid, domain)=>{
      // history.push('/tracking/order',{params:dataIdProduct})
      setTrackingModal(true)
      axios.get(`${baseUrl}/order/${uid}/${domain}/tracking`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
          })
        .then((response) => {          
            const respData = response.data;
            setDataTimeline(respData); 
            
        });
    }
   

    const DetailOrder = (uid) =>{
        history.push('/detail/transaksi',{params: uid})
    }

    const acceptOrder = (uid, domain) =>{
        axios.post(`${baseUrl}/order/${uid}/${domain}/accept`,{data:""},{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
          })
        .then((response) => {          
            const respData = response.data;         
            
            MySwal.fire({
                icon: 'success',
                title: "Pesanan telah diterima"
              }).then((result) => {
                window.location.reload();
            });
        });
    }


    const complainOrder = (uid, domain) =>{
        axios.post(`${baseUrl}/order/${uid}/${domain}/complain`,{reason:""},{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
          })
        .then((response) => {          
            const respData = response.data;         
            
            MySwal.fire({
                icon: 'success',
                title: "Pesanan telah dikomplain"
              }).then((result) => {
                window.location.reload();
            });
        });
    }

     
    const imageDefaultProduct = ProductEmpty.toString();
    const imageDefault = PhotoTemplate.toString();

    return(
        <DashboardLayout>
            <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}>
                    <Typography color="textPrimary" variant="h6">
                        Daftar Pesanan
                    </Typography> 
                </Box>
                
                <Box display="flex" width="100%" >
                <Slider {...settings} className="slideboxmenu">
                    {boxButton}
                </Slider>
                </Box>
             <Grid container spacing={1}>
                
                <Box width="100%" style={{padding:'10px 10px'}}>
                
                {ToggleContent && <div>
                    {dataPurchased.length > 0 ?
                    <div>
                        {dataPurchased && dataPurchased.map((prc, dsx)=>{
                            return(
                                <List key={dsx}>
                                    <Paper>
                                    <div className={classes.mobileOnly}>
                                            <div >
                                                <div style={{display: 'flex',alignItems: 'center'}}>
                                                    <div style={{color: '#ad3322'}}><LocalMallIcon/></div> 
                                                    <div style={{margin:'0 10px'}}>
                                                        Belanja
                                                        <div>
                                                            <span style={{fontSize:'11px'}}> {moment(prc.date).format('LLLL')} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <span style={{textTransform:'capitalize', background:'#efefef', padding:'0 10px', borderRadius:'5px'}}>{prc.status}</span>
                                            </div>
                                    </div>
                                    
                                    <div className={classes.paper}>                                    
                                        <Grid container spacing={2} xs={12} md={12}>                                        
                                            <Grid item xs={4} md={2}>
                                                <img className={classes.img} alt="complex" src={prc.photo === null ? imageDefault : prc.photo }/>
                                            </Grid>
                                            <Grid item xs={8} sm container style={{alignItems:'center'}}>
                                                <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                                    <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                                    {prc.invoice} <span style={{fontSize:'12px'}}>({moment(prc.date).format('LLLL')})</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={5} container direction="column" spacing={2}>
                                                    <Grid item xs className={classes.desktopOnly}>                                       
                                                        <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                            <div><span>Status</span><span style={{width:'30px', margin:'10px'}}>:</span><span>{prc.status === "purchased" && "Menunggu Pembayaran"}</span></div>
                                                            <div><span>Jumlah Produk</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{prc.total_qty} pcs</span></div>
                                                            <div><span>Total Tagihan</span><span style={{width:'50px', margin:'10px'}}>:</span><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prc.total_amount)}</span></div>
                                                        </Typography>                                   
                                                    </Grid>

                                                    <Grid item xs className={classes.mobileOnlywthStyle}>                                       
                                                        <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                            <div>
                                                                <div><strong>Status</strong></div>
                                                                <div>{prc.status === "purchased" && "Menunggu Pembayaran"}</div>
                                                            </div>
                                                            <div>
                                                                <div><strong>Jumlah Produk</strong></div>
                                                                <div>{prc.total_qty} pcs</div>
                                                            </div>
                                                            <div>
                                                                <div><strong>Total Tagihan</strong></div>
                                                                <div>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prc.total_amount)}</div>
                                                            </div>
                                                        </Typography>                                   
                                                    </Grid>
                                                
                                                </Grid>
                                                <Grid item xs={12} md={7} container direction="column" spacing={1}>
                                                
                                                <Typography variant="body2" gutterBottom  className={classes.desktopOnly}>
                                                    <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                    <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                        <span>Batas Pembayaran</span>
                                                        <span style={{margin:'0 10px'}}>:</span>
                                                        <span> {moment(prc.payment.expired_at).format('LLL')} </span>
                                                    </div>                                                     
                                                    </Typography>
                                                    
                                                    <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                        <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                            <span>Metode Pembayaran</span>
                                                            <span style={{margin:'0 10px'}}>:</span> 
                                                            <span style={{textTransform:'capitalize'}}>{prc.payment.type === "bank_transfer" && <div>Virtual Account <span style={{textTransform:'uppercase'}}>{prc.payment.bank}</span></div>} {prc.payment.type === "cstore" && <span>{prc.payment.store}</span>} {prc.payment.type === "gopay" && <span>GOPAY</span>}</span>
                                                        </div>  
                                                       
                                                    </Typography>
                                                    {prc.payment.type === "bank_transfer" &&
                                                    <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                        <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                            <span>No Virtual Account</span>
                                                            <span style={{margin:'0 10px'}}>:</span> 
                                                            <span style={{textTransform:'capitalize'}}>{prc.payment.va_number}</span>
                                                        </div>  
                                                      
                                                    </Typography>}

                                                    {prc.payment.type === "cstore" &&
                                                    <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                        <div style={{display:'flex',width:'100%'}} className={classes.desktopOnly}>
                                                            <span>Kode Pembayaran</span>
                                                            <span style={{margin:'0 10px'}}>:</span> 
                                                            <span style={{textTransform:'capitalize'}}>{prc.payment.payment_code}</span>
                                                        </div>                                                         
                                                    </Typography>}
                                                </Typography>
                                                </Grid>                                           
                                            
                                            </Grid>
                                            <Grid item xs={12} md={2} className={classes.BoxButton}>
                                                    <div style={{margin:'10px 0 0', textAlign:'center'}}>
                                                        <div>
                                                            <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(prc.uid)}>
                                                                Detail Pesanan
                                                            </Button>
                                                            <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(prc.uid)} >
                                                                Batalkan
                                                            </Button>
                                                        </div>                                                 
                                                    </div>
                                                </Grid>

                                                
                                        </Grid>
                                    </div>
                                    </Paper>
                                </List>
                            )
                        })}
                    </div> 
                    : 
                    <div style={{textAlign:'center'}}>
                        <img src={imageDefaultProduct} height="300"/>
                        <p>Tidak ada transaksi</p>
                    </div>
                    } 
              
                    
              </div>}
               
                {!ToggleContent && <div>
                {dataOrderItems.length > 0 ? 
                    
                <div>
                    {dataOrderItems && dataOrderItems.map((item,index)=>{
                    return (
                      <List key={index}>
                        <Paper>
                        <div style={{padding:'5px 10px 0', display:'flex', alignItems:'center'}} className={classes.desktopOnly}>
                            <span><h3 style={{margin:'5px'}}>{item.merchant.name}</h3></span>
                            <span>({moment(item.date).format('LLLL')})</span>
                        </div>
                        <div className={classes.mobileOnly}>
                                <div >
                                    <div style={{display: 'flex',alignItems: 'center'}}>
                                        <div style={{color: '#ad3322'}}><LocalMallIcon/></div> 
                                        <div style={{margin:'0 10px'}}>
                                            {item.merchant.name}
                                            <div>
                                                <span style={{fontSize:'11px'}}> {moment(item.date).format('LLLL')} </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <span style={{textTransform:'capitalize', background:'#efefef', padding:'0 10px', borderRadius:'5px'}}>{item.status}</span>
                                </div>
                        </div>
                        
                                <div className={classes.paper}>                                    
                                    <Grid container spacing={2} xs={12} md={12} style={{position:'relative'}}>                                        
                                    <Grid item xs={12} md={10}> 
                                      {item.products.map((order, k)=>{
                                        return(
                                             <Grid container xs={12} md={12} style={{marginBottom:'20px'}}>                                          
                                                <Grid item xs={3} md={2}>
                                                    <img className={classes.img} alt="complex" src={order.cover === null ? imageDefault : order.cover }/>
                                                </Grid>
                                                <Grid item xs={9} sm container style={{padding:'0 10px'}}>
                                                    <Grid item xs={12} md={12} container direction="column" spacing={2}>
                                                        <Typography gutterBottom variant="subtitle1" className={classes.subtitle1}>
                                                        {order.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={7} container direction="column" spacing={2}>
                                                        <Grid item xs>                                       
                                                            <Typography variant="body2" gutterBottom className={classes.fsizeBox}>
                                                            <div><span>Status</span><span className={classes.statusProd}>:</span><span>{item.status === "paid" && "Menunggu Konfirmasi"} {item.status === "packed" && "Pesanan Diproses"} {item.status === "delivered" && "Pesanan Dikirim"} {item.status === "accepted" && "Pesanan Selesai"} {item.status === "cancelled" && "Pesanan Dibatalkan"}</span></div>
                                                            <div><span>Jumlah Produk</span><span className={classes.jmlProd}>:</span><span>{order.qty} pcs</span></div>
                                                            <div><span>Harga Produk</span><span className={classes.hrgProd}>:</span><span>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(order.price)}</span></div>
                                                            <div><span>Variant</span><span className={classes.variantProd}>:</span><span>{order.variant === null ? "Tidak ada variant" : order.variant }</span></div>
                                                            </Typography>                                   
                                                        </Grid>
                                                    
                                                    </Grid>
                                                    <Grid item xs={12} md={5} container direction="column" spacing={1}>
                                                    <Typography variant="body2" gutterBottom  className={classes.desktopOnly}>
                                                        <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                        <div style={{display:'flex',width:'100%'}}>
                                                            <span>Kurir</span>
                                                            <span style={{margin:'0 10px 0 60px'}}>:</span>
                                                            <span>{item.delivery.courier_name} - {item.delivery.courier_service}</span>
                                                        </div>  
                                                        </Typography>
                                                        
                                                        <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                            <div style={{display:'flex',width:'100%'}}>
                                                                <span>Harga Ongkir</span>
                                                                <span style={{margin:'0 10px'}}>:</span> 
                                                                <span style={{textTransform:'capitalize'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.total_delivery)}</span>
                                                            </div>  
                                                        </Typography>
                                                        {item.status === "delivered" && 
                                                        <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                            <div style={{display:'flex',width:'100%'}}>
                                                                <span>Resi</span>
                                                                <span style={{margin:'0px 10px 0 63px'}}>:</span>
                                                                <span>{item.delivery.delivery_number}</span>
                                                            </div>  
                                                        </Typography>}
                                                        {item.status === "completed" && 
                                                        <Typography color="textPrimary" variant="div" style={{fontSize:'13px'}}>                                
                                                            <div style={{display:'flex',width:'100%'}}>
                                                                <span>Resi</span>
                                                                <span style={{margin:'0px 10px 0 63px'}}>:</span>
                                                                <span>{item.delivery.delivery_number}</span>
                                                            </div>  
                                                        </Typography>}
                                                    </Typography>
                                                    </Grid>                                           
                                                </Grid>
                                            </Grid>
                                        )
                                        })}    
                                        </Grid>                                    
                                        <Grid item xs={12} md={2} className={classes.BoxButton}>
                                                <div style={{margin:'10px 0 0', textAlign:'center'}}>
                                                    {statusProduct === "new"  && <div>
                                                        <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button>
                                                        <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(item.uid)} >
                                                            Batalkan
                                                        </Button>
                                                    </div>}
                                                    {statusProduct === "paid" && <div>
                                                        <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button>
                                                        <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(item.uid)} >
                                                            Batalkan
                                                        </Button>
                                                    </div>}
                                                    {statusProduct === "packed" && <div>
                                                        <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button>
                                                        <Button variant="outlined" color="secondary" size="small" className={classes.btnAction} onClick={()=>confirmCancelOrder(item.uid)} >
                                                            Batalkan
                                                        </Button>
                                                    </div>}
                                                    {statusProduct === "delivered" && <div>  
                                                        <Button variant="contained"  color="primary" size="small" className={classes.btnActionDelivery} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button> 
                                                        <Button variant="outlined" size="small" className={classes.btnActionDelivery} onClick={()=>trackingOrder(item.uid, item.merchant.domain)}>
                                                            Lacak Pesanan
                                                        </Button> 
                                                        <Button variant="outlined" size="small" className={classes.btnActionDelivery} style={{background:'#6dbb06', color:'#fff'}} onClick={()=>acceptOrder(item.uid, item.merchant.domain)}>
                                                            Terima Pesanan
                                                        </Button> 
                                                        <Button variant="outlined" size="small" className={classes.btnActionDelivery} onClick={()=>complainOrder(item.uid, item.merchant.domain)}>
                                                            Komplain Pesanan
                                                        </Button> 
                                                    </div>}

                                                    {statusProduct === "accepted" && <div>
                                                        <Button variant="contained" color="primary"  size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button>
                                                        {item.is_reviewed === false && 
                                                        <Button variant="outlined" size="small" className={classes.btnAction} onClick={()=>createReview(item.uid, item.merchant.domain)}>
                                                            Berikan Nilai
                                                        </Button>}
                                                    </div>}

                                                    {statusProduct === "cancelled" && 
                                                        <div>
                                                        <Button variant="contained" color="primary" size="small" className={classes.btnAction} onClick={()=>DetailOrder(item.uid)}>
                                                            Detail Pesanan
                                                        </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </Grid>
                                    </Grid>
                                    <div className="footerPrice">
                                        <div style={{textAlign:'right'}}> <span style={{color:'#ab0004'}}>Total Belanja : {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(item.total_amount)}</span> </div>
                                    </div>
                                </div>
                         
                     </Paper>
                    </List>
                    )
                }
                   
                    
                )}

                           
                </div> 
                : 
                <div style={{textAlign:'center'}}>
                    <img src={imageDefaultProduct} height="300"/>
                    <p>Tidak ada transaksi</p>
                </div>
                } 
            </div>}
                
                </Box>
             </Grid>
        
        <Dialog open={openReview} fullWidth="true" maxWidth="xs" aria-labelledby="form-dialog-title" disableBackdropClick="true">  
            <DialogTitle id="form-dialog-title">
                Review
              <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseReview}/></span>
            </DialogTitle>      
                <DialogContent> 
                <div>
                {dataProductReview.map((revs, id)=>{
                    return(
                        <div key={id}>   
                                           
                            <div style={{marginBottom:'15px'}}>
                                <div style={{display:'flex', alignItems:'center'}}>                                    
                                        <img src={revs.cover} style={{width:'100%', margin:'0 10px 10px 0', height:'100%', maxWidth:'80px', maxHeight:'80px', objectFit:'cover'}}/>
                                        <div>
                                            <div>{revs.name}</div>
                                            <Rating
                                                name={revs.product_id}
                                                id={revs.product_id}
                                                value={ratingValue}
                                                onChange={(event, newValue) => {
                                                    const k = event.target.id
                                                    setRatingValue(newValue);
                                                }}
                                            />
                                        </div>
                                    
                                </div>
                                <TextField
                                        id="reviews-id"
                                        placeholder="Isi review"
                                        variant="outlined"
                                        fullWidth
                                        onChange={e => setReviewsProduct(e.target.value)}   
                                    />
                                <div style={{margin:'20px 0'}}> 
                                <div>Upload Foto</div>
                                <input
                                    onChange={uploadImages}
                                    accept="image/*"
                                    type="file"
                                />
                                </div>
                             <Button variant="outlined" fullWidth style={{marginTop:'10px'}} onClick={()=>submitReviews(dataUid, revs.product_id)}>Submit</Button>                       
                            </div>                             
                        </div>   
                    )
                })}
               
                </div>
               

                </DialogContent>
            </Dialog>
             

        <Dialog  open={trackingModal}  aria-labelledby="form-dialog-title" disableBackdropClick="true">
                <DialogTitle id="form-dialog-title">
                  Lacak Pesanan
                <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseTrackingModal}/></span>
                </DialogTitle>
                <DialogContent> 
                  <React.Fragment>
                    <h3 style={{textAlign:'center'}}>{dataTimeline.courier && dataTimeline.courier.name} {dataTimeline.courier && dataTimeline.courier.service}</h3>
                    <div style={{textAlign:'center'}}>{dataTimeline.number}</div>
                      {dataTimeline.tracking &&  dataTimeline.tracking.map((timeline, idx)=>{
                          return(
                              <Timeline align="alternate" key={idx}>
                                  <TimelineItem>
                                  <TimelineOppositeContent style={{maxWidth:'116px'}}>
                                      <Typography color="textSecondary">{timeline.date}</Typography>
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                      <TimelineDot />
                                      <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent>
                                      <Typography>{timeline.description}</Typography>
                                  </TimelineContent>
                                  </TimelineItem>                        
                              </Timeline>
                          )
                      })}
                      
                  </React.Fragment>
                </DialogContent>
            </Dialog>

            
            
        </DashboardLayout>
        
    )
}

// import React from 'react';
// import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
// import ActiveOrder from '../components/ActiveOrder';
// import OrderHistory from '../components/OrderHistory';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: '100%',
//     marginTop:'75px'
//   },
// }));

// export default function FullWidthTabs() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleChangeIndex = (index) => {
//     setValue(index);
//   };

//   return (
//     <div className={classes.root}>
//       <AppBar position="static" color="default">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           centered
//           aria-label="My Order"
//         >
//           <Tab label="Pesanan Aktif" {...a11yProps(0)} />
//           <Tab label="Riwayat Pesanan" {...a11yProps(1)} />
//         </Tabs>
//       </AppBar>
//       <SwipeableViews
//         axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//         index={value}
//         onChangeIndex={handleChangeIndex}
//       >
//         <TabPanel value={value} index={0} dir={theme.direction}>
//             <Paper>
//                 <ActiveOrder/>
//             </Paper>
//         </TabPanel>
//         <TabPanel value={value} index={1} dir={theme.direction}>
//             <Paper>
//                 <OrderHistory/>
//             </Paper>
//         </TabPanel>
        
//       </SwipeableViews>
//     </div>
//   );
// }

/*

      const getNew = () =>{
        setToggleContent(true);
        axios.get(`${baseUrl}/order/purchased`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = dataResponse.data;
              setDataPurchased(dataOrderProducts);
              
            });

                       
      }

      const getConfirmed = () =>{
        setToggleContent(false);        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'paid' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            
            });
      }


      const getPacked = () =>{
        setToggleContent(false);
        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'packed' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            //   
            });
      }


      const getDelivered = () =>{
        setToggleContent(false);
        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'delivered' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
                
            });
      }
    //   

      const getAccepted = () =>{
        setToggleContent(false);
        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'completed' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data;
              setTotalPrice(dataResponse.total_price);
              setTotalDelivery(dataResponse.total_delivery);  
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            // 
            });
      }
      

      const getCancelled = () =>{
        setToggleContent(false);
        
        axios.get(`${baseUrl}/order/list`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { status: 'cancelled' }
            })
          .then((response) => {          
              const dataResponse = response.data;
              const dataOrderProducts = response.data.data; 
              setDataOrderItems(dataOrderProducts);           
              dataOrderProducts.map((puid, idx)=>{
                setDataIdProduct(puid.uid);
                setStatusProduct(puid.status)
             
              })
            //   
            });
      }
*/
