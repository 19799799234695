import React,{ useState, useEffect, useRef } from 'react';
import DashboardLayout from '../../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import InputAdornment from '@material-ui/core/InputAdornment';
import '../../../App.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChipInput from "material-ui-chip-input";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { Editor } from '@tinymce/tinymce-react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    inline: {
      display: 'inline',
    },
    productBox:{
        border: '4px solid #ddd',
        width:'100%',
        marginBottom:'10px'
    },
    input: {
        display: 'none',
    },
    AddForm:{
        margin: '60px 35px 20px',
        [theme.breakpoints.down('md')]: {
            margin: '0 15px 20px'
        },
    },
    thumbDelete:{
        position:'absolute',
        background:'#eaeaea',
        padding:'0',
        bottom:'0',
        width:'100%',
        cursor: 'pointer',
        textAlign: 'center'

    }
  }));

  export default function AddProduct(props){
    const classes = useStyles();        
    const [category, setCategory] = useState('');
    const [name, setProductName] = useState('');
    const [urlVideo, setUrlVideo] = useState('');
    const [location, setProvince] = useState('');   
    const [provinceData, setProvinceData] = useState(null);     
    const [stocksType, setStocksType] = useState('');
    const [qty, setQty] = useState('');
    const [description, setProductDesc] = useState('');
    const [price, setPrice] = useState('');    
    const [variants, setInputList] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesUpload, setfilesUpload]= useState([]);
    const maxfileSize = 3097152;
    const [fileSizeImage, setfileSizeImage]= useState(0);
    const editorRef = useRef(null);
    const editorDescription = (content) => {
        setProductDesc(content) 
    };
    function onFileUpload(event) {
        event.preventDefault();
        let id = event.target.id;
        let file_reader = new FileReader();
        let file = event.target.files[0]; 
        let sizeImage= file.size;       
        file_reader.onload = () => {
          setFiles([...files, file_reader.result]);
          setfilesUpload([...filesUpload, {file_id:id, file_image: file_reader.result}]);        
        };
        setfileSizeImage(sizeImage);
        if (sizeImage > maxfileSize) // 2 MiB for bytes.
        {
            MySwal.fire({
                icon: 'warning',
                title: "Gambar melebihi batas ukuran upload! maximal 3 Mb"
            })
        }
        file_reader.readAsDataURL(file);
        
    }


    function onFileUploadOn(event) {
        event.preventDefault();
        let id = event.target.id;
        let file_reader = new FileReader();
        let file = event.target.files[0]; 
        let sizeImage= file.size;       
        file_reader.onload = () => {
          setFiles(...files, file_reader.result);      
        };
        setfileSizeImage(sizeImage);
        if (sizeImage > maxfileSize) // 2 MiB for bytes.
        {
            MySwal.fire({
                icon: 'warning',
                title: "Gambar melebihi batas ukuran upload! maximal 3 Mb"
            })
        }
        file_reader.readAsDataURL(file);
        
    }

    

    const removeImageFromArray=(e)=>{
        const index = e.target.id;
        let newPreview = [...filesUpload];    
        newPreview.splice(index,1);
        setfilesUpload(newPreview);
    }
    
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      };
      
      const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 132,
        height: 121,
        padding: 4,
        boxSizing: 'border-box'
      };
      
      const thumbInner = {
        display: 'flex',
        minWidth: '100%',
        overflow: 'hidden',
        position:'relative'
      };
         
      const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit:'cover'
      };
    const thumbs = filesUpload.map((file, index) => (
        <div style={thumb} key={index}>
            <div style={thumbInner}>
                <img
                    src={file.file_image}
                    style={img}
                    id={index}
                    alt={file.name}
                />
                <span
                    className={classes.thumbDelete}
                    id={index}
                    key={index}
                    onClick={(e) => {
                        removeImageFromArray(e);
                    }}
                    >
                    Delete
                </span>
            </div>
      </div>
    ));
    const handleSelectedTags = (items) => {
        setInputList(items);
    }

    const [optionValue, setOptionValue]= useState([]);
    
    const handleChangeProvince = selectedBot => {
        setProvinceData(selectedBot);
        setProvince(selectedBot.province_code);
        
    }
    const handleChangeStock = (event) => {
        setStocksType(event.target.value);
        
    };
    const handleRadioChange = (event) => {
        setCategory(event.target.value);
    };

    const [weight, setWeightValue] = useState();

 
    const handleWeightValue=(e)=>{
        const {value} = e.target; 
        setWeightValue(value);     
    }

    const provinceJson = async () => { 
        await axios.get('/provinces.json').then((response)=>{
            
            const resOptionValue = response.data;
            setOptionValue(resOptionValue);
        })
    };

    useEffect(()=>{
        provinceJson()
    },[])

    const MySwal = withReactContent(Swal)

    const handleSubmit = async (event) => {
    event.preventDefault();
    if(stocksType === "limited"){
      await axios.post(`${baseUrl}/product/create`, {
            name:name,
            category:category,
            location:location,
            description:description,
            price:price,
            qty:qty,
            weight:weight,
            variants:variants,
            video: urlVideo,
            image:files
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }}).then(result => {
            MySwal.fire({
                icon: 'success',
                title: "Berhasil Menambahkan Produk"
            }).then((result) => {
                window.location.href="/merchant/product";
            });
    
            
        }).catch(e => {
            
            MySwal.fire({
                icon: 'warning',
                title: e.response.data.message
            }).then((result) => {
                window.location.reload();
            });
            
        });  
    } else{
      await axios.post(`${baseUrl}/product/create`, {
            name:name,
            category:category,
            location:location,
            description:description,
            price:price,
            qty:null,
            weight:weight,
            variants:variants,
            video: urlVideo,
            image:files
        },{headers:{
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }}).then(result => {
            MySwal.fire({
                icon: 'success',
                title: "Berhasil Menambahkan Produk"
            }).then((result) => {
                window.location.href="/merchant/product";
            });
    
            
        }).catch(e => {
            
            MySwal.fire({
                icon: 'warning',
                title: e.response.data.message
            }).then((result) => {
                window.location.reload();
            });
            
        });  
    }
   
   
    };

    return(
        <DashboardLayout>
             <Grid container spacing={1}>
                <Box display="flex" justifyContent="space-between" width="100%" className={classes.AddForm}>
                    <Typography color="textPrimary" variant="h6">
                        Tambah Product
                    </Typography> 
                </Box>
                <Grid item xs={12} md={12}>
                <Box width="100%" style={{maxWidth:'92%', margin:'auto'}}>
                        <form onSubmit={handleSubmit}>
                            <label>Upload Gambar (maximal 5 Gambar)</label>
                            <div className="formGroup">                              
                            <div className="uploadWrapper">                                 
                                 <>
                                    {fileSizeImage > maxfileSize ? <></> :  
                                        <div style={thumbsContainer}>
                                            {thumbs}
                                        </div>
                                    }  
                                    {filesUpload.length > 4 ?
                                     <></> 
                                    :  
                                    <label className="upload--button">
                                        <input
                                            onChange={onFileUpload}
                                            id={1}
                                            accept="image/*"
                                            type="file"
                                            />
                                            <CloudUploadIcon style={{margin:'0 5px'}}/>                                           
                                            Upload
                                    </label> }
                                 </>
                                    
                                </div> 
                              
                                                
                                
                        </div>

                        

                        <div className="formGroup">
                            <TextField 
                                id="productName" 
                                required 
                                label="Nama Product" 
                                variant="outlined" 
                                size="small" 
                                fullWidth 
                                placeholder="Oleh-Oleh Khas Jawa"
                                onChange={e => setProductName(e.target.value)}
                            />
                        </div>

                        <div className="formGroup">
                                <InputLabel>Kategori</InputLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={category} required onChange={handleRadioChange} style={{flexWrap:'nowrap',flexDirection:'row'}}>                            
                                    <FormControlLabel value="food" control={<Radio />} label="Food" />
                                    <FormControlLabel value="souvenir" control={<Radio />} label="Souvenir" />
                                </RadioGroup>
                        </div>

                        <div className="formGroup">
                        <FormControl variant="outlined" fullWidth required>
                            <label>Asal Daerah Product</label>
                                <Autocomplete
                                    id="productDaerah"
                                    freeSolo
                                    getOptionLabel={(lb) => lb.province_name}
                                    onChange={(e,v) => setProvince(v.province_code)}
                                    options={optionValue}
                                    defaultValue={{ province_code: optionValue.province_code, province_name: optionValue.province_name }}
                                    renderInput={(params) => (
                                      <TextField {...params} onChange={(e) => setProvince(e.currentTarget.value)} variant="outlined" fullWidth size="small"/>
                                    )}
                                />
                        </FormControl>
                        
                        </div>

                        <div className="formGroup">
                        <label>Deskripsi</label>                        
                        <Editor
                            apiKey="rn0nqyvccgnjuvu7wm3p7m4s62bkavlppnrmrigmaen7uvqw"
                            ref={editorRef}
                            style={{zIndex:'8'}}
                            onEditorChange={editorDescription}
                            initialValue="<p></p>"
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        </div>
                        
                        <div className="formGroup">
                        <TextField
                            id="outlined-multiline-static"
                            label="Url Video"
                            fullWidth
                            size="small"
                            placeholder="Url Video"
                            variant="outlined"
                            onChange={e => setUrlVideo(e.target.value)}
                            />
                        </div>
                        <div className="formGroup">
                            <Grid container spacing={3}>
                                <Grid item xs={6}>                              
                                <TextField
                                        label="Satuan Product"
                                        id="filled-start-adornment"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">gram</InputAdornment>,
                                        }}
                                        onChange={(e)=>handleWeightValue(e)}
                                        fullWidth
                                        variant="outlined"
                                />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    id="outlined-multiline-static"
                                    label="Harga Product"
                                    fullWidth
                                    required
                                    size="small"
                                    placeholder="Harga Product"
                                    variant="outlined"
                                    onChange={e => setPrice(e.target.value)}
                                    />
                                </Grid>                        
                            </Grid>
                        </div>
                        <div className="formGroup">
                        <Grid container spacing={3}>
                                <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth size="small" required>
                                    <InputLabel id="stockProduct">Stock Product</InputLabel>
                                    <Select
                                    labelId="stockProduct"
                                    id="demo-simple-select-outlined"
                                    value={stocksType}
                                    onChange={handleChangeStock}
                                    label="Stock Product"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="unlimited">Unlimited</MenuItem>
                                    <MenuItem value="limited">Terbatas</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {stocksType == "limited" && (<TextField
                                    id="outlined-multiline-static"
                                    label="Jumlah Stok Produk"
                                    fullWidth
                                    required
                                    size="small"
                                    placeholder="Jumlah Stok Produk"
                                    variant="outlined"
                                    onChange={e => setQty(e.target.value)}
                                    />)}
                                    
                                </Grid>                        
                            </Grid>
                        </div>

                        <div className="formGroup">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <FormControl style={{ width: "100%" }}>
                                    <ChipInput
                                    className="customChipInput"
                                    variant="outlined"
                                    label="Variant Product"
                                    placeholder="Tambah Variant"
                                    size="small"
                                    onChange={(chips) => handleSelectedTags(chips)}
                                    />
                                </FormControl>
                            </Grid>   
                            </Grid>
                        </div>
                                {/* <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth size="small" required>
                                <label>Satuan Product</label>
                                
                                <ReactSelect
                                    value={weightType}
                                    onChange={onchangeSelect}
                                    options={options}
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.value}
                                />
                               
                                </FormControl>
                                
                                </Grid>
                                <Grid item xs={6}>
                                    <label>Berat Product</label>
                                    <TextField
                                    id="outlined-multiline-static"
                                    required
                                    fullWidth
                                    size="small"
                                    onChange={e => setWeightValue(e.target.value)}
                                    // onChange={handleChange}
                                    placeholder="Berat"
                                    variant="outlined"
                                    />
                                </Grid>                         */}
                         
                        <Button type="submit" color="primary" variant="outlined" fullWidth>Simpan</Button>
                    </form>
                    </Box>
                </Grid>               
             </Grid>
        </DashboardLayout>
        
    );
}

