import React, { useState} from "react";
import axios from 'axios';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  const classes = useStyles();
  const [password, setPassword] = useState(''); 
  const [email, setEmail] = useState(localStorage.getItem("email")); 
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("tokenF"))); 
  const [password_confirmation, setPasswordConfirm] = useState(''); 
  const MySwal = withReactContent(Swal)
  
  const handleSubmit = async e => {   
    e.preventDefault();  
    await axios.post(`${baseUrl}/auth/change-password`, {
        email,
        password,
        password_confirmation,
        token
    }).then((result) => {
      MySwal.fire({
        icon: 'success',
        title: result.data.message,
      }).then((result)=>{
        window.location.href="/login";
        localStorage.clear();
      })
        
    }).catch(e => {
        
    });    

   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <img src={BgLogin} alt=""/> 
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>        
            <form className={classes.form} onSubmit={handleSubmit} noValidate >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                type="password"
                name="password"
                autoComplete="password"
                onChange={e => setPassword(e.target.value)}
                autoFocus
              />
               <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Confirm Password"
                type="password"
                name="password"
                autoComplete="password"
                onChange={e => setPasswordConfirm(e.target.value)}
                autoFocus
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}


