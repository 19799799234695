import React, { useEffect, useState, useRef } from 'react'
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import axios from 'axios';
import { baseUrl } from 'utils';

const containerStyle = {
  width: '100%',
  height: '340px'
};

const libraries = ["places"];

function MapsLoc() {
  const [mapsLoc, setMapsLoc] = useState([]);
  const [latitude, setLat] =  useState();
  const [longitude, setLng] =  useState();
  const position = {
    lat: latitude,
    lng: longitude
   
  } 

  const center = {
    lat: -6.403273208937583,
    lng: 106.79890710572879
  };

  useEffect(()=>{getLocMap()},[]);

  const getLocMap = () =>{
    axios.get(`${baseUrl}/user/profile`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
  }).then((response)=>{
      const allLocation = response.data.location;
      const latCoord = response.data.location.latitude;
      const lngCoord = response.data.location.longitude;
      setMapsLoc(allLocation);       
      setLat(latCoord);
      setLng(lngCoord);                  
    }).catch(error=> console.error(`Error: ${error}`));
  }

 
   return (
    <LoadScript 
      id="script-loader"
      libraries={libraries}
      googleMapsApiKey="AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM"
      // googleMapsApiKey="AIzaSyCSwuS4b3Zr--DbsjZtzq276wq1g4vIw1I"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapsLoc != null ? position : center}
        zoom={12}
      >
   
        <Marker
          position={mapsLoc === null ? center : position }
        />
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MapsLoc)

// import React from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   StandaloneSearchBox,
//   Marker
// } from "@react-google-maps/api";

// const styles = {
//   input: "px-3 py-3 h-12 placeholder-indigo-500 w-full",
//   h2: "py-4 text-3xl text-gray-600 font-normal text-left",
//   map: "mt-6 rounded-md select-none overflow-hidden sm:w-1/2",
//   mainDiv: "sm:flex justify-between -mt-6"
// };

// const containerStyle = {
//   width: "100%",
//   height: "300px"
// };

// const libraries = ["places"];

// const center = {
//   lat: -6.403273208937583,
//   lng: 106.79890710572879 
// };

// const options = {
//   disableDefaultUI: true
// };

// //COMPONENT
// const ServiceAddress = () => {
//   const [map, setMap] = React.useState(null);

//   const searchBox = React.useRef(null);

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds();
//     map.fitBounds(bounds);
//     setMap(map);
//   }, []);

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null);
//   }, []);

//   return (
//     <div className={styles.mainDiv}>
//       <LoadScript
//         googleMapsApiKey="AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM"
//         libraries={libraries}
//         language="en"
//       >
//         <div>
//           <h2 className={styles.h2}>What is your service address?</h2>
//           <form>
//             <StandaloneSearchBox
//               onLoad={(ref) => (searchBox.current = ref)}
//               onPlacesChanged={() => 
//             >
//               <input
//                 type="text"
//                 name="address"
//                 placeholder="777 E Washington St, Orlando, FL"
//                 autoComplete="off"
//                 className={styles.input}
//               ></input>
//             </StandaloneSearchBox>
//           </form>
//         </div>
//         <div className={styles.map}>
//           <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={center}
//             zoom={17}
//             onLoad={onLoad}
//             onUnmount={onUnmount}
//             options={options}
//           >
//              <Marker
//           position={center}
//         />
//             {/* Child components, such as markers, info windows, etc. */}
//           </GoogleMap>
//         </div>
//       </LoadScript>
//     </div>
//   );
// };

// export default ServiceAddress;


