import React, { useEffect, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UploadImageMerchant from '../../components/UploadImageMerchant';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import PhotoTemplate from '../../img/watermark.png';
import IconButton from '@material-ui/core/IconButton';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MerchantLocation from './MerchantLocation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
      margin:'10px',
    },
    linkProf:{
        display: 'flex',
        alignItems:'center',
        margin:'0 10px 10px',
        [theme.breakpoints.down('md')]: {
         fontSize:'13px'
        }
      },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    profileWrap:{
          [theme.breakpoints.down('md')]: {
              margin:'25px 0'
          }
      },
    PadDense:{
        padding:'10px'
    },
    PadDense:{
        padding:'10px',
        margin:'0 10px',
        [theme.breakpoints.down('md')]: {
            margin:'0'
        }
    },
    margDense:{
        margin:0,
        [theme.breakpoints.up('md')]: {
            margin:'60px 0'
        }
    },
    subHeader:{
        fontSize:'12px',
    },
    btnVar:{
        margin:'2px',
        fontSize:'11px',
        padding:'5px'
    }
  }));

export default function Profile (){
    const classes = useStyles();
    const [profile, setProfiles] = useState('');
    const [avatar, setAvatar] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [merchants, setMerchants] = useState('');
    const [locationMerchant, setlocationMerchant]= useState();
    const [description, setDescription] = useState('');
    axiosRetry(axios, { retries: 3 });
  const url = baseUrl;

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const MySwal = withReactContent(Swal)

     const handleUpdateProfile = (event) => {
        event.preventDefault();
        axios.post(`${url}/merchant/update`, {
            name,
            description
        },{
            headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }).then((response)=>{
            const merchantProfile = response.data;            
            setMerchants(merchantProfile);           
            setOpen(false);        
            MySwal.fire({
                icon: 'success',
                title: response.data.message
            }).then(()=>{
                window.location.href="/merchant/profile"
            })
            
            
          }).catch(error=> console.error(`Error: ${error}`));
    }
    
    
    useEffect(()=>{
        getAllProfile();   
    },[]);

    const getAllProfile = () => {
        axios.get(`${url}/merchant/show`, {
          headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
      }).then((response)=>{
          const allProfile = response.data; 
          setProfiles(allProfile);
          const allLocation = response.data.location; 
          setlocationMerchant(allLocation);  
          const avatars = allProfile.photo.uri.toString();
          setAvatar(avatars);
                   
        }).catch(error=> console.error(`Error: ${error}`));
      }
     const imageDefault = PhotoTemplate.toString();
   
    //   if (!profile) return null;
    return(       
       
            <DashboardLayout>    
                   
            <Grid container className={classes.margDense}>
                    <Grid item xs={12} md={12} style={{marginBottom:'20px'}}>
                        <Paper className={classes.PadDense}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography color="textPrimary" className={classes.link} variant="h6">
                                Profile Toko
                            </Typography> 
                            <Typography color="textPrimary" className={classes.link} variant="span">
                                <Link href="#" onClick={handleClickOpen}>
                                    <IconButton aria-label="Edit">
                                        <CreateOutlinedIcon />
                                    </IconButton>
                                    Edit Profile Toko
                                </Link>
                            </Typography> 
                        </Box>

                        <Grid container>
                        <Grid item xs={6} md={3}>
                            <div className="avatarPhoto">                            
                                { profile.photo === null ? (<img src={imageDefault}/>):(<img src={avatar}/>)} 
                            </div> 
                            <UploadImageMerchant/>
                        </Grid>
                        <Grid item xs={6} md={9}>
                            <div className={classes.profileWrap}>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Nama Toko </div><span className='rightDivSectionProfile'> 
                                <TextField variant="outlined" size="small"  InputProps={{readOnly: true }} fullWidth value={profile.name === null ? ('please update your profile'):(profile.name)}/> </span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile">Domain Toko  </div><span className='rightDivSectionProfile'> <TextField fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.domain === null ? ('please update your profile'):(profile.domain)}/>  </span>
                            </Typography>
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile"> Deskripsi Toko  </div><span className='rightDivSectionProfile'> <TextField multiline rows={3} fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.description === null ? ('please update your profile'):(profile.description)} />   </span>
                            </Typography>
                         
                            <Typography color="textPrimary" className={classes.linkProf} variant="div">
                                <div className="leftDivSectionProfile"> Status Toko  </div><span className='rightDivSectionProfile'> <TextField fullWidth variant="outlined" size="small" InputProps={{readOnly: true }} value={profile.status === null ? ('please update your profile'):(profile.status)} />   </span>
                            </Typography>
                          
                            </div>   
                        </Grid>
                        </Grid>
                </Paper>
                </Grid>
                <Grid item xs={12} md={12}>
                    <MerchantLocation dataLocation={locationMerchant}/>
                </Grid>

            </Grid>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Edit Profile Toko
                    <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleClose}/></span>
                </DialogTitle>
                <DialogContent>
                <form onSubmit={(e)=>handleUpdateProfile(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nama Toko"
                        name="name"
                        autoComplete="name"
                        defaultValue={profile.name}
                        onChange={e => setName(e.target.value)}
                        autoFocus
                    />                   
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="Description"
                        label="Desc"
                        name="description"
                        autoComplete="Description"
                        defaultValue={profile.description === null ? (''):(profile.description)}
                        onChange={e => setDescription(e.target.value)}
                        autoFocus
                    />
                 
                    <Button type="submit" color="primary" fullWidth variant="outlined">
                        Update
                    </Button>
                </form>
               


                </DialogContent>
            </Dialog>
        </DashboardLayout>        
  
        
    )
}