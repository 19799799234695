import React, {useEffect,useState} from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../App.css";
import PaginationData from '../components/Pagination';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../img/broken-256.png';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import ProductEmpty from "../img/No Product (General).svg";
import StarIcon from '@material-ui/icons/Star';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  }
}))



function ProductByLoc() {
    const location = useLocation();
    const myparam = location.state.params;

    const classes = useStyles();
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(12);
    const [metaTotal , setMetaTotal] = useState();
    const imageDefault = PhotoTemplate.toString();
    const [priceMin, setPriceMin]= useState(0);
    const [priceMax, setPriceMax]= useState(9999999);
    const [dataProductByLoc, setdataProductByLoc] = useState([]);
    const [categoryName, setCategoryName] = useState([]);
    const [filteredCategoryName, setFilteredCategoryName] = useState(null);
    const [sortValue, setSortValue] = useState('');
  

    const sortSubmit = () =>{
      axios.get(`${baseUrl}/home/products-location/${myparam}?page=${currentPage}`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: {sorting:sortValue, price: priceMin+'-'+priceMax, category: filteredCategoryName}
          })
      .then((response) => {          
          const dataResponse = response.data.data;
          setdataProductByLoc(dataResponse);          
          const dataTotal = response.data.meta.total;
          const curPage = response.data.meta.current_page;
          // setCurPages(curPage);
          setMetaTotal(dataTotal);
          
      });
    }

    const getDataPagingProduct = () =>{
      axios.get(`${baseUrl}/home/products-location/${myparam}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { page: currentPage,  limit: itemsPerPage }
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setdataProductByLoc(dataResponse)            
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        setMetaTotal(dataTotal);
        
    });
    
    }

    const handleCategory = (e, value) => {
      if (e.target.checked) {
        setCategoryName([...categoryName, e.target.value]);
      } else {
        setCategoryName(categoryName.filter((name) => name !== e.target.value));
      }
    };

    useEffect(()=>{
      if(sortValue !== ''){
        sortSubmit();
      }else{
        getDataPagingProduct();
      }
        if (categoryName.length === 0) {
          setFilteredCategoryName("");
        } else {
          setFilteredCategoryName(categoryName.join(","));
        }
    },[currentPage, categoryName, sortValue])

   
    const categoryProduct = [
      {
        name: 'food',
        lable: 'Kuliner'        
      },
      {
        name: 'souvenir',
        lable: 'Sovenir'        
      }
    ]

    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Rating Tertinggi',
        value: 'popular'        
      },
    ]



    const submitFilter = () =>{
        axios.get(`${baseUrl}/home/products-location/${myparam}?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { price: priceMin+'-'+priceMax, category: filteredCategoryName}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setdataProductByLoc(dataResponse);            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            setMetaTotal(dataTotal);
            
        });
    }

    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/home/products-location/${myparam}?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { price: priceMin+'-'+priceMax}
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setdataProductByLoc(dataResponse);        
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        setMetaTotal(dataTotal);
        
    });
    }

    const submitCategory = () =>{
      axios.get(`${baseUrl}/home/products-location/${myparam}?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: {category: filteredCategoryName}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setdataProductByLoc(dataResponse);            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            setMetaTotal(dataTotal);
            
        });
    }

   

      const NotFoundProduct = ProductEmpty.toString();

     

      

    return (
        <div className={classes.MainPage}>     
         <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>

          <div style={{marginRight:'10px', marginBottom:'20px'}}>
            <label>Urutkan : </label> 
              <select style={{padding:'3px 10px'}} onChange={(e)=>setSortValue(e.target.value)}>
              <option value="--Paling Sesuai--" disabled selected>--Paling Sesuai--</option>
                {sortProduct.map(({ label, value}, index) => {
                  return ( 
                    <option value={value} key={index}>{label}</option>
                  )})}
              </select>
              
            </div>

          </div>      
            <Grid container spacing={3}>           
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Kategori</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            
                            {categoryProduct.map(({ name, lable}, index) => {
                              return ( 
                                <FormControlLabel
                                  key={index}
                                  control={
                                  <Checkbox
                                      id={`custom-checkbox-${index}`}
                                      value={name}
                                      onChange={handleCategory}
                                      name={name}
                                      color="primary"
                                  />
                                  }
                                  label={lable}
                              />
                              )})}
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitCategory()}>Terapkan</Button></div>
                        </Accordion>
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={submitFilter}>Terapkan Semua</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper>

                          <React.Fragment>
                              {dataProductByLoc.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductByLoc.map((prodLoc, k) =>{
                                    return(
                                      
                                        <Grid item xs={6} md={3} key={k}>
                                            <Card>
                                            <Link to={{ pathname: "/product/" + prodLoc.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                                <CardActionArea>
                                                    <img src={prodLoc.cover} width="100%" style={{maxHeight:'132px', objectFit:'contain', minHeight:'132px'}}/>
                                                    <CardContent>
                                                    <Typography gutterBottom variant="body2" component="p" style={{height:'60px', overflow:'hidden'}}> 
                                                        {prodLoc.name}
                                                    </Typography>
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prodLoc.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        <span style={{fontSize:'12px'}}>{prodLoc.location.name}</span> <br />
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                          <StarIcon style={{color:'#FCCE00'}}/>
                                                          <span style={{fontSize:'13px', margin:'0 5px'}}>{prodLoc.rating}</span>
                                                        </div> 
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                   
                                    )
                                  })}
                                  </Grid>

                                  <PaginationData 
                                    stateChanger={setcurrentPage} 
                                    current={currentPage} 
                                    limit={itemsPerPage} 
                                    total_page={metaTotal} 
                                    loadData={getDataPagingProduct}
                                  />
                                </div>
                                
                              }
                               
                            </React.Fragment>
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default ProductByLoc;