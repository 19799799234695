import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AkunAnda from "../img/home1.png";
import ChangePassword from "../img/changepassword.png";
import ChangePassword1 from "../img/changepassword1.png";
import EditProfile from "../img/editprofile.png";
import LoginPage from "../img/loginsosmed.png";
import LoginPage1 from "../img/loginsosmed-1.png";
import Profile from "../img/profile.png";
import RegisterPage from "../img/register.png";
import RegisterPage1 from "../img/register-1.png";
import Registertoko1 from "../img/regis_toko1.png";
import Registertoko2 from "../img/regis_toko2.png";
import Registertoko3 from "../img/regis_toko3.png";
import ProductToko from "../img/create_product.png";
import ProductToko1 from "../img/create_product1.png";
import ProductToko2 from "../img/create_product2.png";
import ProductToko3 from "../img/create_product3.png";
import ProductToko4 from "../img/create_product4.png";
import CheckoutToko from "../img/checkout_product.png";
import CheckoutToko1 from "../img/checkout_product1.png";
import CheckoutToko2 from "../img/checkout_product2.png";
import CheckoutToko3 from "../img/checkout_product3.png";
import CheckoutToko4 from "../img/checkout_product4.png";
import CheckoutToko5 from "../img/checkout_product5.png";
import CheckoutToko6 from "../img/checkout_product6.png";
import Pengiriman from "../img/pengiriman.png";

const useStyles = makeStyles((theme) => ({
    MainPage:{
        margin:'2em 0',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
          }
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
    },
  }));

function FAQ(){
    const classes = useStyles();
    return (
        <div className={classes.MainPage}>
            <h3 style={{textAlign:'center'}}>FAQ LEHOLEH </h3>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Bagaimana cara mengubah username saya sebagai pembeli?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Username yang digunakan untuk masuk ke dalam aplikasi adalah email atau nomor handphone Anda. Untuk saat ini, Anda tidak bisa mengubah username Anda. Dimohon untuk mengisi data akun yang benar agar tidak terjadi kesalahan saat menggunakan aplikasi.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Bagaimana cara mengubah data profil saya?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    A. Via Web
                    <ol>
                        <li>Klik Akun Anda <img src={AkunAnda} width="100%"/></li>
                        <li>Klik Profile <img src={Profile} width="100%"/></li>
                        <li>Klik Edit Profil yang ingin Anda rubah datanya <img src={EditProfile} width="100%"/></li>
                        <li>Klik Simpan</li>
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Berapa lama saya akan menerima pesanan saya?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Estimasi pengiriman pesanan tergantung jenis pengiriman yang dilakukan.
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana cara kontak Customer Service Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Anda dapat mengontak kami melalui
                <ol>
                    <li>Chat 24 jam <a href="https://wa.me/6281288883745">klik disini</a></li>
                    <li>Email melalui <a href="mailto:leholehnusantara@gmail.com">leholehnusantara@gmail.com</a></li>
                </ol>
                <small>*Customer Service 24 jam, Senin sampai dengan Minggu, termasuk Hari Libur Nasional.</small>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa saya tidak bisa login?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Berikut adalah beberapa penyebab Anda tidak bisa login ke akun Leholeh:
                <table border="1">
                    <tr>
                        <th width="300">Alasan</th>
                        <th width="300">Solusi</th>
                    </tr>
                    <tr>
                        <td>
                        Salah memasukkan password
                        </td>
                        <td>
                        Silahkan reset password dengan mengikuti Langkah berikut ini.
                        </td>
                    </tr>
                    <tr>
                        <td>Kode verifikasi (OTP) pada saat login dengan SMS belum sesuai</td>
                        <td>Pastikan nomor handphone sudah sesuai dengan yang terdaftar di akun Leholeh. Jika nomor handphone Anda sudah berubah, silahkan mengikuti Langkah-langkah berikut ini.</td>
                    </tr>
                    <tr>
                        <td>
                        Email &amp; password pada saat login dengan facebook belum sesuai
                        </td>
                        <td>
                        Jika Anda log in dengan Facebook, pastikan email dan password akun Facebook Anda aktif dan sesuai. Maka secara otomatis Anda akan masuk ke akun Leholeh Anda.
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Akun dibatasi untuk alasan keamanan
                        </td>
                        <td>
                        Hal ini dapat terjadi apabila ada percobaan peretasan pada akun. Akun akan aman jika Anda tidak memberikan kode verifikasi (OTP) ataupun PIN kepada siapa pun. Simak tips mudah untuk menjaga akunmu disini.
                        </td>
                    </tr>
                    <tr>
                        <td>Akun dibatasi untuk alasan lain</td>
                        <td>Hal ini dapat terjadi jika akun terlibat dalam pelanggaran Kebijakan Leholeh, tidak membayar tagihan, dll. Anda tidak dapat login ke aplikasi Leholeh untuk alasan keamanan. Silakan klik disini untuk informasi mengenai mengapa akun Leholeh dibatasi.</td>
                    </tr>
                    <tr>
                        <td>Kendala pada aplikasi Leholeh</td>
                        <td>Perbarui aplikasi Leholeh ke versi terbaru pada Play Store/App Store dan pastikan jaringan internet Anda lancar.</td>
                    </tr>
                </table>
                <p>Jika Anda masih menemukan kendala, hubungi Customer Service Leholeh atau Agent Live Chat kami. Untuk informasi lebih lanjut tentang hal yang disarankan dan hal yang dilarang, Anda dapat membacanya di Syarat Layanan Leholeh dan Peraturan Komunitasi Leholeh.</p>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana cara mengubah password akun Leholeh saya?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
            
                Anda dapat mengikuti Langkah berikut untuk mengganti password:
                <br/>    <br/>
                A. Melalui Situs Web
                <ol>
                        <li>Klik Akun Anda <img src={AkunAnda} width="100%"/></li>
                        <li>Klik Change Password <img src={ChangePassword} width="100%"/></li>
                        <li>Masukkan password Anda saat ini, setelah itu masukkan password baru  <img src={ChangePassword1} width="100%"/></li>
                        <li>Klik Submit</li>
                    </ol>
                <p>Catatan : </p>
                <ul>
                    <li>jika nomor handphone Anda saat ini berbeda dengan nomor handphone yang terdaftar di Leholeh, silahkan isi data Anda <a href="/profile">disini</a>. Jika Anda lupa password akun Leholeh Anda, silahkan <a href="/update/password">klik disini.</a></li>
                    <li>Password harus 8-16 karakter dan setidaknya memiliki 1 huruf besar dan 1 huruf kecil.</li>
                </ul>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana cara mengubah nomor handphone di aplikasi Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    A. Dalam kondisi login
                    <ol>
                        <li>Klik <strong>Akun Saya</strong> lalu pilih <strong>Profil.</strong></li>
                        <li>Klik <strong>Ubah</strong> disamping nomor handphone Anda</li>
                        <li>Ubah nomor handphone lama Anda dengan nomor handphone baru Anda.</li>
                        <li>Masukkan kode verifikasi (OTP) yang Anda terima dari nomor handphone baru Anda.</li>
                        <li>Klik <strong>Simpan</strong></li>
                    </ol>
                    Pastikan nomor handphone yang akan digunakan belum terdaftar di akun Leholeh lainnya. Tiap nomor handphone hanya dapat digunakan untuk 1 akun Leholeh. Klik disini jika Anda tidak bisa mengubah nomor handphone.
                    <br/><br/>
                    B. Dalam kondisi logout
                    <table border="1">
                    <tr>
                        <th width="300">Logout Ingat Password</th>
                        <th width="300">Logout &amp; Lupa Password</th>
                        <th width="300">Logout &amp; Nomor Handphone Lama Tidak Aktif/Hangus</th>
                    </tr>
                    <tr>
                        <td>
                        Anda dapat login ke aplikasi Leholeh &amp; mengikuti langkah-langkah dalam kondisi login.
                        </td>
                        <td>
                        Anda dapat mengikuti langkah-langkah disini untuk mengubah <em>password</em> Leholeh Anda.
                        </td>
                        <td>
                        Hubungi Customer Service Leholeh atau isi form disini. Lampirkan data:
                        <ol>
                            <li>Nama lengkap:</li>
                            <li>Username yang digunakan:</li>
                            <li>Email aktif yang digunakan:</li>
                            <li>No. handphone baru (pastikan tidak pernah terdaftar di Leholeh)</li>
                            <li>Lampiran foto identitas (KTP/SIM/Kartu Keluarga)*:</li>
                            <li>Lampiran foto <em>selfie</em> beserta identitas (KTP/SIM/Kartu Keluarga)*:</li>
                        </ol>
                        *Pastikan foto identitas dan wajah yang Anda lampirkan terlihat jelas, tidak buram, tidak terpotong, tidak melalui proses edit, scan, dan tidak berbentuk pdf.

                        </td>
                    </tr>
                    </table>
                    Proses pengajuan perubahan nomor handphone dalam kondisi logout akan ditindaklanjuti oleh tim terkait Leholeh dengan estimasi waktu 3x24 jam sejak melakukan pelaporan.


                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa saya tidak menerima kode OTP?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Anda tidak akan menerima kode verifikasi (OTP) apabila:
                <ol>
                    <li>Terjadi masalahjaringan dari operator seluler handphone Anda</li>
                    <li>Terjadi masalah koneksi internet/jaringan WiFi</li>
                    <li>Nomor Anda sudah terdaftar pada akun lain</li>
                </ol>
                Kode Verifikasi (OTP) merupakan kode verifikasi yang biasanya dikirimkan melalui <strong>SMS</strong> atau <strong>Whatsapp.</strong>
                <br/>
                Oleh karena itu, sebelum mengirimkan permintan kode verifikasi (OTP), mohon pastikan hal berikut ini:
                <ol>
                    <li>Nomor handphone yang terdaftar pada akun Anda sudah benar dan dalam keadaan aktif</li>
                    <li>Sinyal operator nomor handphone yang Anda gunakan tidak bermasalah</li>
                    <li>Nomor handphone Anda belum digunakan pada akun yang lain</li>
                </ol>
                Apabila setelah semua langkah di atas telah Anda lakukan dan masih belum menerima kode verifikasi (OTP), silahkan hubungi Customer Service Leholeh <a href="https://wa.me/6281288883745">disini</a> dengan melampirkan data-data berikut:
                <ul>
                    <li>Username</li>
                    <li>Waktu terjadinya kendala</li>
                    <li>Jenis provider (Telkomsel, Indosat, XL, dan lain-lain)</li>
                    <li>Halaman tempat Anda mengirimkan permintaan kode verifikasi (OTP), misalnya di halaman saat akan daftar akun baru Anda.</li>
                    <li>Nomor handphone</li>
                    <li>Screenshoot/Screen Record</li>
                </ul>
                <strong>Catatan : </strong>
                <ul>
                    <li>Untuk mengetahui cara mengubah nomor handphone di aplikasi Leholeh dan mengapa Anda tidak dapat mengubahnya dapat dilihat disini.</li>
                </ul>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana caranya login ke akun Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Jika Anda sudah memiliki akun Leholeh, Anda dapat login dengan Langkah-langkah berikut: <br/>
                    A. Via Situs
                    <ol>
                        <li>Kunjungi situs <a href="https://leholeh.id/">disini</a></li>
                        <li>Klik <strong>Masuk</strong> <img src={LoginPage} width="100%"/></li>
                        <li>
                            Silahkan Masuk dengan menggunakan : <br/>
                            <ul>
                                <li><strong>Alamat email (Gmail)</strong> dengan klik <strong>Lanjutkan dengan Google</strong></li>
                                <li><strong>Akun Facebook</strong> dengan klik <strong>Lanjutkan dengan Facebook</strong></li>
                            </ul>
                            Atau login dengan memasukkan Email/Nomor Handphone serta password Anda. Klik Masuk.
                            <img src={LoginPage1} width="100%"/>
                        </li>                       
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana cara membuat akun Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Cara membuat akun Leholeh adalah sebagai berikut: <br/>
                    A. Melalui Situs
                    <ol>
                        <li>Buka situs Leholeh <a href="https://leholeh.id/">disini</a></li>
                        <li>Pada halaman utama, klik <strong>Daftar</strong> <img src={RegisterPage} width="100%"/></li>
                        <li>Masukkan alamat email/nomor handphone Anda.
                            <img src={RegisterPage1} width="100%"/>
                        </li>     
                        <li>Masukkan kode OTP yang didapat melalui alamat email (jika Anda mendaftar menggunakan alamat email) atau pesan ke nomor handphone (jika Anda mendaftar menggunakan nomor handphone).</li>
                        <li>klik <strong>Klik Verifikasi.</strong></li>                  
                    </ol>
                <br/>
                *Silahkan lengkapi data profil Anda untuk dapat menggunakan seluruh fitur aplikasi. Jika Anda diminta untuk memasukkan kode verifikasi (OTP) saat login, pastikan Anda tidak menginformasikannya kepada siapa pun karena kode verifikasi (OTP) bersifat rahasia.
                <br/> <br/>
                Jika Anda belum memiliki akun Leholeh, daftarkan diri Anda di Leholeh dengan mengikuti Langkah disini. Jika Anda mengalami kendala saat login, temukan informasi lebih lanjut disini atau hubungi Customer Service Leholeh.
                <br/>
                <strong>Catatan:</strong>
                <ol>
                    <li>Password untuk akun Leholeh Anda dapat dibuat dengan minimal 8 karakter dan maksimal 16 karakter dengan perpaduan huruf kapital, huruf kecil, dan angka/karakter.</li>
                    <li>Untuk meningkatkan keamanan akun Anda, mohon verifikasi perangkat yang Anda gunakan dengan kode verifikasi (OTP) yang dikirim ke nomor handphone yang terdaftar pada akun Anda ke kolom yang tersedia.</li>
                </ol>
                Jika Anda sudah memiliki akun Leholeh silahkan klik disini untuk informasi cara masuk ke aku Leholeh Anda.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa akun saya dibatasi?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Demi alasan keamanan, kami membatasi akun yang diduga telah melanggar syarat & ketentuan Leholeh.
                <br/>
                Pembatasan akun dilakukan terhadap pelanggaran-pelanggaran namun tidak terbatas pada pembuatan pesanan palsu, penyalahgunaan promosi, penipuan, dan lainnya.
                <br/> <br/>
                <strong>Cara Mengajukan Naik Banding untuk Memulihkan Akun</strong>
                <br/>
                Anda dapat mengajukan naik banding untuk memulihkan akun dengan mengisi formulir disini dan lampirkan dokumen-dokumen di bawah ini:
                <ul>
                    <li>Foto identitas yang sah (Contoh: KTP, paspor, atau kartu pelajar)</li>
                    <li>Bukti pembelian produk (Contoh: No. resi dari Penjual, foto produk yang dibeli, dll.)</li>
                </ul>

                Akun yang dibatasi akan ditutup secara permanen dalam 30 hari setelah notifikasi dikirimkan, jika:
                <ul>
                    <li>Pengajuan naik banding tidak dilakukan dalam 30 hari, atau</li>
                    <li>Pengajuan naik banding tersebut gagal.</li>
                </ul>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa penilaian produk/respon saya disembunyikan?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Jika penilaian produk/respon Anda disembunyikan, maka ada kemungkinan bahwa:
                <br/>
                Pembatasan akun dilakukan terhadap pelanggaran-pelanggaran namun tidak terbatas pada pembuatan pesanan palsu, penyalahgunaan promosi, penipuan, dan lainnya.
                <br/> <br/>
                <ol>
                    <li>
                        Anda memberikan penilaian yang tidak relevan dengan produk (bagi Pembeli). Hal ini dapat mencakup: 
                        <ul>
                            <li>Penggunaan emoji yang berlebihan (Spam emoji).</li>
                            <li>Penggunaan kata yang sama berulang-ulang (Spam kata).</li>
                            <li>Penggunaan karakter yang sama secara berulang-ulang (Spam karakter).</li>
                            <li>Penggunaan foto atau video yang tidak relevan dengan produk (Spam foto/video)</li>
                        </ul>
                    </li>
                    <li>
                         Anda memberikan respon yang tidak relevan dengan penilaian pembeli (bagi penjual).
                    </li>
                    <li>
                        Anda memberikan penilaian produk/respon yang kurang pantas. Hal ini dapat mencakup:
                        <ul>
                            <li>Penilaian produk/respon yang vulgar: Penggunaan kata kasar atau tidak sopan.</li>
                            <li>Penilaian produk/respon yang menyinggung SARA: Ulasan mengandung unsut suku, agama, atau ras.</li>
                            <li>Penilaian produk/respon yang mengandung informasi pribadi Pengguna lain, seperti nomor handphone, alamat, akun media social, dll.</li>
                            <li>Penilaian produk/respon mengandung iklan di luar Leholeh dalam bentuk apa pun, seperti iklan promosi, layanan masyarakat, komersial, mengandung agama tertentu, dll.</li>
                        </ul>
                    </li>
                </ol>
                Catatan:
                <ul>
                    <li>Penilaian produk dapat dijadikan tolak ukur bagi Pembeli untuk menemukan dan membandingkan barang dengan mudah di Shopee. Sedangkan bagi Penjual, penilaian produk merupakan tolak ukur kepuasan pelanggan bagi produk dan tokonya. Untuk mengetahui cara menulis penilaian produk yang baik, silakan ikuti tips selengkapnya disini.</li>
                    <li>Untuk mengetahui informasi lebih lanjut mengenai penilaian produk yang dihapus, silakan klik disini.</li>
                </ul>

                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Apa saja tips pengumpulan bukti pengembalian barang dan/atau dana?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Berikut adalah tips yang dapat Anda ikuti untuk mengumpulkan bukti yang diperlukan dalam proses pengembalian barang dan/atau dana:
                <ul style={{listStyleType:'none'}}>
                    <li style={{marginBottom:'10px'}}>
                        A. “Saya menerima produk yang salah (salah ukuran, salah warna, atau beda produk”
                        <ol>
                            <li>Pastikan data pengiriman (alamat asal dan tujuan) terbaca dengan baik dan sesuai dengan resi pengiriman.</li>
                            <li>Perlihatkan jenis dan tipe barang dengan jelas.</li>
                            <li>Perlihatkan bagian produk yang salah dengan jelas.</li>
                        </ol>
                    </li>
                    <li style={{marginBottom:'10px'}}>
                        B. “Saya menerima produk yang rusak (fungsi)”
                        <ol>
                            <li>Pastikan data pengiriman (alamat asal dan tujuan) terbaca dengan baik dan sesuai dengan resi pengiriman.</li>
                            <li>Perlihatkan jenis dan tipe barang dengan jelas.</li>
                            <li>Hubungkan barang (atau Sebagian) yang diterima dengan colokan listrik dan/atau lakukan simulasi penggunaan barang.</li>
                            <li>Jika ada, bandingkan dengan barang lain yang berfungsi dengan baik.</li>
                        </ol>
                    </li>

                    <li style={{marginBottom:'10px'}}>
                        C. “Saya menerima produk yang rusak (fisik)”
                        <ol>
                            <li>Pastikan data pengiriman (alamat asal dan tujuan) terbaca dengan baik dan sesuai dengan resi pengiriman.</li>
                            <li>Perlihatkan jenis dan tipe barang dengan jelas.</li>
                            <li>Perlihatkan bagian yang rusak dengan jelas, dekatkan ke kamera jika perlu</li>
                            <li>Jika ada, bandingkan dengan gambar yang terdapat di kotak barang.</li>
                        </ol>
                    </li>

                    <li style={{marginBottom:'10px'}}>
                        D. “Saya menerima produk yang kurang”
                        <ol>
                            <li>Pastikan data pengiriman (alamat asal dan tujuan) terbaca dengan baik dan sesuai dengan resi pengiriman.</li>
                            <li>Perlihatkan jenis dan tipe barang dengan jelas.</li>
                            <li>Perlihatkan dan hitung total jumlah barang dengan jelas.</li>
                            <li>Jika ada, perlihatkan contoh gambar dari barang yang tidak Anda terima.</li>
                        </ol>
                    </li>

                </ul>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Bagaimana cara mendaftarkan toko ke Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    A. Via Web
                    <ol>
                        <li>Buka situs leholeh di web browser dengan mengetik www.leholeh.id</li>
                        <li>Klik <strong>Profil</strong>, kemudian klik <strong>Buat Toko</strong> <img src={Registertoko1} width="100%"/></li>
                        <li>Masukkan Nama Toko dan Deskripsi Toko Anda, kemudian klik Daftar/Register <img src={Registertoko2} width="100%"/></li>
                        <li>Isi data profil toko Anda dengan lengkap <img src={Registertoko3} width="100%"/> </li>
                        <li>Toko Anda Siap Digunakan</li>
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Bagaimana cara memasukkan produk toko Anda?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <ol>
                        <li>Klik <strong>Product Toko</strong> <img src={ProductToko} width="100%"/></li>
                        <li>Klik <strong>Tambah Produk</strong><img src={ProductToko1} width="100%"/></li>
                        <li>Masukkan data produk anda dengan lengkap dan sesuai, kemudian klik <strong>Simpan</strong> <img src={ProductToko2} width="100%"/></li>
                        <li>Data produk yang sudah disimpan akan ditampilkan pada menu <strong>Produk Toko</strong> <img src={ProductToko3} width="100%"/> </li>
                        <li>Jika Anda ingin menambahkan produk kembali, klik <strong>Tambah Produk.</strong> </li>
                        <li>Jika Anda ingin merubah data produk, klik <strong>Edit/Ubah</strong> </li>
                        <li>Jika Anda ingin menghapus data produk, klik <strong>Hapus</strong> <img src={ProductToko4} width="100%"/>  </li>
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Bagaimana cara checkout produk di Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Berikut adalah cara checkout produk di Leholeh:
                    <ol>
                        <li>Temukan produk yang Anda inginkan dengan menjelajahi berbagai kategori dan sub-kategori atau menggunakan fitur pencarian <img src={CheckoutToko} width="100%"/></li>
                        <li>Pilih produk yang Anda inginkan dari hasil pencarian</li>
                        <li>Pada halaman produk, Anda dapat klik icon keranjang untuk memasukkan produk ke keranjang belanja, atau klik <strong>Beli Sekarang</strong> untuk membuat pesanan<img src={CheckoutToko1} width="100%"/></li>
                        <li>Apabila produk tersebut memiliki varian yang harus dipilih, setelah Anda klik icon keranjang atau <strong>Beli Sekarang</strong>, akan muncul pilihan yang harus dipilih terlebih dahulu. 
                        Klik <strong>Beli Langsung</strong> untuk lanjut ke proses checkout <img src={CheckoutToko2} width="100%"/> </li>
                        <li>Centang produk yang akan dibayar pada halaman keranjang, kemudian klik <strong>Beli.</strong> 
                        Anda dapat memasukkan tambahan informasi pada kolom catatan/notes kepada pembeli pada pesanan Anda. Anda juga dapat menghapus produk yang masuk pada halaman keranjang dengan meng-klik icon kotak sampah yang ada pada bagian Keranjang <img src={CheckoutToko3} width="100%"/> </li>
                        <li>Pada halaman checkout, Anda wajib memilih jasa pengiriman yang akan digunakan untuk pemesanan produk. Anda dapat melihat total produk yang harus Anda bayar. Kemudian klik <strong>Pilih Pembayaran</strong> <img src={CheckoutToko4} width="100%"/></li>
                        <li>Pilih metode pembayaran yang diinginkan. Jika sudah selesai, klik <strong>Buat Pesanan.</strong> <img src={CheckoutToko5} width="100%"/> </li>
                        <li>Apabila pembayaran sudah berhasil dilakukan dan dikonfirmasi, produk yang Anda beli akan otomatis ada di halaman <strong>Pesanan Saya</strong> di bagian <strong>Pesanan Diproses</strong> <img src={CheckoutToko6} width="100%"/></li>
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Kenapa saya perlu memberikan berat produk?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Selain jarak, berat produk juga digunakan untuk menentukan besaran biaya ongkos kirim pada saat Pembeli melakukan checkout . Ongkos kirim akan dihitung sedemikian rupa oleh sistem berdasarkan data yang diinput oleh Penjual dan juga Pembeli, sehingga semua produk dalam 1 pesanan dapat masuk dalam satu ongkos kirim yang sama.</p>
                    <p><strong>Contoh:</strong> Ongkos kirim adalah Rp.10.000 per paket 1Kg. apabila Pembeli membeli 4 produk (setiap produknya memiliki berat 250g) dari Penjual yang sama, system kami akan tetap memberi ongkos kirim Rp.10.000 karena empat produk tersebut bisa dimasukkan ke dalam paket 1Kg.</p>
                    <p>Akan tetapi hal ini tidak berlaku jika Pembeli membeli produk dari beberapa penjual yang berbeda. Total berat produk akan dihitung terpisah meskipun dilakukan dalam satu checkout yang sama. <img src={Pengiriman} width="100%"/></p>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                    Mengapa saya tidak bisa melakukan checkout pesanan?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Jika Anda tidak dapat membuat pesanan pada proses checkout, mohon pastikan Anda sudah mengatur alamat & titik koordinat yang sesuai, serta memilih jam pengiriman pesanan.</p>
                    <p>Berikut cara mengatur koordinat alamat &amp; memilih jam pengiriman pesanan:</p>
                    <ol>
                        <li>Klik tombol Edit Lokasi untuk mengatur lokasi peta yang sesuai.</li>
                        <li>Pilih jasa kirim dan jenis pengiriman yang diinginkan</li>
                        <li>lalu klik <strong>Pilih Pembayaran</strong></li>
                    </ol>                   
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Apa yang terjadi jika saya melakukan pelanggaran produk?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Apabila Anda memiliki produk yang melanggar panduan pelanggaran produk, maka produk tersebut akan diturunkan, diblokir, dan/atau dihapus. Simak informasi berikut ini:</p>
                     <ol>
                        <li><strong>Produk akan diturunkan</strong> <br/>Hal ini hanya berlaku bagi jenis pelanggaran kategori produk yang salah. Produk yang diturunkan tetap ditampilkan dan dapat dicari oleh Pembeli, namun, peringkat pencarian akan mengalami penurunan sementara. Penjual dapat memperbaiki dan menyelesaikan pelanggaran tersebut agar peringkat pencarian produk meningkat kembali.</li>
                        <li><strong>Produk akan diblokir dan/atau dihapus </strong> <br/>
                        Hal ini berlaku bagi semua produk yang melanggar panduan pelanggaran produk. Toko dengan produk yang dihapus Leholeh akan mendapatkan poin penalti berdasarkan Sistem Poin Penalti.</li>
                    </ol>
                    <strong>Catatan:</strong> 
                    <ul>
                        <li>Leholeh akan memberhentikan keuntungan eksklusif apabila toko memiliki poin penalti yang tinggi. Keuntungan eksklusif akan didapatkan kembali dalam 28 hari apabila toko meningkatkan performa toko dan mencapai target. Selain itu, jika poin penalti yang didapat semakin banyak, akun dapat dibatasi atau bahkan dihapus.</li>
                        <li>Daftar produk yang perlu diubah untuk mematuhi kebijakan Leholeh akan diblokir terlebih dahulu. Penjual memiliki waktu untuk mengubah produk tersebut hingga batas waktu yang ditentukan. Jika tidak, produk akan dihapus.</li>
                        <li>Produk dengan pelanggaran kategori produk yang salah akan diturunkan dan berada di peringkat pencarian yang rendah sampai Anda mengambil tindakan untuk menyelesaikan pelanggaran tersebut.</li>
                    </ul>                  
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa saya mengalami kendala saat upload produk?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Jika Anda mengalami kendala saat upload produk, ada kemungkinan bahwa:</p>
                     <ul>
                        <li>Akun Anda dibatasi.</li>
                        <li>Aplikasi Leholeh sedang mengalami kendala.</li>
                    </ul>                  
                </Typography>
                </AccordionDetails>
            </Accordion>
            
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Bagaimana jika terjadi kendala/error/blank page pada aplikasi Leholeh saya?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Anda bisa membersihkan cache pada aplikasi Leholeh. Pastikan koneksi internet yang Anda gunakan stabil.</p>
                    <p>Lakukan hal di bawah sebagai Langkah awal penanganan kendala dengan cara berikut. Setelah melakukan Langkah-langkah di atas, silahkan logout lalu login kembalik ke aplikasi Leholeh dan coba secara berkala. Jika masih menemukan kendala, Anda dapat menghubungi Customer Service disini.</p>                 
                    <strong>Catatan:</strong><br/>
                    Berikut contoh-contoh kendala pada aplikasi Leholeh:
                    <ol>
                    <li>Muncul keterangan error pada halaman aplikasi</li>
                    <li>Halaman aplikasi tidak menampilkan gambar (blank page)</li>
                    <li>Tidak bisa memilih metode pembayaran</li>
                    <li>Halaman aplikasi tidak terbuka</li>
                    <li>Tidak bisa memasukkan produk ke keranjang belanja</li>
                    </ol>
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa produk saya tidak muncul di toko saat dicari?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Jika produk yang sudah di-upload atau di-update tidak muncul di toko atau pada menu pencarian, <strong>mohon menunggu dalam estimasi waktu 2x24 jam terhitung sejak produk pertama kali di-upload atau di-update. Hal ini dikarenakan Leholeh menerapkan sistem Quality Control pada setiap produk yang di-upload oleh Penjual untuk memastikan tidak adanya pelanggaran.</strong> </p>
                    <p>Apabila produk yang di-upload atau di-update lolos dari proses Quality Control, maka produk Anda akan muncul di aplikasi Leholeh dalam estimasi waktu 2x24 jam. Namun, apabila produk yang di-upload atau di-update tidak lolos dari proses Quality Control, maka Anda akan mendapatkan notifikasi bahwa produk tersebut dihapus atau diblokir karena tidak sesuai dengan kebijakan produk Leholeh.</p>                 
                    <p>Sebagai langkah awal penanganan kendala, Anda dapat mengikuti panduan ini.</p>
                    Apabila masih terjadi kendala setelah lebih dari 2x24 jam, silakan hubungi Customer Service Leholeh <a href="https://wa.me/6281288883745" target="_blank">disini</a> dengan melampirkan data berikut:
                    <ul>
                        <li>Username: </li>
                        <li>Nomor ID Produk: </li>
                        <li>Nama produk:</li>
                        <li>Kata pencarian yang digunakan: </li>
                    </ul>
                    <strong>Catatan:</strong><br/>
                    Pastikan Anda sudah:
                    <ul>
                        <li>Mengaktifkan jasa kirim pada produk yang di-upload. Klik disini untuk informasi lebih lanjut.</li>
                        <li>Verifikasi no. handphone. Klik disini untuk informasi lebih lanjut.</li>
                        <li>Verifikasi identitas. Klik disini untuk informasi lebih lanjut.</li>
                    </ul>
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Apakah harga yang tercantum sudah termasuk ongkos kirim?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Untuk harga yang tercantum belum termasuk ongkos kirim. Apabila Anda mengaktifkan Termasuk Ongkos Kirim, disarankan untuk memberikan informasi biaya pengiriman di deskripsi produk untuk menghindari adanya kesalahpahaman.</p>
                    <p>Apabila Anda sudah mempunyai fitur jasa kirim, maka ongkos kirim akan dikalkulasi berdasarkan berat produk dan lokasi alamat penjual dan pembeli. Ongkos kirim ini akan terlihat saat checkout beserta jumlah total pesanan yang harus dibayar.</p>               
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Mengapa produk saya dihapus atau diblokir oleh Leholeh?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Beberapa alasan produk Anda dihapus atau diblokir oleh Leholeh:</p>
                    <ol>
                        <li>Melanggar hukum dan peraturan pemerintah</li>
                        <li>Melanggar kebijakan Leholeh</li>
                        <li>Produk Spam</li>
                        <li>Informasi yang tidak lengkap dan pelanggaran lain</li>
                        <li> Transaksi lintas batas</li>
                    </ol>     
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>
                Dapatkah saya mengubah harga produk saya?
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <p>Anda dapat mengubah harga produk Anda kapanpun, tetapi apabila Pembeli telah memesan suatu produk, harga produk atas pesanan tersebut tidak dapat diubah.</p>
                </Typography>
                </AccordionDetails>
            </Accordion>
            
            
          
            
        </div>
    )
}
export default FAQ;
