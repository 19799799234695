import React, { useEffect, useState } from "react";
import DashboardLayout from '../DashboardLayout';
import MUIDataTable from "mui-datatables";
import {
  Button,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@material-ui/core";

function ModalBox(props) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    dname: "",
    dsource: ""
  });

  const handleChange = name => e => {
    setState({
      ...state,
      [name]: e.target.value
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div > 
      <Button variant="contained" color="default" size="small" onClick={handleClickOpen} style={{marginBottom:'10px'}}>
        Buat Akun Bank
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Buat Akun Bank</DialogTitle>
        <DialogContent>
          <Select
            native
            fullWidth
            value={state.dsource || ""}
            onChange={handleChange("dsource")}
          >
            <option value="" />
            <option value={"bca"}>Bank Mandiri</option>
            <option value={"bri"}>Bank BRI</option>
            <option value={"bri"}>Bank BCA</option>
          </Select>

          <TextField
            autoFocus
            margin="dense"
            id="norek"
            label="Nomor Rekening"
            type="number"
            value={state.dnorek || ""}
            onChange={handleChange("dnorek")}
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            id="accname"
            label="Account Name"
            type="text"
            value={state.dname || ""}
            onChange={handleChange("daccname")}
            fullWidth
          />

          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.addDataSource(state.dname, state.dsource);
              setOpen(false);
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function BankAccount() {
  const columns = ["#", "Bank", "Nomor Rekening","Nama Akun", "Status", "Tgl Buat"];
  const [data, setData] = useState([]);

  let id = 0;
  function createData(bank, norek, accName, status, createdDate) {
    id += 1;
    return [id, bank, norek, accName, status, createdDate];
  }

  useEffect(() => {
    const data = [
      createData("Bank BCA", "212220029", "Ahmad Suhaili", "Active", "2021-05-20"),
      createData("Bank Mandiri", "127820029902", "Ahmad Suhaili", "Non Active", "2021-05-15"),
    ];
    setData(data);
  }, []);

  const options = {
    filterType: "checkbox"
  };

  const addDataSource = (dnorek,daccname, dsource) => {
    const updated = [...data];
    updated.push(createData(dnorek,daccname, dsource));
    setData(updated);
  };

  return (
    <React.Fragment>
         <DashboardLayout>
          <div style={{width:'100%'}}>
            <ModalBox
              addDataSource={(dnorek,daccname, dsource) => addDataSource(dnorek,daccname, dsource)}
            />
            <MUIDataTable
              title={"Akun Bank"}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
        </DashboardLayout>
    </React.Fragment>
  );
}
export default BankAccount;
// import React from "react";
// import MUIDataTable from "mui-datatables";
// import CustomToolbar from "../../components/CustomToolbar";
// import Button from '@material-ui/core/Button';
// import DashboardLayout from '../DashboardLayout';

// class Example extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       data: [ 
//           ["1","Bank BCA", "218822902", "aleeas", "Active", "2021-05-20"]
//     ]
//     };
//   }

//   render() {
    
//     const columns = [     
//       {
//         name: "#",
//         options: {
//           filter: false,
//         }
//       },
//       {
//         name: "Bank",
//         options: {
//           filter: true,
//         }
//       },
//       {
//         name: "Nomor Rekening",
//         options: {
//           filter: false,
//         }
//       },
//       {
//         name: "Nama Akun",
//         options: {
//           filter: true,
//         }
//       },
//       {
//         name: "Status",
//         options: {
//           filter: true,
//           sort: false,
//         }
//       },
//       {
//         name: "Tgl Buat",
//         options: {
//           filter: true,
//         }
//       },
//       {
//         label: "Actions",
//         options: {
//           customBodyRenderLite: (dataIndex, rowIndex) => {
//             return (
//                 <div>
//                      <Button style={{margin:'0 5px'}} variant="contained" color="primary" onClick={() => window.alert(`Clicked "Edit" for row ${rowIndex} with dataIndex of ${dataIndex}`)}>
//                         Edit
//                     </Button>

//                     <Button variant="contained" color="secondary" onClick={() => {
//                         const { data } = this.state;
//                         data.shift();
//                         this.setState({ data });
//                     }}>
//                         Delete
//                     </Button> 
//                 </div>
                  
//             );
//           },
//         }
//     }
//     //   {
//     //     name: "Add",
//     //     options: {
//     //       filter: false,
//     //       sort: false,
//     //       empty: true,
//     //       customBodyRenderLite: (dataIndex) => {
//     //         return (
//     //             <button onClick={() => {
//     //                 const { data } = this.state;
//     //                 data.unshift(["Mason Ray", "Computer Scientist", "San Francisco", 39, "$142,000"]);
//     //                 this.setState({ data });
//     //               }}>
//     //                 Add
//     //               </button>
//     //         );
//     //       }
//     //     }
//     //   },
//     //   {
//     //     name: "Edit",
//     //     options: {
//     //       filter: false,
//     //       sort: false,
//     //       empty: true,
//     //       customBodyRenderLite: (dataIndex, rowIndex) => {
//     //         return (
//     //           <button onClick={() => window.alert(`Clicked "Edit" for row ${rowIndex} with dataIndex of ${dataIndex}`)}>
//     //             Edit
//     //           </button>
//     //         );
//     //       }
//     //     }
//     //   },      
//     //   {
//     //     name: "Delete",
//     //     options: {
//     //       filter: false,
//     //       sort: false,
//     //       empty: true,
//     //       customBodyRenderLite: (dataIndex) => {
//     //         return (
//     //             <button onClick={() => {
//     //                 const { data } = this.state;
//     //                 data.shift();
//     //                 this.setState({ data });
//     //               }}>
//     //                 Delete
//     //               </button>              
//     //         );
//     //       }
//     //     }
//     //   },
      
//     ];

//     const data1 = [
//       {Name: "Gabby George", Title: "Business Analyst", Location: "Minneapolis", Age: 30, Salary: "$100,000"},
//       {Name: "Aiden Lloyd", Title: "Business Consultant", Location: "Dallas", Age: 55, Salary: "$200,000"},
//       {Name: "Jaden Collins", Title: "Attorney", Location: "Santa Ana", Age: 27, Salary: "$500,000"},
//       {Name: "Franky Rees", Title: "Business Analyst", Location: "St. Petersburg", Age: 22, Salary: "$50,000"},
//       {Name: "Aaren Rose", Title: "Business Consultant", Location: "Toledo", Age: 28, Salary: "$75,000"},
//       {Name: "Blake Duncan", Title: "Business Management Analyst", Location: "San Diego", Age: 65, Salary: "$94,000"},
//       {Name: "Frankie Parry", Title: "Agency Legal Counsel", Location: "Jacksonville", Age: 71, Salary: "$210,000"},
//       {Name: "Lane Wilson", Title: "Commercial Specialist", Location: "Omaha", Age: 19, Salary: "$65,000"},
//       {Name: "Robin Duncan", Title: "Business Analyst", Location: "Los Angeles", Age: 20, Salary: "$77,000"},
//       {Name: "Mel Brooks", Title: "Business Consultant", Location: "Oklahoma City", Age: 37, Salary: "$135,000"},
//       {Name: "Harper White", Title: "Attorney", Location: "Pittsburgh", Age: 52, Salary: "$420,000"},
//       {Name: "Kris Humphrey", Title: "Agency Legal Counsel", Location: "Laredo", Age: 30, Salary: "$150,000"},
//       {Name: "Frankie Long", Title: "Industrial Analyst", Location: "Austin", Age: 31, Salary: "$170,000"},
//       {Name: "Brynn Robbins", Title: "Business Analyst", Location: "Norfolk", Age: 22, Salary: "$90,000"},
//       {Name: "Justice Mann", Title: "Business Consultant", Location: "Chicago", Age: 24, Salary: "$133,000"},
//       {Name: "Addison Navarro", Title: "Business Management Analyst", Location: "New York", Age: 50, Salary: "$295,000"},
//       {Name: "Jesse Welch", Title: "Agency Legal Counsel", Location: "Seattle", Age: 28, Salary: "$200,000"},
//       {Name: "Eli Mejia", Title: "Commercial Specialist", Location: "Long Beach", Age: 65, Salary: "$400,000"},
//       {Name: "Gene Leblanc", Title: "Industrial Analyst", Location: "Hartford", Age: 34, Salary: "$110,000"},
//       {Name: "Danny Leon", Title: "Computer Scientist", Location: "Newark", Age: 60, Salary: "$220,000"},
//       {Name: "Lane Lee", Title: "Corporate Counselor", Location: "Cincinnati", Age: 52, Salary: "$180,000"},
//       {Name: "Jesse Hall", Title: "Business Analyst", Location: "Baltimore", Age: 44, Salary: "$99,000"},
//       {Name: "Danni Hudson", Title: "Agency Legal Counsel", Location: "Tampa", Age: 37, Salary: "$90,000"},
//       {Name: "Terry Macdonald", Title: "Commercial Specialist", Location: "Miami", Age: 39, Salary: "$140,000"},
//       {Name: "Justice Mccarthy", Title: "Attorney", Location: "Tucson", Age: 26, Salary: "$330,000"},
//       {Name: "Silver Carey", Title: "Computer Scientist", Location: "Memphis", Age: 47, Salary: "$250,000" },
//       {Name: "Franky Miles", Title: "Industrial Analyst", Location: "Buffalo", Age: 49, Salary: "$190,000"},
//       {Name: "Glen Nixon", Title: "Corporate Counselor", Location: "Arlington", Age: 44, Salary: "$80,000"},
//       {Name: "Gabby Strickland", Title: "Business Process Consultant", Location: "Scottsdale", Age: 26, Salary: "$45,000"},
//       {Name: "Mason Ray", Title: "Computer Scientist", Location: "San Francisco", Age: 39, Salary: "$142,000"}
//     ];

//     const options = {
//       filter: true,
//       filterType: 'dropdown',
//       responsive: 'vertical',
//       onColumnSortChange: (changedColumn, direction) => 
//       onChangeRowsPerPage: numberOfRows => 
//       onChangePage: currentPage => 
//       customToolbar: () => {
//         return (
//           <CustomToolbar />
//         );
//       }
//     };
   

//     return (
//       <React.Fragment>
//         <DashboardLayout>
//           <div style={{width:'100%'}}>
//             <MUIDataTable title={"Akun Bank"} data={this.state.data} columns={columns} options={options} />
//           </div>
//         </DashboardLayout>
//       </React.Fragment>
     
//     );

//   }
// }

// export default Example;