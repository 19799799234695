import React, { useState} from "react";
import axios from 'axios';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BannerRegis1 from '../img/slide-reg-1.png';
import BannerRegis2 from '../img/slide-reg-2.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from "react-google-login";
import GoogleIcon from '../img/google.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../App.css';
import ReactPixel from 'react-facebook-pixel';
import Slider from "react-slick";
import { baseUrl } from "utils";

const settings = {
  dots: false,
  infinite: true,
  arrows:false,
  autoplay:true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
      arrows:false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};


const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px',
    marginTop:'30px'
  },
  sectionDesktop: {
    display: 'none',
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },   
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  ReactPixel.init('829256154386906', {}, { debug: true, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'ViewContent');
  
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState(''); 

  const MySwal = withReactContent(Swal);
  const [login, setLogin] = useState(false);
  const [facebookProvider, setFacebookProvider] =  useState("facebook");

  const responseFacebook = (response) => {
    const tokenFacebook =  response.accessToken;
    const emailFacebook =  response.email; 

    axios.post(`${baseUrl}/auth/social`, {
            provider:facebookProvider,
            token:tokenFacebook,
            email:emailFacebook
        }).then(result => {        
           
            const dataValidasi = result.data.data.is_registered;
            if( dataValidasi === true){
              MySwal.fire({
                icon: 'error',
                title: "Email sudah terdaftar"
              }).then(()=>{
                window.location.reload();
              })
            }else{
              MySwal.fire({
                icon: 'success',
                title: result.data.message
              }).then(()=>{
                history.push('/reg/social',{params: {
                  email:emailFacebook,
                  provider:facebookProvider,
                  token:tokenFacebook
                }})
          
              })
            }
            
        }).catch(e => {
            // setIsError(true);
            MySwal.fire({
              icon: 'error',
              title: e.response.data.message
            })
        });

  }
  const [googleProvider, setGoogleProvider] =  useState("google");

  const responseGoogle = response => {
    const tokenGoogle = response.accessToken;
    const emailGoogle = response.profileObj.email;
    
    axios.post(`${baseUrl}/auth/social`, {
            provider:googleProvider,
            token:tokenGoogle,
            email:emailGoogle
        }).then(result => {        
           
            const dataValidasi = result.data.data.is_registered;
            if( dataValidasi === true){
              MySwal.fire({
                icon: 'error',
                title: "Email sudah terdaftar"
              }).then(()=>{
                window.location.reload();
              })
            }else{
              MySwal.fire({
                icon: 'success',
                title: result.data.message
              }).then(()=>{
                history.push('/reg/social',{params: {
                  email:emailGoogle,
                  provider:googleProvider,
                  token:tokenGoogle
                }})
          
              })
            }
            
        }).catch(e => {
            // setIsError(true);
            MySwal.fire({
              icon: 'error',
              title: e.response.data.message
            })
        });
  };

  const handleSubmit = async e => {   
    e.preventDefault();  
    await axios.post(`${baseUrl}/auth/check`, {
        username
    }).then((result) => {
      
      MySwal.fire({
        icon: 'success',
        title:"Kami telah mengirim kode OTP ke email anda, silahkan check email / hp anda!"
      }).then((result)=>{
        // window.location.href="/reg/verify"
        history.push('/reg/verify?username=' + username,{params: username})
      })
        
    }).catch(e => {
        
        MySwal.fire({
          icon: 'warning',
          title: e.response.data.message,
        })
    });    
    // await axios.post(`${baseUrl}/auth/register`, {
    //     name,
    //     username,
    //     password,
    //     sex,
    //     birthdate
    // }).then(result => {
    //     if (result.status === 200) {
    //       MySwal.fire({
    //         icon: 'success',
    //         title: result.data.message
    //       })
    //       window.location.href="/"
    //     } 
        
    // }).catch(e => {
    //     
    // }); 
   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <Slider {...settings}>
          <div>
            <img src={BannerRegis1} width="100%"/>
          </div>
          <div>
            <img src={BannerRegis2} width="100%"/>
          </div>
        </Slider>
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
          <div className={classes.sectionDesktop}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>   
          </div>     
            <form className={classes.form} onSubmit={handleSubmit} noValidate >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email / No HP"
                name="username"
                autoComplete='off'
                onChange={e => setUsername(e.target.value)}
                autoFocus
              />
             
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Daftar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot/password" variant="body2">
                    Lupa password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"Sudah punya akun? Login sekarang"}
                  </Link>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6}>
                  {/* <div style={{marginTop:'20px'}}> {!login &&
                      <FacebookLogin
                        appId="1463660717345120"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={responseFacebook}
                        render={renderProps => (
                          <Button 
                          onClick={renderProps.onClick} 
                          fullWidth
                          style={{background:'#0e15e0',color:'#fff', fontSize:'11px'}}
                          startIcon={<FacebookIcon/>}
                          >
                              Facebook
                          </Button>
                        )}
                      />
                    }
                  </div> */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{marginTop:'20px'}}>
                  <GoogleLogin
                    clientId="129876100405-hlq4a0sehsm3g4442epqheedr5bbms8a.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    render={renderProps => (
                      <Button
                      variant="contained"
                      style={{background:'#fff', fontSize:'11px'}}
                      fullWidth
                      onClick={renderProps.onClick}
                      >
                        <img src={GoogleIcon} width="15" style={{marginRight:'5px'}}/> Google
                      </Button>
                      )}
                    onFailure={responseGoogle}                   
                  />
                  </div>
                </Grid>
              </Grid>
              <p style={{textAlign:'center'}}> Dengan mendaftar, saya menyetujui <br/>
                <a href="/terms">Syarat dan Ketentuan</a> serta <a href="/privacy">Kebijakan Privasi</a></p>
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}

 {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="fullname"
                label="Nama Lengkap"
                name="fullname"
                autoComplete="fullname"
                onChange={e => setName(e.target.value)}
                autoFocus
              />
               <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={e => setUsername(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <div className={classes.sexState}>
              <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Jenis Kelamin</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sex}
                    onChange={handleChange}
                    >
                    <MenuItem value={"m"}>Laki-Laki</MenuItem>
                    <MenuItem value={"f"}>Perempuan</MenuItem>
                    </Select>
                </FormControl>
              </div>
              <div className={classes.sexState}>
                <form className={classes.formControl} noValidate>
                <TextField
                    id="date"
                    label="Tanggal Lahir"
                    type="date"
                    defaultValue="2004-05-05"
                    className={classes.textField}
                    onChange={handleChangeDate}                    
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </form>
                </div>

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="I agree to the terms and conditions and the privacy policy"
              /> */}
