/*global google*/
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { GoogleMap, StandaloneSearchBox, Marker, LoadScript } from "@react-google-maps/api";

const lib = ["places"];
const key = "AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM"; 
// const key = "AIzaSyCSwuS4b3Zr--DbsjZtzq276wq1g4vIw1I";
let markerArray = [];

// function Map(){
//   const [currentLocation, setcurrentLocation]= useState({ lat: 0, lng: 0 });
//   const [markers, setMarkers] = useState([]);
//   const [zoomPlace, setzoomPlace] = useState(10);
//   const [bounds, setBounds] = useState(null);
//   const inputRef = useRef();

//   const onMapLoad = map => {
//     navigator?.geolocation.getCurrentPosition(
//       ({ coords: { latitude: lat, longitude: lng } }) => {
//         const pos = { lat, lng };
//         setcurrentLocation(pos);
//       }
//     );
//     google.maps.event.addListener(map, "bounds_changed", () => {
//       
//       setBounds(map.getBounds());
//       // this.setState({ bounds: map.getBounds()});
//     });
//   };

//   const onSBLoad = () => {
//     this.searchBox = inputRef.current.focus();
//   };

//   const onPlacesChanged = () => {
//     markerArray = [];
//     let results = this.searchBox.getPlaces();
//     for (let i = 0; i < results.length; i++) {
//       let place = results[i].geometry.location;
//       markerArray.push(place);
//     }
//     setMarkers(markerArray);
//     // 
//   };


//   return (
//     <LoadScript googleMapsApiKey={key} libraries={lib}>
//       <div>
//         <div id="searchbox" style={{position:'relative'}}>
       
//           <StandaloneSearchBox
//             onLoad={onSBLoad}          
//             onPlacesChanged={onPlacesChanged}
//             bounds={bounds}
//           >
//             <input
//               type="text"
//               placeholder="Customized your placeholder"
//               ref={inputRef}
//               style={{
//                 boxSizing: `border-box`,
//                 border: `1px solid transparent`,
//                 width: `240px`,
//                 height: `32px`,
//                 padding: `0 12px`,
//                 borderRadius: `3px`,
//                 boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//                 fontSize: `14px`,
//                 outline: `none`,
//                 textOverflow: `ellipses`,
//                 position: "relative",
//                 zIndex:'2',
//                 top:"33%",
//                 left: "50%",
//               }}
//             />
//           </StandaloneSearchBox>
//         </div>
//         <br />
//         <div>
//           <GoogleMap
//             center={currentLocation}
//             zoom={zoomPlace}            
//             scrollWheelZoom={true}
//             onLoad={map => onMapLoad(map)}
//             mapContainerStyle={{ height: "400px", width: "100%" }}
//           >
//             {markers.map((mark, index) => (
//               <Marker key={index} position={mark} />
//             ))}
//           </GoogleMap>
//         </div>
//       </div>
//       </LoadScript>
//     );
// }
class Map extends React.Component {
  state = {
    currentLocation: { lat: 0, lng: 0 },
    markers: [],
    zoomPlace :10,
    bounds: null
  };

  onMapLoad = map => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        this.setState({ currentLocation: pos });
      }
    );
    google.maps.event.addListener(map, "bounds_changed", () => {
      
      this.setState({ bounds: map.getBounds()});
    });
  };

  onSBLoad = ref => {
    this.searchBox = ref;
  };

  onPlacesChanged = () => {
    markerArray = [];
    let results = this.searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);
    }
    this.setState({ 
      markers: markerArray
    });
    // 
  };

  render() {
    return (
    <LoadScript googleMapsApiKey={key} libraries={lib}>
      <div>
        <div id="searchbox" style={{position:'relative'}}>
       
          <StandaloneSearchBox
            onLoad={this.onSBLoad}
            onPlacesChanged={this.onPlacesChanged}
            bounds={this.state.bounds}
          >
            <input
              type="text"
              placeholder="Customized your placeholder"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                zIndex:'2',
                top:"30px",
                left: "50%",
              }}
            />
          </StandaloneSearchBox>
        </div>
        <br />
        <div>
          <GoogleMap
            center={this.state.currentLocation}
            zoom={this.state.zoomPlace}
            scrollWheelZoom={true}
            onLoad={map => this.onMapLoad(map)}
            mapContainerStyle={{ height: "400px", width: "100%" }}
          >
            {this.state.markers.map((mark, index) => (
              <Marker key={index} position={mark} />
            ))}
          </GoogleMap>
        </div>
      </div>
      </LoadScript>
    );
  }
}

export default Map;
// import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
// import { MapContainer, TileLayer, useMap, useMapEvents, Marker, Popup } from "react-leaflet";
// // import { geosearch } from 'esri-leaflet-geocoder';
// import "leaflet/dist/leaflet.css";
// import "leaflet-geosearch/dist/geosearch.css";
// import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
// import markerIconPng from "leaflet/dist/images/marker-icon.png"
// import {Icon} from 'leaflet'

// import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

// import icon from "./constants";

// // Cordinates of Marcillac
// const center = [-6.403273208937583, 106.79890710572879];
// const purpleOptions = { color: "white" };


// function LeafletgeoSearch() {
//   const map = useMap();
//   useEffect(() => {
//     const provider = new OpenStreetMapProvider();
//     const searchControl = new GeoSearchControl({
//       provider,
//       marker: {
//         icon
//       },
//       keepResult:true,  
//     });
//     map.addControl(searchControl);
    
//     // map.on('results', handleOnSearchResuts)
//     // searchControl.on('results', handleOnSearchResuts);
//     // return () => {
//     //   map.off('results', handleOnSearchResuts);
//     //   map.removeControl(searchControl);
//     // }
//     return () => map.removeControl(searchControl);
//   }, []);

//   return null;
// }


// function LocationMarker() {
//   const [draggable, setDraggable] = useState(false)
//   const [position, setPosition] = useState(center)
//   const markerRef = useRef(null)
//   const eventHandlers = useMemo(
//     () => ({
//       dragend() {
//         const marker = markerRef.current
//         if (marker != null) {
//           setPosition(marker.getLatLng())
//         }
//         // 
//       },
//     }),
//     [],
//   )
//   const toggleDraggable = useCallback(() => {
//     setDraggable((d) => !d)
//   }, [])

//   const map = useMapEvents({
//     click() {
//       map.locate()
//     },
//     locationfound(e) {
//       setPosition(e.latlng)
//       map.flyTo(e.latlng, map.getZoom())
//     },
//   })
//   
//   return position === null ? null : (
//     <Marker
//     icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
//     draggable={draggable}
//     eventHandlers={eventHandlers}
//     position={position}
//     ref={markerRef}>
//     <Popup minWidth={90}>
//       <span onClick={toggleDraggable}>
//         {draggable
//           ? 'Marker is draggable'
//           : 'Click here to make marker draggable'}
//       </span>
//     </Popup>
//   </Marker>  
//   )
// }

// class MapWrapper extends React.Component {
  
//   render() {

//     return (
//       <div id="mapid" style={{position:'relative', zIndex:0}}>
//         <MapContainer          
//           center={center}
//           zoom={13}
//           scrollWheelZoom={true}
//           style={{ height: "400px" }}
//         >
//           <TileLayer
//             attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
//             url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
//           />
//           <LeafletgeoSearch />
//           <LocationMarker />
//         </MapContainer>
//       </div>
//     );
//   }
// }

// export default MapWrapper;

// // import React, { useState, useRef } from 'react'
// // import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
// // import { makeStyles } from '@material-ui/styles'

// // const useStyles = makeStyles({
// //   mapDiv: {
// //     height: 400,
// //   },
// // })

// // const EventsExample = () => {
// //   const mapRef = useRef(null)
// //   const classes = useStyles()
// //   const [zoom, setZoom] = useState(13)
// //   const [hasLocation, setHasLocation] = useState(false)
// //   const [latlng, setLatlng] = useState({ lat: -6.403273208937583, lng: 106.79890710572879 })

// //   const handleClick = () => {
// //     const map = mapRef.current

// //     if (map !== null) {
// //       map.leafletElement.locate()
// //     }
// //   }

// //   const handleLocationFound = (e) => {
// //     setHasLocation(true)
// //     setLatlng(e.latlng)
// //   }

// //   const marker = hasLocation ? (
// //     <Marker position={latlng}>
// //       <Popup>You are here</Popup>
// //     </Marker>
// //   ) : null

// //   return (
// //     <div style={{margin:'6em', maxWidth:'600px'}}>
// //         <MapContainer
// //           length={4}
// //           zoom={zoom}
// //           ref={mapRef}
// //           center={latlng}
// //           onClick={handleClick}
// //           style={{height:'400px', width:'400px'}}
// //           onLocationfound={handleLocationFound}
// //         >
// //           <TileLayer
// //             attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
// //             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// //           />
// //           {marker}
// //         </MapContainer>
// //     </div>
    
// //   )
// // }

// // export default EventsExample

// // /*global google*/
// // import ReactDOM from "react-dom";
// // import React from "react";

// // import { GoogleMap, StandaloneSearchBox, Marker, LoadScript } from "@react-google-maps/api";

// // const lib = ["places"];
// // const key = "AIzaSyAwmxz_aFf-wHIPEuJRNxRWgSMvtrkTeTM"; 
// // let markerArray = [];
// // class Map extends React.Component {
// //   state = {
// //     currentLocation: { lat: 0, lng: 0 },
// //     markers: [],
// //     zoomPlace :10,
// //     bounds: null
// //   };

// //   onMapLoad = map => {
// //     navigator?.geolocation.getCurrentPosition(
// //       ({ coords: { latitude: lat, longitude: lng } }) => {
// //         const pos = { lat, lng };
// //         this.setState({ currentLocation: pos });
// //       }
// //     );
// //     google.maps.event.addListener(map, "bounds_changed", () => {
// //       
// //       this.setState({ bounds: map.getBounds()});
// //     });
// //   };

// //   onSBLoad = ref => {
// //     this.searchBox = ref;
// //   };

// //   onPlacesChanged = () => {
// //     markerArray = [];
// //     let results = this.searchBox.getPlaces();
// //     for (let i = 0; i < results.length; i++) {
// //       let place = results[i].geometry.location;
// //       markerArray.push(place);
// //     }
// //     this.setState({ 
// //       markers: markerArray
// //     });
// //     // 
// //   };

// //   render() {
// //     return (
// //     <LoadScript googleMapsApiKey={key} libraries={lib}>
// //       <div style={{margin:'6em'}}>
// //         <div id="searchbox">
       
// //           <StandaloneSearchBox
// //             onLoad={this.onSBLoad}
// //             onPlacesChanged={this.onPlacesChanged}
// //             bounds={this.state.bounds}
// //           >
// //             <input
// //               type="text"
// //               placeholder="Customized your placeholder"
// //               style={{
// //                 boxSizing: `border-box`,
// //                 border: `1px solid transparent`,
// //                 width: `240px`,
// //                 height: `32px`,
// //                 padding: `0 12px`,
// //                 borderRadius: `3px`,
// //                 boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
// //                 fontSize: `14px`,
// //                 outline: `none`,
// //                 textOverflow: `ellipses`,
// //                 position: "absolute",
// //                 top:"20%",
// //                 left: "50%",
// //                 marginLeft: "-120px"
// //               }}
// //             />
// //           </StandaloneSearchBox>
// //         </div>
// //         <br />
// //         <div>
// //           <GoogleMap
// //             center={this.state.currentLocation}
// //             zoom={this.state.zoomPlace}
// //             scrollWheelZoom={true}
// //             onLoad={map => this.onMapLoad(map)}
// //             mapContainerStyle={{ height: "400px", width: "100%" }}
// //           >
// //             {this.state.markers.map((mark, index) => (
// //               <Marker key={index} position={mark} />
// //             ))}
// //           </GoogleMap>
// //         </div>
// //       </div>
// //       </LoadScript>
// //     );
// //   }
// // }

// // export default Map;
// // import React, { useEffect } from "react";
// // import { MapContainer, TileLayer, useMap } from "react-leaflet";
// // import "leaflet/dist/leaflet.css";
// // import "leaflet-geosearch/dist/geosearch.css";

// // import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

// // import icon from "./constants";

// // // Cordinates of Marcillac
// // const center = [-6.403273208937583, 106.79890710572879];
// // const purpleOptions = { color: "white" };

// // function LeafletgeoSearch() {
// //   const map = useMap();
// //   useEffect(() => {
// //     const provider = new OpenStreetMapProvider();

// //     const searchControl = new GeoSearchControl({
// //       provider,
// //       marker: {
// //         icon
// //       }      
// //     });

// //     map.addControl(searchControl);

// //     return () => map.removeControl(searchControl);
// //   }, []);

// //   return null;
// // }

// // class MapWrapper extends React.Component {
// //   render() {
// //     return (
// //       <div id="mapid" style={{margin:'6em'}}>
// //         <MapContainer
// //           center={center}
// //           zoom={13}
// //           scrollWheelZoom={true}
// //           style={{ height: "400px" }}
// //         >
// //           <TileLayer
// //             attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
// //             url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
// //           />
// //           <LeafletgeoSearch />
// //         </MapContainer>
// //       </div>
// //     );
// //   }
// // }

// // export default MapWrapper;






