import React,{useEffect} from 'react';
import DashboardLayout from '../../DashboardLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  productBox:{
      border: '4px solid #ddd',
      width:'100%',
      marginBottom:'10px'
  },
  merchStatus:{
    width:'50px', 
    margin:'10px 10px 10px 65px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  merchFsize:{
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  merchJmlProd:{
    width:'50px', margin:'10px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  merchJmlPrice:{
    width:'50px', margin:'10px 10px 10px 32px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  merchVariant:{
    width:'50px', margin:'10px 10px 10px 60px',
    [theme.breakpoints.down('md')]: {
      margin: '10px',
    },
  },
  desktopOnly:{
      display: 'none !important',
      [theme.breakpoints.up('md')]: {
        display: 'block !important',
      },
  },
  mobileOnly:{
      display: 'flex',
      width:'100%',
      justifyContent:'space-between',
      borderBottom: '1px solid #ddd',
      padding: '10px 15px',
      alignItems:'center',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      }
  },
  boxMyOrder :{
      margin:'60px 5px 0',
      [theme.breakpoints.down('md')]: {
          margin: '0 15px 0px'
      },
  },
  BoxButton:{       
      [theme.breakpoints.up('md')]: {
          display:'flex',
          alignItems:'center'
      },
  },
  btnAction:{
      marginBottom:'10px', 
      marginRight:'10px', 
      width:'100%',
      fontSize:'12px',
      [theme.breakpoints.down('md')]: {
          width:'46%',
      }
  },
  btnActionDelivery:{
      marginBottom:'10px', 
      marginRight:'10px', 
      width:'100%',
      [theme.breakpoints.down('md')]: {
          width:'25%',
      }
  },
  paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 960,
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  image: {
      width: 128,
      height: 100,
      [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '100%',
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      width:'100%',
      objectFit:'cover',
      maxHeight: '100px',
      height:'100%'
    },
  subtitle1:{
      margin:'0px 6px 10px',
      height:'auto',
      [theme.breakpoints.down('sm')]: {
          margin:'5px 7px',
          height: '30px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
      }
    },
}));

export default function MerchantProduct(props){
    const classes = useStyles();    
    const settings = {
      dots: false,
      arrows:false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
      
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            dots: false,
            arrows:false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0,
            dots: false,
            arrows:false,
          }
        }
      ]
    };
    let pathname = window.location.pathname;
    useEffect(() => {
        pathname = window.location.pathname;
    }, [window.location.pathname]);

    return(
        <DashboardLayout>
                <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}>
                    <Typography color="textPrimary" variant="h6">
                        Daftar Pesanan
                    </Typography>                     
                </Box>
                <Box display="flex" width="100%" >
                <Slider {...settings} className="slideboxmenuMerchant">                   
                    <Link to="/merchant/new-order" className={`${pathname.match('/merchant/new-order') ? 'link-active' : ''}`}>
                        Pesanan Baru
                    </Link> 
                    <Link to="/merchant/packed" className={`${pathname.match('/merchant/packed') ? 'link-active' : ''}`}>
                        Pesanan Diproses
                    </Link>
                    <Link to="/merchant/delivered" className={`${pathname.match('/merchant/delivered') ? 'link-active' : ''}`}>
                        Pesanan Dikirim
                    </Link> 
                    <Link to="/merchant/accepted" className={`${pathname.match('/merchant/accepted') ? 'link-active' : ''}`}>
                        Pesanan Selesai
                    </Link> 
                    <Link to="/merchant/cancelled" className={`${pathname.match('/merchant/cancelled') ? 'link-active' : ''}`}>
                        Pesanan Dibatalkan
                    </Link> 
                </Slider>
                </Box>
             <Grid container spacing={1}>               
                <Box width="100%" style={{padding:'10px 10px'}}>
                    {props.children}
                </Box>
             </Grid>
            
        </DashboardLayout>
        
    )
}