import React, {useEffect,useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../App.css";
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import PhotoTemplate from '../img/broken-256.png';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import ProductEmpty from "../img/No Product (General).svg";
import Rating from '@material-ui/lab/Rating';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import StarIcon from '@material-ui/icons/Star';
import PaginationData from '../components/Pagination';
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainPage:{
      margin:'2em 0',
      [theme.breakpoints.up('md')]: {
          margin:'6em', padding:'2em'
        }
  }
}))


function SearchProductHome() {
    const location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(15);
    const [priceMin, setPriceMin]= useState();
    const [priceMax, setPriceMax]= useState();
    const [metaTotal , setMetaTotal] = useState();
    const imageDefault = PhotoTemplate.toString();
    const MySwal = withReactContent(Swal)
    const myparam = location.state.params;
    const [dataProductSearch, setDataProductSearch] = useState([]);
    const [sortValue, setSortValue] = useState('');
 

    const sortSubmit = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: { keyword:myparam, sorting:sortValue}
          })
      .then((response) => {          
          const dataResponse = response.data.data;
          setDataProductSearch(dataResponse);          
          const dataTotal = response.data.meta.total;
          const curPage = response.data.meta.current_page;
          setMetaTotal(dataTotal);
          
      });
    }

    const getDataPagingProduct = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
          headers: {
              "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },params: { keyword: myparam }
          })
      .then((response) => {          
          const dataResponse = response.data.data;
          setDataProductSearch(dataResponse);            
          const dataTotal = response.data.meta.total;
          setMetaTotal(dataTotal);
          
      });
    }


    const categoryProduct = [
      {
        name: 'food',
        lable: 'Kuliner'        
      },
      {
        name: 'souvenir',
        lable: 'Sovenir'        
      }
    ]

    const sortProduct = [
      {        
        label: 'Terbaru', 
        value: 'new'       
      },
      {
        label: 'Terlama',
        value: 'old'        
      },
      {
        label: 'Termurah',
        value: 'cheap'        
      },
      {
        label: 'Termahal',
        value: 'expensive'        
      },
      {
        label: 'Rating Tertinggi',
        value: 'popular'        
      }
    ]

    

    const [categoryName, setCategoryName] = useState([]);
    const [filteredCategoryName, setFilteredCategoryName] = useState("");

    const handleCategory = (e, value) => {
      if (e.target.checked) {
        setCategoryName([...categoryName, e.target.value]);
      } else {
        setCategoryName(categoryName.filter((name) => name !== e.target.value));
      }
    };


    const [provinceCode, setProvinceCode] = useState([]);
    const [filteredProvinceCode, setFilteredProvinceCode] = useState("");
    const handleChangeProvince = (e, value) =>{
      if (e.target.checked) {
        setProvinceCode([...provinceCode, e.target.value]);
      } else {
        setProvinceCode(provinceCode.filter((province_code) => province_code !== e.target.value));
      }
    }


    useEffect(()=>{
        if(sortValue !== ''){
          sortSubmit();
        }else{
          getDataPagingProduct();
        }
        provinceJson();

        if (categoryName.length === 0) {
          setFilteredCategoryName("");
        } else {
          setFilteredCategoryName(categoryName.join(","));
        }

        if (provinceCode.length === 0) {
          setFilteredProvinceCode("");
        } else {
          setFilteredProvinceCode(provinceCode.join(","));
        }
       
    },[currentPage, sortValue, categoryName, provinceCode])

    const [dataProvince, setDataProvince] = useState([]);
    const provinceJson = async () => { 
        axios.get(`/provinces.json`)
        .then((response)=>{
            
            const dataProv = response.data;
            setDataProvince(dataProv);
        })
    };

    // const [category, setCategory] = useState([]);
 
     

    const submitFilter = async () =>{
      try {
        await axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { price: priceMin+'-'+priceMax, keyword:myparam, category: filteredCategoryName, location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);            
            const dataTotal = response.data.meta.total;
            setMetaTotal(dataTotal);
            
        });
      } catch (error) {
        MySwal.fire({
          icon: 'warning',
          title: error.response.data.message
        })
      }
        
     
        
    }

    
    const submitPriceRange = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
        headers: {
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        },params: { price: priceMin+'-'+priceMax, keyword:myparam}
        })
    .then((response) => {          
        const dataResponse = response.data.data;
        setDataProductSearch(dataResponse);
        
        const dataTotal = response.data.meta.total;
        const curPage = response.data.meta.current_page;
        // setCurPages(curPage);
        setMetaTotal(dataTotal);
        
    });
    }

    const submitCategory = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { keyword:myparam, category: filteredCategoryName}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
            
        });
    }

    const submitLocation = () =>{
      axios.get(`${baseUrl}/product/search?page=${currentPage}`,{
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },params: { keyword:myparam, location:filteredProvinceCode}
            })
        .then((response) => {          
            const dataResponse = response.data.data;
            setDataProductSearch(dataResponse);
            
            const dataTotal = response.data.meta.total;
            const curPage = response.data.meta.current_page;
            // setCurPages(curPage);
            setMetaTotal(dataTotal);
            
        });
    }

  
   

      const NotFoundProduct = ProductEmpty.toString();

     

      

    return (
        <div className={classes.MainPage}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <div style={{marginBottom:'20px'}}>Hasil Pencarian <strong>"{myparam}"</strong></div>
            <div style={{marginRight:'10px'}}>
              <label>Urutkan : </label> 
                <select style={{padding:'3px 10px'}} onChange={(e)=>setSortValue(e.target.value)}>
                <option value="--Paling Sesuai--" disabled selected>--Paling Sesuai--</option>
                  {sortProduct.map(({ label, value}, index) => {
                    return ( 
                      <option value={value} key={index}>{label}</option>
                    )})}
                </select>
                
              </div>

            </div>
            
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Paper style={{padding:'10px'}}>
                        <h4 style={{margin:'0 0 10px'}}>Filter</h4>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Harga</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div>
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Min" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMin(e.target.value)}
                                fullWidth 
                                autoComplete="off"
                              /> 
                              <div style={{margin:'10px'}}></div> 
                              <TextField 
                                id="outlined-basic" 
                                label="Harga Max" 
                                variant="outlined" 
                                size="small" 
                                onChange={e=>setPriceMax(e.target.value)}
                                fullWidth 
                                autoComplete="off" 
                              />
                              </div>                              
                     
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitPriceRange()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Kategori</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            
                            {categoryProduct.map(({ name, lable}, index) => {
                              return ( 
                                <FormControlLabel
                                  key={index}
                                  control={
                                  <Checkbox
                                      id={`custom-checkbox-${index}`}
                                      value={name}
                                      onChange={handleCategory}
                                      name={name}
                                      color="primary"
                                  />
                                  }
                                  label={lable}
                              />
                              )})}
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitCategory()}>Terapkan</Button></div>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography >Lokasi</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                               
                                {dataProvince.map((prov,index)=>{
                                    return(
                                        <FormControlLabel
                                            key={index}
                                            style={{width:'100%'}}
                                            control={
                                            <Checkbox
                                                onChange={handleChangeProvince}
                                                name={prov.province_name}
                                                value={prov.province_code}
                                                color="primary"
                                            />
                                            }
                                            label={prov.province_name}
                                        />
                                    )
                                })}
                                
                                </div>
                                
                            </AccordionDetails>
                            <div><Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={()=>submitLocation()}>Terapkan</Button></div>
                        </Accordion>
                        
                        <Button variant="contained" color="secondary" fullWidth style={{marginTop:'10px',backgroundColor:'#ab0004'}} onClick={submitFilter}>Terapkan Semua</Button>

                        
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                     <Paper style={{padding:'10px'}}>
                       
                       <React.Fragment>
                              {dataProductSearch.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> : 
                                <div>
                                  <Grid container spacing={3}>
                                  {dataProductSearch.map((prdSearch, k) =>{
                                    return(
                                      
                                        <Grid item xs={12} md={3} key={k}>
                                            <Card>
                                            <Link to={{ pathname: "/product/" + prdSearch.slug }} style={{color:"#656565", textDecoration:'none'}}>
                                              <CardActionArea>
                                                    <img src={prdSearch.cover} width="100%" style={{maxHeight:'132px', minHeight:'132px', objectFit:'contain'}}/>
                                                    <div className="cardContent">
                                                    <p>
                                                        {prdSearch.name}
                                                    </p>
                                                    <Typography variant="body2"  component="p">
                                                        <div style={{fontSize:'16px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(prdSearch.price)}</strong></div>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        <span style={{fontSize:'12px'}}>{prdSearch.location.name}</span> <br />
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                          <StarIcon style={{color:'#FCCE00'}}/>
                                                          <span style={{fontSize:'13px', margin:'0 5px'}}>{prdSearch.rating}</span>
                                                        </div>
                                                    </Typography>
                                                    </div>
                                                </CardActionArea>
                                              </Link>
                                            </Card>
                                        </Grid>
                                   
                                    )
                                  })}
                                  </Grid>

                                  
                                </div>
                                
                              }

                            <PaginationData 
                              stateChanger={setcurrentPage} 
                              current={currentPage} 
                              limit={itemsPerPage} 
                              total_page={metaTotal} 
                              loadData={getDataPagingProduct}
                            />
                               
                            </React.Fragment>
                        {/* <TabPanel value={valueTab} index={1}>
                          <React.Fragment>
                          {dataProductSearch.length === 0 ? <div style={{textAlign:'center'}}><img src={NotFoundProduct} /></div> :
                            <div>
                               <Grid container spacing={3}>
                               {dataProductSearch.map((strSearch, k) =>{
                                   return(
                                       <Grid item xs={12} md={3} key={k}>
                                           <Card>
                                               <CardActionArea>
                                                   <img src={strSearch.merchant.photo === null ? imageDefault : strSearch.merchant.photo.uri} width="100%" style={{maxHeight:'132px'}}/>
                                                   <CardContent>
                                                   <Typography gutterBottom variant="body2" component="p" style={{height:'30px', overflow:'hidden'}}> 
                                                       {strSearch.merchant.name}
                                                   </Typography>
                                                   <Typography variant="body2"  component="p" style={{marginBottom:'20px'}}>
                                                       <div>{strSearch.merchant.description}</div>
                                                   </Typography>
                                                   <Typography variant="body2" component="p">
                                                       <span style={{fontSize:'12px'}}>{strSearch.merchant.location.address} {strSearch.merchant.location.subdistrict.name} {strSearch.merchant.location.city.name} {strSearch.merchant.location.province.name}</span> <br />
                                                      
                                                   </Typography>
                                                   </CardContent>
                                               </CardActionArea>
                                           </Card>
                                       </Grid>
                                   )
                               })}
                               </Grid>
                                <ul className="pageNumbers">
                                    <li>
                                            <button
                                            onClick={()=>handlePrevbtn(currentPage)}
                                            disabled={currentPage === pages[0] ? true : false}
                                            >
                                            Prev
                                            </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                            <button
                                            onClick={()=>handleNextbtn(currentPage)}
                                            disabled={currentPage == pages[pages.length - 1] ? true : false}
                                            >
                                            Next
                                            </button>
                                        </li>
                                </ul>
                           
                            </div>
                            }
                          </React.Fragment>
                        
                        </TabPanel> */}
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default SearchProductHome;