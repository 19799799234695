import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    bottom: '0',
    width: '100%',
    left:'0',
    zIndex:'99',
    boxShadow:'3px 3px 5px 6px #e8e8e8'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
  },
}));

function BreakpointUp(props) {
  const classes = useStyles();
  const pathname = window.location.pathname;
  const [value, setValue] = React.useState(pathname);

  return (
    <div className={classes.root}>
      <div className={classes.container}>   
        <Hidden mdUp>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction 
            label="Product Toko" 
            value="/merchant/product"
            icon={<StoreOutlinedIcon />}  
            component={Link}
            to="/merchant/product"/>
          <BottomNavigationAction 
            label="Pengiriman Toko" 
            value="/merchant/delivery"
            icon={<LocalShippingOutlinedIcon />} 
            component={Link}
            to="/merchant/delivery"
          />
          <BottomNavigationAction 
            label="Pesanan Toko" 
            value="/merchant/order"
            icon={<LocalMallOutlinedIcon />} 
            component={Link}
            to="/merchant/order"
          />
        </BottomNavigation>
        </Hidden>       
      </div>
    </div>
  );
}

BreakpointUp.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(BreakpointUp);
