import React, {useEffect, useState} from 'react';
import DashboardLayout from '../DashboardLayout';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Wallet from './MyWallet';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrl } from 'utils';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  TitleSaldo:{
    margin:'40px 0 0',
    [theme.breakpoints.down('md')]: {
      margin:'0'
  }
  }
}))
export default function Saldo() {
  const classes = useStyles();
  const [balance , setBalance] =  useState();
  const [bank, setBank] = useState();
  const [norek, setNorek] = useState();
  const [amount, setAmount] = useState();
  const [otp, setOtp] = useState(0);
  const [BankId, setBankId] = useState();
  const minWithdrawBank = 15000;
  const minGopay = 11000;
  const [bankCode, setBankcode] = useState();
  const settings = {
    dots: false,    
    infinite: false,
    speed: 500,
    autoplay:false,
    adaptiveHeight:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide : 0,
          arrows: false
        }
      }
    ]
  };

  useEffect(()=>{
    getBalance();
    getDataPayoutBank();
  },[])

  const getBalance = () =>{
    axios.get(`${baseUrl}/user/wallet`,{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {          
        
        const dataWallet = response.data.balance;
        setBalance(dataWallet);
        
      });
  }
const [payoutBanks, setpayoutBanks] = useState([]);
const getDataPayoutBank = () =>{
  axios.get(`${baseUrl}/data/payout-banks`,{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {          
        
        const dataPayout = response.data;
        setpayoutBanks(dataPayout);
        
      });
}
const [withdrawpay, setwithdrawpay] = useState(false);
const [otpSendPayBank, setotpSendPayBank] = useState(false);


const withdrawal = () =>{
  if(balance > minWithdrawBank){
    setwithdrawpay(true);
  }else{
    MySwal.fire({
      icon: 'warning',
      title: 'Maaf saldo anda tidak mencukupi !! Silakan periksa informasi penarikan '
    })
  }
}


const [overAmount, setoverAmount] = useState(false);
const [minPayout, setminPayout] = useState(false);
const [addBank, setAddBank] = useState(false);
const handleCloseAddBank = () =>{
  setAddBank(false);
}
const addAccBank =() =>{
  setAddBank(true);
}

useEffect(()=>{
  if(amount > balance ){
    setoverAmount(true);
  }
 
},[amount])

const handleCloseWithdrawal = () =>{
  setwithdrawpay(false);
}

const sendOTPVerify = () =>{ 
  if( amount < minGopay || amount < minWithdrawBank){
    setminPayout(true);
  }else{
    axios.post(`${baseUrl}/user/payout/otp`,{},{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {          
        
        setwithdrawpay(false);    
        MySwal.fire({
            icon: 'success',
            title: response.data.message
        }).then((result) => {
          setotpSendPayBank(true);        
        });
      })
  }
}
const [openAlertSuccess, setopenAlertSuccess]= useState(false);

const handleCloseSuccess = () =>{
  setopenAlertSuccess(false);
}
const submitHandlerOTP = () =>{

    axios.post(`${baseUrl}/user/payout/create`,{
      bank_id:BankId,
      amount:amount,
      password:otp
    },{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {   
       setotpSendPayBank(false);
        MySwal.fire({
            icon: 'success',
            title: response.data.message
        }).then(()=>{
          window.location.reload();
        })
      }).catch((err)=>{
        alert(err.response.data.message);
      })
  
 
}

const MySwal = withReactContent(Swal);


const [openFailure, setopenFailure]= useState(false);



const handleCloseAmount = () =>{
  setoverAmount(false);
}
const handleCloseMinpayment = () =>{
  setminPayout(false)
}
const handleCloseFailure = () =>{
  setopenFailure(false);
}

const [norekValid, setnorekValid] = useState(false);
const [dataRekValid, setdataRekValid] = useState([]);

const handleRek = () =>{
  axios.post(`${baseUrl}/user/bank/validate`,{
    bank:bank,
    account:norek,
    },{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
      })
    .then((response) => {          
        // 
        const dataValidId = response.data.data.id;
        setdataRekValid(response.data.data);       
        setAddBank(false); 
        MySwal.fire({
            icon: 'success',
            title: response.data.message
        }).then(()=>{
          axios.post(`${baseUrl}/user/bank/create`,{validation_id: dataValidId},{
              headers: {
                  "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
              }
            })
          .then((response) => {          
              
              MySwal.fire({
                  icon: 'success',
                  title: response.data.message
              }).then((result) => {
                window.location.reload();      
              });
            })
         
        })
      }).catch((err)=>{
        setAddBank(false); 
        MySwal.fire({
          icon: 'error',
          title: err.response.data.message
        }).then(()=>{
          window.location.reload();
        })
      });
  
}

const [norekBank, setnorekBank] = useState([])
useEffect(()=>{
  axios.get(`${baseUrl}/user/bank/list`,{
      headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    })
  .then((response) => {                
      const dataRekBank = response.data;
      setnorekBank(dataRekBank);
      console.log(dataRekBank)
      
    });
},[])

  return (
    <React.Fragment>
        <DashboardLayout>
        <div style={{padding:'25px', width:'100%'}}>
            <h3 className={classes.TitleSaldo}>Saldo</h3>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                  <div style={{margin:'20px 0'}}><strong>Informasi Saldo</strong></div>
                  <div>Total Saldo</div>
                  <div style={{fontSize:'18px'}}><strong>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(balance)} </strong></div>
                  <div>
                    <Button variant="outlined" size="small" style={{borderColor:'#ab0004', color:'#ab0004', fontSize:'11px',margin:'20px 0', padding:'3px 20px' , marginRight:'10px'}} onClick={withdrawal}>Tarik Saldo</Button>
                    <Button variant="outlined" size="small" style={{borderColor:'#ab0004', color:'#ab0004', fontSize:'11px',margin:'20px 0', padding:'3px 20px'}} onClick={addAccBank}>Tambah Akun Bank</Button>
                  </div>
                  <Slider {...settings} style={{textAlign:'center'}} className="walletSlider">
                    {norekBank.map((atm, indx)=>{
                      return(
                        <div key={indx}>
                          <div className="cardatm card--front">
                              <div className="card__logo" style={{textTransform:'capitalize'}}>Bank {atm.bank_name}</div>
                              
                              <div className="card__number"><div>Nomor Rekening </div>{atm.account_no}</div>   
                              <div className="card__owner">{atm.account_name}</div>                           
                            </div>
                          </div>
                      )
                    })}                   
                  </Slider>
              </Grid>
              <Grid item xs={12} md={6}>
                  <div style={{border:'1px solid #ddd', borderRadius:'5px', padding:'20px'}}>
                     <h4>Informasi Penarikan</h4>
                      <p>Minimum Penarikan</p>
                      <ol>
                        <li>Minimum penarikan saldo adalah Rp. 15.000 untuk penarikan menggunakan Transfer Bank dan OVO</li>
                        <li>Minimum penarikan saldo adalah Rp. 15.000 untuk penarikan menggunakan Gopay</li>
                      </ol>
                      <p>Biaya Admin</p>
                      <ol>
                        <li>Biaya admin untuk penarikan menggunakan Transfer Bank dan OVO adalah Rp. 5.000</li>
                        <li>Biaya admin untuk penarikan menggunakan Gopay adalah Rp. 1.000</li>
                      </ol>
                  </div>
                </Grid>
            </Grid>

            <div>
              <h3>Riwayat Saldo</h3>
              <div style={{ height: 400, width:'100%'}}>
                <Wallet/>
              </div>
            </div>
            
        </div>
          <Dialog open={addBank} aria-labelledby="form-dialog-title" fullWidth="true" maxWidth="sm">
            <DialogTitle id="form-dialog-title">
              Tambah Rekening Bank
            <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseAddBank}/></span>
            </DialogTitle>
            <DialogContent>
              <form validate onSubmit={(e)=>handleRek(e.preventDefault())}>
              <div style={{marginBottom:'15px'}}>
              <Autocomplete
                id="combo-box-demo"
                options={payoutBanks}
                disableClearable
                getOptionLabel={(option) => option.name}
                onChange={(e,v) => setBank(v.code)}
                renderInput={(params) => <TextField {...params} onChange={(e) => setBank(e.currentTarget.value)} fullWidth size="small" label="Pilih Bank" variant="outlined" />}
              />
              </div>
              <div style={{display:'flex', alignItems:'center',marginBottom:'15px'}}>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  id="norek"
                  label="Nomor Rekening"
                  type="number"
                  required
                  onChange={(e)=>setNorek(e.target.value)}
                  fullWidth
                />
                
              </div>
              <div style={{maxWidth:'200px', margin:'auto'}}>
                <Button variant="contained" size="small" type="submit" color="primary" fullWidth >Tambah</Button>
              </div>
              
             </form>
            </DialogContent>
          </Dialog>


          <Dialog open={withdrawpay} aria-labelledby="form-dialog-title" fullWidth="true" maxWidth="sm">
            <DialogTitle id="form-dialog-title">
              Penarikan Saldo
            <span style={{position:'absolute', right:'25px', cursor:'pointer'}}><ClearOutlinedIcon onClick={handleCloseWithdrawal}/></span>
            </DialogTitle>
            <DialogContent>
              <form validate onSubmit={(e)=>sendOTPVerify(e.preventDefault())}>
              <div style={{marginBottom:'15px'}}>
              <Autocomplete
                id="combo-box-demo"
                options={norekBank}
                disableClearable
                getOptionLabel={(option) => option.account_name}
                renderOption={(option) => (
                  <React.Fragment>                   
                   {option.account_no} {option.account_name}
                  </React.Fragment>
                )}
                onChange={(e,v) => {setBankId(v.id); setnorekValid(true); setBankcode(v.bank_name)}}
                renderInput={(params) => <TextField {...params} onChange={(e) => {setBank(e.currentTarget.value); setnorekValid(true)}} fullWidth size="small" label="Pilih Akun Bank" variant="outlined" />}
              />
              </div>
              {norekValid &&
                <React.Fragment>              
                <div style={{marginBottom:'15px'}}>
                  <TextField
                      autoFocus
                      variant="outlined"
                      margin="dense"
                      id="amount"
                      label="Jumlah Penarikan"
                      type="number"
                      InputProps={{
                        inputProps: { 
                          min: 5000 
                        }
                      }}
                      required
                      onChange={(e)=>setAmount(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div style={{marginBottom:'15px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    {amount > 0 && 
                    <React.Fragment>
                        <TextField                      
                          margin="dense"
                          id="amount"
                          label="Biaya Penarikan"
                          type="number"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          value={bankCode === 'gopay' ? 1000 : 5000}
                          style={{marginRight:'15px'}}
                        />
                  
                      <TextField                      
                          margin="dense"
                          id="amount"
                          label="Total Bersih"
                          type="number"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          value={amount - (bankCode === 'gopay' ? 1000 : 5000) }
                          
                        />
                    </React.Fragment>
                     }
                    </div>
                  </div>
                  </React.Fragment> 
                  }
                  <div style={{textAlign:'center'}}>
                                   
                  {amount <= balance && minGopay <= amount && minPayout <= amount ?
                    <Button type="submit" variant="outlined" style={{width:'200px'}}>Submit</Button>
                    :
                    <Button variant="outlined" disabled style={{width:'200px'}}>Submit</Button>
                  }

                  </div>


                   <Snackbar 
                      open={overAmount} 
                      autoHideDuration={1000}             
                      >
                      <Alert onClose={handleCloseAmount} severity="error">
                      Maaf, Saldo anda tidak mencukupi !!
                      </Alert>
                    </Snackbar>

                    <Snackbar 
                      open={minPayout} 
                      autoHideDuration={1000}             
                      >
                      <Alert onClose={handleCloseMinpayment} severity="error">
                      Maaf, Jumlah minimal penarikan anda tidak sesuai dengan ketentuan penarikan !!  Silakan periksa informasi penarikan 
                      </Alert>
                    </Snackbar>
                    

                    <Snackbar open={openFailure} autoHideDuration={6000} onClose={handleCloseFailure}>
                      <Alert onClose={handleCloseFailure} severity="error">
                      Nomor Rekening tidak valid
                      </Alert>
                    </Snackbar>


                    <Snackbar open={openAlertSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                      <Alert onClose={handleCloseSuccess} severity="error">
                      Kode OTP tidak valid ! 
                      </Alert>
                    </Snackbar>

                    
              
             </form>
            </DialogContent>
          </Dialog>


          <Dialog open={otpSendPayBank} aria-labelledby="form-dialog-title" disableBackdropClick="true">
            <DialogTitle id="form-dialog-title" style={{textAlign:'center',paddingBottom:'0'}}>
              Masukan Kode OTP yang dikirim ke No telepon anda            
            </DialogTitle>
            <DialogContent style={{textAlign:'center'}}>          
               
                    <div style={{marginBottom:'10px'}}>
                           <TextField
                                className="otp-field"
                                type="text"
                                name="otp"
                                fullWidth
                                variant="outlined"
                                onChange={e => setOtp(e.target.value)}
                            />
                    </div>
                    <div>                       
                        <Button type="submit" onClick={()=>submitHandlerOTP()} variant="outlined">
                            Verify OTP
                        </Button>
                    </div>
                
            </DialogContent>            
        </Dialog>      
        </DashboardLayout>
    </React.Fragment>
   
  );
}