import React,{useEffect, useState} from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import '../styles/styles.css';

export default function PaginationData({stateChanger, ...props}) {
    const {current, limit, total_page, loadData} = props;
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    
    useEffect(()=>{
        loadData();
    },[])

    const handleClick = (event) => {
      stateChanger(Number(event.target.id));      
      window.scrollTo(1000, 2000)  
    };
  
    const pages = [];
    for (let i = 1; i <= Math.ceil(total_page / limit); i++) {
      pages.push(i);
    }
    
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={current == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    const handleNextbtn = (current) => {

        stateChanger(current + 1);   
        if (current + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = (current) => {
        stateChanger(current - 1);
        if ((current - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }

    return (
        <ul className="pageNumbers">
              <li>
                <button
                  onClick={()=>handlePrevbtn(current)}
                  disabled={current === pages[0] ? true : false}
                >
                  <ChevronLeftIcon />
                </button>
              </li>
              {pageDecrementBtn}
              {renderPageNumbers}
              {pageIncrementBtn}

              <li>
                <button
                  onClick={()=>handleNextbtn(current)}
                  disabled={current == pages[pages.length - 1] ? true : false}
                >
                  <ChevronRightIcon />
                </button>
              </li>
            </ul>
    )
}
