import React,{useState,useEffect} from 'react';
import DashboardLayout from '../DashboardLayout';
import '../../App.css';
import {firestore} from '../../FirebaseConfig';
import { useLocation } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/id'
import ScrollToBottom from 'react-scroll-to-bottom';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  hidden: {
      display: "-webkit-box",
      WebkitLineClamp: 4,
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      [theme.breakpoints.up('md')]: {
          display: 'none !important',
        },
  },
  fsizeBox:{
      fontSize:'13px',
      [theme.breakpoints.down('md')]: {
          fontSize:'12px',
      },
  },
  statusProd:{
      width:'50px', 
      margin:'10px 10px 10px 60px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  uname:{
      width:'50px', 
      margin:'10px 10px 10px 50px'
  },
  jmlProd:{
      width:'50px', 
      margin:'10px'
  },
  hrgProd:{
      width:'50px', 
      margin:'10px 10px 10px 17px'
  },
  variantProd:{
      width:'50px', 
      margin:'10px 10px 10px 57px',
      [theme.breakpoints.down('md')]: {
          margin:'10px',
      },
  },
  productBox:{
      border: '4px solid #ddd',
      width:'100%',
      marginBottom:'10px'
  },
  desktopOnly:{
      display: 'block',
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
  },
  mobileOnlywthStyle:{
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
  },
  mobileOnly:{
      display: 'flex',
      width:'100%',
      justifyContent:'space-between',
      borderBottom: '1px solid #ddd',
      padding: '10px 15px',
      alignItems:'center',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      }
  },
  boxMyOrder :{
      margin:'60px 5px 0',
      [theme.breakpoints.down('md')]: {
          margin: '0 15px 0px'
      },
  },
  BoxButton:{       
      [theme.breakpoints.up('md')]: {
          display:'flex',
          alignItems:'center'
      },
  },
  btnAction:{
      marginBottom:'10px', 
      marginRight:'10px', 
      width:'100%',
      [theme.breakpoints.down('md')]: {
          width:'46%',
      }
  },
  
  paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 960,
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  image: {
      width: 128,
      height: 100,
      [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '100%',
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      width:'100%',
      objectFit:'cover',
      maxHeight: '100px',
      height:'100%'
    },
  subtitle1:{
      margin:'0px 6px 10px',
      height:'auto',
      [theme.breakpoints.down('sm')]: {
          margin:'5px 7px',
          height: '30px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
      }
    },
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '70vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '55vh',
    overflowY: 'auto'
  },
 
}));

function ChatComplain() {
  const classes = useStyles();
  const [discuss,setDiscuss]=useState([]);  
  const location = useLocation();
  const complUid = location.state.params; 
  const complain_uid = complUid.c_uid;
  const merchant_domain = complUid.c_user_id;
  const merchant_name = complUid.c_merchant_name;
  const merchant_photo = complUid.c_merchant_photo === null ? complUid.c_merchant_photo : complUid.c_merchant_photo.uri; 
  const user_name = complUid.c_user_name;
  const user_photo = complUid.c_user_photo;


  const [scm ,smChat] = useState();

  useEffect(()=>{
    getDiscuss();
  },[])

  const getDiscuss = () =>{
    firestore.collection(`chat`).doc(`${complain_uid}`).collection('messages').orderBy("date","asc")
    .onSnapshot((querySnapshot) => {
      var messagesKomplain = [];
      querySnapshot.forEach((doc) => {
        messagesKomplain.push(doc.data());
      });
      setDiscuss(messagesKomplain);

  })

    
  }

const handleChangeMessage = (val) =>{
  smChat(val);    
}

  const sendMessage =(event) =>{
    if(event.key === 'Enter'){
     
      firestore.collection(`chat/${complain_uid}/messages/`).add({
        message: scm, 
        type:"text", 
        date: moment().format(), 
        userid:merchant_domain, 
        status:'sent'
      })
      .then(msg => {
        smChat('');
        getDiscuss();
      })
      .catch(error => {
        
      })
    }
    
  }

 

  
  return (
        <DashboardLayout>
           <Box display="flex" justifyContent="space-between" width="100%" className={classes.boxMyOrder}></Box>
            <Grid container component={Paper} className={classes.chatSection}>  
           
                <Grid item xs={12}>
                    <List > 
                    <ScrollToBottom className={classes.messageArea}>  
                        {discuss.length !== 0 ? <>
                          {discuss.map((dsc, idx)=>{
                            return(                           
                            <div>
                              {dsc.userid !== merchant_domain ? 
                              <ListItem key={idx} style={{justifyContent:'start'}}>
                                <div>
                                     <Avatar alt={user_name} src={user_photo} />
                                </div>
                                <div className="talk-bubble-sender tri-right left-top" >
                                  <div>
                                    <div>{dsc.message}</div>
                                    <small>{moment(dsc.date).format("HH:mm")}</small>
                                  </div>
                                </div>
                              </ListItem> 
                              : 
                              <ListItem key={idx} style={{justifyContent:'end'}}>
                                <div className="talk-bubble-me tri-left left-top" >
                                 <div>
                                    <div>{dsc.message}</div>
                                    <small>{moment(dsc.date).format("HH:mm")}</small>
                                  </div>
                                </div>
                                <div>
                                     <Avatar alt={merchant_name} src={merchant_photo} />
                                </div>
                              </ListItem>
                              }
                              
                            </div>
                            )
                          })}
                          </>  : <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>Belum Ada Diskusi</div>}                    
                        </ScrollToBottom>
                        </List>
                    <Divider />
                    <Grid container style={{padding: '20px'}}>
                        <input 
                          type="text" 
                          onChange={(e)=>handleChangeMessage(e.target.value)} 
                          className="inputMessage"
                          placeholder="Chat disini"
                          onKeyPress={sendMessage}
                          value={scm}
                        />
                    </Grid>
                </Grid>
            </Grid>
          {/* <input type="text" onChange={(e)=>smChat(e.target.value)}/>
          <button >Send</button> */}
        </DashboardLayout>
  );
}

export default ChatComplain;