import React, { useState} from "react";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useLocation } from "react-router-dom";
import { baseUrl } from 'utils';

const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  const classes = useStyles();
  const [password, setPassword] = useState(''); 
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const dataObj = location.state.params;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async e => {   
    e.preventDefault();  
    await axios.post(`${baseUrl}/auth/password-change`, {
        username : dataObj.username,
        token: dataObj.token,
        password: password,
        password_confirmation: password,
    }).then((result) => {
     
      MySwal.fire({
        icon: 'success',
        title: "Password Berhasil di Reset Silahkan Login"
      }).then(()=>{
          window.location.href="/login"
      })
        
    }).catch(e => {
        
    });    

   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <img src={BgLogin} alt=""/> 
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>        
            <form className={classes.form} onSubmit={handleSubmit} noValidate >

            <TextField              
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={e => setPassword(e.target.value)}
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
               <TextField              
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="cpassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                id="cpassword"
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}


