import React, { useState } from "react";
import { Redirect, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useAuth } from "./Auth";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/Login.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from "react-google-login";
import GoogleIcon from '../img/google.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../App.css';
import { baseUrl } from 'utils';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://leholeh.id/">
        Leholeh
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  MainLogin: {
    backgroundColor: '#fff',
    marginBottom: '10px',
    marginTop: '30px'
  },
  sectionDesktop: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginTop: '60px'
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter: {
    textAlign: 'center',
  }
}));

export default function Login(props) {
  const classes = useStyles();
  const [isLoggedIn, setLoggedIn] = useState(false);
  // const [setIsError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();
  const history = useHistory();

  // const referer = props.location.state ? props.location.state.referer : '/';
  const MySwal = withReactContent(Swal);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // const [dataLoginFB, setDataLoginFB] = useState({});
  const [facebookProvider, setFacebookProvider] = useState("facebook");

  const responseFacebook = (response) => {
    const tokenFacebook = response.accessToken;
    const emailFacebook = response.email;


    axios.post(`${baseUrl}/auth/social`, {
      provider: facebookProvider,
      token: tokenFacebook,
      email: emailFacebook
    }).then(result => {

      MySwal.fire({
        icon: 'success',
        title: "Token Valid"
      }).then(() => {
        axios.post(`${baseUrl}/auth/social/login`, {
          email: emailFacebook,
          provider: facebookProvider,
          token: tokenFacebook
        }).then(result => {
          setAuthTokens(result.data.data.token);
          setLoggedIn(true);
          MySwal.fire({
            icon: 'success',
            title: "Login Berhasil ^_^"
          }).then((result) => {
            history.push('/')
          })


        }).catch(e => {
          // setIsError(true);
          MySwal.fire({
            icon: 'error',
            title: e.response.data.message
          })
        });

      })
    }).catch(e => {
      // setIsError(true);
      MySwal.fire({
        icon: 'error',
        title: e.response.data.message
      })
    });

  }
  const [googleProvider, setGoogleProvider] = useState("google");

  const responseGoogle = response => {

    const tokenGoogle = response.accessToken;
    const emailGoogle = response.profileObj.email;
    axios.post(`${baseUrl}/auth/social`, {
      provider: googleProvider,
      token: tokenGoogle,
      email: emailGoogle
    }).then(result => {

      MySwal.fire({
        icon: 'success',
        title: "Token Valid"
      }).then(() => {
        axios.post(`${baseUrl}/auth/social/login`, {
          email: emailGoogle,
          provider: googleProvider,
          token: tokenGoogle
        }).then(result => {
          setAuthTokens(result.data.data.token);
          setLoggedIn(true);
          MySwal.fire({
            icon: 'success',
            title: "Login Berhasil ^_^"
          }).then((result) => {
            history.push('/')
          })


        }).catch(e => {
          // setIsError(true);
          MySwal.fire({
            icon: 'error',
            title: e.response.data.message
          })
        });

      })
    }).catch(e => {
      // setIsError(true);
      MySwal.fire({
        icon: 'error',
        title: e.response.data.message
      })
    });

  };


  const handleSubmit = async e => {
    e.preventDefault();
    await axios.post(`${baseUrl}/auth/login`, {
      username,
      password
    }).then(result => {
      setAuthTokens(result.data.data.token);
      setLoggedIn(true);

      MySwal.fire({
        icon: 'success',
        title: "Login Berhasil"
      }).then((result) => {
        history.push('/')
      })

    }).catch(e => {
      MySwal.fire({
        icon: 'error',
        title: "Email atau Password yang kamu masukan salah!"
      })
    });
  };


  if (isLoggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div className={classes.MainLogin}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <div className={classes.sectionDesktop}>
            <img src={BgLogin} alt="" width="100%" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <div className={classes.sectionDesktop}>
                <img src={Logo} style={{ height: 80, width: 80 }} alt="" width="60px" />
              </div>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email / Handphone"
                  name="email"
                  autoComplete="off"
                  onChange={e => setUsername(e.target.value)}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="off"
                  onChange={e => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Masuk
                </Button>
                <Grid container>
                  <Grid item md={4}>
                    <Link href="/forgot/password" variant="body2">
                      Lupa Password?
                    </Link>
                  </Grid>
                  <Grid item md={8}>
                    <Link href="/register" variant="body2">
                      {"Belum punya akun? Daftar sekarang"}
                    </Link>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    {/* <div style={{marginTop:'20px'}}>
                      <FacebookLogin
                        appId="1463660717345120"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={responseFacebook}
                        render={renderProps => (
                          <Button
                          onClick={renderProps.onClick}
                          style={{background:'#0e15e0',color:'#fff', fontSize:'12px'}}
                          fullWidth
                          startIcon={<FacebookIcon/>}
                          >
                             Facebook
                          </Button>
                        )}
                       />
                  </div> */}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={{ marginTop: '20px' }}>
                      <GoogleLogin
                        clientId="129876100405-hlq4a0sehsm3g4442epqheedr5bbms8a.apps.googleusercontent.com"
                        // buttonText="Login With Google"
                        onSuccess={responseGoogle}
                        render={renderProps => (
                          <Button
                            variant="contained"
                            style={{ background: '#fff', fontSize: '12px' }}
                            fullWidth
                            onClick={renderProps.onClick}
                          >
                            <img src={GoogleIcon} width="15" style={{ marginRight: '5px' }} /> Google
                          </Button>
                        )}
                        onFailure={responseGoogle}
                      />
                    </div>
                  </Grid>
                </Grid>

              </form>
            </div>

          </Container>
        </Grid>

      </Grid>
    </div>
  );
}